import { swvTheme } from "../../../swvTheme";

export const styles = {
    profileContainer: {
        width: "100svw !important",
        height: "100svh !important",

        // needs important due to swvModalDefault class
        maxWidth: "unset !important",
        minWidth: "unset !important",

        marginTop: "40px",

        overflowX: 'hidden',
        overflowY: 'scroll',

        [swvTheme.breakpoints.up('md')]: {
            width: "clamp(150px, 950px, 100svw) !important",
            height: "fit-content !important",

            padding: "3rem 5rem !important",
        },
    },

    profileForm: {
        display: 'grid',
        justifyContent: 'center',
        gap: ".5rem 0",

        "& input": {
            borderColor: swvTheme.palette.secondary.gray + " !important",
            width: "550px",
        },

        [swvTheme.breakpoints.up('md')]: {
            "& div:nth-child(5)": {
                gridColumn: "1/2 !important",
            },
            "& div:nth-child(6)": {
                gridColumn: "3/4 !important",
            },

            "& div, span": {
                gridColumnStart: "1",
                gridColumnEnd: "4",
            },
        },
    },

    changeImgButton: {
        color: swvTheme.palette.button.dark,
        textAlign: "center",
        cursor: "pointer",

        [swvTheme.breakpoints.up('md')]: {
            gridColumnStart: "1",
            gridColumnEnd: "4",
        },

        "&:hover": {
            textDecoration: "underline"
        }
    },

    saveButton: {
        backgroundColor: swvTheme.palette.button.dark,

        "&:hover": {
            backgroundColor: swvTheme.palette.button.dark,
            filter: "brightness(0.8)"
        }
    },

    personAvatarContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    backToSearchButton: {
        m: '-50px 20px 0 -57px',
    },
    backToSearchButtonIcon: {
        position: 'relative',
        left: '-2px'
    },
    personAvatarInnerFlexWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    personAvatar: {
        width: '120px',
        height: '120px',
        '&:hover': {
            cursor: 'pointer',
        },
    },
};