import React from 'react';
import { Box } from '@mui/material';

import DecorativeEdgeImage from '../../../assets/icons/decorative-edge.svg';


const decorativeEdge = {
  zIndex: 2,
  width: {
    xs: '60px',
    sm: '118px',
  },
  height: {
    xs: '60px',
    sm: '118px',
  },
  background: `url("${DecorativeEdgeImage}") no-repeat`,
  backgroundSize: 'contain',
  position: 'absolute',
};

export const styles = {
  container: {
    position: 'relative',
  },
  leftTopEdge: {
    ...decorativeEdge,
    left: '0',
    top: 0,
    transform: 'rotate(-90deg)',
  },
  rightTopEdge: {
    ...decorativeEdge,
    top: 0,
    right: '0',
  },
  leftBottomEdge: {
    ...decorativeEdge,
    left: '0',
    bottom: 0,
    transform: 'rotate(180deg)',
  },
  rightBottomEdge: {
    ...decorativeEdge,
    right: '0',
    bottom: 0,
    transform: 'rotate(90deg)',
  },
};

export const DecorativeCorners = ({ children, sx }) => (
  <Box sx={{...styles.container, ...sx}}>
    <Box sx={styles.leftTopEdge} aria-hidden />
    <Box sx={styles.rightTopEdge} aria-hidden />
    <Box sx={styles.leftBottomEdge} aria-hidden />
    <Box sx={styles.rightBottomEdge} aria-hidden />

    {children}
  </Box>
);
