import React from 'react';
import { useEffect, useState } from 'react';
import { useForm, useWatch, FormProvider } from 'react-hook-form';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';


import * as endpoints from '../../../constants/endpoints';

import ModalWrapper from '../../common-components/ModalWrapper';

import * as PersonService from '../../../services/requests/person';
import * as AddressRegistrationService from '../../../services/requests/addressRegistration';
import * as MemberService from '../../../services/requests/member';
import * as CommunicationDataService from '../../../services/requests/communicationData';


import { useStyles } from './styles';
import { swvTheme } from '../../../swvTheme';

import { useSnackbarHelpers, questionPopup } from '../../../helpers/utils';
import { Messages } from '../../../constants/messages';

import { PersonSearch } from './components/PersonSearch';
import { ContactPermissionsPerson } from './components/ContactPermissionsPerson';
import { PersonDetails } from './components/PersonDetails';
import { AddressDetails } from './components/AddressDetails';
import { MembershipDetails } from './components/MembershipDetails';
import { FunctionList } from './components/FunctionList';
import { ContactPersonList } from './components/ContactPersonList';
import { CommunicationDataDetails } from './components/CommunicationDataDetails';
import { CommunicationDataDetailsPerson } from './components/CommunicationDataDetailsPerson';
import { ImageUploadPopup } from '../ImageUploadPopup';
import { urlIsLoadable } from '../../../helpers/utils';
import { getPhotoUrl } from '../../../helpers/database';

import { ContactPersonContext } from './PersonContext';
import Typography from '@mui/material/Typography';
import {
  ArrowBackIosNew,
  DeleteForever,
  Person as PersonIcon,
  Photo,
} from '@mui/icons-material';
import { Avatar } from '@mui/material';
import { chooseGender } from '../../../helpers';
import { ImagePreviewPopup } from '../ImagePreviewPopup';

export default function PersonPopUp({
  isOpen,
  onClose,
  chosenPerson,
  setChosenPerson,
  onPersonApply,
  onMemberApply,
  onContactPersonApply,
  chosenPersonCaller,
  companyData,
  onSubmit,
}) {
  const classes = useStyles();

  const [chosenContactPerson, setChosenContactPerson] = useState(null);
  const [isNewContactPerson, setIsNewContactPerson] = useState(false);
  const { showErrorSnackbar, showInfoSnackbar } = useSnackbarHelpers();
  const [personList, setPersonList] = useState(null);
  const [isShowFilter, setIsShowFilter] = useState(false);
  const [isPhotoUploadOpen, setIsPhotoUploadOpen] = useState(false);
  const [photoURL, setPhotoURL] = useState(null);
  const [isImagePreviewPopupOpen, setIsImagePreviewPopupOpen] = useState(false);


  const openPhotoUploadPopup = () => setIsPhotoUploadOpen(true);
  const closePhotoUploadPopup = () => setIsPhotoUploadOpen(false);


  const openImagePreviewPopup = () => {
    setIsImagePreviewPopupOpen(true);
  };

  const closeImagePreviewPopup = () => {
    setIsImagePreviewPopupOpen(false);
  };


  const showRow = (row) => {
    return (row.value !== null && row.value !== '' && row.value !== undefined) || !isShowFilter
  };

  const onSwitchChange = (event) => {
    setIsShowFilter(event.currentTarget.checked);
  };

  // person

  async function getPerson(id) {
    await PersonService.getOne(id, setChosenPerson);
  }

  const onPersonSearch = async (query) => {
    await PersonService.getList(
      //TODO: add pagination
      {
        Firstname: query?.FirstnameQuery || values.Firstname,
        Lastname: query?.LastnameQuery || values.Lastname,
      },
      (data) => {
        setPersonList(data.resultList)
      },
    );
  };

  const insertPerson = async () => {
    const data = {
      Photo: values.Photo || null,
      Title_Front: values.Title_Front || null,
      Title_Business: values.Title_Business || null,
      Firstname: values.Firstname,
      Lastname: values.Lastname,
      Title_Back: values.Title_Back || null,
      Birthdate: values.Birthdate || null,
      Gender: values.Gender || null,
      IsFamily: values.IsFamily,
      FK_Language: 1, // TODO: fixme
      IsDead: values.IsDead,
      Deathdate: values.Deathdate || null,
      IsPensioner: values.IsPensioner,
    };

    await PersonService.insertOne(data, (res) => {
      setChosenPerson(res);
      console.log(res)
      methods.reset({ ...values, ID: res.ID });
    });
  };

  const updatePerson = async (resetWarning) => {
    const data = {
      ID: values.ID,
      Photo: values.Photo,
      Title_Front: values.Title_Front,
      Title_Business: values.Title_Business,
      Firstname: values.Firstname,
      Lastname: values.Lastname,
      Title_Back: values.Title_Back,
      Birthdate: values.Birthdate,
      IsFamily: values.IsFamily,
      Gender: values.Gender,
      IsDead: values.IsDead,
      Deathdate: values.Deathdate,
      IsPensioner: values.IsPensioner,
    };

    await PersonService.updateOne(data, async (data) => {
      resetWarning();
    });
  };

  const deletePerson = (id) => {
    return new Promise((resolve, reject) => {
      PersonService.deleteOne(id, (error, result) => {
        if (error) {
          reject(error);
        } else {
          resolve(result);
        }
      });
    });
  };

  //AddressRegistration
  async function insertAddressRegistration(resetWarning) {
    const data = {
      FK_Person: chosenPerson.ID,
      County: values.County,
      FK_District: values.FK_District,
      Zipcode: values.Zipcode,
      City: values.City,
      Street: values.Street,
      Number: values.Number,
      Latitude: values.Latitude,
      Longitude: values.Longitude,
    };

    const result = await AddressRegistrationService.insertOne(data)
    if (result.isSuccess) {
      if (result.data) {
        chosenPerson.AddressRegistration = result.data;
      } else {
        showInfoSnackbar(Messages.ChangeProposalCreated);
      }
    } else {
      showErrorSnackbar(Messages.Error);
    }
    methods.reset({ ...values, AddressRegistrationID: data.ID });
    resetWarning();


  }

  async function updateAddressRegistration(resetWarning) {
    const data = {
      ID: values.AddressRegistrationID,
      County: values.County,
      FK_District: values.FK_District,
      Zipcode: values.Zipcode,
      City: values.City,
      Street: values.Street,
      Number: values.Number,
      Latitude: values.Latitude,
      Longitude: values.Longitude,
    };

    const result = await AddressRegistrationService.updateOne(data)
    if (result.isSuccess) {
      if (result.data) {
        showInfoSnackbar(Messages.Updated)
        await getPerson(chosenPerson?.ID);
      } else {
        showInfoSnackbar(Messages.ChangeProposalCreated)
      }
    } else {
      showErrorSnackbar(Messages.Error)
    }

    resetWarning();

  }

  // Member

  async function insertMember(resetWarning) {
    const data = {
      Type: values.MemberType,
      Number: values.MemberNumber,
      Start: values.MemberStart,
      End: values.MemberEnd,
      Fee: values.MemberFee,
      FK_Person: values.ID,
      FK_Recruiter: values.MemberRecruiter?.ID,
      PromoActivity: values.MemberPromoActivity,
      FK_Company: null,
      FK_Panel: null,
      FK_Organization: values.FK_Organization || chosenPerson?.Member?.FK_Organization,
    };

    await MemberService.insertOne(data, async (data) => {
      chosenPerson.Member = data;
      methods.reset({ ...values, MemberID: data.ID });
      resetWarning();
    });
  }

  async function updateMember(resetWarning) {
    const data = {
      ID: values.MemberID,
      Type: values.MemberType,
      Number: values.MemberNumber,
      Start: values.MemberStart,
      End: values.MemberEnd,
      Fee: values.MemberFee,
      FK_Recruiter: values.MemberRecruiter?.ID,
      PromoActivity: values.MemberPromoActivity,
    };

    await MemberService.updateOne(data, resetWarning);
  }

  // CommunicationData
  async function insertCommunicationData(resetWarning) {
    const data = {
      Fax: values.Fax,
      Telephone: values.Telephone,
      TelephonePrivate: values.TelephonePrivate,
      Mobile: values.Mobile,
      MobilePrivate: values.MobilePrivate,
      Email: values.Email,
      EmailEvent: values.EmailEvent,
      EmailFinancial: values.EmailFinancial,
      EmailNewsletter: values.EmailNewsletter,
      Homepage: values.Homepage,

      IsLetterApproval: values.IsLetterApproval,
      IsTelephoneApproval: values.IsTelephoneApproval,
      IsMobileApproval: values.IsMobileApproval,
      IsEmailApproval: values.IsEmailApproval,
      IsSocialMediaApproval: values.IsSocialMediaApproval,
      IsMessengerApproval: values.IsMessengerApproval,
      IsSMSApproval: values.IsSMSApproval,
      IsNewsletterApproval: values.IsNewsletterApproval,

      WhatsApp: values.WhatsApp,
      Telegram: values.Telegram,
      Messenger: values.Messenger,
      Signal: values.Signal,
      Xing: values.Xing,
      LinkedIn: values.LinkedIn,
      Facebook: values.Facebook,
      Instagram: values.Instagram,
      Twitter: values.Twitter,
      TikTok: values.TikTok,

      // ID: null,
      CompanyId: companyData?.ID ?? chosenContactPerson?.FK_Company,
      PersonId: values.ID,
      ContactPersonId: chosenContactPerson?.ID,

      Position: values.Position,
      Start: values?.Start || chosenContactPerson?.Start || null,
      End: values?.End || chosenContactPerson?.End || null,
    };

    const result = await CommunicationDataService.insertOne(data)
    if (result.isSuccess) {
      if (result.data) {
        setChosenContactPerson(result.data);
        methods.reset({ ...values, CommunicationDataID: result.data.CommunicationData.ID });
        showInfoSnackbar(Messages.Success);

        if (onContactPersonApply) {
          onContactPersonApply();
        }

      } else {
        showInfoSnackbar(Messages.ChangeProposalCreated);
      }
    } else {
      showErrorSnackbar(Messages.Error);
    }
    resetWarning();



  }

  async function updateCommunicationData(resetWarning) {
    const data = {
      Fax: values.Fax,
      Telephone: values.Telephone,
      TelephonePrivate: values.TelephonePrivate,
      Mobile: values.Mobile,
      MobilePrivate: values.MobilePrivate,
      Email: values.Email,
      EmailEvent: values.EmailEvent,
      EmailFinancial: values.EmailFinancial,
      EmailNewsletter: values.EmailNewsletter,
      Homepage: values.Homepage,

      IsLetterApproval: values.IsLetterApproval,
      IsTelephoneApproval: values.IsTelephoneApproval,
      IsMobileApproval: values.IsMobileApproval,
      IsEmailApproval: values.IsEmailApproval,
      IsSocialMediaApproval: values.IsSocialMediaApproval,
      IsMessengerApproval: values.IsMessengerApproval,
      IsSMSApproval: values.IsSMSApproval,
      IsNewsletterApproval: values.IsNewsletterApproval,

      WhatsApp: values.WhatsApp,
      Telegram: values.Telegram,
      Messenger: values.Messenger,
      Signal: values.Signal,
      Xing: values.Xing,
      LinkedIn: values.LinkedIn,
      Facebook: values.Facebook,
      Instagram: values.Instagram,
      Twitter: values.Twitter,
      TikTok: values.TikTok,

      Position: values.Position,
      Start: values.Start || null,
      End: values.End || null,

      ID: values.CommunicationDataID,
      PersonId: null,
      ContactPersonId: chosenContactPerson.ID,
    };

    const result = await CommunicationDataService.updateOne(data);

    if (result.isSuccess) {
      if (result.data) {
        setChosenContactPerson({
          ...chosenContactPerson,
          CommunicationData: result.data,
        });
        showInfoSnackbar(Messages.Success);
        await getPerson(chosenPerson?.ID);

        if (onContactPersonApply) {
          onContactPersonApply();
        }
        resetWarning();

      } else {
        showInfoSnackbar(Messages.ChangeProposalCreated);
      }
    } else {
      showErrorSnackbar(Messages.Error);
    }


  }

  // CommunicationDataPerson

  async function insertCommunicationDataPerson(resetWarning) {
    const data = {
      Fax: values.FaxPerson,
      Telephone: values.TelephonePerson,
      TelephonePrivate: values.TelephonePrivatePerson,
      Mobile: values.MobilePerson,
      MobilePrivate: values.MobilePrivatePerson,
      Email: values.EmailPerson,
      EmailEvent: values.EmailEventPerson,
      EmailFinancial: values.EmailFinancialPerson,
      EmailNewsletter: values.EmailNewsletterPerson,
      Homepage: values.HomepagePerson,

      IsLetterApproval: values.IsLetterApprovalPerson,
      IsTelephoneApproval: values.IsTelephoneApprovalPerson,
      IsMobileApproval: values.IsMobileApprovalPerson,
      IsEmailApproval: values.IsEmailApprovalPerson,
      IsSocialMediaApproval: values.IsSocialMediaApprovalPerson,
      IsMessengerApproval: values.IsMessengerApprovalPerson,
      IsSMSApproval: values.IsSMSApprovalPerson,
      IsNewsletterApproval: values.IsNewsletterApprovalPerson,

      WhatsApp: values.WhatsAppPerson,
      Telegram: values.TelegramPerson,
      Messenger: values.MessengerPerson,
      Signal: values.SignalPerson,
      Xing: values.XingPerson,
      LinkedIn: values.LinkedInPerson,
      Facebook: values.FacebookPerson,
      Instagram: values.InstagramPerson,
      Twitter: values.TwitterPerson,
      TikTok: values.TikTokPerson,

      // ID: null,
      CompanyId: null,
      PersonId: values.ID,
      ContactPersonId: null,
    };

    const result = await CommunicationDataService.insertOne(data)
    if (result.isSuccess) {
      if (result.data) {
        chosenPerson.CommunicationData = result.data;
        methods.reset({ ...values, CommunicationDataIDPerson: result.data.ID });
        showInfoSnackbar(Messages.Success);

        resetWarning();
      } else {
        showInfoSnackbar(Messages.ChangeProposalCreated);
      }
    } else {
      showErrorSnackbar(Messages.Error);
    }

  }

  async function updateCommunicationDataPerson(resetWarning) {
    const data = {
      Fax: values.FaxPerson,
      Telephone: values.TelephonePerson,
      TelephonePrivate: values.TelephonePrivatePerson,
      Mobile: values.MobilePerson,
      MobilePrivate: values.MobilePrivatePerson,
      Email: values.EmailPerson,
      EmailEvent: values.EmailEventPerson,
      EmailFinancial: values.EmailFinancialPerson,
      EmailNewsletter: values.EmailNewsletterPerson,
      Homepage: values.HomepagePerson,

      IsLetterApproval: values.IsLetterApprovalPerson,
      IsTelephoneApproval: values.IsTelephoneApprovalPerson,
      IsMobileApproval: values.IsMobileApprovalPerson,
      IsEmailApproval: values.IsEmailApprovalPerson,
      IsSocialMediaApproval: values.IsSocialMediaApprovalPerson,
      IsMessengerApproval: values.IsMessengerApprovalPerson,
      IsSMSApproval: values.IsSMSApprovalPerson,
      IsNewsletterApproval: values.IsNewsletterApprovalPerson,

      WhatsApp: values.WhatsAppPerson,
      Telegram: values.TelegramPerson,
      Messenger: values.MessengerPerson,
      Signal: values.SignalPerson,
      Xing: values.XingPerson,
      LinkedIn: values.LinkedInPerson,
      Facebook: values.FacebookPerson,
      Instagram: values.InstagramPerson,
      Twitter: values.TwitterPerson,
      TikTok: values.TikTokPerson,

      ID: values.CommunicationDataIDPerson,
      PersonId: chosenPerson.ID,
      ContactPersonId: null,
    };

    const result = await CommunicationDataService.updateOne(data);

    if (result.isSuccess) {
      if (result.data) {
        setChosenPerson({
          ...chosenPerson,
          CommunicationData: result.data,
        });

        showInfoSnackbar(Messages.Success)
        resetWarning();
      } else {
        showInfoSnackbar(Messages.ChangeProposalCreated)
      }
    } else {
      showErrorSnackbar(Messages.Error)
    }



  }

  const methods = useForm({
    defaultValues: null,
  });

  const values = useWatch({ control: methods.control });

  useEffect(() => {
    setIsNewContactPerson(false);
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      if (chosenPerson) {
        const defaultValues = {
          ID: chosenPerson.ID,
          isModified: false,
          Photo: chosenPerson.Photo,
          Title_Front: chosenPerson.Title_Front,
          Title_Business: chosenPerson.Title_Business,
          Firstname: chosenPerson.Firstname,
          Lastname: chosenPerson.Lastname,
          Title_Back: chosenPerson.Title_Back,
          Birthdate: chosenPerson.Birthdate,
          IsFamily: chosenPerson.IsFamily,
          Gender: chosenPerson.Gender,
          IsDead: chosenPerson.IsDead,
          Deathdate: chosenPerson.Deathdate,
          IsPensioner: chosenPerson.IsPensioner,

          AddressRegistrationID: chosenPerson.AddressRegistration?.ID,
          County: chosenPerson.AddressRegistration?.County,
          FK_District: chosenPerson.AddressRegistration?.FK_District,
          Zipcode: chosenPerson.AddressRegistration?.Zipcode,
          City: chosenPerson.AddressRegistration?.City,
          Street: chosenPerson.AddressRegistration?.Street,
          Number: chosenPerson.AddressRegistration?.Number,
          Longitude: chosenPerson.AddressRegistration?.Longitude,
          Latitude: chosenPerson.AddressRegistration?.Latitude,

          CommunicationDataIDPerson: chosenPerson?.CommunicationData?.ID,
          FaxPerson: chosenPerson.CommunicationData?.Fax,
          TelephonePerson: chosenPerson.CommunicationData?.Telephone,
          TelephonePrivatePerson: chosenPerson.CommunicationData?.TelephonePrivate,
          MobilePerson: chosenPerson.CommunicationData?.Mobile,
          MobilePrivatePerson: chosenPerson.CommunicationData?.MobilePrivate,
          EmailPerson: chosenPerson.CommunicationData?.Email,
          EmailEventPerson: chosenPerson.CommunicationData?.EmailEvent,
          EmailFinancialPerson: chosenPerson.CommunicationData?.EmailFinancial,
          EmailNewsletterPerson: chosenPerson.CommunicationData?.EmailNewsletter,
          HomepagePerson: chosenPerson.CommunicationData?.Homepage,

          IsLetterApprovalPerson: chosenPerson.CommunicationData?.IsLetterApproval ?? true,
          IsTelephoneApprovalPerson: chosenPerson.CommunicationData?.IsTelephoneApproval ?? true,
          IsMobileApprovalPerson: chosenPerson.CommunicationData?.IsMobileApproval ?? true,
          IsEmailApprovalPerson: chosenPerson.CommunicationData?.IsEmailApproval ?? true,
          IsSocialMediaApprovalPerson: chosenPerson.CommunicationData?.IsSocialMediaApproval ?? true,
          IsMessengerApprovalPerson: chosenPerson.CommunicationData?.IsMessengerApproval ?? true,
          IsSMSApprovalPerson: chosenPerson.CommunicationData?.IsSMSApproval ?? true,
          IsNewsletterApprovalPerson: chosenPerson.CommunicationData?.IsNewsletterApproval ?? true,

          WhatsAppPerson: chosenPerson.CommunicationData?.WhatsApp,
          TelegramPerson: chosenPerson.CommunicationData?.Telegram,
          MessengerPerson: chosenPerson.CommunicationData?.Messenger,
          SignalPerson: chosenPerson.CommunicationData?.Signal,
          XingPerson: chosenPerson.CommunicationData?.Xing,
          LinkedInPerson: chosenPerson.CommunicationData?.LinkedIn,
          FacebookPerson: chosenPerson.CommunicationData?.Facebook,
          InstagramPerson: chosenPerson.CommunicationData?.Instagram,
          TwitterPerson: chosenPerson.CommunicationData?.Twitter,
          TikTokPerson: chosenPerson.CommunicationData?.TikTok,

          MemberID: chosenPerson.Member?.ID,
          MemberType: chosenPerson.Member?.Type,
          MemberNumber: chosenPerson.Member?.Number,
          MemberRecruiter: chosenPerson.Member?.Recruiter,
          MemberPromoActivity: chosenPerson.Member?.PromoActivity,
          MemberStart: chosenPerson.Member?.Start || null,
          MemberEnd: chosenPerson.Member?.End || null,
          MemberFee: chosenPerson.Member?.Fee,
          FK_Company: null,
          FK_Panel: null,
          FK_Organization: chosenPerson.Member?.FK_Organization,

          CommunicationDataID: chosenContactPerson?.CommunicationData?.ID,
          Fax: chosenContactPerson?.CommunicationData?.Fax,
          Telephone: chosenContactPerson?.CommunicationData?.Telephone,
          TelephonePrivate: chosenContactPerson?.CommunicationData?.TelephonePrivate,
          Mobile: chosenContactPerson?.CommunicationData?.Mobile,
          MobilePrivate: chosenContactPerson?.CommunicationData?.MobilePrivate,
          Email: chosenContactPerson?.CommunicationData?.Email,
          EmailEvent: chosenContactPerson?.CommunicationData?.EmailEvent,
          EmailFinancial: chosenContactPerson?.CommunicationData?.EmailFinancial,
          EmailNewsletter: chosenContactPerson?.CommunicationData?.EmailNewsletter,
          Homepage: chosenContactPerson?.CommunicationData?.Homepage,

          IsLetterApproval: chosenContactPerson?.CommunicationData?.IsLetterApproval ?? true,
          IsTelephoneApproval: chosenContactPerson?.CommunicationData?.IsTelephoneApproval ?? true,
          IsMobileApproval: chosenContactPerson?.CommunicationData?.IsMobileApproval ?? true,
          IsEmailApproval: chosenContactPerson?.CommunicationData?.IsEmailApproval ?? true,
          IsSocialMediaApproval: chosenContactPerson?.CommunicationData?.IsSocialMediaApproval ?? true,
          IsMessengerApproval: chosenContactPerson?.CommunicationData?.IsMessengerApproval ?? true,
          IsSMSApproval: chosenContactPerson?.CommunicationData?.IsSMSApproval ?? true,
          IsNewsletterApproval: chosenContactPerson?.CommunicationData?.IsNewsletterApproval ?? true,

          WhatsApp: chosenContactPerson?.CommunicationData?.WhatsApp,
          Telegram: chosenContactPerson?.CommunicationData?.Telegram,
          Messenger: chosenContactPerson?.CommunicationData?.Messenger,
          Signal: chosenContactPerson?.CommunicationData?.Signal,
          Xing: chosenContactPerson?.CommunicationData?.Xing,
          LinkedIn: chosenContactPerson?.CommunicationData?.LinkedIn,
          Facebook: chosenContactPerson?.CommunicationData?.Facebook,
          Instagram: chosenContactPerson?.CommunicationData?.Instagram,
          Twitter: chosenContactPerson?.CommunicationData?.Twitter,
          TikTok: chosenContactPerson?.CommunicationData?.TikTok,

          ContactPersonId: chosenContactPerson?.ID,
          Position: chosenContactPerson?.Position,
          Start: chosenContactPerson?.Start,
          End: chosenContactPerson?.End,
          FirstnameQuery: values.FirstnameQuery,
          LastnameQuery: values.LastnameQuery,
        };

        methods.reset(defaultValues);

        urlIsLoadable(getPhotoUrl(chosenPerson), setPhotoURL);
      }
    }
  }, [chosenPerson, chosenContactPerson]);

  useEffect(() => {
    const existingContactPerson = chosenPerson?.ContactPerson?.find(
      (o) => o.FK_Company == companyData?.ID,
    );

    if (existingContactPerson) {
      if (chosenContactPerson?.ID != existingContactPerson?.ID) {
        setChosenContactPerson(existingContactPerson);
      }
    }
  }, [chosenPerson]);

  const onRowClick = async (row) => {
    setChosenContactPerson(null);
    setIsNewContactPerson(false);
    await getPerson(row.ID);
  };

  const handleNewContactPersonClick = () => {
    if (
      chosenPerson.ContactPerson?.find((o) => o.FK_Company == companyData?.ID)
    ) {
      showErrorSnackbar(Messages.ErrorContactPerson);
    } else {
      methods.reset({
        ...values,

        CommunicationDataID: null,
        Fax: null,
        Telephone: null,
        TelephonePrivate: null,
        Mobile: null,
        MobilePrivate: null,
        Email: null,
        EmailEvent: null,
        EmailFinancial: null,
        EmailNewsletter: null,
        Homepage: null,

        IsLetterApproval: true,
        IsEmailApproval: true,
        IsTelephoneApproval: true,
        IsMobileApproval: true,
        IsSMSApproval: true,
        IsSocialMediaApproval: true,
        IsMessengerApproval: true,
        IsNewsletterApproval: true,

        WhatsApp: null,
        Telegram: null,
        Messenger: null,
        Signal: null,
        Xing: null,
        LinkedIn: null,
        Facebook: null,
        Instagram: null,
        Twitter: null,
        TikTok: null,

        Position: null,
        Start: null,
        End: null,
        isModified: false
      });

      setChosenContactPerson(null);
      setIsNewContactPerson(true);
    }
  };

  const updatePhoto = async (base64EncodedPhotos) => {
    values.Photo = base64EncodedPhotos ? base64EncodedPhotos[0] : '';
    setPhotoURL(base64EncodedPhotos ? base64EncodedPhotos[0] : '');

    if (values.ID) {
      const data = {
        ID: values.ID,
        Photo: base64EncodedPhotos ? base64EncodedPhotos[0] : '',
      };

      await PersonService.updateOne(data, async (data) => {
        chosenPerson.Photo = data.Photo;
      });
    }

    closePhotoUploadPopup();
  };

  const deletePhoto = () => {
    updatePhoto(null);
  };

  useEffect(() => {
    if (!isOpen) {
      const defaultValues = {
        ID: '',
        Photo: '',
        Title_Front: '',
        Title_Business: '',
        Firstname: '',
        Lastname: '',
        Title_Back: '',
        Birthdate: '',
        IsFamily: '',
        Gender: '',
        IsDead: false,
        Deathdate: '',
        IsPensioner: false,
      };
      setChosenContactPerson(null);
      setPersonList(null);
      methods.reset(defaultValues);
    }
  }, [isOpen]);

  const onNewButtonClick = () => {
    setChosenPerson({
      ID: '',
      Photo: '',
      Title_Front: '',
      Title_Business: '',
      Firstname: values.Firstname,
      Lastname: values.Lastname,
      Title_Back: '',
      Birthdate: '',
      IsFamily: '',
      Gender: '',
      IsDead: false,
      Deathdate: '',
      IsPensioner: false,
    });
  };

  return (
    <ContactPersonContext.Provider
      value={{
        setChosenContactPerson,
        chosenContactPerson,
        chosenPerson,
        handleNewContactPersonClick,
        companyData,
        showRow,
      }}
    >
      <ModalWrapper
        isOpen={isOpen}
        onClose={onClose}
        title=''
        className={classes.personWrapper}
      >
        <FormProvider {...methods}>
          {
            !chosenPerson && (
              <PersonSearch
                onSearch={onPersonSearch}
                onRowClick={onRowClick}
                onNewButtonClick={onNewButtonClick}
                deletePerson={deletePerson}
                chosenPerson={chosenPerson}
                personList={personList}
                companyData={companyData}
              />
            )
          }
          {chosenPerson && (
            <Box className={classes.personInfoTable}>
              <Card
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  p: 2,
                  [swvTheme.breakpoints.up('md')]: {
                    justifyContent: 'start',
                  },
                }}
              >
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                  <IconButton
                    fontSize={'small'}
                    onClick={() => {
                      setChosenPerson(null);
                      methods.reset({
                        ...(personList && {
                          FirstnameQuery: values.FirstnameQuery,
                          LastnameQuery: values.LastnameQuery
                        })
                      });
                    }}>
                    <ArrowBackIosNew
                      fontSize={'small'}
                      sx={{}}
                    />
                  </IconButton>

                  <Avatar
                    src={photoURL}
                    onClick={openImagePreviewPopup}
                    classname={classes.personAvatar}
                  >
                    <PersonIcon />
                  </Avatar>
                </Box>
                <Typography variant="h1" fontSize={20} pl={1} pr={3}>
                  {(chosenPerson?.Title_Front ?? '') +
                    ' ' +
                    (chosenPerson?.Title_Business ?? '') +
                    ' ' +
                    (chosenPerson?.Firstname ?? '') +
                    ' ' +
                    (chosenPerson?.Lastname ?? '') +
                    ' ' +
                    (chosenPerson?.Title_Back ?? '')
                  }
                </Typography>
                {chosenPerson.ID &&
                  <IconButton style={{ marginRight: '0px', marginLeft: 'auto' }}
                    onClick={() => {
                      deletePerson(chosenPerson.ID)
                        .then(() => {
                          setChosenPerson(null)
                        }).then(() => {
                          onPersonSearch()
                        })
                        .catch(error => {
                          console.log('Error during deletion:', error)
                        })
                    }}
                  >
                    <DeleteForever style={{ color: 'red' }} />
                  </IconButton>
                }
              </Card>
              <Box>
                <ImageUploadPopup
                  isOpen={isPhotoUploadOpen}
                  onClose={closePhotoUploadPopup}
                  onSave={updatePhoto}
                />
                <ImagePreviewPopup
                  onClose={closeImagePreviewPopup}
                  isOpen={isImagePreviewPopupOpen}
                  imageToPreview={photoURL}
                  fallbackImage={<PersonIcon />}
                  onDeleteImage={deletePhoto}
                  onChangeImage={openPhotoUploadPopup}
                />
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  justifyContent="center"
                  mt={5}
                  mb={2}
                >
                  <Typography color={swvTheme.typography.lilac}>
                    alle Daten
                  </Typography>
                  <Switch
                    onChange={onSwitchChange}
                    sx={{
                      '& .Mui-checked+.MuiSwitch-track': {
                        backgroundColor: '#732382 !important',
                      },
                      '& .Mui-checked .MuiSwitch-thumb': {
                        color: '#732382',
                      },
                    }}
                  />
                  <Typography color={swvTheme.typography.lilac}>
                    befüllte Daten
                  </Typography>
                </Stack>
                <Card className={classes.personDetailsSection}>
                  <PersonDetails
                    chosenPersonCaller={chosenPersonCaller}
                    insertPerson={insertPerson}
                    updatePerson={updatePerson}
                    onPersonApply={onPersonApply}
                    companyData={companyData}
                  />
                </Card>
                <Card className={classes.personDetailsSection}>
                  <AddressDetails
                    insertAddressRegistration={insertAddressRegistration}
                    updateAddressRegistration={updateAddressRegistration}
                  />
                </Card>

                <Card className={classes.personDetailsSection}>
                  <CommunicationDataDetailsPerson
                    updateCommunicationDataPerson={updateCommunicationDataPerson}
                    insertCommunicationDataPerson={insertCommunicationDataPerson}
                  />
                </Card>
                <Card className={classes.personDetailsSection}>
                  <ContactPermissionsPerson
                    updateCommunicationDataPerson={updateCommunicationDataPerson}
                    insertCommunicationDataPerson={insertCommunicationDataPerson}
                  />
                </Card>
                <Card className={classes.personDetailsSection}>
                  <MembershipDetails
                    chosenPerson={chosenPerson}
                    insertMember={insertMember}
                    updateMember={updateMember}
                    onMemberApply={onMemberApply}
                  />
                </Card>
                <FunctionList />
                <ContactPersonList />

                {(chosenContactPerson || isNewContactPerson) && (
                  <Card className={classes.personDetailsSection}>
                    <CommunicationDataDetails
                      updateCommunicationData={updateCommunicationData}
                      insertCommunicationData={insertCommunicationData}
                    />
                  </Card>
                )}
              </Box>
            </Box>
          )}
        </FormProvider>
      </ModalWrapper>
    </ContactPersonContext.Provider>
  );
}
