import React from 'react';

export default function Success() {
  return (
    <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M50.0006 91.6666C73.0125 91.6666 91.6673 73.0118 91.6673 49.9999C91.6673 26.9881 73.0125 8.33325 50.0006 8.33325C26.9888 8.33325 8.33398 26.9881 8.33398 49.9999C8.33398 73.0118 26.9888 91.6666 50.0006 91.6666Z"
        stroke="#14A550"
        strokeLinecap="square"
      />
      <path d="M29.166 53.0877L40.6618 64.5834L69.856 35.4167" stroke="#14A550" />
    </svg>
  );
}
