import * as endpoints from '../../constants/endpoints';
import { apiClient } from '../apiClient';

export const getList = async (companyId, success, error) => {
  const response = await apiClient.getListNew(endpoints.getFileList, null,  { companyId }, success, error);
  return response.data.data;
};

export const getPhotoList = async (companyId, success, error) => {
  const response = await apiClient.getListNew(endpoints.getPhotoList, null, { companyId }, success, error);
  return response.data.data;
};

export const insertOne = async (data, success, error) => {
  const response = await apiClient.postForm(endpoints.insertFile, data, success, error);
  return response.data;
};

export const deleteOne = async (id, success, error) => {
  const response = await apiClient.deleteOne(endpoints.deleteFile, id, success, error);
  return response.data;
};

export const downloadOne = async (id, filename, success, error) => {
  return await apiClient.downloadOne(endpoints.downloadFile, id, filename, success, error);
};

export const getBase64Uri = async (id, filename, success, error) => {
  return await apiClient.getBase64Uri(endpoints.getBase64Uri, id, filename, success, error);
};

