import React from 'react';
import TableCell from '@mui/material/TableCell';
import { ReactComponent as CheckIcon } from '../../../assets/icons/check.svg';
import { useStyles } from './styles';

export default function CheckCell({ flag }) {
  const classes = useStyles();

  return <TableCell align="center">{flag ? <CheckIcon /> : ''}</TableCell>;
}
