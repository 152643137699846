import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQuery } from "./basequery";
import * as endpoints from "../../constants/endpoints";

export const TreeViewAPI = createApi({
    reducerPath: "TreeView",
    baseQuery,
    tagTypes: ['TreeViewRoots'],
    endpoints: (build) => ({
        getList: build.query({
            query: () => ({
                url: endpoints.treeViewController,
                method: 'GET',
            }),
            providesTags: ['GoalStatusEnum']
        }),
        getFoundCompaniesList: build.query({
            query: (nodeIds) => ({
                url: endpoints.treeViewControllerGetFoundCompanies,
                method: 'GET',
                params: { nodeIds }
            })
        })
    }),
});

export const { useGetListQuery, useGetFoundCompaniesListQuery } = TreeViewAPI