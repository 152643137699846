import React, { useCallback, useState, useEffect } from 'react';

import { Box, Typography, Card, CardContent } from '@mui/material';
import { ArrowForward, AssignmentTurnedInOutlined } from '@mui/icons-material';

import BoxCenter from '../BoxCenter';
import * as GoalStatusService from '../../../services/requests/goalStatus';
import { chooseHardGoal } from '../../../helpers/database';
import FormLink from '../FormLink';
import { swvTheme } from '../../../swvTheme';
import { useStyles } from './styles';
import UserGoalPopUp from '../../popups/UserGoal/UserGoal';
import { useNavigate } from 'react-router-dom';

export default function GoalList() {
  const classes = useStyles();

  const [goalStatusList, setGoalStatusList] = useState(null);
  const [maxGoals, setMaxGoals] = useState(3);
  const [selectedGoal, setSelectedGoal] = useState(null);

  const [isUserGoalModalOpen, setIsUserGoalModalOpen] = useState(false);

  const currentUser = JSON.parse(localStorage.getItem('userData'));

  useEffect(() => {
    if (currentUser?.ID && !isUserGoalModalOpen) {
      getGoalStatusList(currentUser?.ID);
    }
  }, [currentUser?.ID, isUserGoalModalOpen]);

  const getGoalStatusList = useCallback(
    async (userId) => {
      await GoalStatusService.getList(userId, setGoalStatusList);
    },
    [goalStatusList],
  );

  // Goals modal
  const onUserGoalClicked = (goal) => {
    setIsUserGoalModalOpen(true);
    setSelectedGoal(goal);
  };

  const onUserGoalModalClose = () => {
    setIsUserGoalModalOpen(false);
  };

  const navigate = useNavigate();

  return (
    <Box
      width="32%"
      mb={7}
      sx={{
        [swvTheme.breakpoints.down('md')]: {
          width: 'auto',
        },
      }}
    >
      <Card
        sx={{
          borderRadius: '8px',
          backgroundImage:
            'linear-gradient(rgba(245, 0, 0, 1), rgba(115, 35, 130, 1))',
          color: '#FFFFFF',
          cursor: 'pointer',
        }}
      // onClick={onUserGoalClicked}
      >
        <CardContent
          sx={{
            '&.MuiCardContent-root': {
              padding: '16px',
            },
          }}
        >
          <Typography
            fontWeight={700}
            textTransform="uppercase"
            fontSize={14}
          >
            Meine Ziele
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'baseline',
            }}
          >
            <Typography fontWeight={700} fontSize={96} sx={{ lineHeight: 'initial' }}>
              {goalStatusList?.length && Math.round(goalStatusList?.filter(o => o.Goal.ActualValueAll ? (o.Goal.ActualValueAll >= o.Goal.Setpoint) : (o.ActualValue >= o.Goal.Setpoint)).length / goalStatusList?.length * 100)}
            </Typography>
            <Typography fontWeight={700} fontSize={20}>
              %
            </Typography>
          </Box>
          <Typography textAlign="center" fontSize={16}>
            Du hast <span style={{ fontWeight: '700' }}>{goalStatusList?.filter(o => o.Goal.ActualValueAll ? (o.Goal.ActualValueAll >= o.Goal.Setpoint) : (o.ActualValue >= o.Goal.Setpoint)).length}</span> von {goalStatusList?.length} {goalStatusList?.length !== 1 ? 'geplanten Zielen' : 'geplanten Ziel'} erreicht!
          </Typography>
        </CardContent>
      </Card>

      <Box mt={4}>
        <Typography mb={1} fontSize={18}>
          Meine offenen Ziele
        </Typography>
        <Box gap={2} display="flex" flexDirection="column">
          {goalStatusList?.filter(o => o.Goal.ActualValueAll ? (o.Goal.ActualValueAll < o.Goal.Setpoint) : (o.ActualValue < o.Goal.Setpoint)).slice(0, maxGoals).map((row) => (
            <Card
              key={row.ID}
              onClick={() => onUserGoalClicked(row)}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                backgroundColor: '#F9E1E1',
                cursor: 'pointer',
              }}
            >
              <Box display="grid" alignItems="center" gridTemplateColumns={"auto 1fr auto"}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  height={54}
                  width={54}
                  backgroundColor="#FFFFFF"
                  borderRadius="50%"
                  margin="13px"
                  color="#F50000"
                >
                  <AssignmentTurnedInOutlined />
                </Box>
                <CardContent
                  sx={{
                    '&.MuiCardContent-root': {
                      padding: '0',
                    },
                  }}
                >
                  <Typography fontWeight={700}>
                    {chooseHardGoal(row.Goal?.Hard) || row.Goal?.Soft}
                  </Typography>
                </CardContent>
              </Box>
              <ArrowForward
                fontSize="small"
                sx={{
                  margin: '24px',
                  color: '#F50000',
                }}
              />
            </Card>
          ))}
        </Box>
        {maxGoals >= goalStatusList?.length ? null : (
          <BoxCenter sx={{ flexDirection: 'row' }}>
            <FormLink
              onClick={() => {
                navigate('/ziele', { replace: false });
              }}
              color={swvTheme.typography.p}
            >
              Alle Aufgaben ansehen
            </FormLink>
            <ArrowForward fontSize="small" />
          </BoxCenter>
        )}
      </Box>
      <UserGoalPopUp
        isOpen={isUserGoalModalOpen}
        onClose={onUserGoalModalClose}
        selectedGoal={selectedGoal}
      />
    </Box>
  );
}
