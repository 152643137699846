import React, { useEffect, useCallback, useState } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Container from '@mui/material/Container';

import { useSnackbarHelpers } from '../../../helpers/utils';

import TextField from '@mui/material/TextField';

import * as GoalStatusService from '../../../services/requests/goalStatus';
import { chooseHardGoal } from '../../../helpers/database';

import BoxCenter from '../../common-components/BoxCenter';

import { useStyles } from './styles';
import { useGlobalStyles } from '../../../customStyles';

import { getDate } from '../../../helpers/database';
import { swvTheme } from '../../../swvTheme';
import CustomButton from '../../common-components/CustomButton';
import { GoalStatusAPI } from '../../../redux/services/GoalStatusService';
import CustomFormSelect from '../../common-components/CustomFormSelect/CustomFormSelect';
import { useForm } from 'react-hook-form';

export default function UserGoalRow({ currentUser, selectedGoal, onChange }) {
  const classes = useStyles();
  const cssClass = useGlobalStyles();

  const [edited, setEdited] = useState([]);
  const [goalStatusList, setGoalStatusList] = useState(null);
  const [selectedGoalStatus, setSelectedGoalStatus] = useState(null);

  const { data: goalStatusEnum } =
    GoalStatusAPI.useGetListEnumQuery(undefined);

  useEffect(() => {
    if (selectedGoal) {
      setGoalStatusList([selectedGoal]);
    } else if (currentUser?.ID) {
      getGoalStatusList(currentUser?.ID);
    }
  }, [currentUser?.ID]);

  const getGoalStatusList = useCallback(
    async (userId) => {
      await GoalStatusService.getList(userId, setGoalStatusList);
    },
    [goalStatusList],
  );

  const handleRowClick = (selectedGoal) => {
    setSelectedGoalStatus(selectedGoal);
  };

  const { register, handleSubmit, control, reset, getValues, setValue, formState: { errors }, watch } = useForm({
    defaultValues: {
      Status: selectedGoal?.Goal?.ActualValueAll ?? (selectedGoal?.ActualValue ?? 0),
    },
    values: {
      Status: selectedGoal?.Goal?.ActualValueAll ?? (selectedGoal?.ActualValue ?? 0)
    }
  });
  const formValues = watch(); // Get all form values

  useEffect(() => {
    console.log("formValues.Status", formValues.Status, "selectedGoal.ActualValue", selectedGoal.ActualValue)
    if (formValues.Status !== selectedGoal.ActualValue) {
      onChange({ ...selectedGoal, ActualValue: formValues.Status, Goal: { ...selectedGoal.Goal, ActualValueAll: formValues.Status } })
    }
  }, [formValues.Status]);


  return (
    <TableRow
      key={selectedGoal.ID}
      selected={selectedGoalStatus?.ID == selectedGoal.ID}
      onClick={() => handleRowClick(selectedGoal)}
    >
      <TableCell>
        {chooseHardGoal(selectedGoal.Goal?.Hard) || selectedGoal.Goal?.Soft}
      </TableCell>
      <TableCell>{getDate(selectedGoal.Goal?.Start)}</TableCell>
      <TableCell>{getDate(selectedGoal.Goal?.End)}</TableCell>
      <TableCell>{goalStatusEnum?.find(gs => gs.Type === "Formular" && gs.Status === (selectedGoal.Goal.Setpoint))?.Title}</TableCell>
      <TableCell>
        <CustomFormSelect
          fullWidth={true}
          name="Status"
          label="Status"
          disabled={false}
          options={goalStatusEnum}
          valueKey='Status'
          titleKey='Title'
          control={control}
          required
          chooseAll={false}
        />
        {/* goalStatusEnum?.find(gs => gs.Type === "Formular" && gs.Status === (selectedGoal.Goal.ActualValueAll ?? selectedGoal.ActualValue))?.Title */}
      </TableCell>
    </TableRow>
  );
}
