import React, { useState, useContext } from 'react';

import ModalWrapper from '../../common-components/ModalWrapper';
import BoxCenter from '../../common-components/BoxCenter';
import CustomButton from '../../common-components/CustomButton';

import ElectorialListPopup from '../ElectorialList';
import VotingCardPopUp from '../VotingCard';
import SupportStatementPopUp from '../SupportStatement';
import ConsentSupportStatementPopUp from '../ConsentSupportStatement';

import { CompanyContext } from '../../pages/Company/CompanyContext';
import { useGlobalStyles } from '../../../customStyles';
import { useStyles } from './styles';
import { Grid } from '@mui/material';
import { FormSendingContext } from '../../pages/FormSending/FormSendingContext';

export default function CreateFormForMultipleCompaniesPopUp({ isOpen, onClose }) {
  const classes = useStyles();
  const cssClass = useGlobalStyles();

  const { selectedCompanies } = useContext(FormSendingContext);

  // ElectorialListModal

  const [isElectorialListModalOpen, setIsElectorialListModalOpen] =
    useState(false);

  const onElectorialListModalOpen = async () => {
    setIsElectorialListModalOpen(true);
  };

  const onElectorialListModalClose = async () => {
    setIsElectorialListModalOpen(false);
  };

  // VotingCardModal

  const [isVotingCardModalOpen, setIsVotingCardModalOpen] = useState(false);

  const onVotingCardModalOpen = async () => {
    setIsVotingCardModalOpen(true);
  };

  const onVotingCardModalClose = async () => {
    setIsVotingCardModalOpen(false);
  };

  // SupportStatementModal

  const [isSupportStatementModalOpen, setIsSupportStatementModalOpen] =
    useState(false);

  const onSupportStatementModalOpen = async () => {
    setIsSupportStatementModalOpen(true);
  };

  const onSupportStatementModalClose = async () => {
    setIsSupportStatementModalOpen(false);
  };

  // onConsentSupportStatementModal

  const [
    isConsentSupportStatementModalOpen,
    setIsConsentSupportStatementModalOpen,
  ] = useState(false);

  const onConsentSupportStatementModalOpen = async () => {
    setIsConsentSupportStatementModalOpen(true);
  };

  const onConsentSupportStatementModalClose = async () => {
    setIsConsentSupportStatementModalOpen(false);
  };

  return (
    <>
      <ModalWrapper
        isOpen={isOpen}
        onClose={onClose}
        title="Formular erstellen"
        className={cssClass.swvModalResponsiveMods + " " + classes.wrapper}
      >
        <BoxCenter sx={{ gap: 2, mt: 4 }}>
          <CustomButton
            value="Wählerliste"
            largeBorderRadius
            sx={{ width: '300px !important', fontSize: '14px' }}
            onClick={() => {
              onElectorialListModalOpen()
            }}
          />
        </BoxCenter>

        <BoxCenter sx={{ gap: 2, mt: 4 }}>
          <CustomButton
            value="Wahlkarte"
            largeBorderRadius
            sx={{ width: '300px !important', fontSize: '14px' }}
            onClick={() => {
              onVotingCardModalOpen()
            }}
          />
        </BoxCenter>

        <BoxCenter sx={{ gap: 2, my: 4 }}>
          <CustomButton
            value="Unterstützungserklärung"
            largeBorderRadius
            sx={{ width: '300px !important', fontSize: '14px' }}
            onClick={() => {
              onSupportStatementModalOpen()
            }}
          />
        </BoxCenter>

        <BoxCenter sx={{ gap: 2, my: 4 }}>
          <CustomButton
            value="Zustimmungserklärung"
            largeBorderRadius
            sx={{ width: '300px !important', fontSize: '14px' }}
            onClick={() => {
              onConsentSupportStatementModalOpen()
            }}
          />
        </BoxCenter>
      </ModalWrapper>
      <ElectorialListPopup
        isOpen={isElectorialListModalOpen}
        onClose={onElectorialListModalClose}
        mode="multiple"
      />
      <VotingCardPopUp
        isOpen={isVotingCardModalOpen}
        onClose={onVotingCardModalClose}
        mode="multiple"
      />
      <SupportStatementPopUp
        isOpen={isSupportStatementModalOpen}
        onClose={onSupportStatementModalClose}
        mode="multiple"
      />

      <ConsentSupportStatementPopUp
        isOpen={isConsentSupportStatementModalOpen}
        onClose={onConsentSupportStatementModalClose}
        mode="multiple"
      />
    </>
  );
}
