import { ArrowForward, AssistantOutlined, Clear, Close, FilterAltOutlined, Search as SearchIcon } from "@mui/icons-material";
import { Box, Button, Checkbox, Fade, FormControl, FormControlLabel, IconButton, Input, InputLabel, Popper, Tooltip, Typography, InputAdornment, } from "@mui/material";
import FormTextField from "../FormTextField";
import { swvTheme as swvThemeImport } from "../../../swvTheme";
import { useGlobalStyles } from "../../../customStyles";
import React, { useContext, useState } from "react";
import BoxCenter from "../BoxCenter";
import { useForm, useWatch } from "react-hook-form";
import { FormSendingContext } from "../../pages/FormSending/FormSendingContext";
import BusinessSubcategoryAssistantPopup from "../../popups/BusinessSubcategoryAssistant/BusinessSubcategoryAssistant";

const Search = ({ listnames }: { listnames: Array<string> }) => {
    const pageSize = 100;

    const swvTheme: any = swvThemeImport;
    const cssClass = useGlobalStyles();

    const {
        searchText, setSearchText,
        lists, setLists,
        searchData, setSearchData,
        setSearchedSubcategoryValue,
        control, subcategoryValue
    } = useContext(FormSendingContext);

    const onClearClick = () => {
        setSearchText('');
        setIsSearchClicked(false);
    };

    const setIsSearchClicked = (value: boolean) => {
        setSearchData((old: any) => ({ ...old, other: { ...old.other, isSearchClicked: value } }))
    }

    const setSearchInputError = (value: boolean) => {
        setSearchData((old: any) => ({ ...old, other: { ...old.other, searchInputError: value } }))
    }

    const [isSearchFilterModalOpen, setIsSearchFilterModalOpen] = useState(false);
    const [isBusinessSubcategoryAssistantOpen, setIsBusinessSubcategoryAssistantOpen] = useState(false)


    const onBusinessSubcategoryAssistantApply = (appliedBusinessSubcategories: any) => {
        setIsBusinessSubcategoryAssistantOpen(false)
        let subcategoryText = '';

        appliedBusinessSubcategories.map((subcategory: any) => {
            subcategoryText = subcategoryText.concat(subcategory.County, subcategory.Number, ' ')
        })

        setSearchedSubcategoryValue('searchedSubcategories', subcategoryText.trim())
    }

    const onBusinessSubcategoryAssistantClose = () => {
        setIsBusinessSubcategoryAssistantOpen(false)
    }

    const onCompanyChecked = (event: any) => {
        const checked = event.target.checked;

        setSearchData((old: any) => ({ ...old, company: { ...old.company, checked: checked } }))
        localStorage.setItem('companyChecked', checked);
    };

    const onMemberChecked = (event: any) => {
        const checked = event.target.checked;

        setSearchData((old: any) => ({ ...old, other: { ...old.other, memberChecked: checked } }));
        localStorage.setItem('memberChecked', checked);
    };

    const onFamilyChecked = (event: any) => {
        const checked = event.target.checked;

        setSearchData((old: any) => ({ ...old, other: { ...old.other, familyChecked: checked } }));
        localStorage.setItem('familyChecked', checked);
    };

    const enterPressed = (event: any) => {
        const code = event.keyCode || event.which;
        const { value } = event.target;

        if (code === 13) {
            event.preventDefault();
            //ensure input is atleast 3 characters long that arent only whitespaces or subcategory value with atleast 3 characters has been entered
            if (value?.trim().length > 2 || subcategoryValue.searchedSubcategories!.trim().length > 2) {
                setSearchData((old: any) => ({ ...old, company: { ...old.company, isEnabled: true, checked: true, resultCount: 0, page: 0 }, other: { ...old.other, searchInputError: false } }))

                //if(event.target.name === 'Suche')
                //setSearchText(value);
                onSearchClicked(true);
                localStorage.setItem('searchQuery', searchText);
            } else {
                setSearchInputError(true);
            }
        }
    };

    const onSearchClicked = async (isEnterClicked = false) => {
        localStorage.setItem('searchQuery', searchText);

        listnames.forEach(listname => {
            setSearchData((old: any) => ({ ...old, [listname]: { ...old[listname], resultCount: 0, page: 0, listOpen: false } }))

            setLists((old: any) => ({ ...old, [listname]: [] }));

            setSearchInputError(false);
            searchData[listname].getList(searchText, isEnterClicked, true, 0, subcategoryValue);
        })

        setIsSearchClicked(true);
    };


    const [filterPopupAnchorEl, setFilterPopupAnchorEl] = useState(null);
    const onSearchFilterModalOpen = () => {
        setFilterPopupAnchorEl(null);
        onClearClick();
        setIsSearchFilterModalOpen(true);
    };

    // Search filter popup
    const handleFilterClick = (event: any) => {
        onSearchFilterModalOpen();
        // setFilterPopupAnchorEl(filterPopupAnchorEl ? null : event.currentTarget);
    };

    const handleAssistantClick = (event: any) => {
        setIsBusinessSubcategoryAssistantOpen(true)
    }

    const open = Boolean(filterPopupAnchorEl);
    const id = open ? 'bottom-end' : undefined;

    return (
        <>
            <Box display='flex' alignItems='center' gap={1}>
                <Box width='100%'>
                    <FormControl>
                        <Box display='flex' justifyContent='space-between'>
                            <InputLabel
                                shrink
                                className={cssClass.customInputLabel}
                                sx={{ color: '#732382 !important' }}
                            >
                                Suche
                            </InputLabel>
                            {/* ToDo: add this in again
                 <FormLink
                  sx={{ textDecoration: 'underline', color: '#732382', pr: 5 }}
                  onClick={onFavoriteClicked}
                >
                  Favoriten
  </FormLink>*/}
                        </Box>
                        <Box display='flex' justifyContent='space-between'>
                            <Input
                                value={searchText}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    setSearchText(value || '');
                                }}
                                name='Suche'
                                onKeyUp={(e) => enterPressed(e)}
                                endAdornment={
                                    <>
                                        {/* @ts-ignore */}
                                        <InputAdornment>
                                            <IconButton
                                                onClick={onClearClick}
                                                sx={{
                                                    visibility: searchText ? 'visible' : 'hidden',
                                                    marginRight: '38px',
                                                }}
                                            >
                                                <Clear />
                                            </IconButton>
                                        </InputAdornment>
                                        {/* @ts-ignore */}
                                        <InputAdornment>
                                            {/* @ts-ignore */}
                                            <IconButton
                                                id='search-button'
                                                onClick={onSearchClicked}
                                                disabled={(!searchText || searchText.trim().length < 3) && subcategoryValue.searchedSubcategories!.trim().length < 3}
                                                sx={{
                                                    backgroundColor: '#732382',
                                                    borderRadius: 'unset',
                                                    height: '100%',
                                                    position: 'absolute',
                                                    right: '0',
                                                    width: '44px',
                                                    '&:hover': {
                                                        backgroundColor: '#732382',
                                                    },
                                                    '&.Mui-disabled': {
                                                        backgroundColor: '#732382',
                                                        pointerEvents: 'initial',
                                                        opacity: '0.5',
                                                    },
                                                }}
                                            >
                                                <SearchIcon sx={{ color: '#FFFFFF' }} />
                                            </IconButton>
                                        </InputAdornment>
                                    </>
                                }
                                className={cssClass.customInput}
                                //@ts-ignore
                                borderColor='#3E004D'
                                sx={{ maxHeight: '46px', width: '100%' }}
                            />
                            <IconButton aria-describedby={id} onClick={handleFilterClick}>
                                <FilterAltOutlined sx={{ color: '#3E004D' }} />
                            </IconButton>
                        </Box>
                        {searchData.other.searchInputError &&
                            <Typography color={swvTheme.palette.error.main}>
                                Suche muss mindestens 3 Zeichen enthalten
                            </Typography>
                        }

                    </FormControl>

                    <Box mt={1} display='flex' justifyContent='space-between' sx={{
                        marginBottom: 1,
                        flexDirection: 'column',
                        marginX: '3px',

                        [swvTheme.breakpoints.up('sm')]: {
                            flexDirection: 'row',
                            marginBottom: 2,
                            marginX: '3px',
                        },
                    }}>
                        <Box display='flex' sx={{
                            [swvTheme.breakpoints.down('sm')]: {
                                borderBottom: '1px dotted #000000',
                                marginY: '10px',
                                padding: '10px 0',
                                width: '100%',
                            },
                        }}>
                            <Box>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={searchData.company.checked}
                                            onChange={onCompanyChecked}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    }
                                    label='Firma'
                                    className={cssClass.customCheckbox}
                                />
                            </Box>
                        </Box>

                        <Box>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={searchData.other.memberChecked}
                                        onChange={onMemberChecked}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                }
                                label='Nur SWV Mitglieder'
                                className={cssClass.customCheckbox}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={searchData.other.familyChecked}
                                        onChange={onFamilyChecked}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                }
                                label='Nur SWV Familie'
                                className={cssClass.customCheckbox}
                            />

                        </Box>
                    </Box>
                    <Box display='flex' alignItems='center' sx={{ minWidth: "100%" }}>
                        <FormTextField
                            name='searchedSubcategories'
                            label='Fachgruppen (mehrere mit Abstand möglich)'
                            placeholder='z.B. "W704 W106"'
                            control={control}
                            type='text'
                            onKeyUp={(e: any) => enterPressed(e)}
                            sx={{ minWidth: '340px', [swvTheme.breakpoints.down('sm')]: {} }}
                        />
                        <Tooltip title="Fachgruppenassistent öffnen" arrow>
                            <IconButton style={{ color: swvTheme.typography.lilac, textAlign: "center", marginLeft: "0.5em" }}
                                onClick={handleAssistantClick}
                            >
                                <AssistantOutlined />
                            </IconButton>
                        </Tooltip>
                    </Box>

                </Box>

                <Popper
                    id={id}
                    open={open}
                    placement='bottom-end'
                    anchorEl={filterPopupAnchorEl}
                    transition
                >
                    {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>
                            <Box
                                p={2}
                                bgcolor='#DFCFE2'
                                borderRadius='4px'
                                width='260px'
                            >
                                <Button
                                    onClick={onSearchFilterModalOpen}
                                    endIcon={<ArrowForward />}
                                    size='large'
                                    className={cssClass.buttonDark}
                                    sx={{ width: '100%', mt: 2 }}
                                >
                                    Zur Spezialsuche
                                </Button>
                                <BoxCenter>
                                    <IconButton onClick={handleFilterClick}>
                                        <Close />
                                    </IconButton>
                                </BoxCenter>
                            </Box>
                        </Fade>
                    )}
                </Popper>
            </Box>

            <BusinessSubcategoryAssistantPopup
                isOpen={isBusinessSubcategoryAssistantOpen}
                onClose={onBusinessSubcategoryAssistantClose}
                handleApply={onBusinessSubcategoryAssistantApply}
            />
        </>
    )
}

export default Search;