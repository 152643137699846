import React from 'react';

import UserGoals from '../../pages/UserGoals';

import ModalWrapper from '../../common-components/ModalWrapper';

import { useStyles } from './styles';

export default function UserGoalPopUp({ isOpen, onClose, selectedGoal }) {
  const classes = useStyles();

  const currentUser = JSON.parse(localStorage.getItem('userData'));

  return (
    <ModalWrapper isOpen={isOpen} onClose={onClose} title="Zielauswertung">
      <UserGoals currentUser={currentUser} onClose={onClose} selectedGoal={selectedGoal} />
    </ModalWrapper>
  );
}