import React, { useContext, useState, useEffect } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';

import { CompanyContext } from '../../../../pages/Company/CompanyContext';
import { CompanyLocationContext } from '../../CompanyLocationContext';

import { useStyles } from  './styles';
import { chooseContactPersonPosition } from '../../../../../helpers/database';
import PersonPopUp from '../../../Person';
import FormSelect from '../../../../common-components/FormSelect';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Typography } from '@mui/material';
import { useForm, useWatch } from 'react-hook-form';

export default function ContactPerson() {
  const classes = useStyles();

  const { contactPersonList, getBusinessRegistrationListCompany } = useContext(CompanyContext);

  const {
    updateAddressRegistration,
    chosenPerson,
    setChosenPerson,
    currentAddressRegistration,
    chosenContactPerson,
    setChosenContactPerson,
    getPerson,
    handleSubmit,
    isOpen
  } = useContext(CompanyLocationContext);

  const [isPersonModalOpen, setOpenPersonModal] = useState(false);

  const { control, reset } = useForm({
    defaultValues: {
      selectedLineID: 'reset',
    }
  });

  const values = useWatch({ control });

  useEffect(() => {
    const idToSearch = values?.selectedLineID

    if(!isOpen || (idToSearch === chosenContactPerson?.ID) || values.selectedLineID === 'reset') {
      return
    }

    const potentialRowSelected = contactPersonList?.filter((person) => person.ID === idToSearch)
    const rowSelected = potentialRowSelected[0] ?? null

    setChosenContactPerson(rowSelected)
    setChosenPerson(rowSelected?.Person)
  }, [values]);

  useEffect(() => {
    if(chosenContactPerson && isOpen) {
      reset({
        selectedLineID: chosenContactPerson?.ID ?? ''
      })
    } else if(chosenContactPerson && !isOpen) {
      reset()
    }
  }, [chosenContactPerson]);

  useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen]);

  useEffect(() => {
    setChosenPerson(currentAddressRegistration?.ContactPerson?.Person);
  }, [currentAddressRegistration])

  const onPersonModalClose = async () => {
    setOpenPersonModal(false);
  };

  const onContactPersonApply = async (person) => {
    setChosenContactPerson(person)
    await onPersonModalClose();
    await handleSubmit();
  };

  return (
    <>
      <Typography variant={"h1"} py={2}>Ansprechperson</Typography>
      <FormControl fullWidth>
        <FormSelect
          name={"selectedLineID"}
          label={'Person (Position)'}
          control={control}
          value={values?.selectedLineID}
          required
        >
          {contactPersonList?.map((row) => {
            return (
            <MenuItem key={row.ID} value={row.ID}>
              {row.Person?.Firstname} {row.Person?.Lastname} ({chooseContactPersonPosition(row.Position)})
            </MenuItem>
            )
          })}
        </FormSelect>
      </FormControl>

      <PersonPopUp
        isOpen={isPersonModalOpen}
        onClose={onPersonModalClose}
        chosenPerson={chosenPerson}
        setChosenPerson={setChosenPerson}
        getPerson={getPerson}
        onContactPersonApply={onContactPersonApply}
      />
  </>
  );
}
