import React from 'react';

export default function Calendar() {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M1 3.66675H21V21.0001H1V3.66675Z" stroke="currentColor"/>
      <path d="M1 9H21" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M6.33325 6.5V0" stroke="currentColor"/>
      <path d="M15.6667 6.5V0" stroke="currentColor"/>
      <path d="M5.33333 12.3333C5.14924 12.3333 5 12.4825 5 12.6666C5 12.8507 5.14924 12.9999 5.33333 12.9999C5.51743 12.9999 5.66667 12.8507 5.66667 12.6666C5.66667 12.4825 5.51743 12.3333 5.33333 12.3333" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M5.33333 17C5.14924 17 5 17.1492 5 17.3333C5 17.5174 5.14924 17.6667 5.33333 17.6667C5.51743 17.6667 5.66667 17.5174 5.66667 17.3333C5.66667 17.1492 5.51743 17 5.33333 17" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M11.0001 12.3333C10.816 12.3333 10.6667 12.4825 10.6667 12.6666C10.6667 12.8507 10.816 12.9999 11.0001 12.9999C11.1842 12.9999 11.3334 12.8507 11.3334 12.6666C11.3334 12.4825 11.1842 12.3333 11.0001 12.3333" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M11.0001 17C10.816 17 10.6667 17.1492 10.6667 17.3333C10.6667 17.5174 10.816 17.6667 11.0001 17.6667C11.1842 17.6667 11.3334 17.5174 11.3334 17.3333C11.3334 17.1492 11.1842 17 11.0001 17" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16.6666 12.3333C16.4825 12.3333 16.3333 12.4825 16.3333 12.6666C16.3333 12.8507 16.4825 12.9999 16.6666 12.9999C16.8507 12.9999 16.9999 12.8507 16.9999 12.6666C16.9999 12.4825 16.8507 12.3333 16.6666 12.3333" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16.6666 17C16.4825 17 16.3333 17.1492 16.3333 17.3333C16.3333 17.5174 16.4825 17.6667 16.6666 17.6667C16.8507 17.6667 16.9999 17.5174 16.9999 17.3333C16.9999 17.1492 16.8507 17 16.6666 17" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}