import { createUseStyles } from 'react-jss';
import { swvTheme } from '../../../../../swvTheme';

export const useStyles = createUseStyles({
    spaceBetweenTables: {
        '& .MuiTable-root': {
            marginBottom: '22px',
        }
    }
});
