import React from 'react';
import { useStyles } from './styles';
import { Box } from '@mui/material';
import { swvTheme } from '../../../swvTheme';

export default function CustomSelect({
  value,
  valueToDisplay,
  required,
  disabled,
  placeholder,
  onClick,
  selectedRecord,
  component,
}) {
  const classes = useStyles();
  const name = value?.Name;

  const valueTrunc = value?.length > 50 ? value?.slice(0, 50) + '...' : value;
  const valueNumberTrunc = name?.length > 45 ? name?.slice(0, 45) + '...' : name;

  const valueToDisplayTrunc = valueToDisplay?.length > 50 ? valueToDisplay?.slice(0, 50) + '...' : valueToDisplay;

  return (
    <Box className={classes.mainContainer}>
      <Box
        id="my-select"
        className={classes.customSelect}
        disabled={disabled}
        onClick={() => onClick && onClick(selectedRecord)}
      >
        <Box style={{ overflow: 'hidden' }}>
          {valueToDisplay ? (
            valueToDisplayTrunc
          ) : value ? (
            value?.Number ? `${value?.Number || ''}, ${valueNumberTrunc}` : valueTrunc
          ) : (
            <label>{`${placeholder}` + (required ? ' *' : '')}</label>
          )}
        </Box>

        {value ? (
          <Box>
              <label htmlFor="my-select" className={classes.customLabel}>
                {`${placeholder}` + (required ? ' *' : '')}
              </label>
          </Box>
        ) : (
          ''
        )}
        <Box height={"100%"} color={swvTheme.palette.primary.dark}>
          {component}
        </Box>
      </Box>
    </Box>
  );
}
