import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';

import { DecorativeCorners } from '../DecorativeCorners';

import { styles } from './styles';

export const DragAndDropArea = ({ onFilesSelected, children, sx }) => {
  const [isDragOver, setIsDragOver] = useState(false);

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    setIsDragOver(false);
  };

  const handleFileDrop = (event) => {
    event.preventDefault();
    onFilesSelected(event.dataTransfer.files);
    setIsDragOver(false);
  };

  return (
    <Box
      sx={sx}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleFileDrop}
    >
      <DecorativeCorners sx={styles.dragAndDropContainer}>
        {isDragOver && <Box aria-hidden sx={styles.dragOverOverlay} />}
        {children}
      </DecorativeCorners>
    </Box>
  );
};

DragAndDropArea.propTypes = {
  onFilesSelected: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};
