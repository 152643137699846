import { createUseStyles } from 'react-jss';

import { swvTheme } from '../../../swvTheme';

export const useStyles = createUseStyles({
  grid: {
    position: 'fixed',
    zIndex: '10',
    gridGap: '1px',
    flexWrap: 'nowrap',
    bottom: 0,
    backgroundColor: '#FFFFFF',
    '& .MuiGrid-item': {
      width: '25%',
    },

    [swvTheme.breakpoints.up('sm')]: {
      flexDirection: 'column',

      position: 'fixed',
      top: '50%',
      translate: '0 -50%',
      right: 0,

      width: 'auto',
      height: 'fit-content',

      justifyContent: 'center',
      marginRight: '16px',
      gridGap: '22px',
      backgroundColor: 'transparent',
    },
  },
  iconButton: {
    backgroundColor: swvTheme.palette.button.dark,
    color: '#FFFFFF',
    width: '100%',
    height: '64px',
    borderRadius: 'initial',
    '&.MuiIconButton-root:hover': {
      backgroundColor: swvTheme.palette.button.dark,
    },

    [swvTheme.breakpoints.up('sm')]: {
      borderRadius: '8px 16px 8px 16px',
      width: '90px',
      height: '62px',      
    },
  },
});
