import React, { useEffect, useCallback, useState } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Container from '@mui/material/Container';

import { useSnackbarHelpers } from '../../../helpers/utils';

import TextField from '@mui/material/TextField';

import * as GoalStatusService from '../../../services/requests/goalStatus';
import { chooseHardGoal } from '../../../helpers/database';

import BoxCenter from '../../common-components/BoxCenter';

import { useStyles } from './styles';
import { useGlobalStyles } from '../../../customStyles';

import { getDate } from '../../../helpers/database';
import { swvTheme } from '../../../swvTheme';
import CustomButton from '../../common-components/CustomButton';
import { GoalStatusAPI } from '../../../redux/services/GoalStatusService';
import CustomFormSelect from '../../common-components/CustomFormSelect/CustomFormSelect';
import { useForm } from 'react-hook-form';
import UserGoalRow from '../UserGoalRow/UserGoalRow';

export default function UserGoals({ currentUser, selectedGoal, onClose }) {
  const classes = useStyles();
  const cssClass = useGlobalStyles();

  const [edited, setEdited] = useState([]);
  const [goalStatusList, setGoalStatusList] = useState(null);
  const [selectedGoalStatus, setSelectedGoalStatus] = useState(null);

  const { } = useSnackbarHelpers();

  const { data: goalStatusEnum } =
    GoalStatusAPI.useGetListEnumQuery(undefined);

  console.log("goalStatusEnum", goalStatusEnum, "find Formular", goalStatusEnum?.find(gs => gs.Type === "Formular" && gs.Status === 0))

  useEffect(() => {
    if (selectedGoal) {
      setGoalStatusList([selectedGoal]);
    } else if (currentUser?.ID) {
      getGoalStatusList(currentUser?.ID);
    }
  }, [currentUser?.ID]);

  const getGoalStatusList = useCallback(
    async (userId) => {
      await GoalStatusService.getList(userId, setGoalStatusList);
    },
    [goalStatusList],
  );

  const handleRowClick = (row) => {
    setSelectedGoalStatus(row);
  };

  const handleChangeGoal = (event, row) => {
    const newGoalStatusList = goalStatusList?.map((o) => {
      if (o.ID == row.ID) {
        const editedGoal = {
          ...row,
          ActualValue: event.target.value,
        };
        setEdited([...edited, editedGoal]);
        return editedGoal;
      } else {
        return o;
      }
    });

    setGoalStatusList(newGoalStatusList);
  };

  const handleUpdate = async () => {
    const data = Array.from(new Set(edited));

    await GoalStatusService.updateMany(data, () => {
      setEdited([]);
      onClose();
    });
  };

  const onChange = (goal) => {
    if (edited.find(element => element.ID === goal.ID)) {
      setEdited(old => old.map(element => {
        if (goal.ID === element.ID) {
          return goal
        }
        return element
      }))
    } else {
      setEdited(old => [...old, goal])
    }

    setGoalStatusList((old) => old.map(element => {
      if (goal.ID === element.ID) {
        return goal
      }
      return element
    }))
  }

  return (
    <Container
      maxWidth="xl"
      sx={{
        [swvTheme.breakpoints.down('sm')]: {
          padding: 0,
        },
      }}
    >
      <TableContainer
        sx={{ '.MuiTableBody-root .MuiTableCell-root': { height: '55px' } }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: "45%" }}>Name</TableCell>
              <TableCell style={{ width: "10%" }}>Anfang</TableCell>
              <TableCell style={{ width: "10%" }}>Ende</TableCell>
              <TableCell style={{ width: "15%" }}>Ziel</TableCell>
              <TableCell style={{ width: "20%" }}>Ist</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {goalStatusList?.map((row) => (
              <UserGoalRow key={row.ID} selectedGoal={row} onChange={onChange} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <BoxCenter>
        <CustomButton
          value="Speichern"
          onClick={handleUpdate}
          disabled={!edited.length}
        />
      </BoxCenter>
    </Container>
  );
}
