export const styles = {
  imagePreviewContainer: {
    p: {
      xs: '0 2px',
      sm: '50px 30px'
    }
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '20px',
    mb: '30px',
    
  },
  decorativeCorners: {
    maxWidth: '660px',
    width: '100%',
    p: {
      xs:'20px',
      sm: '35px'
    }
  },
  imageWrapper: {
    mb: '20px',
    border: '1px solid rgba(245, 0, 0, 1)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    maxWidth: '838px',
    width: '100%',
    maxHeight: { xs: '330px', sm: 'unset' },
    height: 'auto',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    gap: {
      xs: '10px',
      sm: '20px'
    },
    flexWrap: 'wrap'
  },
  openFileBrowserButton: {
    width: '200px',
    minWidth: 'unset',
    maxWidth: '100%',
    fontSize: { xs: '12px', sm: '13px' },
    fontWeight: '500',
    lineHeight: '120%',
    padding: '16px !important',
    boxSizing: 'border-box',
  },
  secondaryActionButton: {
    width: '200px',
    borderWidth: '1px',
    backgroundColor: '#fff',
    color: 'button.light',
    borderColor: 'button.light',
    minWidth: 'unset',
    maxWidth: '100%',
    fontSize: { xs: '12px', sm: '13px' },
    fontWeight: '500',
    lineHeight: '120%',
    padding: '16px !important',
    boxSizing: 'border-box',
    '&:hover': {
      backgroundColor: '#f0f0f0',
      color: 'button.light',
      borderColor: 'button.light',
    },
  },
  tableContainer: {
    m: {
      xs: '15px 0 0',
      sm: '35px 0 0',
    },
  },
  tableCell: {
    padding: {
      xs: '15px 7px 11px !important',
      sm: '15px 13px 11px !important',
    }
  },
  formSelect: {
    minWidth: {
      sm: '10rem',
      xs: '9.5rem'
    }
  }
};
 