import React, { useState, useEffect, useContext } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Messages } from '../../../../../constants/messages';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Box,
  IconButton,
} from '@mui/material';
import moment from 'moment';
import { Edit, Close } from '@mui/icons-material/';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Container from '@mui/material/Container';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import FormSelect from '../../../../common-components/FormSelect';
import FormTextField from '../../../../common-components/FormTextField';
import MembershipPopUp from '../../../Membership';
import RecruiterPopUp from '../../../Recruiter';

import { useStyles } from './styles';

import FormDatePicker from '../../../../common-components/FormDatePicker';
import CustomSelect from '../../../../common-components/CustomSelect';
import BoxCenter from '../../../../common-components/BoxCenter';
import { useSnackbarHelpers } from '../../../../../helpers/utils';
import { ContactPersonContext } from '../../PersonContext';
import { getDate, chooseMemberType, getPerson } from '../../../../../helpers/database';
import { useGlobalStyles } from '../../../../../customStyles';
import CustomButton from '../../../../common-components/CustomButton';
import BusinessCard from '../../../../common-components/Icons/BusinessCard';
import CustomFormSelect from '../../../../common-components/CustomFormSelect/CustomFormSelect';
import * as OrganisationService from '../../../../../services/requests/organisation';
import { memberTypes } from '../../../../../constants/memberTypes';


export const MembershipDetails = ({
  insertMember,
  updateMember,
  onMemberApply,
  chosenPerson,
}) => {
  const cssClass = useGlobalStyles();
  const classes = useStyles();
  const { showRow } = useContext(ContactPersonContext);
  const { control, reset } = useFormContext();
  const values = useWatch({ control });
  const [isEditMode, setIsEditMode] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [organisationList, setOrganisationList] = useState([]);
  const { showErrorSnackbar } = useSnackbarHelpers();

  const disabled =
    !values?.MemberType ||
    !values?.MemberNumber ||
    !values?.MemberFee ||
    values?.MemberStart == null;


  const getOrganisationList = async () => {
    await OrganisationService.getList({}, setOrganisationList)
  }

  useEffect(() => {
    getOrganisationList();
  }, []);

  // RecruiterPopUp

  const [isRecruiterPopUpOpen, setIsRecruiterPopUpOpen] = useState(false);

  const onRecruiterPopUpOpen = async () => {
    setIsRecruiterPopUpOpen(true);
  };

  const onRecruiterPopUpClose = async () => {
    setIsRecruiterPopUpOpen(false);
  };

  const onPersonApply = async (member) => {
    reset({
      ...values,
      MemberRecruiter: member.Person,
    });
    setIsRecruiterPopUpOpen(false);
  };

  // MembershipPopUp

  const [isMembershipPopUpOpen, setIsMembershipPopUpOpen] = useState(false);

  const onMembershipPopUpOpen = async () => {
    setIsMembershipPopUpOpen(true);
  };

  const onMembershipPopUpClose = async () => {
    setIsMembershipPopUpOpen(false);
  };

  const createData = (name, value) => {
    return { name, value };
  };

  const tableRows = [
    createData('Typ:', chooseMemberType(values?.MemberType)),
    createData('Organisation:', organisationList?.find(organisation => organisation.ID == chosenPerson?.Member?.FK_Organization)?.Name),
    createData('Nummer:', values?.MemberNumber),
    createData('Beitrag:', values?.MemberFee),
    createData('Werber:', getPerson(values?.MemberRecruiter)),
    createData('Werbeaktivität:', values?.MemberPromoActivity),
    createData('Anfang:', getDate(values?.MemberStart)),
    createData('Ende:', getDate(values?.MemberEnd))
  ];

  useEffect(() => {
    if (isEditMode) {
      values.isModified = true;
    }
  }, [values]);

  const resetWarning = () => {
    values.isModified = false;
    setShowWarning(false);
    setIsEditMode(false);
  }

  return (
    <>
      <Box >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            p: 2,
          }}
        >
          <Typography
            color="#732382"
            fontSize={18}
            fontWeight={500}
            py={2}
          >
            Mitgliedschaft
          </Typography>

          {showWarning &&
            <Typography color="primary">{Messages.WarningUnsafed}</Typography>}

          {isEditMode ? (
            <>
              <IconButton aria-label="close" onClick={() => {
                if (values?.isModified)
                  setShowWarning(true);
                setIsEditMode(false)
              }}>
                <Close />
              </IconButton>
            </>
          ) : (
            <IconButton
              aria-label="edit"
              color="primary"
              onClick={() => setIsEditMode(!isEditMode)}
            >
              <Edit />
            </IconButton>
          )}
        </Box>
        {isEditMode ? (<FormControl fullWidth>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Table className={cssClass.twoColumnDataTable}>
              <TableBody className={cssClass.customTableBody}>
                <TableRow>
                  <TableCell className={cssClass.shrinkCell}>Typ</TableCell>
                  <TableCell>
                    <CustomFormSelect
                      required
                      label="Typ"
                      name="MemberType"
                      control={control}
                      value={values.MemberType}
                      options={memberTypes}
                      valueKey='value'
                      titleKey='name'
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={cssClass.shrinkCell}>Organisation</TableCell>
                  <TableCell>
                    <CustomFormSelect
                      name="FK_Organization"
                      label="Organisation"
                      control={control}
                      valueKey='ID'
                      options={organisationList}
                      titleKey='Name'
                      required
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={cssClass.shrinkCell}>Nummer</TableCell>
                  <TableCell>
                    <FormTextField
                      name="MemberNumber"
                      label="Nummer"
                      control={control}
                      placeholder='z.B. N123456'
                      required
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={cssClass.shrinkCell}>Werber</TableCell>
                  <TableCell>
                    <CustomSelect
                      placeholder="Werber"
                      value={getPerson(values?.MemberRecruiter)}
                      onClick={onRecruiterPopUpOpen}
                      selectedRecord={values?.MemberRecruiter}
                      component={<BusinessCard />}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={cssClass.shrinkCell}>Werbeaktivität</TableCell>
                  <TableCell>
                    <FormTextField
                      name="MemberPromoActivity"
                      label="Werbeaktivität"
                      control={control}
                      placeholder='z.B. Informationsstand Messegelände'
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={cssClass.shrinkCell}>Beitrag</TableCell>
                  <TableCell>
                    <FormTextField
                      name="MemberFee"
                      label="Beitrag"
                      control={control}
                      required
                      type="number"
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={cssClass.shrinkCell}>Anfang</TableCell>
                  <TableCell>
                    <FormDatePicker
                      name="MemberStart"
                      label="Anfang"
                      control={control}
                      required
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={cssClass.shrinkCell}>Ende</TableCell>
                  <TableCell>
                    <FormDatePicker
                      name="MemberEnd"
                      label="Ende"
                      control={control}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <BoxCenter sx={{ pb: '25px' }}>
              {onMemberApply && Boolean(values.MemberID) && (
                <CustomButton
                  onClick={() => onMemberApply(values.ID)}
                  disabled={!values.ID}
                  sx={{ minWidth: '200px' }}
                  value="Übernehmen"
                />
              )}
              <CustomButton
                value="Verwerfen"
                onClick={() => {
                  setIsEditMode(false);
                }}
                sx={{ width: '180px' }}
                light
                isClose
              />
              <CustomButton
                onClick={() => onMembershipPopUpOpen()}
                sx={{ minWidth: '180px' }}
                value="Formular"
              />
              <CustomButton
                onClick={() => { values.MemberID ? updateMember(resetWarning) : insertMember(resetWarning); }}
                disabled={disabled}
                sx={{ minWidth: '180px' }}
                value="Speichern"
              />
            </BoxCenter>
          </LocalizationProvider>
        </FormControl>
        ) : (
          <Table>
            <TableBody>
              {tableRows?.map((row) => (showRow(row) &&
                <TableRow key={row.name}>
                  <TableCell >
                    {row.name}
                  </TableCell>
                  <TableCell align="left">{row.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Box>

      <MembershipPopUp
        isOpen={isMembershipPopUpOpen}
        onClose={onMembershipPopUpClose}
        chosenPerson={chosenPerson}
      />

      <RecruiterPopUp
        isOpen={isRecruiterPopUpOpen}
        onClose={onRecruiterPopUpClose}
        chosenPerson={chosenPerson}
        onPersonApply={onPersonApply}
      />
    </>
  );
};
