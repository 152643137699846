import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
    truncate: {
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitLineClamp: 1,
        lineClamp: 1,
        WebkitBoxOrient: 'vertical',
    }
});
