import React from 'react';

export default function LogoLarge() {
  return (
    <img
      style={{
        width: '174px',
        height: '174px',
        alignSelf: 'center',
        marginBottom: '44px',
      }}
      src="/assets/img/transparent-logo.png"
    />
  );
}
