import React, { useState, useEffect } from 'react';

import { useForm, FormProvider } from 'react-hook-form';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

import FormControl from '@mui/material/FormControl';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import Navbar from '../Navbar';
import AdminCheckPopUp, { PopupModes } from '../../popups/AdminCheck/AdminCheck';

import { a11yProps } from '../../common-components/TabPanel/tabsProps';
import TabPanel from '../../common-components/TabPanel';
import SectionHeading from '../../common-components/SectionHeading';

import * as CommentService from '../../../services/requests/comment';
import * as ActivityService from '../../../services/requests/activity';

import Filters from './components/Filters/Filters';
import CommentTable from './components/CommentTable/CommentTable';
import ActivityTable from './components/ActivityTable/ActivityTable';

import { AdminCheckContext } from './AdminCheckContext';

const TabIndexes = {
  Notizen: 0,
  Aktivitäten: 1,
};

export default function AdminCheck() {
  const [activityList, setActivityList] = useState(null);
  const [chosenActivity, setChosenActivity] = useState(null);
  const [commentList, setCommentList] = useState(null);
  const [chosenComment, setChosenComment] = useState(null);
  const [isAdminCheckModalOpen, setIsAdminCheckModalOpen] = useState(false);
  const [currentTab, setCurrentTab] = useState(TabIndexes.Notizen);

  const userData = JSON.parse(localStorage.getItem('userData'));

  const handleAdminCheckModalOpen = () => {
    setIsAdminCheckModalOpen(true);
  };

  const handleAdminCheckModalClose = () => {
    setIsAdminCheckModalOpen(false);
  };

  const handleChange = (_, newValue) => {
    setCurrentTab(newValue);
  };

  const getCommentList = async (query) => {
    await CommentService.getListEx(query, setCommentList);
  };

  const getActivityList = async (query) => {
    await ActivityService.getListEx(query, setActivityList);
  };

  const methods = useForm({
    defaultValues: {
      ID: '',
      County: userData?.County || null,
      Start: null,
      End: null,
      Comment: '',
      Telephone: '',
      ContactPerson: '',
      IsApproved: false,
      IsActivity: false,
    },
  });

  useEffect(() => {
    const currentValues = methods.getValues();

    if (chosenComment) {
      const defaultValues = {
        ID: chosenComment.ID,
        County: currentValues.County,
        Start: currentValues.Start,
        End: currentValues.End,
        Comment: chosenComment.Content,
        Telephone: '',
        ContactPerson: '',
        IsApproved: currentValues.IsApproved,
        IsActivity: false,
      };
      methods.reset(defaultValues);
    }

    if (chosenActivity) {
      const defaultValues = {
        ID: chosenActivity.ID,
        County: currentValues.County,
        Start: currentValues.Start,
        End: currentValues.End,
        Type: chosenActivity.Type,
        Comment: chosenActivity.Comment,
        Telephone: chosenActivity.Telephone,
        ContactPerson: chosenActivity.ContactPerson,
        IsApproved: currentValues.IsApproved,
        IsActivity: true,
      };
      methods.reset(defaultValues);
    }
  }, [chosenComment, chosenActivity]);

  useEffect(() => {
    return () => {
      setCommentList(null);
      setActivityList(null);
      setChosenComment(null);
      setChosenActivity(null);
    };
  }, []);

  return (
    <AdminCheckContext.Provider
      value={{
        commentList,
        chosenComment,
        setChosenComment,
        getCommentList,

        activityList,
        chosenActivity,
        setChosenActivity,
        getActivityList,

        handleAdminCheckModalOpen,
      }}
    >
      <FormProvider {...methods}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Container maxWidth="lg">
            <FormControl fullWidth>
              <SectionHeading title="Kontrolle" />
              <Box>
                <Tabs value={currentTab} onChange={handleChange}>
                  <Tab label="Notizen" {...a11yProps(0)} />
                  <Tab label="Aktivitäten" {...a11yProps(1)} />
                </Tabs>
              </Box>

              <Filters />

              <Box>
                <TabPanel value={currentTab} index={TabIndexes.Notizen}>
                  <CommentTable />
                </TabPanel>

                <TabPanel value={currentTab} index={TabIndexes.Aktivitäten}>
                  <ActivityTable />
                </TabPanel>
              </Box>

            </FormControl>
          </Container>
        </LocalizationProvider>

        <AdminCheckPopUp
          mode={currentTab === TabIndexes.Notizen ? PopupModes.Notizen : PopupModes.Aktivitäten}
          isOpen={isAdminCheckModalOpen}
          onClose={handleAdminCheckModalClose}
        />
      </FormProvider>
    </AdminCheckContext.Provider>
  );
}
