import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Container, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, useMediaQuery
} from '@mui/material';

//@ts-ignore
import QuickActionButtons from '../../common-components/QuickActionButtons';
//@ts-ignore
import Navbar from '../Navbar';
//@ts-ignore
import BoxCenter from '../../common-components/BoxCenter';
//@ts-ignore
import CustomButton from '../../common-components/CustomButton';
//@ts-ignore
import { useGlobalStyles } from '../../../customStyles';
//@ts-ignore
import SectionHeading from '../../common-components/SectionHeading';
//@ts-ignore
import { useGetFormsQuery } from '../../../redux/services/FormService';

import { IForm } from '../../../redux/slices/formSlice'

import dayjs from 'dayjs'
import CustomFormSelect from '../../common-components/CustomFormSelect/CustomFormSelect';
import { GoalStatusAPI, useUpdateGoalStatusMutation } from '../../../redux/services/GoalStatusService';
import { useForm } from 'react-hook-form';


//import { useStyles } from './styles';

const infoFiles = [
  'Antrag_Wirtschaftsparlament.docx',
  'Fragen_Millionärssteuer.pdf',
  'Grafik_Frauen_Neugründungen.png',
  'WK_Broschüre_ALLE.pdf',
  'Grafik_EPUundKMU.png',
];

export const FormOverviewRow = ({ companyPage = false, row, onChange }: { companyPage?: boolean, row: any, onChange: any }) => {
  const theme = useTheme();
  //const classes = useStyles();
  const cssClass = useGlobalStyles();

  const { data, error, isLoading } = useGetFormsQuery(null, undefined);
  const { data: goalStatusEnum } =
    GoalStatusAPI.useGetListEnumQuery(undefined);



  const forms: Array<IForm> = data;

  const pageSize = 10;
  const resultCount = forms?.length ?? 0;

  const [page, setPage] = useState(0);
  const [formList, setFormList] = useState<Array<IForm>>([])

  useEffect(() => {
    let list: Array<any> = forms?.slice(page * pageSize, (page + 1) * pageSize);

    while (list?.length < 10) {
      list.push({})
    }

    setFormList(list);
  }, [forms])

  const handlePageChange = (event: any, inputPage: number) => {
    const newPage = inputPage - 1;
    setPage(newPage);

    let list: Array<any> = forms.slice(newPage * pageSize, (newPage + 1) * pageSize);

    while (list?.length < 10) {
      list.push({})
    }

    setFormList(list);
  }

  const { register, handleSubmit, control, reset, getValues, setValue, formState: { errors }, watch } = useForm({
    defaultValues: {
      Status: row?.Goal?.ActualValueAll,
    },
    values: {
      Status: row?.Goal?.ActualValueAll
    }
  });
  const formValues = watch(); // Get all form values

  useEffect(() => {
    if (formValues.Status !== row?.Goal?.ActualValueAll) {
      onChange({ ...row, ActualValue: formValues.Status, Goal: { ...row.Goal, ActualValueAll: formValues.Status } })
    }
  }, [formValues.Status]);

  const isMobile = useMediaQuery("(max-width: 850px)")

  return (
    <>
      <TableRow
        key={row.ID}
        selected={undefined}
        onClick={() => { }}
        style={{ height: "6vh" }}
      >
        <TableCell>{row?.Date ? dayjs(row.Date).format('DD.MM.YYYY HH:mm') : ""}</TableCell>
        <TableCell>{row?.Goal?.Soft}</TableCell>
        {/* <TableCell>{row.Company?.Name}</TableCell> */}
        {!isMobile && <TableCell>{row?.EmailDelivery !== undefined ? (row.EmailDelivery === false ? ("Nicht ausgewählt") : row.EmailDeliveryFailed === false ? ("Erfolgreich") : ("Fehlgeschlagen")) : ""} </TableCell>}
        {!companyPage && <TableCell>{row?.Email}</TableCell>}
        {/* <TableCell>{row.CopyEmail}</TableCell> */}
        <TableCell>
          {row.ID && <CustomFormSelect
            fullWidth={true}
            name="Status"
            label="Status"
            disabled={false}
            options={goalStatusEnum}
            valueKey='Status'
            titleKey='Title'
            control={control}
            required
            chooseAll={false}
          />}
        </TableCell>
        <TableCell>{row.User ? (row.User?.Firstname + " " + row.User?.Lastname) : " "}</TableCell>
      </TableRow>
    </>
  );
};
