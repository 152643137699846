import { createUseStyles } from 'react-jss';
import { swvTheme } from "../../../swvTheme";

export const useStyles = createUseStyles({
  wrapper: {
    maxWidth: "600px",
  },
  profileContainer: {
    width: "100svw !important",
    height: "100svh !important",

    // needs important due to swvModalDefault class
    maxWidth: "unset !important",
    minWidth: "unset !important",

    marginTop: "40px",

    overflowX: 'hidden',
    overflowY: 'scroll',

    [swvTheme.breakpoints.up('md')]: {
      width: "clamp(150px, 950px, 100svw) !important",
      height: "fit-content !important",

      padding: "3rem 5rem !important",
    },
  },
});
