import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import {
	Button,
	Card,
	CardMedia,
	FormControlLabel,
	FormGroup,
	IconButton,
	Paper,
	Switch,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	useMediaQuery,
} from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

import { useSnackbarHelpers } from '../../../helpers/utils';
import { Messages } from '../../../constants/messages';

import * as CompanyService from '../../../services/requests/company';
import * as CommentService from '../../../services/requests/comment';
import * as ActivityService from '../../../services/requests/activity';
import * as PersonService from '../../../services/requests/person';
import * as AddressRegistrationService from '../../../services/requests/addressRegistration';
import * as FavoriteService from '../../../services/requests/favorite';
import * as PhotoService from '../../../services/requests/photo';
import * as FileService from '../../../services/requests/file';
import * as ContactPersonService from '../../../services/requests/contactPerson';
import * as CommunicationDataService from '../../../services/requests/communicationData';
import * as BusinessRegistrationService from '../../../services/requests/businessRegistration';

import Navbar from '../Navbar';

import AddressPopUp from '../../popups/Address';
import ActivityPopUp from '../../popups/Activity';
import PersonPopUp from '../../popups/Person';
import CommentPopUp from '../../popups/Comment';
import PhotoPopUp from '../../popups/Photo';
import FilePopUp from '../../popups/File';
import CompanyLocationPopUp from '../../popups/CompanyLocation';
import CreateFormPopUp from '../../popups/CreateForm';

import ReadOnlyTables from './components/ReadOnlyTables/ReadOnlyTables';
import EditableTables from './components/EditableTables/EditableTables';
import AddressTable from './components/AddressTable/AddressTable';
import BusinessTable from './components/BusinessTable/BusinessTable';
import CommentTable from './components/CommentTable/CommentTable';
import PhotoTable from './components/PhotoTable/PhotoTable';
import AttachmentTable from './components/AttachmentTable/AttachmentTable';
import ActivityTable from './components/ActivityTable/ActivityTable';
import ContactPersonTable from './components/ContactPersonTable/ContactPersonTable';
import ContactPermissions from './components/Tables/ContactPermissions/ContactPermissions';

import { useStyles } from './styles';

import { CompanyContext } from './CompanyContext';
import QuickActionButtons from '../../common-components/QuickActionButtons';
import { ArrowBackIos } from '@mui/icons-material';
import CompanyImageCarousel from './components/CompanyImageCarousel/CompanyImageCarousel';
import CompanyIcon from '../../common-components/CompanyIcon/CompanyIcon';
import CustomButton from '../../common-components/CustomButton';
import BaseDataTables from './components/BaseDataTable/BaseDataTable';
import ContactTable from './components/ContactTable/ContactTable';
import { swvTheme } from '../../../swvTheme';
import Printer from '../../common-components/Icons/Printer';
import { urlIsLoadable } from '../../../helpers/utils';
import { ImageUploadPopup, PopupModes as PhotoUploadPopupModes } from '../../popups/ImageUploadPopup';
import { Avatar } from '@mui/material';
import { ImagePreviewPopup } from '../../popups/ImagePreviewPopup';
import { getPhotoUrl } from '../../../helpers/database';
import PersonToContactPersonPopup from '../../popups/PersonToContactPerson/PersonToContactPerson';
import { FormOverviewRow } from '../FormOverviewRow/FormOverviewRow';
import { useGetFormsQuery, useUpdateGoalStatusMutation } from '../../../redux/services/FormService';

const styles = {
	personAvatarContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	backToSearchButton: {
		m: '-50px 20px 0 -57px',
	},
	backToSearchButtonIcon: {
		position: 'relative',
		left: '-2px',
	},
	personAvatarInnerFlexWrapper: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
	personAvatar: {
		width: '120px',
		height: '120px',
		'&:hover': {
			cursor: 'pointer',
		},
	},
};

export default function Company() {
	const classes = useStyles();

	const params = useParams();

	const navigate = useNavigate();
	const [companyData, setCompanyData] = useState('');
	const [businessRegistrationList, setBusinessRegistrationList] = useState(null);
	const [commentList, setCommentList] = useState(null);
	const [photoList, setPhotoList] = useState([]);
	const [photoUriList, setPhotoUriList] = useState([])
	const [fileList, setFileList] = useState(null);
	const [contactPersonList, setContactPersonList] = useState(null);

	const [isAddressModalOpen, setOpenAddressModal] = useState(false);
	const [chosenAddressRegistration, setChosenAddressRegistration] = useState(null);
	const [isActivityPopUpOpen, setIsActivityPopUpOpen] = useState(false);
	const [chosenActivity, setChosenActivity] = useState(null);
	const [activityList, setActivityList] = useState(null);

	const [isPersonModalOpen, setIsOpenPersonModal] = useState(false);
	const [chosenPerson, setChosenPerson] = useState(null);
	const [chosenContactPerson, setChosenContactPerson] = useState(null);

	const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
	const [chosenComment, setChosenComment] = useState(null);

	const [contactPersonToAdd, setContactPersonToAdd] = useState(null)
	const [isPersonToContactPersonModalOpen, setIsPersonToContactPersonModalOpen] = useState(false)

	const [isPhotoModalOpen, setIsPhotoModalOpen] = useState(false);
	const [chosenPhoto, setChosenPhoto] = useState(null);

	const [isFileModalOpen, setIsFileModalOpen] = useState(false);
	const [chosenFile, setChosenFile] = useState(null);

	const [isCompanyLocationPopUpOpen, setIsCompanyLocationPopUpOpen] = useState(false);
	const [isCreateFormPopUpOpen, setIsCreateFormPopUpOpen] = useState(false);

	const [updatedCompanyFields, setUpdatedCompanyFields] = useState(null);
	const [updatedCompanyContactFields, setUpdatedCompanyContactFields] = useState(null);
	const [updatedContactPermissionFields, setUpdatedContactPermissionFields] = useState(null);

	const [chosenBusinessRegistration, setChosenBusinessRegistration] = useState(null);
	const [chosenAddressRow, setChosenAddressRow] = useState(null);

	const { showInfoSnackbar, showErrorSnackbar } = useSnackbarHelpers();

	const [isShowFilter, setIsShowFilter] = useState(false);

	const [isPhotoUploadModalOpen, setIsPhotoUploadModalOpen] = useState(false);

	const [isImagePreviewPopupOpen, setIsImagePreviewPopupOpen] = useState(false);

	const [photoURL, setPhotoURL] = useState(null);

	const [mode, setMode] = useState('contactPersonSelect')

	const openPhotoUploadPopup = () => setIsPhotoUploadModalOpen(true);
	const closePhotoUploadPopup = () => setIsPhotoUploadModalOpen(false);

	const openImagePreviewPopup = () => {
		setIsImagePreviewPopupOpen(true);
	};

	const closeImagePreviewPopup = () => {
		setIsImagePreviewPopupOpen(false);
	};

	const showRow = (row) => {
		return (row.value !== null && row.value !== '' && row.value !== undefined) || !isShowFilter;
	};

	const onSwitchChange = (event) => {
		setIsShowFilter(event.currentTarget.checked);
	};

	useEffect(() => {
		getCompany();
	}, [params.id]);

	const getBusinessRegistrationListCompany = async () => {
		await BusinessRegistrationService.getList(
			companyData.ID,
			null,
			setBusinessRegistrationList,
		);
	};

	const deleteCompanyLocation = async (row) => {
		if (row.Business) {
			await BusinessRegistrationService.deleteOne(row.ID, async () => {
				await getBusinessRegistrationListCompany();
			});
		} else {
			await deleteAddressRegistration();
		}
	};

	useEffect(() => {
		if (companyData?.ID) {
			getBusinessRegistrationListCompany();
			CommentService.getList(companyData.ID, setCommentList);
			FileService.getPhotoList(companyData.ID, setPhotoList);
			FileService.getList(companyData.ID, setFileList);
			ActivityService.getList(companyData.ID, setActivityList);
			ContactPersonService.getList(companyData.ID, null, setContactPersonList);

			//urlIsLoadable(getPhotoUrl(companyData), setPhotoURL);
		}
	}, [companyData]);

	function blobToBase64(blob) {
		return new Promise((resolve, _) => {
			const reader = new FileReader();
			reader.onloadend = () => resolve(reader.result);
			reader.readAsDataURL(blob);
		});
	}

	useEffect(() => {
		const fetchPhotoUris = async () => {
			if (photoList && photoList.length) {
				const tempPhotoUriList = await Promise.all(photoList.map(async element => {
					return { ID: element.ID, imageUri: await FileService.getBase64Uri(element.ID) };
				}));

				setPhotoUriList(tempPhotoUriList);
			}
		};

		fetchPhotoUris();
	}, [photoList]);

	useEffect(() => { console.log("PhotoUriList", photoUriList) }, [photoUriList])

	// Company

	const getCompany = async () => {
		await CompanyService.getOne({ UniqueWKO: params.id }, setCompanyData, () => {
			navigate('/', { replace: false });
		});
	};

	const setContactPersonMaster = async (id) => {
		if (companyData.FK_ContactPerson != id) {
			await CompanyService.updateOne(
				{ ID: companyData.ID, FK_ContactPerson: id },
				getCompany,
			);
		}
	};

	async function deleteContactPerson(id) {
		if (companyData.FK_ContactPerson == id) {
			showInfoSnackbar(Messages.ErrorMasterContactPerson);
		} else {
			const result = await ContactPersonService.deleteOne(id)

			if (result.isSuccess === true && result.data) {
				await ContactPersonService.getList(
					companyData.ID,
					null,
					setContactPersonList,
				);
			} else if (result.isSuccess === true && !result.data) {
				showInfoSnackbar(Messages.ChangeProposalCreated)
			}

		}
	}

	async function deleteActivity(id) {
		await ActivityService.deleteOne(id, async () => {
			await ActivityService.getList(companyData.ID, setActivityList);
		});
	}

	// Photo

	/*const downloadPhoto = async (id, filename) => {
		await PhotoService.downloadOne(id, filename);
	};

	async function deletePhoto(id) {
		await PhotoService.deleteOne(id, async () => {
			await PhotoService.getList(companyData.ID, setPhotoList);
		});
	}*/

	// File

	async function downloadFile(id, filename) {
		await FileService.downloadOne(id, filename);
	}

	async function deleteFile(id) {
		await FileService.deleteOne(id, async () => {
			await FileService.getList(companyData.ID, setFileList);
		});
	}

	async function deleteComment(id) {
		await CommentService.deleteOne(id, async () => {
			await CommentService.getList(companyData.ID, setCommentList);
		});
	}

	async function getPerson(id) {
		await PersonService.getOne(id, setChosenPerson);
	}

	// Address

	const onOpenAddressModal = async (row) => {
		setChosenAddressRegistration(row.AddressRegistration);
		setChosenAddressRow(row);
		setOpenAddressModal(true);
	};

	async function deleteAddressRegistration(id, field = null) {
		const result = await AddressRegistrationService.deleteOne(id)
		if (result.isSuccess) {
			if (result.data) {
				setChosenAddressRegistration(null);

				if (field) {
					await getCompany();
				} else {
					await getBusinessRegistrationListCompany();
				}
				showInfoSnackbar(Messages.Success);
			} else {
				showInfoSnackbar(Messages.ChangeProposalCreated);
			}
		} else {
			showErrorSnackbar(Messages.Error);
		}


	}

	const onAddressModalClose = () => {
		setChosenAddressRegistration(null);
		setOpenAddressModal(false);
	};

	const onAddressRegistrationInsert = async (data) => {
		const { ID, ...rest } = data;

		const payload = {
			FK_Company: companyData.ID,
			...rest,
			as: chosenAddressRow.field,
		};

		const result = await AddressRegistrationService.insertOne(payload)
		if (result.isSuccess) {
			if (result.data) {
				setChosenAddressRegistration(null);
				setOpenAddressModal(false);
			} else {
				showInfoSnackbar(Messages.ChangeProposalCreated);
			}
		} else {
			showErrorSnackbar(Messages.Error);
		}
		await getCompany();

	};

	const onAddressRegistrationUpdate = async (data) => {
		const { ID, ...rest } = data;

		const payload = {
			companyId: companyData.ID,
			ID: chosenAddressRegistration?.ID,
			...rest,
		};

		const result = await AddressRegistrationService.updateOne(payload)
		if (result.isSuccess) {
			if (result.data) {
				setChosenAddressRegistration(null);
				await getCompany();
				await getBusinessRegistrationListCompany();
				showInfoSnackbar(Messages.Success)
			} else {
				showInfoSnackbar(Messages.ChangeProposalCreated);
			}
		} else {
			showErrorSnackbar(Messages.Error);
		}
		setOpenAddressModal(false);


	};

	// Activity

	const onActivityModalOpen = (activity = null) => {
		setChosenActivity(activity);
		setIsActivityPopUpOpen(true);
	};

	const onActivityModalClose = () => {
		setIsActivityPopUpOpen(false);
	};

	const onActivityModalSubmit = async (activity) => {
		if (!activity.ID) {
			const { ID, ...rest } = activity;
			const data = { ...rest, FK_Company: companyData.ID };

			console.log("data!:", data)
			await ActivityService.insertOne(data, async () => {
				setIsActivityPopUpOpen(false);
				await ActivityService.getList(companyData.ID, setActivityList);
			});
		} else {
			console.log("activity!:", activity)
			await ActivityService.updateOne(activity, async () => {
				setIsActivityPopUpOpen(false);
				await ActivityService.getList(companyData.ID, setActivityList);
			});
		}
	};

	// Person
	const onPersonModalOpen = async (contactPerson = null) => {
		if (contactPerson) {
			await getPerson(contactPerson.Person.ID);
			setChosenContactPerson(contactPerson);
		} else {
			setChosenPerson(null);
			setChosenContactPerson(null);
		}

		setIsOpenPersonModal(true);
	};

	const onPersonModalClose = async () => {
		setChosenPerson(null);
		setIsOpenPersonModal(false);
	};

	const onPersonToContactPersonModalClose = () => {
		setIsPersonToContactPersonModalOpen(false)
	}

	const onPersonApply = async () => {
		setIsOpenPersonModal(false);
		await getCompany();
		await ContactPersonService.getList(
			companyData.ID,
			null,
			setContactPersonList,
		);
	};

	const onContactPersonApply = async () => {
		setIsOpenPersonModal(false);
		await getCompany();
		await ContactPersonService.getList(
			companyData.ID,
			null,
			setContactPersonList,
		);
	};

	const onContactPersonAdd = (person) => {
		setContactPersonToAdd(person.ID);
		setIsPersonToContactPersonModalOpen(true)
	}

	// Comment

	const onCommentModalOpen = (comment = null) => {
		setChosenComment(comment);
		setIsCommentModalOpen(true);
	};

	const onCommentModalClose = () => {
		setIsCommentModalOpen(false);
	};

	const onCommentModalSubmit = async (comment) => {
		if (!comment.ID) {
			const { ID, ...rest } = comment;
			const data = {
				...rest,
				FK_Company: companyData.ID,
				Creation: Date.now(),
			};

			await CommentService.insertOne(data, async () => {
				setIsCommentModalOpen(false);
				await CommentService.getList(companyData.ID, setCommentList);
			});
		} else {
			await CommentService.updateOne(comment, async () => {
				setIsCommentModalOpen(false);
				await CommentService.getList(companyData.ID, setCommentList);
			});
		}
	};

	// Photo

	const onPhotoModalOpen = (photo = null) => {
		setChosenPhoto(photo);
		setIsPhotoModalOpen(true);
	};

	const onPhotoModalClose = async () => {
		setIsPhotoModalOpen(false);
		await FileService.getPhotoList(companyData.ID, setPhotoList);
	};

	// File

	const onFileModalOpen = (file = null) => {
		setChosenFile(file);
		setIsFileModalOpen(true);
	};

	const onFileModalClose = async () => {
		setIsFileModalOpen(false);
		await FileService.getList(companyData.ID, setFileList);
	};

	// Company

	const onCompanySave = async (updatedCompanyFields, resetWarning) => {
		const data = updatedCompanyFields;

		const payload = {
			ID: companyData.ID,
			Name: data.Name,
			Abbreviation: data.Abbreviation,
			WKO: data.WKO,
			Founding: data.Founding,
			County: data.County,
			Commercialregister: data.Commercialregister,
			ZVR: data.ZVR,
			Employees: data.Employees,
		};

		const result = await CompanyService.updateOne(payload)
		if (result.isSuccess) {
			if (result.data) {
				showInfoSnackbar(Messages.Updated)
				resetWarning();
				await getCompany();
			} else {
				showInfoSnackbar(Messages.ChangeProposalCreated)
			}
		} else {
			showErrorSnackbar(Messages.Error)
		}



	};

	const updateCompanyProfilePhoto = async (base64EncodedPhotos) => {
		setPhotoURL(base64EncodedPhotos ? base64EncodedPhotos[0] : '');

		const data = {
			ID: companyData.ID,
			Photo: base64EncodedPhotos ? base64EncodedPhotos[0] : '',
		};

		await CompanyService.updateOne(data, async (data) => {
			companyData.Photo = data.Photo;
		});

		closePhotoUploadPopup();
	};

	const deleteCompanyProfilePhoto = async () => {
		return updateCompanyProfilePhoto(null);
	};

	const saveCommunicationData = async (resetWarning) => {
		const data = Object.assign(
			updatedCompanyContactFields,
			updatedContactPermissionFields,
		);

		const payload = {
			ID: companyData.CommunicationData?.ID,
			CompanyId: companyData.ID,
			PersonId: null,
			Fax: data.Fax,
			Telephone: data.Telephone,
			TelephonePrivate: data.TelephonePrivate,
			Mobile: data.Mobile,
			MobilePrivate: data.MobilePrivate,
			Email: data.Email,
			EmailEvent: data.EmailEvent,
			EmailFinancial: data.EmailFinancial,
			EmailNewsletter: data.EmailNewsletter,
			Homepage: data.Homepage,

			IsEmailApproval: data.IsEmailApproval,
			IsTelephoneApproval: data.IsTelephoneApproval,
			IsMobileApproval: data.IsMobileApproval,
			IsLetterApproval: data.IsLetterApproval,
			IsSocialMediaApproval: data.IsSocialMediaApproval,
			IsSMSApproval: data.IsSMSApproval,
			IsMessengerApproval: data.IsMessengerApproval,
			IsNewsletterApproval: data.IsNewsletterApproval,

			WhatsApp: data.WhatsApp,
			Telegram: data.Telegram,
			Messenger: data.Messenger,
			Signal: data.Signal,
			Xing: data.Xing,
			LinkedIn: data.LinkedIn,
			Facebook: data.Facebook,
			Instagram: data.Instagram,
			Twitter: data.Twitter,
			TikTok: data.TikTok,
		};

		let result;

		if (payload.ID) {
			result = await CommunicationDataService.updateOne(payload)
		} else {
			result = await CommunicationDataService.insertOne(payload)
		}

		if (result.isSuccess) {
			if (result.data) {
				showInfoSnackbar(Messages.Success);
				await getCompany();
			} else {
				showInfoSnackbar(Messages.ChangeProposalCreated);
			}
		} else {
			showErrorSnackbar(Messages.Error);
		}
		resetWarning();

	};

	// Favorite

	const onFavoriteClicked = async () => {
		if (companyData.IsFavorite) {
			await FavoriteService.deleteOneEx(companyData.ID, getCompany);
		} else {
			await FavoriteService.insertOne(
				{ companyId: companyData.ID },
				getCompany,
			);
		}
	};

	// CompanyLocation

	const onCompanyLocationOpen = (businessRegistration = null) => {
		if (businessRegistration) {
			setChosenBusinessRegistration(businessRegistration);
		} else {
			setChosenBusinessRegistration(null);
		}
		setIsCompanyLocationPopUpOpen(true);
	};

	const onCompanyLocationClose = () => {
		setIsCompanyLocationPopUpOpen(false);
		setChosenAddressRegistration(null);
	};

	const disabled =
		!updatedCompanyFields?.Name ||
		!updatedCompanyFields?.Abbreviation ||
		!updatedCompanyFields?.WKO ||
		!updatedCompanyFields?.County;

	const onCreateFormPopUpOpen = () => {
		setIsCreateFormPopUpOpen(true);
	};

	const onCreateFormPopUpClose = () => {
		setIsCreateFormPopUpOpen(false);
	};

	// Create a ref for the component you want to scroll
	const scrollRef = useRef();

	// Function to scroll to the end of the component
	const scrollToBottom = () => {
		// Scroll the component to the end
		scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
	};

	const { data, error, isLoading } = useGetFormsQuery(null, undefined);
	const forms = data;

	const [formList, setFormList] = useState([])
	const [page, setPage] = useState(0);
	const pageSize = 10;

	useEffect(() => {
		let list = forms?.slice(page * pageSize, (page + 1) * pageSize);

		while (list?.length < 10) {
			list.push({})
		}

		setFormList(list);
	}, [forms])

	const [updateFormStatus, { error: updateError, isLoading: isUpdating }] = useUpdateGoalStatusMutation();
	const onChange = (form) => {
		updateFormStatus(form)
		setFormList(old => old.map(el => {
			if (el.ID === form.ID) {
				return form
			}
			return el
		}))
	}

	const isMobile = useMediaQuery("(max-width: 850px)")

	return (
		<CompanyContext.Provider
			value={{
				companyData,

				chosenAddressRegistrationCompany: chosenAddressRegistration,
				setChosenAddressRegistrationCompany: setChosenAddressRegistration,

				chosenBusinessRegistrationCompany: chosenBusinessRegistration,
				getBusinessRegistrationListCompany,

				chosenBusinessRegistration,
				businessRegistrationList,

				onContactPersonAdd,
				mode,

				chosenPerson,
				setChosenPerson,
				setUpdatedCompanyFields,
				setUpdatedCompanyContactFields,
				setUpdatedContactPermissionFields,

				commentList,
				chosenComment,

				photoList,
				chosenPhoto,

				fileList,
				chosenFile,

				activityList,
				chosenActivity,

				contactPersonList,
				setContactPersonList,
				chosenContactPerson,

				chosenAddressRow,
				chosenAddressRegistration,

				showRow,
				saveCommunicationData,
			}}
		>
			<QuickActionButtons mail={companyData?.CommunicationData?.Email} tel={companyData?.CommunicationData?.Telephone} photos={scrollToBottom} />
			<Container
				ref={scrollRef}
				maxWidth='md'
				sx={{
					p: 0,
					overflowX: "hidden",
					[swvTheme.breakpoints.up('sm')]: {
						p: 2,
						mt: 5,
						mb: 5,
					},
				}}
			>
				<Grid
					container
					spacing={0}
					p={2}
					backgroundColor='#E9E9E9'
					borderRadius='4px'
					sx={{
						[swvTheme.breakpoints.up('sm')]: {
							p: 4,
						},
					}}
				>
					<Grid xs={12} className={classes.companyInfoTable}>
						<Card
							sx={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								p: 2,
							}}
						>
							<ArrowBackIos
								fontSize={'small'}
								onClick={() => navigate('/', { replace: true })}
							/>

							<Avatar
								style={{ height: '50px', width: '50px' }}
								src={photoURL}
								onClick={openImagePreviewPopup}
								className={classes.personAvatar}
							>
								<CompanyIcon />
							</Avatar>

							<ImageUploadPopup
								isOpen={isPhotoUploadModalOpen}
								onClose={closePhotoUploadPopup}
								onSave={updateCompanyProfilePhoto}
							/>

							<ImagePreviewPopup
								onClose={closeImagePreviewPopup}
								isOpen={isImagePreviewPopupOpen}
								imageToPreview={photoURL}
								fallbackImage={<CompanyIcon />}
								onDeleteImage={deleteCompanyProfilePhoto}
								onChangeImage={openPhotoUploadPopup}
							/>

							<Typography variant='h1' fontSize={20} pl={1} pr={3}>
								{companyData.Name}
							</Typography>
							{companyData?.IsFavorite ? (
								<FavoriteIcon
									sx={{ cursor: 'pointer', color: '#3E004D', fontSize: 20 }}
									onClick={() => onFavoriteClicked()}
								/>
							) : (
								<FavoriteBorderIcon
									sx={{ cursor: 'pointer', color: '#3E004D', fontSize: 20 }}
									onClick={() => onFavoriteClicked()}
								/>
							)}
						</Card>

						<Box textAlign='center' mt={3}>
							<CustomButton
								value='Formular erstellen'
								largeBorderRadius
								sx={{ width: '300px !important', fontSize: '14px' }}
								startIcon={
									<Printer
										sx={{
											height: '30px',
											width: 'auto',
										}}
									/>
								}
								onClick={onCreateFormPopUpOpen}
							/>
						</Box>

						<Stack
							direction='row'
							spacing={1}
							alignItems='center'
							justifyContent='center'
							mt={5}
						>
							<Typography color={swvTheme.typography.lilac}>
								alle Daten
							</Typography>
							<Switch
								onChange={onSwitchChange}
								sx={{
									'& .Mui-checked+.MuiSwitch-track': {
										backgroundColor: '#732382 !important',
									},
									'& .Mui-checked .MuiSwitch-thumb': {
										color: '#732382',
									},
								}}
							/>
							<Typography color={swvTheme.typography.lilac}>
								befüllte Daten
							</Typography>
						</Stack>

						<BaseDataTables onCompanySave={onCompanySave} />

						<AddressTable
							onOpenAddressModal={onOpenAddressModal}
							deleteAddressRegistration={deleteAddressRegistration}
						/>

						<ContactTable onCompanySave={onCompanySave} />

						<ContactPermissions />

						<BusinessTable
							onCompanyLocationOpen={onCompanyLocationOpen}
							deleteCompanyLocation={deleteCompanyLocation}
						/>

						<CommentTable
							onCommentModalOpen={onCommentModalOpen}
							deleteComment={deleteComment}
						/>

						<Grid container columnSpacing={6}>
							<AttachmentTable
								onFileModalOpen={onFileModalOpen}
								downloadFile={downloadFile}
								deleteFile={deleteFile}
							/>
						</Grid>

						<Grid container columnSpacing={6}>
							<Grid xs={12} mt={3}>

								<Box sx={{ alignItems: 'center' }}>
									<Paper sx={{ borderRadius: '8px', overflowX: 'scroll' }}>
										<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} p={2}>
											<Typography color={swvTheme.typography.lilac} fontSize={18} fontWeight={500}>
												Formulare
											</Typography>
										</Box>
										<Table style={isMobile ? { width: "400%" } : {}}>
											<TableHead>
												<TableRow>
													<TableCell style={{ width: "15%" }}>Datum</TableCell>
													<TableCell style={{ width: "40%" }}>Titel</TableCell>
													{/* <TableCell style={{ width: "15%" }}>Firma</TableCell> */}
													{!isMobile && <TableCell style={{ width: "10%" }}>E-Mail Zustellung</TableCell>}
													{/* <TableCell style={{ width: "15%" }}>Kopie Empfänger</TableCell> */}
													<TableCell style={{ width: "30%" }}>Status</TableCell>
													<TableCell style={{ width: "15%" }}>Ersteller</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{formList?.filter(row => row.FK_Company == companyData.ID).map((row) => (
													<FormOverviewRow companyPage={true} onChange={onChange} row={row} key={row.ID} />
												))}
											</TableBody>
										</Table>
									</Paper>
								</Box>
							</Grid>
						</Grid>

						<ActivityTable
							onActivityModalOpen={onActivityModalOpen}
							deleteActivity={deleteActivity}
						/>

						<ContactPersonTable
							onPersonModalOpen={onPersonModalOpen}
							deleteContactPerson={deleteContactPerson}
							setContactPersonMaster={setContactPersonMaster}
						/>

						{photoUriList?.length === photoList?.length &&
							<CompanyImageCarousel
								imageList={photoList}
								imageUriList={photoUriList}
								onPhotoModalOpen={onPhotoModalOpen}
								deletePhoto={() => { }} //ToDo: add deletePhoto function
							/>
						}
					</Grid>
				</Grid>
			</Container>

			<AddressPopUp
				isOpen={isAddressModalOpen}
				onClose={onAddressModalClose}
				CompanyContext={CompanyContext}
				onInsert={onAddressRegistrationInsert}
				onUpdate={onAddressRegistrationUpdate}
			/>

			<ActivityPopUp
				isOpen={isActivityPopUpOpen}
				onSubmit={onActivityModalSubmit}
				onClose={onActivityModalClose}
				chosenActivity={chosenActivity}
				ActivityContext={CompanyContext}
			/>

			<PersonPopUp
				isOpen={isPersonModalOpen}
				onClose={onPersonModalClose}
				chosenPerson={chosenPerson}
				setChosenPerson={setChosenPerson}
				onPersonApply={onPersonApply}
				onContactPersonApply={onContactPersonApply}
				chosenPersonCaller={chosenPerson}
				companyData={companyData}
			/>

			<PersonToContactPersonPopup
				personId={contactPersonToAdd}
				company={companyData}
				isOpen={isPersonToContactPersonModalOpen}
				onClose={onPersonToContactPersonModalClose}
			/>

			<CommentPopUp
				isOpen={isCommentModalOpen}
				onClose={onCommentModalClose}
				onSubmit={onCommentModalSubmit}
				CommentContext={CompanyContext}
			/>

			<PhotoPopUp
				isOpen={false}
				onClose={onPhotoModalClose}
				PhotoContext={CompanyContext}
			/>

			<ImageUploadPopup
				onClose={onPhotoModalClose}
				onSave={(data) => {
					FileService.insertOne(data, onPhotoModalClose);
				}}
				isOpen={isPhotoModalOpen}
				showAdditionalInfoFields
				companyData={companyData}
			/>

			<FilePopUp
				isOpen={isFileModalOpen}
				onClose={onFileModalClose}
				FileContext={CompanyContext}
			/>

			<CompanyLocationPopUp
				isOpen={isCompanyLocationPopUpOpen}
				onClose={onCompanyLocationClose}
			/>

			<CreateFormPopUp
				isOpen={isCreateFormPopUpOpen}
				onClose={onCreateFormPopUpClose}
			/>
		</CompanyContext.Provider>
	);
}
