import React, { useContext, useState } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { AdminCheckContext } from '../../AdminCheckContext';
import { getDate, getPerson } from '../../../../../helpers/database';

import { useStyles } from './styles';
import { useGlobalStyles } from '../../../../../customStyles';

export default function CommentTable() {
  const classes = useStyles();
  const cssClass = useGlobalStyles();

  const {
    commentList,
    chosenComment,
    setChosenComment,
    setChosenActivity,
    handleAdminCheckModalOpen,
  } = useContext(AdminCheckContext);

  const handleRowClick = (row) => {
    setChosenActivity(null);
    setChosenComment(row);
    handleAdminCheckModalOpen();
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={cssClass.dateCell}>Erstellt</TableCell>
            <TableCell className={cssClass.mediumCell}>Benutzer</TableCell>
            <TableCell className={cssClass.mediumCell}>Firma</TableCell>
            <TableCell>Notiz</TableCell>
            <TableCell className={cssClass.dateCell}>Freigabe</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {commentList?.map((row) => {
            return (
              <TableRow
                key={row.ID}
                selected={chosenComment?.ID == row.ID}
                onClick={() => handleRowClick(row)}
              >
                <TableCell>{getDate(row.Creation)}</TableCell>
                <TableCell>{getPerson(row.User)}</TableCell>
                <TableCell>{row.Company.Name}</TableCell>
                <TableCell>{row.Content}</TableCell>
                <TableCell>{getDate(row.Approval)}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}