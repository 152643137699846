import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';


const initialAddressState = {
  addresses: [],
};

const AddressSlice = createSlice({
  name: 'address',
  initialState: initialAddressState,
  reducers: {
    setAddresses(state, action) {
      state.addresses = action.payload;
    },
  },
});

export default AddressSlice.reducer;
export const { setAddresses } = AddressSlice.actions;
