import * as endpoints from '../../constants/endpoints';
import { apiClient } from '../apiClient';

export const getList = async (companyId, personId, success, error) => {
  const response = await apiClient.getList(endpoints.getContactPersonList, { companyId, personId }, success, error);
  return response.data.data;
};

export const deleteOne = async (id, success, error) => {
  const response = await apiClient.deleteOne(endpoints.deleteContactPerson, id, success, error);
  return response.data;
};

export const generateMembershipPDF = async (data, success, error) => {
  const response = await apiClient.post(endpoints.generateMembershipPDF, data, success, error);
  return response.data;
};

export const generateElectoralListPDF = async (data, success, error) => {
  const response = await apiClient.post(endpoints.generateElectoralListPDF, data, success, error);
  return response.data;
};

export const generateVotingCardPDF = async (data, success, error) => {
  const response = await apiClient.post(endpoints.generateVotingCardPDF, data, success, error);
  return response.data;
};

export const generateSupportStatementPDF = async (data, success, error) => {
  const response = await apiClient.post(endpoints.generateSupportStatementPDF, data, success, error);
  return response.data;
};

export const generateConsentSupportStatementPDF = async (data, success, error) => {
  const response = await apiClient.post(endpoints.generateConsentSupportStatementPDF, data, success, error);
  return response.data;
};

export const scanCard = async (data, success, error) => {
  const response = await apiClient.post(endpoints.scanCard, data, success, error);
  return response.data;
};
