import * as endpoints from '../../constants/endpoints';
import { apiClient } from '../apiClient';

export const getList = async (query, success, error) => {
  const response = await apiClient.getList(endpoints.getCompanyList, query, success, error);
  return response.data;
};

export const getListPersonResultCount = async (query, success, error) => {
  const response = await apiClient.get(endpoints.getListPersonResultCount, query, success, error);
  return response.data;
}

export const getListAddressResultCount = async (query, success, error) => {
  const response = await apiClient.get(endpoints.getListAddressResultCount, query, success, error);
  return response.data;
}

export const getExportList = async (query, success, error) => {
  const response = await apiClient.postDownload(endpoints.getCompanyExportList, query, success, error);
  return response.data;
};

export const getOne = async (data, success, error) => {
  const response = await apiClient.post(endpoints.getCompany, data, success, error);
  return response.data;
};

export const getHistoryList = async (data, success, error) => {
  const response = await apiClient.post(endpoints.getHistoryList, data, success, error);
  return response.data;
};

export const updateOne = async (data, success, error) => {
  const response = await apiClient.updateOne(endpoints.updateCompany, data, success, error);
  return response.data;
};

export const insertOne = async (data, success, error) => {
  const response = await apiClient.insertOne(endpoints.insertCompany, data, success, error);
  return response.data;
};

export const removeMunicipality = async (companyId, success, error) => {
  const response = await apiClient.put(endpoints.removeMunicipality, { companyId }, success, error);
  return response.data;
};

export const getMunicipalityCompanyList = async (municipalityId, success, error) => {
  const response = await apiClient.getList(endpoints.getMunicipalityCompanyList, { municipalityId }, success, error);
  return response.data;
};

export const getListEx = async (query, success, error) => {
  const response = await apiClient.getList(endpoints.getCompanyListEx, query, success, error);
  return response.data;
};

export const checkCompanyWKO = async (WKO, success, error) => {
  const response = await apiClient.get(endpoints.checkCompanyWKO, { WKO }, null, null, success, error);
  return response.data;
}

export const deleteOne = async (id, success, error) => {
  const response = await apiClient.deleteOne(endpoints.deleteCompany, id, success, error);
  return response.data;
}
