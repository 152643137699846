import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import ModalWrapper from '../../common-components/ModalWrapper';
import { DecorativeCorners } from '../../common-components/DecorativeCorners';

import { Avatar, Box, Link } from '@mui/material';

import { styles } from './styles';

export const ImagePreviewPopup = ({
  onClose,
  isOpen,
  imageToPreview,
  onDeleteImage,
  onChangeImage,
  fallbackImage,
}) => {
  const handleChangeImage = () => {
    onClose();
    onChangeImage();
  };

  const handleDeleteImage = () => {
    onClose();
    onDeleteImage();
  };

  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={onClose}
      modalWidth="600px !important"
    >
      <DecorativeCorners sx={styles.decorativeCorders}>
        <Box sx={styles.rootContainer}>
          <Avatar sx={styles.avatar} src={imageToPreview}>
            {fallbackImage}
          </Avatar>
          <Box sx={styles.buttonContainer}>
            <Link onClick={handleChangeImage} sx={styles.button}>
              Ändern
            </Link>
            <Link onClick={handleDeleteImage} sx={styles.button}>
              Löschen
            </Link>
          </Box>
        </Box>
      </DecorativeCorners>
    </ModalWrapper>
  );
};

ImagePreviewPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  imageToPreview: PropTypes.string.isRequired,
  onDeleteImage: PropTypes.func.isRequired,
  onChangeImage: PropTypes.func.isRequired,
  fallbackImage: PropTypes.node,
};
