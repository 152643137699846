import React, { useCallback, useEffect, useState, useContext } from 'react';
import { useForm, useWatch } from 'react-hook-form';

import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Container from '@mui/material/Container';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import FormSelect from '../../../../common-components/FormSelect';
import BoxCenter from '../../../../common-components/BoxCenter';
import FormDatePicker from '../../../../common-components/FormDatePicker';
import CustomSelect from '../../../../common-components/CustomSelect';

import { CompanyLocationContext } from '../../CompanyLocationContext';
import { CompanyContext } from '../../../../pages/Company/CompanyContext';

import { BusinessCategoryAPI } from "../../../../../redux/services/BusinessCategoryService";
import { BusinessAPI } from "../../../../../redux/services/BusinessService";
import * as BusinessRegistrationService from '../../../../../services/requests/businessRegistration';

import { chooseCounty } from '../../../../../helpers/database';
import { tailString } from '../../../../../helpers/utils';
import BusinessSubcategoryPopUp from '../../../BusinessSubcategory';

import { useStyles } from './styles';

export default function BusinessRegistrationDetails() {
  const classes = useStyles();

  const { getBusinessRegistrationListCompany } = useContext(CompanyContext);

  const {
    isOpen,
    currentAddressRegistration,
    getBusinessRegistrationList,
    currentBusinessRegistration,
    setCurrentBusinessRegistration,
    setCurrentTab
  } = useContext(CompanyLocationContext);

  const { data: businessCategoryListResponse } = BusinessCategoryAPI.useGetListQuery(undefined, { skip: !isOpen });
  const [isBusinessSubcategoryModalOpen, setIsBusinessSubcategoryModalOpen] = useState(false);

  const { control, reset, setValue } = useForm({
    defaultValues: {
      ID: '',
      businessCategory: '',
      businessSubcategory: '',
      businessSubcategoryNumber: '',
      businessSubcategoryName: '',
      business: '',
      status: '',
      start: '',
      end: '',
    }
  });

  const values = useWatch({ control });

  const { data: businessListResponse } = BusinessAPI.useGetListQuery(values?.businessSubcategory, { skip: !isOpen });

  useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen]);

  useEffect(() => {
    if (currentBusinessRegistration) {
      reset({
        ID: currentBusinessRegistration.ID,
        businessCategory: currentBusinessRegistration.Business.BusinessSubcategory.BusinessCategory.ID,
        businessSubcategory: currentBusinessRegistration.Business.BusinessSubcategory.ID,
        businessSubcategoryNumber: currentBusinessRegistration.Business.BusinessSubcategory.Number,
        businessSubcategoryName: currentBusinessRegistration.Business.BusinessSubcategory.Name,
        business: currentBusinessRegistration.Business.ID,
        status: currentBusinessRegistration.Status,
        start: currentBusinessRegistration.Start,
        end: currentBusinessRegistration.End,
      });
    }
  }, [currentBusinessRegistration]);

  const handleSubmit = useCallback(async () => {
    const data = {
      ID: currentBusinessRegistration?.ID,
      FK_AddressRegistration: currentBusinessRegistration?.AddressRegistration?.ID || currentAddressRegistration?.ID,
      FK_BusinessCategory: values.businessCategory,
      FK_BusinessSubcategory: values.businessSubcategory,
      FK_Business: values.business,
      Status: values.status,
      Start: values.start,
      End: values.end || null,
    }

    const success = async (data) => {
      setCurrentBusinessRegistration(data);
      await getBusinessRegistrationList();
      await getBusinessRegistrationListCompany();
    }

    if (currentBusinessRegistration)
      BusinessRegistrationService.updateOne(data, success);
    else
      BusinessRegistrationService.insertOne(data, success);

    setCurrentTab(1)
  }, [values])

  const disabled =
    !values?.businessCategory ||
    !values?.businessSubcategory ||
    !values?.business ||
    values?.status === '' ||
    !values?.start;

  // BusinessSubcategoryPopup

  const onBusinessSubcategoryModalOpen = () => {
    setIsBusinessSubcategoryModalOpen(true);
  }

  const onBusinessSubcategoryModalClose = () => {
    setIsBusinessSubcategoryModalOpen(false);
  }

  const onBusinessSubcategoryApply = (businessSubcategory) => {
    setValue('businessSubcategory', businessSubcategory.ID);
    setValue('businessSubcategoryNumber', businessSubcategory.Number);
    setValue('businessSubcategoryName', businessSubcategory.Name);
    setIsBusinessSubcategoryModalOpen(false);
  }

  return (
    <Container maxWidth="sm">
      <FormControl fullWidth>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <FormSelect
            name="businessCategory"
            label="Sparte"
            control={control}
            value={values?.businessCategory || ''}
            required
          >
            {businessCategoryListResponse?.data?.map((row) => (
              <MenuItem
                key={row.ID}
                value={row.ID}
                >
                  {row.Name}
              </MenuItem>)
            )}
          </FormSelect>
          <p>Bundesland: {chooseCounty(currentAddressRegistration?.County)}</p>
          <CustomSelect
            placeholder='Fachgruppe'
            disabled={!values?.businessCategory}
            value={values?.businessSubcategory}
            valueToDisplay={values?.businessSubcategory ? values?.businessSubcategoryNumber + ', ' + values?.businessSubcategoryName : ''}
            onClick={onBusinessSubcategoryModalOpen}
            required
          />
          <FormSelect
            name="business"
            label="Gewerbe"
            control={control}
            value={values?.business || ''}
            disabled={!values?.businessSubcategory}
            required
          >
            {businessListResponse?.data?.map((row) => (
                <MenuItem
                  key={row.ID}
                  value={row.ID}
                >
                  {row.Number}, {tailString(row.Name, 50)}
                </MenuItem>
              )
            )}
          </FormSelect>
          <FormSelect
            name="status"
            label="Status"
            control={control}
            value={values.status}
            required
          >
            <MenuItem value={1}>Aktiv</MenuItem>
            <MenuItem value={0}>Inaktiv</MenuItem>
            <MenuItem value={2}>Ruhend</MenuItem>
          </FormSelect>
          <FormDatePicker
            name="start"
            label="Anfang"
            control={control}
            required
          />
          <FormDatePicker
            name="end"
            label="Ende"
            control={control}
          />
          <BoxCenter>
            <Button
                onClick={() => setCurrentTab(1)}
            >
              Zurück
            </Button>
            <Button
              disabled={disabled}
              onClick={() => handleSubmit()}
            >
              Speichern
            </Button>
          </BoxCenter>

          <BusinessSubcategoryPopUp
            isOpen={isBusinessSubcategoryModalOpen}
            onClose={onBusinessSubcategoryModalClose}
            currentBusinessCategory={values.businessCategory}
            currentCounty={currentAddressRegistration?.County}
            currentBusinessSubcategoryNumber={values.businessSubcategoryNumber}
            currentBusinessSubcategoryName={values.businessSubcategoryName}
            handleApply={onBusinessSubcategoryApply}
        />
        </LocalizationProvider>
      </FormControl>
    </Container>
  );
};