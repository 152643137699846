import { baseQuery } from "./basequery";
import * as endpoints from "../../constants/endpoints";
import { createApi } from '@reduxjs/toolkit/query/react';

export const FormAPI = createApi({
    reducerPath: 'Forms',
    baseQuery,
    tagTypes: ['Forms'],
    endpoints: (builder) => ({
        getForms: builder.query({
            query: () => ({
                url: endpoints.getForm,
                method: 'GET',
            }),
            providesTags: ['Forms']
        }),
        updateGoalStatus: builder.mutation({
            query: (updatedEntity) => ({
                url: endpoints.updateFormStatus,
                method: 'PUT',
                params: { formID: updatedEntity.ID, status: updatedEntity.Goal.ActualValueAll },
            }),
            invalidatesTags: ['Forms']
        })
    })
});

export const { useGetFormsQuery, useUpdateGoalStatusMutation } = FormAPI;