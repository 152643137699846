import React, { useState } from 'react';

import { Box } from '@mui/material';

export default function ImageWithFallback({
  src,
  alt,
  className,
  onClick,
  children,
}) {
  const [error, setError] = useState(false);

  return !error ? (
    <Box
      component="img"
      className={className ?? ''}
      alt={alt ?? ''}
      src={src ?? ''}
      onClick={onClick ?? (() => {})}
      onError={() => setError(true)}
    ></Box>
  ) : (
    <>{children}</>
  );
}

