import dayjs from "dayjs";

export const getElectionYears = () => {
    // Get the current year
    const currentYear = dayjs().year();

    // Find the next year divisible by 5
    const nextYear = currentYear % 5 === 0 ? currentYear : currentYear + (5 - currentYear % 5);

    // Create the array
    const yearsArray = [];
    for (let year = nextYear; year >= nextYear - 20; year -= 5) {
        if (year >= 2020) {
            yearsArray.push(year);
        }
    }

    return yearsArray;
}

export const getElectionYear = () => {
    // Get the current year
    const currentYear = dayjs().year();

    // Find the next year divisible by 5
    const nextYear = currentYear % 5 === 0 ? currentYear : currentYear + (5 - currentYear % 5);

    return nextYear;
}