import React from 'react';

export default function Printer() {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_1_505)">
				<path d="M6 6.0011V1.0011H14.8486H18V5.2686V6.0011" stroke="currentColor" strokeLinecap="square"/>
				<path d="M5.5 10.5001C6.05228 10.5001 6.5 10.0524 6.5 9.50012C6.5 8.94784 6.05228 8.50012 5.5 8.50012C4.94772 8.50012 4.5 8.94784 4.5 9.50012C4.5 10.0524 4.94772 10.5001 5.5 10.5001Z" fill="currentColor"/>
				<path d="M6 18.0011H4C2.89543 18.0011 2 17.1057 2 16.0011V8.0011C2 6.89653 2.89543 6.0011 4 6.0011H20C21.1046 6.0011 22 6.89653 22 8.0011V16.0011C22 17.1057 21.1045 18.0011 19.9999 18.0011C19.3049 18.0011 18.6416 18.0011 18 18.0011" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
				<rect x="6" y="14.0011" width="12" height="9" stroke="currentColor" strokeLinecap="round"/>
			</g>
			<defs>
				<clipPath id="clip0_1_505">
					<rect width="24" height="24" fill="none" transform="translate(0 0.00109863)"/>
				</clipPath>
			</defs>
		</svg>
	)
}