import { Messages } from "../constants/messages";
import { IUser } from "../redux/slices/appSlice";

export const fillEmailText = (companyData: any, userData: IUser, form: string) => {
    let person = companyData.Master.Person;

    let mailText = Messages.MailtextForm;
    let salutation = person.Gender === "M" ? "er Herr " : (person.Gender === "W" ? "e Frau " : "* ");
    if (person.Title_Business) {
        salutation += `${person.Title_Front.trim()} `;
    }
    if (person.Title_Front) {
        salutation += `${person.Title_Front.trim()} `;
    }
    if (person.Firstname) {
        salutation += `${person.Firstname.trim()} `;
    }
    if (person.Lastname) {
        salutation += `${person.Lastname.trim()}`;
    }

    mailText = mailText
        .replace('[Anrede]', salutation)
        .replace('[Formular]', form)
        .replace('[Name]', `${userData.Firstname} ${userData.Lastname}`)
        .replace('[Tel]', `Tel: ${userData.Mobile}`)
        .replace('[Email]', `Email: ${userData.Email}`);

    return mailText;
};
