import React from 'react';

import FormSelect from '../FormSelect';
import { MenuItem } from '@mui/material';
import { useStyles } from './styles';
import { useGlobalStyles } from '../../../customStyles';

export default function FormBooleanSelect({
  name,
  label,
  control,
  value,
  required,
  disabled,
  defaultValue,
  emptyText,
  trueText,
  falseText,
  noDefaultValue
}) {
  const classes = useStyles();
  const cssGlobalClass = useGlobalStyles();

  return (
    <FormSelect
      required={required}
      label={label}
      name={name}
      control={control}
      value={value}
      defaultValue={defaultValue}
      emptyText={emptyText}
      disabled={disabled}
      noDefaultValue={noDefaultValue}
    >
      <MenuItem value={true}>{trueText ?? 'Ja'}</MenuItem>
      <MenuItem value={false}>{falseText ?? 'Nein'}</MenuItem>
    </FormSelect>
  )};
