import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQuery } from "./basequery";
import * as endpoints from '../../constants/endpoints';

export interface IInsertContactPerson {
    position: string,
    personId: number,
    companyId: number,
    start?: Date | string,
    end?: Date | string
}

export const ContactPersonApi = createApi({
    reducerPath: "ContactPersonService",
    baseQuery,
    tagTypes: ["ContactPerson", "ContactPersonList"],
    endpoints: (builder) => ({
        insertContactPerson: builder.mutation({
            query: (data: IInsertContactPerson) => ({
                url: endpoints.insertContactPerson,
                body: data,
                method: "POST",
            }),
            invalidatesTags: ["ContactPersonList"]
        }),
        getContactPerson: builder.query({
            query: (id: number) => ({
                url: endpoints.getContactPerson,
                params: { id },
                method: "GET",
            }),
            providesTags: (result, error, id) => [{ type: "ContactPerson", id: id }]
        })
    })
})

export const { useGetContactPersonQuery, useInsertContactPersonMutation } = ContactPersonApi