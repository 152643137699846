import React, { useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Button, Box, Container, CircularProgress } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import * as AuthenticationService from '../../../services/requests/authentication';
import { useSnackbarHelpers } from '../../../helpers/utils';
import { Messages } from '../../../constants/messages';
import BoxCenter from '../../common-components/BoxCenter';
import FormLink from '../../common-components/FormLink';
import FormTextField from '../../common-components/FormTextField';
import LogoLarge from '../../common-components/LogoLarge';

import { useStyles } from './styles';
import { useGlobalStyles } from '../../../customStyles';

export default function Login() {
  const classes = useStyles();
  const cssClass = useGlobalStyles();

  const navigate = useNavigate();
  const [emailError, setEmailError] = useState(false);
  const { showErrorSnackbar } = useSnackbarHelpers();
  
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { control, handleSubmit } = useForm({
    defaultValues: {
      Email: '',
      Password: '',
    },
  });

  const values = useWatch({ control });

  const onForgotPasswordClicked = () => {
    navigate('/passwortvergessen', { replace: false });
  };

  async function onLoginClicked() {
    setIsSubmitting(true);
    await AuthenticationService.authenticate(
      values.Email,
      values.Password,
      (data) => {
        localStorage.setItem('email', values.Email);
        localStorage.setItem('ID', data.id);
        navigate('/zweifaktor', { replace: false });
        setIsSubmitting(false);
      },
      () => {
        showErrorSnackbar(Messages.ErrorParameters);
        setIsSubmitting(false);
      },
    );
  }

  const disabled = !values.Email || emailError || !values.Password;

  return (
    <Box className={cssClass.gradientBackground}>
      <Container maxWidth="xs">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
            justifyContent: 'center',
          }}
        >
          <LogoLarge />
          <form onSubmit={handleSubmit(onLoginClicked)}>
            <FormTextField
              name="Email"
              label="E-Mail Adresse"
              control={control}
              required
              type="email"
              func={setEmailError}
              sx={{
                marginBottom: '12px',
              }}
              light
              autoFocus
            />
            <FormTextField
              name="Password"
              label="Passwort"
              control={control}
              required
              type="password"
              light
            />
            <FormLink onClick={onForgotPasswordClicked} sx={{ color: '#FFFFFF' }}>
              Passwort vergessen?
            </FormLink>
            <BoxCenter>
              <Button
                type="submit"
                disabled={disabled || isSubmitting}
                endIcon={isSubmitting ? <CircularProgress size={21} /> : <ArrowForwardIcon />}
                size="large"
                className={[cssClass.buttonDark, cssClass.buttonLogin]}
                sx={{ width: '210px' }}
              >
                Anmelden
              </Button>
            </BoxCenter>
          </form>
        </Box>
      </Container>
    </Box>
  );
}
