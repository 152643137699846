import React from 'react';
import { useEffect, useContext, useState } from 'react';
import { Controller } from 'react-hook-form';

import TextField from '@mui/material/TextField';

import { useStyles } from './styles';
import { useGlobalStyles } from '../../../customStyles';

export default function FormMemoField({ label, name, control, value, onChange, required, rows, disabled }) {
  const classes = useStyles();
  const cssGlobalClass = useGlobalStyles();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <TextField
          label={label}
          multiline
          rows={rows}
          onChange={onChange}
          value={value}
          disabled={disabled}
          required={required}
        />
      )}
    />
  )};