import React, { useContext } from 'react';

import { Typography, IconButton } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import { Edit, DeleteForever, SecurityOutlined } from '@mui/icons-material/';

import { CompanyContext } from '../../CompanyContext';
import { getDate, getPerson } from '../../../../../helpers/database';

import { useStyles } from './styles';
import { useGlobalStyles } from '../../../../../customStyles';
import { swvTheme } from '../../../../../swvTheme';

export default function CommentTable({ onCommentModalOpen, deleteComment }) {
  const classes = useStyles();
  const cssClass = useGlobalStyles();

  const { commentList, chosenComment } = useContext(CompanyContext);

  return (
    <Grid xs={12} mt={3}>
      <Box
        sx={{
          backgroundColor: '#FFFFFF',
          borderRadius: '8px',
          boxShadow: swvTheme.boxShadow,
        }}
      >
        <Typography color={swvTheme.typography.lilac} fontSize={18} fontWeight={500} p={2}>
          Notizen
        </Typography>
        {commentList?.map((row) => (
          <Grid container spacing={2} p={2} sx={{ fontSize: '14px' }} key={row.ID}>
            <Grid
              item
              xs={11}
              sx={{
                fontSize: '12px',
                fontWeight: '300',
                paddingTop: '0',
                paddingBottom: '0',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {getDate(row.Creation)}
              {row.IsPrivate && <SecurityOutlined fontSize="small" sx={{ paddingLeft: '6px' }} />}
            </Grid>
            <Grid
              item
              xs={1}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                paddingTop: '0',
                paddingBottom: '0',
              }}
            >
              <IconButton
                color="primary"
                onClick={() => deleteComment(row.ID)}
              >
                <DeleteForever />
              </IconButton>
              <IconButton
                color="primary"
                onClick={() => onCommentModalOpen(row)}
              >
                <Edit />
              </IconButton>
            </Grid>
            <Grid item xs={12} sx={{ borderBottom: '1px dashed #C7C7C7;' }}>
              {row.Content}
            </Grid>
          </Grid>
        ))}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 2,
            justifyContent: 'flex-end',
            padding: 2,
            cursor: 'pointer',
          }}
        >
          <AddIcon sx={{ fill: '#F50000' }} onClick={() => onCommentModalOpen()} />
        </Box>
      </Box>
    </Grid>
  );
}
