import { createUseStyles } from 'react-jss';

import { useGlobalStyles } from "../../../customStyles";
import { swvTheme } from "../../../swvTheme";

export const useStyles = createUseStyles({
    navbarMenu: {
        height: 'clamp(150px, 700px, 1010px)',

        margin: "17px 0",

        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',

        color: swvTheme.palette.secondary.light,

        "& .MuiMenuItem-root": {
            padding: '0',

            "&:hover": {
                backgroundColor: 'initial'
            }
        },

        '& a, & .menuItem': {
            color: 'inherit',
            textDecoration: 'none',
            padding: '6px 16px'
        }
    },

    navbarMenuContainer: {
        position: "absolute",
        width: '100svw',

        [swvTheme.breakpoints.up('md')]: {
            width: 'clamp(12vw, 12vw, 12vw)',
        },

        zIndex: '5',

        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',

        padding: "0",

        background: swvTheme.palette.background.gradient,
        boxShadow: "4px 0px 2px -2px gray",

    }
});
