import * as endpoints from '../../constants/endpoints';
import { apiClient } from '../apiClient';

export const insertOne = async (data, success, error) => {
  const response = await apiClient.insertOne(endpoints.insertMember, data, success, error);
  return response.data;
};

export const updateOne = async (data, success, error) => {
  const response = await apiClient.updateOne(endpoints.updateMember, data, success, error);
  return response.data;
};

export const getListOfMember = async (query, success, error) => {
  const response = await apiClient.getListNew(endpoints.getListOfMember,undefined, query, success, error);
  return response.data;
};