import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQuery } from "./basequery";
import * as endpoints from "../../constants/endpoints";

export const GoalStatusAPI = createApi({
    reducerPath: "GoalStatus",
    baseQuery,
    tagTypes: ['GoalStatusEnum', 'GoalStatus'],
    endpoints: (build) => ({
        getListEnum: build.query({
            query: () => ({
                url: endpoints.goalStatusEnumController,
                method: 'GET',
            }),
            providesTags: ['GoalStatusEnum']
        }),
        getListGoals: build.query({
            query: () => ({
                url: endpoints.getGoalStatusListNew,
                method: 'GET',
            }),
            providesTags: ['GoalStatus']
        }),
        updateGoalStatus: build.mutation({
            query: (updatedEntity) => ({
                url: endpoints.updateGoalStatusNew,
                method: 'PUT',
                params: { formId: updatedEntity.ID, goalId: updatedEntity.goalId, status: updatedEntity.Goal.ActualValueAll },
            }),
            invalidatesTags: ['GoalStatus']
        })
    })
});

export const { useGetListEnumQuery, useGetListGoalsQuery, useUpdateGoalStatusMutation } = GoalStatusAPI