import { createUseStyles } from 'react-jss';
import { swvTheme } from '../../../../../swvTheme';

export const useStyles = createUseStyles({
	searchButton: {
		[swvTheme.breakpoints.up("md")]: {
			width: "50%",
		}
	}
});
