import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useSpring, animated } from '@react-spring/web';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { TransitionProps } from '@mui/material/transitions';
import Collapse from '@mui/material/Collapse';
import { alpha, styled } from '@mui/material/styles';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem, TreeItemProps, treeItemClasses } from '@mui/x-tree-view/TreeItem';
import { Button, Container, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useGetFoundCompaniesListQuery, useGetListQuery } from '../../../redux/services/TreeViewService';
import SectionHeading from '../../common-components/SectionHeading';
import Organisation from '../../popups/Organisation';
import DataCard from '../../common-components/DataCard';
import { Remove } from '@mui/icons-material';
import { getBusinessRegistrationListOverview, getPhotoUrl } from '../../../helpers';
import { baseQuery } from '../../../redux/services/basequery';

function MinusSquare(props: SvgIconProps) {
    return (
        <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
        </SvgIcon>
    );
}

function PlusSquare(props: SvgIconProps) {
    return (
        <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
        </SvgIcon>
    );
}

function CloseSquare(props: SvgIconProps) {
    return (
        <SvgIcon
            className="close"
            fontSize="inherit"
            style={{ width: 14, height: 14 }}
            {...props}
        >
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
        </SvgIcon>
    );
}

function TransitionComponent(props: TransitionProps) {
    const style = useSpring({
        to: {
            opacity: props.in ? 1 : 0,
            transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
        },
    });

    return (
        <animated.div style={style}>
            <Collapse {...props} />
        </animated.div>
    );
}

const CustomTreeItem = React.forwardRef(
    (props: TreeItemProps, ref: React.Ref<HTMLLIElement>) => (
        <TreeItem {...props} TransitionComponent={TransitionComponent} ref={ref} />
    ),
);
CustomTreeItem.displayName = 'CustomTreeItem'

const StyledTreeItem = styled(CustomTreeItem)(({ theme }) => ({
    [`& .${treeItemClasses.iconContainer}`]: {
        '& .close': {
            opacity: 0.3,
        },
    },
    [`& .${treeItemClasses.group}`]: {
        marginLeft: 15,
        paddingLeft: 18,
        borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
}));

declare module 'react' {
    interface CSSProperties {
        '--tree-view-color'?: string;
        '--tree-view-bg-color'?: string;
    }
}

type StyledTreeItemProps = TreeItemProps & {
    bgColor?: string;
    bgColorForDarkMode?: string;
    color?: string;
    colorForDarkMode?: string;
    labelIcon: React.ElementType<SvgIconProps>;
    labelInfo?: string;
    labelText: string;
};

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
    color: theme.palette.text.secondary,
    [`& .${treeItemClasses.content}`]: {
        color: theme.palette.text.secondary,
        borderTopRightRadius: theme.spacing(2),
        borderBottomRightRadius: theme.spacing(2),
        paddingRight: theme.spacing(1),
        fontWeight: theme.typography.fontWeightMedium,
        '&.Mui-expanded': {
            fontWeight: theme.typography.fontWeightRegular,
        },
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
        '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
            backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
            color: 'var(--tree-view-color)',
        },
        [`& .${treeItemClasses.label}`]: {
            fontWeight: 'inherit',
            color: 'inherit',
        },
    },
    [`& .${treeItemClasses.group}`]: {
        marginLeft: 0,
        [`& .${treeItemClasses.content}`]: {
            paddingLeft: theme.spacing(2),
        },
    },
})) as unknown as typeof TreeItem;

const IconTreeItem = React.forwardRef(function StyledTreeItem(
    props: StyledTreeItemProps,
    ref: React.Ref<HTMLLIElement>,
) {
    const theme = useTheme();
    const {
        bgColor,
        color,
        labelIcon: LabelIcon,
        labelInfo,
        labelText,
        colorForDarkMode,
        bgColorForDarkMode,
        ...other
    } = props;

    const styleProps = {
        '--tree-view-color': theme.palette.mode !== 'dark' ? color : colorForDarkMode,
        '--tree-view-bg-color':
            theme.palette.mode !== 'dark' ? bgColor : bgColorForDarkMode,
    };

    return (
        <StyledTreeItemRoot
            label={
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        p: 0.5,
                        pr: 0,
                    }}
                >
                    <Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />
                    <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
                        {labelText}
                    </Typography>
                    <Typography variant="caption" color="inherit">
                        {labelInfo}
                    </Typography>
                </Box>
            }
            style={styleProps}
            {...other}
            ref={ref}
        />
    );
});

export default function CustomTreeView() {
    const { data: treeViewList } = useGetListQuery(undefined)

    const [expanded, setExpanded] = useState<string[]>([]);
    const [selected, setSelected] = useState<string[]>([]);

    const [queryParams, setQueryParams] = useState<Array<string>>([])

    const { data: foundCompanies, error, isLoading, refetch } = useGetFoundCompaniesListQuery(queryParams, { refetchOnMountOrArgChange: true });

    const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
        let tempNodeIds = [...nodeIds];
        let clickedNode = tempNodeIds[0];
        if (expanded.length > tempNodeIds.length) {
            clickedNode = expanded.filter(str => !tempNodeIds.includes(str))[0];
        }

        if (tempNodeIds.length > 1 && tempNodeIds?.filter(str => (!str.includes('_'))).length > 1) {
            tempNodeIds = nodeIds.filter(str => clickedNode.includes(str))
        }
        if (expanded.includes(clickedNode)) {
            tempNodeIds = [...tempNodeIds.filter(str => !str.includes(clickedNode))]
        }
        console.log("tempNodeIds", tempNodeIds, "tempNodeIds.filter", tempNodeIds.filter(str => !str.includes(clickedNode)))

        setExpanded([...tempNodeIds]);
    };

    const handleSelect = (event: React.SyntheticEvent, nodeIds: string[]) => {
        setSelected(nodeIds);
    };

    const handleExpandClick = () => {
        setExpanded((oldExpanded) =>
            oldExpanded.length === 0 ? ['1', '5', '6', '7'] : [],
        );
    };

    const handleSelectClick = () => {
        setSelected((oldSelected) =>
            oldSelected.length === 0 ? ['1', '2', '3', '4', '5', '6', '7', '8', '9'] : [],
        );
    };

    const fetchCompanies = (nodeIds: Array<string>) => {
        setQueryParams(nodeIds)
    }

    useEffect(() => {
        const timerId = setTimeout(() => {
            fetchCompanies(expanded);
        }, 500);

        // Cleanup function to clear the timer if the component unmounts or if searchTerm changes
        return () => {
            clearTimeout(timerId);
        };

    }, [expanded])

    useEffect(() => { console.log("foundCompanies", foundCompanies) }, [foundCompanies])

    return (
        <Container maxWidth='xl' >
            <SectionHeading title="Fraktionssuche" />
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                <Box sx={{ minHeight: 270, flexGrow: 1, maxWidth: "48%" }}>
                    <TreeView
                        aria-label="controlled"
                        defaultExpanded={['1']}
                        defaultCollapseIcon={<MinusSquare />}
                        defaultExpandIcon={<PlusSquare />}
                        defaultEndIcon={<CloseSquare />}
                        sx={{ overflowX: 'hidden' }}
                        expanded={expanded}
                        selected={selected}
                        onNodeToggle={handleToggle}
                        onNodeSelect={handleSelect}
                        multiSelect
                    >
                        {!treeViewList &&
                            <h3>Loading...</h3>}
                        {treeViewList &&
                            <Box sx={{ mb: 1 }}>
                                <Button
                                    onClick={handleExpandClick}
                                    disabled={expanded.length === 0}
                                >
                                    {'Alles Einklappen'}
                                </Button>
                            </Box>
                        }
                        {treeViewList?.map((organisation: any, index: number) => {
                            return <StyledTreeItem key={index} nodeId={organisation.ID.toString()} label={organisation.Name}>
                                {organisation.Counties?.map((county: any, index1: number) => {
                                    return <StyledTreeItem key={index + "_" + index1} nodeId={organisation.ID + "_" + county.Short} label={county.Name}>
                                        {county?.BusinessCategories.map((bc: any, index2: number) => {
                                            return <StyledTreeItem key={index + "_" + index1 + "_" + index2} nodeId={organisation.ID + "_" + county.Short + "_" + bc.ID} label={bc.Name} >
                                                {bc.BusinessSubcategories.map((bsc: any, index3: number) => {
                                                    return <StyledTreeItem key={index + "_" + index1 + "_" + index2 + "_" + index3} nodeId={organisation.ID + "_" + county.Short + "_" + bc.ID + "_" + bsc.ID} label={bsc.Name} >
                                                        {bsc.BusinessGroups.map((bg: any, index4: number) => {
                                                            return <StyledTreeItem key={index + "_" + index1 + "_" + index2 + "_" + index3 + "_" + index4} nodeId={organisation.ID + "_" + county.Short + "_" + bc.ID + "_" + bsc.ID + "_" + bg.ID} label={bg.Name} >
                                                            </StyledTreeItem>
                                                        })}
                                                    </StyledTreeItem>
                                                })}
                                            </StyledTreeItem>
                                        })}
                                    </StyledTreeItem>
                                })}
                            </StyledTreeItem>
                        })}

                    </TreeView>
                </Box>
                <Box sx={{ minHeight: 270, flexGrow: 1, maxWidth: "48%" }}>
                    <>{(foundCompanies?.length ?? 0) === 0 &&
                        <h3 style={{fontWeight: "400"}}>Keine Auswahl oder keine Ergebnisse</h3>}</>
                    <>{foundCompanies?.length > 0 && foundCompanies?.map((row: any) =>
                        <Grid
                            key={row.ID}
                            item
                            xs={2}
                            md={1}
                            onClick={() => /* ToDo: onRowClicked(row) */ { }}
                            sx={{ paddingTop: "0.5em" }}
                        >
                            <DataCard
                                key={row.ID}
                                lineOne={row.Name}
                                lineTwo={`FG Nr.: ${getBusinessRegistrationListOverview(row)}`}
                                placeholderImage='company'
                                image={getPhotoUrl(row)}
                                truncate
                                customIcon={<></>}
                            />
                        </Grid>
                    )}</>
                </Box>
            </Box>
        </Container >
    );
}