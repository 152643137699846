import { createUseStyles } from 'react-jss';
import { swvTheme } from '../../../../../swvTheme';

export const useStyles = createUseStyles({
    spaceBetweenTables: {
        gap: '36px',
        display: 'flex',
        flexDirection: 'column',
    }
});
