export const styles = {
  checkboxBase: {
    padding: 0,
    marginLeft: '11px',

    '&:hover': {
      background: 'none',
    },
  },
  label: {
    '& .MuiFormControlLabel-label': {
      ml: '12px',
      fontSize: '14px',
      letterSpacing: '0.28px',
    },
    '&:hover .MuiIcon-root': {
      border: ({ palette }) => `1px solid ${palette.button.light}`,
    },
  },
  checkboxIconBase: {
    width: '26px',
    height: '26px',
    borderRadius: '3px',
  },
  checkboxIconChecked: {
    border: ({ palette }) => `1px solid ${palette.button.light}`,
    '& path': {
      stroke: ({ palette }) => palette.button.light,
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  checkboxIconUnchecked: {
    border: ({ palette }) => `1px solid ${palette.secondary.gray}`,
  },
};
