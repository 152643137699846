import { chooseContactPersonPosition } from "./database";

export type UserRoleValues = 'B' | 'L' | 'F' | '';

export type CountyValues = 'B' | 'K' | 'M' | 'N' | 'O' | 'S' | 'T' | 'V' | 'W' | '';

export function getTitleForType(type: string) {
    switch (type) {
        case 'company': return "Firma";
        case 'person': return "Person";
        case 'contactPerson': return "Kontaktperson";
        case 'address': return "Addresse";
        default: return '-';
    }
}

export function getUserRoleName(userRole: UserRoleValues) {
    switch (userRole) {
        case 'B': return "Bund";
        case 'L': return "Landesorganisation";
        case 'F': return "Funktionär";
        default: return '-';
    }
}

export function getCountyNameForAbbreviation(county: string) {
    switch (county) {
        case 'B': return "Burgenland";
        case 'K': return "Kärnten";
        case 'M': return "Steiermark";
        case 'N': return "Niederösterreich";
        case 'O': return "Oberösterreich";
        case 'S': return "Salzburg";
        case 'T': return "Tirol";
        case 'V': return "Vorarlberg";
        case 'W': return "Wien";
        default: return "-"
    }
}

export function getActionTypeForAbbreviation(actionType: string) {
    switch (actionType) {
        case 'C': return "Erstellt";
        case 'D': return 'Gelöscht';
        case 'U': return 'Geändert';
    }
}

export function hasElevatedPrivileges(userRole: UserRoleValues) {
    return userRole === 'L' || userRole === 'B'
}


//usage var unique = Array.filter(onlyUnique); filters array for only unique values
export function onlyUnique(value: any, index: number, array: Array<any>) {
    return array.indexOf(value) === index;
}

export function getDisplayedTextForField(value: string | number, key: string) {
    const datePattern = /^(\d{4})-(\d{2})-(\d{2})$/;
    if (typeof value === 'string' && datePattern.test(value)) {
        const date = new Date(value);

        return date.toLocaleDateString('de-DE');
    }
    switch (value) {
        case 'true': return 'Ja';
        case 'false': return 'Nein';
        case 'as': return 'Als';
        case 'FK_StandardadresseWK': return 'WKO Standardadresse';
        case 'FK_ZustelladresseWK': return 'WKO Zustelladresse';
        case 'FK_ZustelladresseSWV': return 'SWV Zustelladresse';
        case 'City': return 'Stadt';
        case 'County': return 'Bundesland';
        case 'Number': return 'Hausnummer';
        case 'Street': return 'Straße';
        case 'Zipcode': return 'PLZ';
        case 'Founding': return 'Gründung';
        case 'Employees': return 'Mitarbeiter';
        case 'Abbreviation': return 'Unternehmensform';
        case 'Commercialregister': return 'Firmenbuch';
        case 'ZVR': return 'ZVR';
        case 'Name': return 'Name';
        case 'IsFamily': return 'Familienmitglied';
        case 'IsPensioner': return 'Pensionist';
        case 'IsLarge': return 'Großunternehmen';
        case 'Title_Front': return 'Titel Vorne';
        case 'Title_Business': return 'Titel Beruf';
        case 'Title_Back': return 'Titel Hinten';
        case 'Firstname': return 'Vorname';
        case 'Lastname': return 'Nachname';
        case 'Birthdate': return 'Geburtsdatum';
        case 'IsSMSApproval': return 'SMS Genehmigung';
        case 'IsEmailApproval': return 'E-Mail Genehmigung';
        case 'IsLetterApproval': return 'Brief Genehmigung';
        case 'IsMobileApproval': return 'Mobiltelefon Genehmigung';
        case 'IsMessengerApproval': return 'Messenger Genehmigung';
        case 'IsTelephoneApproval': return 'Telefon Genehmigung';
        case 'IsNewsletterApproval': return 'Newsletter Genehmigung';
        case 'IsSocialMediaApproval': return 'Social Media Genehmigung';
        case 'EmailNewsletter': return 'Newsletter E-Mail';
        case 'Email': return 'E-Mail';
        case 'Telephone': return 'Telefonnummer';
        case 'MobilePrivate': return 'Mobiltelefonnummer Privat';
        case 'Mobile': return 'Mobiltelefonnummer';
        case 'TelephonePrivate': return 'Telefonnummer Privat';
        case 'EmailFinancial': return 'Finanzen E-Mail';
        case 'EmailEvent': return 'Event E-Mail';
        case 'start': return 'Start';
        case 'position': return 'Position';
        case 'K':
        case 'B':
        case 'W':
        case 'T':
        case 'M':
        case 'S':
        case 'N':
        case '0': return getCountyNameForAbbreviation(value)
        case 'V':
            if (key.toLowerCase() === 'position') {
                return chooseContactPersonPosition(value)
            } else {
                return getCountyNameForAbbreviation(value)
            }
        case 'F':
        case 'G':
        case 'P':
        case 'A': return chooseContactPersonPosition(value);
        default: return value;
    }
}