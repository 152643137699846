import React, { useMemo, useContext } from 'react';
import { IconButton, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';

import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Edit, Replay } from '@mui/icons-material/';

import { CompanyContext } from '../../CompanyContext';

import { getAddressRegistration } from '../../../../../helpers/database';

import { useGlobalStyles } from '../../../../../customStyles';
import { useStyles } from './styles';
import { swvTheme } from '../../../../../swvTheme';

export default function AddressTable({ onOpenAddressModal, deleteAddressRegistration }) {
  const classes = useStyles();
  const cssClass = useGlobalStyles();
  const { companyData } = useContext(CompanyContext);

  const addressList = useMemo(
    () => [
      {
        AddressRegistration: companyData?.StandardWKO,
        name: 'WKO Standard',
        field: 'FK_StandardadresseWK',
      },
      {
        AddressRegistration: companyData?.DeliveryWKO,
        name: 'WKO Zustell',
        field: 'FK_ZustelladresseWK',
      },
      {
        AddressRegistration: companyData?.DeliverySWV,
        name: 'SWV Zustell',
        field: 'FK_ZustelladresseSWV',
      },
    ],
    [companyData],
  );

  return (
    <Grid xs={12} mt={3}>
      <Box sx={{ alignItems: 'center' }}>
        <Paper sx={{ borderRadius: '8px' }}>
          <Box p={2}>
            <Typography color={swvTheme.typography.lilac} fontSize={18} fontWeight={500}>
              Adressen
            </Typography>
          </Box>
          <Table>
            <TableBody className={cssClass.customTableBody}>
              {addressList?.map((row) => (
                <TableRow key={row.ID}>
                  <TableCell className={cssClass.shrinkCell}>{row.name}</TableCell>
                  <TableCell>{getAddressRegistration(row.AddressRegistration)}</TableCell>
                  <TableCell align="right" sx={{ paddingLeft: '0 !important', paddingRight: '0 !important', maxWidth: 'none', width: '40px' }}>
                    {row.name == 'WKO Zustell' && getAddressRegistration(row.AddressRegistration) && (
                      <IconButton
                        color="primary"
                        onClick={() => deleteAddressRegistration(row.AddressRegistration.ID, row.field)}
                      >
                        <Replay />
                      </IconButton>
                    )}
                  </TableCell>
                  <TableCell align="right" sx={{ paddingLeft: '0 !important', maxWidth: 'none', width: '44px' }}>
                    <IconButton
                      color="primary"
                      onClick={() => onOpenAddressModal(row)}
                    >
                      <Edit />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </Box>
    </Grid>
  );
}
