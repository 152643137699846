import React, { useContext } from 'react';

import { IconButton, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import SvgIconDoc from '../../../../common-components/SvgIconDoc';
import { Add, Edit, DeleteForever } from '@mui/icons-material/';

import { CompanyContext } from '../../CompanyContext';
import { getDate } from '../../../../../helpers/database';
import { useStyles } from './styles';
import { useGlobalStyles } from '../../../../../customStyles';
import { swvTheme } from '../../../../../swvTheme';

export default function AttachmentTable({ onFileModalOpen, downloadFile, deleteFile }) {
  const classes = useStyles();
  const cssClass = useGlobalStyles();

  const { fileList, chosenFile } = useContext(CompanyContext);

  return (
    <Grid xs={12} mt={3}>
      <Box sx={{ alignItems: 'center' }}>
        <Paper sx={{ borderRadius: '8px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} p={2}>
            <Typography color={swvTheme.typography.lilac} fontSize={18} fontWeight={500}>
              Dokumente
            </Typography>
          </Box>
          {fileList?.map((row) => (
            <Grid container spacing={2} p={2} sx={{ fontSize: '14px' }} key={row.ID}>
              <Grid
                item
                xs={11}
                sx={{
                  fontSize: '12px',
                  fontWeight: '300',
                  paddingTop: '0',
                  paddingBottom: '0',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {getDate(row.Creation)}
                {/* &nbsp;<SvgIconDoc />&nbsp; */}
              </Grid>

              <Grid
                item
                xs={1}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  paddingTop: '0',
                  paddingBottom: '0',
                }}
              >
                <IconButton
                  color="primary"
                  onClick={() => deleteFile(row.ID)}
                >
                  <DeleteForever />
                </IconButton>
                <IconButton
                  color="primary"
                  onClick={() => onFileModalOpen(row)}
                >
                  <Edit />
                </IconButton>
              </Grid>
              <Grid item xs={12} sx={{ borderBottom: '1px dashed #C7C7C7;' }}>
                {row.Caption}
              </Grid>
            </Grid>
          ))}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 2,
              justifyContent: 'flex-end',
              padding: 2,
              cursor: 'pointer',
            }}
          >
            <Add sx={{ fill: '#F50000' }} onClick={() => onFileModalOpen()} />
          </Box>
        </Paper>

        {/*
        <TableContainer sx={{ borderRadius: '8px' }}>
          <Typography color="#732382" fontSize={18} fontWeight={500} p={2}>
            Dokumente
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={cssClass.dateCell}>Erstellt</TableCell>
                <TableCell>Ersteller</TableCell>
                <TableCell className={cssClass.btnCell}>Laden</TableCell>
                <TableCell className={cssClass.btnCell}>Löschen</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fileList?.map((row) => (
                <TableRow key={row.ID} selected={chosenFile?.ID == row?.ID}>
                  <TableCell onClick={() => onFileModalOpen(row)}>
                    {getDate(row.Creation)}
                  </TableCell>
                  <TableCell onClick={() => onFileModalOpen(row)}>
                    {getPerson(row.User)}
                  </TableCell>
                  <DownloadCell
                    func={downloadFile}
                    id={row.ID}
                    name={row.Originalname}
                  />
                  <DeleteCell func={deleteFile} id={row.ID} />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        */}
      </Box>
      {/*
        <BoxLeft>
          <Button onClick={() => onFileModalOpen()}>Neu</Button>
        </BoxLeft>
      */}
    </Grid>
  );
}
