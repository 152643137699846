import React, { useState, useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { Button } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import * as AuthenticationService from '../../../services/requests/authentication';
import { useSnackbarHelpers } from '../../../helpers/utils';
import { Messages } from '../../../constants/messages';
import BoxCenter from '../../common-components/BoxCenter';
import FormTextField from '../../common-components/FormTextField';
import SectionHeading from '../../common-components/SectionHeading';
import LogoLarge from '../../common-components/LogoLarge';

import { useStyles } from './styles';
import { useGlobalStyles } from '../../../customStyles';

export default function PasswordSet() {
  const classes = useStyles();
  const cssClass = useGlobalStyles();

  const navigate = useNavigate();
  const params = useParams();
  const { showErrorSnackbar } = useSnackbarHelpers();

  const { control } = useForm({
    defaultValues: {
      Password: '',
      Confirmation: '',
    },
  });

  const values = useWatch({ control });

  async function onSaveClicked() {
    if (values.Password != values.Confirmation) {
      showErrorSnackbar(Messages.ErrorPasswords);
    } else {
      const preload = {
        token: params.token,
        password: values.Password,
      };

      await AuthenticationService.setPassword(preload, (data) => {
        showErrorSnackbar(Messages.Success);
        navigate('/login', { replace: false });
      });
    }
  }

  const disabled = !values.Password || !values.Confirmation;

  return (
    <Box className={cssClass.gradientBackground}>
      <Container maxWidth="xs">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
            justifyContent: 'center',
          }}
        >
          <LogoLarge />
          <SectionHeading
            title="Passwort ändern"
            sx={{
              mt: 2,
              mb: 2,
              '& .MuiTypography-h1': {
                color: '#FFF',
              },
            }}
          />
          <FormTextField
            name="Password"
            label="Neues Passwort"
            control={control}
            type="password"
            required
            light
          />
          <FormTextField
            name="Confirmation"
            label="Neues Passwort bestätigen"
            control={control}
            type="password"
            required
            light
          />
          <BoxCenter>
            <Button
              onClick={onSaveClicked}
              disabled={disabled}
              className={[cssClass.buttonDark, cssClass.buttonLogin]}
            >
              Speichern
            </Button>
          </BoxCenter>
        </Box>
      </Container>
    </Box>
  );
}
