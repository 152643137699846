import React, { useState, useEffect, useContext } from 'react';
import { useForm, useWatch } from 'react-hook-form';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Avatar, FormControl, Grid, Typography } from '@mui/material';
import * as FileService from '../../../services/requests/file';

import ModalWrapper from '../../common-components/ModalWrapper';
import BoxCenter from '../../common-components/BoxCenter';
import FormTextField from '../../common-components/FormTextField';
import FormCheckbox from '../../common-components/FormCheckbox';

import { useStyles } from './styles';
import { useGlobalStyles } from '../../../customStyles';

import { CameraAltOutlined, PhotoSizeSelectActualOutlined, UploadFile } from '@mui/icons-material';
import CustomButton from '../../common-components/CustomButton';
import Success from '../../common-components/Icons/Success';
import { DragAndDropArea } from '../../common-components/DragAndDropArea';

export default function FilePopUp({ isOpen, onClose, FileContext }) {
  const classes = useStyles();
  const cssClass = useGlobalStyles();
  const hiddenFileInput = React.useRef(null);

  const { chosenFile, companyData } = useContext(FileContext);

  const [file, setFile] = useState(null);

  const { control, reset } = useForm({
    defaultValues: {
      caption: '',
      sendMail: false,
      email: '',
      filename: '',
    },
  });

  const values = useWatch({ control });

  useEffect(() => {
    if (isOpen) {
      if (chosenFile?.ID) {
        const defaultValues = {
          caption: chosenFile.Caption,
          sendMail: chosenFile.Email != '',
          email: chosenFile.Email,
          filename: chosenFile.Originalname,
        };

        reset(defaultValues);
      }
    } else {
      const defaultValues = {
        caption: '',
        sendMail: false,
        email: '',
        filename: '',
      };

      reset(defaultValues);
    }

    setFile(null);
  }, [isOpen]);

  const onFileClicked = (_) => {
    hiddenFileInput.current.click();
  };

  const onCheckboxClicked = () => {
    if (values.sendMail) {
      reset({ ...values, sendMail: !values.sendMail, email: '' });
    }
  };

  const onFileChanged = (files) => {
    const file = files[0];

    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = async function () {
      reset({ ...values, filename: file.name, upload: reader.result });
      setFile(file);
    };
  };

  const onSaveClicked = async () => {
    const fd = new FormData();
    fd.append('companyId', companyData.ID);
    fd.append('caption', values.caption);
    fd.append('email', values.email);
    fd.append('file', file);

    await FileService.insertOne(fd, onClose);
  };

  const disabled = !values.caption || (!file && !chosenFile) || (values.sendMail && !values.email);
  const disabledEmail = !values.sendMail;

  return (
    <ModalWrapper isOpen={isOpen} onClose={onClose} title="Datei" className={cssClass.swvModalResponsiveMods + ' ' + classes.wrapper}>
      <FormControl>
        <DragAndDropArea
          onFilesSelected={onFileChanged}
        >

        <Grid container columnSpacing={{ xs: 5 }} rowSpacing={{ xs: 5, md: 0 }}>
          <Grid item xs={12} md={6}>
            <BoxCenter sx={{ alignItems: 'center', gap: '16px' }}>
              <CameraAltOutlined sx={{
                fontSize: {
                  xs: '40px',
                  sm: '50px'
                }
              }}/>
              <PhotoSizeSelectActualOutlined sx={{
                fontSize: {
                  xs: '37px',
                  sm: '46px'
                }
              }} />
            </BoxCenter>
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: {
                xs: '10px',
                sm: '20px'
              }
            }}>
              <Typography sx={{
                fontSize: {
                  xs: '20px',
                  sm: '24px',
                },
                mb: {
                  xs: '8px',
                  sm: '16px'
                }
              }}>
                Datei hochladen
              </Typography>
              <Typography sx={{
                fontSize: {
                  xs: '16px',
                  sm: '18px',
                },
                mb: {
                  xs: '3px',
                  sm: '16px'
                }
              }}>
                Drag & Drop
              </Typography>
              <Typography sx={{
                fontSize: {
                  xs: '14px',
                  sm: '16px',
                }
              }}>oder</Typography>
            </Box>
            <BoxCenter>
              <CustomButton value="Bibliothek" onClick={onFileClicked} />
            </BoxCenter>
          </Grid>
          <Grid item xs={12} md={6}>
            {file ? (
              <BoxCenter sx={{ flexDirection: 'column', alignItems: 'center' }}>
                <Success />
                <Typography align="center" fontWeight={500} fontSize={18}>
                  Datei wurde erfolgreich <br /> hochgeladen!
                </Typography>
              </BoxCenter>
            ) : (
              <BoxCenter>
                <Avatar
                  sx={{
                    m: {
                      xs: 0,
                      sm: 1
                    },
                    bgcolor: 'grey',
                    width: 90,
                    height: 90,
                  }}
                >
                  <UploadFile sx={{ fontSize: 54 }} />
                </Avatar>
              </BoxCenter>
            )}
            <BoxCenter>{values.filename}</BoxCenter>
            <Box sx={{ mt:{ xs: 0, sm: 3 } }}>
              <FormTextField
                name="caption"
                label="Bezeichnung"
                control={control}
                fullWidth
                required
                sx={{ mb: '10px'}}
              />
              <FormCheckbox
                name="sendMail"
                control={control}
                label="Kopie an folgende Email Adresse:"
                onClick={onCheckboxClicked}
                sx={{
                  '& .MuiFormControlLabel-label': {
                    lineHeight: '1.4',
                    fontSize: '14px',
                    ml: {
                      xs: '8px',
                      sm: '12px'
                    }
                  },
                  mb: '5px'
                }}
              />
              <FormTextField name="email" label="Email" control={control} fullWidth required disabled={disabledEmail} />
              <BoxCenter>
                <Button onClick={onSaveClicked} disabled={disabled}>
                  Speichern
                </Button>
              </BoxCenter>
            </Box>
          </Grid>
        </Grid>

        <input accept="*/*" type="file" ref={hiddenFileInput} onChange={(e) => onFileChanged(e.target.files)} style={{ display: 'none' }} />
        </DragAndDropArea>
      </FormControl>
    </ModalWrapper>
  );
}
