import React, { useContext, useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';

import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';

import CountySelect from '../../../../common-components/CountySelect';
import DistrictSelect from '../../../../common-components/DistrictSelect';
import BoxCenter from '../../../../common-components/BoxCenter';
import FormTextField from '../../../../common-components/FormTextField';

import { CompanyLocationContext } from '../../CompanyLocationContext';

import { useStyles } from './styles';
import { Typography } from '@mui/material';

export default function AddressRegistrationDetails() {
  const classes = useStyles();

  const {
    isOpen,
    setCompanyLocationData,
    currentAddressRegistration,
    setLocationDataSaveable
  } = useContext(CompanyLocationContext);

  const { control, reset } = useForm({
    defaultValues: {
      ID: '',
      County: '',
      FK_District: '',
      Zipcode: '',
      City: '',
      Street: '',
      Number: '',
      Latitude: '',
      Longitude: '',
    }
  });

  const values = useWatch({ control });
  setCompanyLocationData(values)

  useEffect(() => {
    if (currentAddressRegistration && isOpen) {
      reset({
        ID: currentAddressRegistration?.ID,
        County: currentAddressRegistration?.County,
        FK_District: currentAddressRegistration?.FK_District,
        Zipcode: currentAddressRegistration?.Zipcode,
        City: currentAddressRegistration?.City,
        Street: currentAddressRegistration?.Street,
        Number: currentAddressRegistration?.Number,
        Latitude: currentAddressRegistration?.Latitude,
        Longitude: currentAddressRegistration?.Longitude,
      });
    } else if (currentAddressRegistration && !isOpen) {
      reset();
    }
  }, [currentAddressRegistration]);

  useEffect(() => {
    setLocationDataSaveable(
      values?.County &&
      values?.Zipcode &&
      values?.City &&
      values?.Street &&
      values?.Number
    );
  }, [values]);

  useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen]);

  return (
    <FormControl fullWidth>
      <Typography variant={'h1'} pb={2}>Adresse</Typography>

      <CountySelect
        required
        name="County"
        control={control}
        value={values?.County}
      />
      <DistrictSelect
        name="FK_District"
        control={control}
        value={values.FK_District}
        county={values.County}
      />
      <FormTextField
        name="Zipcode"
        label="PLZ"
        control={control}
        required
        type="number"
      />
      <FormTextField
        name="City"
        label="Ort"
        control={control}
        required
      />
      <FormTextField
        name="Street"
        label="Straße"
        control={control}
        required
      />
      <FormTextField
        name="Number"
        label="Nummer"
        control={control}
        required
      />
      <FormTextField
        name="Latitude"
        label="Breite"
        control={control}
      />
      <FormTextField
        name="Longitude"
        label="Länge"
        control={control}
      />
    </FormControl>
  );
};