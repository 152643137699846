import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as CompanyService from '../../../services/requests/company';
import { chooseAction, getDate } from '../../../helpers/database';

import ModalWrapper from '../../common-components/ModalWrapper';

import { useStyles } from './styles';
import { useGlobalStyles } from '../../../customStyles';

export default function HistoryPopUp({ isOpen, onClose }) {
  const classes = useStyles();
  const cssClass = useGlobalStyles();

  const navigate = useNavigate();
  const [companyList, setCompanyList] = useState(null);

  useEffect(() => {
    if (isOpen) {
      getHistoryList();
    }
  }, [isOpen]);

  const getHistoryList = async () => {
    await CompanyService.getHistoryList(null, setCompanyList);
  };

  const handleRowClick = (row) => {
    onClose();
    navigate(`/firma/${row.Company.UniqueWKO}`, { replace: false });
  };

  return (
    <ModalWrapper isOpen={isOpen} onClose={onClose} title="Verlauf">
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={cssClass.dateCell}>Datum</TableCell>
                <TableCell>Firmenname</TableCell>
                <TableCell width='150px'>Aktivität</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {companyList?.map((row) => (
                <TableRow
                  key={row.ID}
                  onClick={() => handleRowClick(row)}
                >
                  <TableCell>{getDate(row.Creation)}</TableCell>
                  <TableCell>{row.Company.Name}</TableCell>
                  <TableCell>{chooseAction(row.Action)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
    </ModalWrapper>
  );
}
