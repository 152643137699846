import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./basequery"
import { IDistrict } from "../../interfaces/IDistrict";

export const DistrictApi = createApi({
    reducerPath: "DistrictApi",
    baseQuery,
    endpoints: (builder) => ({
        getAllDistricts: builder.query<IDistrict[], void>({
            query: () => "/district"
        })
    })
});

export const { useGetAllDistrictsQuery } = DistrictApi