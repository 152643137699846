import {fetchBaseQuery} from "@reduxjs/toolkit/query";
import {AUTH_TOKEN_KEY} from "../../constants/appConstants";
import {isRejectedWithValue} from "@reduxjs/toolkit";
import {goTo} from "../../router/routerHistory";

export const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BE_URL,
    prepareHeaders: async (headers) => {
        const token = localStorage.getItem(AUTH_TOKEN_KEY);
        // If we have a token set in localstorage, let's assume that we should be passing it.
        if (token) {
            headers.set("authorization", `Bearer ${token}`);
        }

        return headers;
    },
});

export const unauthenticatedMiddleware =
    ({ dispatch }) =>
        (next) =>
            (action) => {
                //TODO
/*                 if (isRejectedWithValue(action) && action.payload.status === 401) {
                    localStorage.removeItem(AUTH_TOKEN_KEY);
                    goTo('/login');
                } */

                return next(action);
    };
