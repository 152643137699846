import React, { useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';

import { Button, Box, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import * as AuthenticationService from '../../../services/requests/authentication';
import { useSnackbarHelpers } from '../../../helpers/utils';

import { useStyles } from './styles';
import { useGlobalStyles } from '../../../customStyles';

import SectionHeading from '../../common-components/SectionHeading';
import BoxCenter from '../../common-components/BoxCenter';
import FormLink from '../../common-components/FormLink';
import FormTextField from '../../common-components/FormTextField';
import LogoLarge from '../../common-components/LogoLarge';
import { Messages } from '../../../constants/messages';

export default function PasswordForgotten() {
  const classes = useStyles();
  const cssClass = useGlobalStyles();

  const navigate = useNavigate();
  const [emailError, setEmailError] = useState(false);
  const { showInfoSnackbar } = useSnackbarHelpers();

  const { control } = useForm({
    defaultValues: {
      Email: '',
    },
  });

  const values = useWatch({ control });

  async function onResetClicked() {
    await AuthenticationService.forgotPassword(
      values.Email,
      () => {
        showInfoSnackbar(Messages.Success);
        navigate('/login', { replace: false });
      },
      () => {
        showInfoSnackbar(Messages.Error);
      },
    );
  }

  const onCancelClicked = () => {
    navigate('/', { replace: false });
  };

  const disabled = !values.Email || emailError;

  return (
    <Box className={cssClass.gradientBackground}>
      <Container maxWidth="xs">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
            justifyContent: 'center',
          }}
        >
          <LogoLarge />
          <SectionHeading
            title="Passwort vergessen"
            sx={{
              mt: 2,
              mb: 2,
              '& .MuiTypography-h1': {
                color: '#FFF',
              },
            }}
          />
          <FormTextField
            name="Email"
            label="E-Mail"
            control={control}
            type="email"
            required
            func={setEmailError}
            light
          />
          <BoxCenter>
            <Button
              type="submit"
              disabled={disabled}
              onClick={() => onResetClicked()}
              className={[cssClass.buttonDark, cssClass.buttonLogin]}
            >
              Zurücksetzen
            </Button>
          </BoxCenter>
          <BoxCenter>
            <FormLink
              onClick={() => onCancelClicked()}
              sx={{ color: '#FFFFFF' }}
            >
              Abbrechen
            </FormLink>
          </BoxCenter>
        </Box>
      </Container>
    </Box>
  );
}
