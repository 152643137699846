import React, { useCallback, useContext } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';

import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Container from '@mui/material/Container';

import CountySelect from '../../../../common-components/CountySelect';
import FilterTop from '../../../../common-components/FilterTop';
import FormTextField from '../../../../common-components/FormTextField';

import { AdminGoalContext } from '../../AdminGoalContext';

import { chooseCounty, getPerson } from '../../../../../helpers/database';

import { useStyles } from './styles';
import { useGlobalStyles } from '../../../../../customStyles';

export default function UserSearch() {
  const classes = useStyles();
  const cssClass = useGlobalStyles();

  const {
    userList,
    getUserList,
    setCurrentUser,
    currentUser,
    setCurrentTab,
  } = useContext(AdminGoalContext);

  const { control } = useForm({
    defaultValues: {
      Name: '',
      County: '',
    },
  });

  const values = useWatch({ control });

  const handleRowClick = ((row) => {
    setCurrentUser(row);
    setCurrentTab(3);
  });

  return (
    <Container maxWidth="md">
      <FormControl>
        <FilterTop>
          <FormTextField
            fullWidth={false}
            name="Name"
            label="Name"
            control={control}
          />
          <CountySelect
            name="County"
            control={control}
            value={values?.County}
          />
          <Button onClick={() => getUserList(values)} className={classes.searchButton}>
            Suchen
          </Button>
        </FilterTop>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell className={cssClass.mediumCell}>Bundesland</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userList?.map((row) => (
                <TableRow
                  key={row.ID}
                  selected={currentUser?.ID == row.ID}
                  onClick={() => handleRowClick(row)}
                >
                  <TableCell>{getPerson(row)}</TableCell>
                  <TableCell>{chooseCounty(row.County)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </FormControl>
    </Container>
  );
}