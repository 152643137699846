import React, { useContext } from 'react';
import { useWatch, useFormContext } from 'react-hook-form';

import Container from '@mui/material/Container';

import CountySelect from '../../../../common-components/CountySelect';
import FormDatePicker from '../../../../common-components/FormDatePicker';
import FilterBottom from '../../../../common-components/FilterBottom';
import FormCheckbox from '../../../../common-components/FormCheckbox';

import { AdminCheckContext } from '../../AdminCheckContext';
import { styles } from './styles';
import CustomButton from '../../../../common-components/CustomButton';



export default function Filters() {
  const { control } = useFormContext();
  const values = useWatch({ control });

  const { getActivityList, getCommentList } = useContext(AdminCheckContext);

  const userData = JSON.parse(localStorage.getItem('userData'));

  const search = async () => {
    const preload = {
      Start: values.Start,
      End: values.End,
      County: values.County,
      IsApproved: values.IsApproved,
    };

    await getCommentList(preload);
    await getActivityList(preload);
  };

  return (
    <Container maxWidth="lg">
      <FilterBottom>
        <CountySelect
          name="County"
          control={control}
          value={values?.County || userData?.County}
          disabled={userData?.County}
        />
        <FormDatePicker
          name="Start"
          label="Anfang"
          control={control}
        />
        <FormDatePicker
          name="End"
          label="Ende"
          control={control}
        />
        <FormCheckbox
          name="IsApproved"
          control={control}
          label="Auch freigegebene"
        />
        <CustomButton
          value="Suchen"
          sx={styles.searchButton}
          onClick={() => search()}
        />
      </FilterBottom>
    </Container>
  );
}
