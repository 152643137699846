import React from 'react';
import { MenuItem, SxProps } from '@mui/material';
import FormSelect from '../FormSelect';
import { useStyles } from './styles';
import { useGlobalStyles } from '../../../customStyles';
import { Control } from 'react-hook-form';

interface ICountySelect {
  name: string,
  label?: string,
  control: Control<any>,
  value?: any,
  required?: boolean
  disabled?: boolean,
  noAsterisk?: boolean
  onClick?: () => void
  sx?: SxProps
}

export default function CountySelect({
  name,
  label,
  control,
  value,
  required,
  disabled,
  noAsterisk,
  onClick,
  sx
}: ICountySelect) {
  const classes = useStyles();
  const cssGlobalClass = useGlobalStyles();

  return (
    <FormSelect
      name={name}
      value={value}
      disabled={disabled}
      label={label ?? 'Bundesland'}
      control={control}
      onClick={onClick}
      required={required}
      noAsterisk={noAsterisk}
      sx={sx}
    >
      <MenuItem value={'B'}>Burgenland</MenuItem>
      <MenuItem value={'K'}>Kärnten</MenuItem>
      <MenuItem value={'N'}>Niederösterreich</MenuItem>
      <MenuItem value={'O'}>Oberösterreich</MenuItem>
      <MenuItem value={'S'}>Salzburg</MenuItem>
      <MenuItem value={'M'}>Steiermark</MenuItem>
      <MenuItem value={'T'}>Tirol</MenuItem>
      <MenuItem value={'V'}>Vorarlberg</MenuItem>
      <MenuItem value={'W'}>Wien</MenuItem>
    </FormSelect>
  );
}
