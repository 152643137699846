import * as endpoints from '../../constants/endpoints';
import { apiClient } from '../apiClient';

export const getList = async (query, success, error) => {
  const response = await apiClient.getList(endpoints.getPersonList, query, success, error);
  return response.data;
};

export const getSearchExport = async (query, success, error) => {
  const response = await apiClient.getList(endpoints.getPersonSearchExport, query, success, error);
  return response.data;
};

export const getListExport = async (query, success, error) => {
  const response = await apiClient.getList(endpoints.getPersonListExport, query, success, error);
  return response.data;
};

export const getListExportCSV = async (query, success, error) => {
  const response = await apiClient.getList(endpoints.getPersonListExportCSV, query, success, error);
  return response.data;
};

export const getOne = async (id, success, error) => {
  const response = await apiClient.getOne(endpoints.getPerson, id, success, error);
  return response.data;
};

export const insertOne = async (data, success, error) => {
  const response = await apiClient.insertOne(endpoints.insertPerson, { data }, success, error);
  return response.data;
};

export const updateOne = async (data, success, error) => {
  const response = await apiClient.updateOne(endpoints.updatePerson, { data }, success, error);
  return response.data;
};

export const deleteOne = async (id, success, error) => {
  const response = await apiClient.deleteOne(endpoints.deletePerson, id, success, error);
  return response.data;
};
