import React from 'react';

import { Controller } from 'react-hook-form';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import { Icon } from '@mui/material';

import { styles } from './styles';

import { ReactComponent as CheckIcon } from '../../../assets/icons/check.svg';

export default function FormCheckbox({ label, name, control, onClick, sx }) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <FormControlLabel
          sx={{...styles.label, ...sx}}
          control={
            <Checkbox
              sx={styles.checkboxBase}
              icon={
                <Icon
                  sx={{
                    ...styles.checkboxIconBase,
                    ...styles.checkboxIconUnchecked,
                  }}
                />
              }
              checkedIcon={
                <Icon
                  sx={{
                    ...styles.checkboxIconBase,
                    ...styles.checkboxIconChecked,
                  }}
                >
                  <CheckIcon />
                </Icon>
              }
              checked={value}
              onChange={onChange}
              onClick={onClick}
            />
          }
          label={label}
        />
      )}
    />
  );
}
