import React, { useState, useContext, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Messages } from '../../../../../constants/messages';
import MenuItem from '@mui/material/MenuItem';
import Container from '@mui/material/Container';
import { ContactPersonContext } from '../../PersonContext';
import { chooseGender } from '../../../../../helpers/database';

import {
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Box,
  IconButton,
  Button,

} from '@mui/material';
import { Edit, Close } from '@mui/icons-material/';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import FormSelect from '../../../../common-components/FormSelect';
import FormDatePicker from '../../../../common-components/FormDatePicker';
import FormTextField from '../../../../common-components/FormTextField';
import FormBooleanSelect from '../../../../common-components/FormBooleanSelect';

import BoxCenter from '../../../../common-components/BoxCenter';
import { useSnackbarHelpers } from '../../../../../helpers/utils';
import { getDate } from '../../../../../helpers/database';
import DataItemActionsPopup from '../../../DataItemActions';
import { useGlobalStyles } from '../../../../../customStyles';
import CustomButton from '../../../../common-components/CustomButton';


export const PersonDetails = ({
  chosenPersonCaller,
  insertPerson,
  updatePerson,
  onPersonApply,
  companyData,
}) => {
  const cssClass = useGlobalStyles();
  const { showRow } = useContext(ContactPersonContext);

  const [isEditMode, setIsEditMode] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const { showErrorSnackbar } = useSnackbarHelpers();

  const [isDataItemActionsPopupOpen, setIsDataItemActionsPopupOpen] = useState(false);
  const [chosenDataItem, setChosenDataItem] = useState(null);

  const { control } = useFormContext();
  const values = useWatch({ control });

  const disabled =
    values?.Firstname === '' ||
    values?.Lastname === '' ||
    values?.Family === '';

  const createData = (name, value) => {
    return { name, value };
  }

  const tableRows = [
    createData('Akad. Titel', values?.Title_Front),
    createData('Berufl. Titel', values?.Title_Business),
    createData('Vorname', values?.Firstname),
    createData('Nachname', values?.Lastname),
    createData('Akad. Titel nachg.', values?.Title_Back),
    createData('Geschlecht', chooseGender(values?.Gender)),
    createData('Geburtsdatum', getDate(values?.Birthdate)),
    createData('SWV Familie', (values?.IsFamily === true) ? 'Ja' : 'Nein'),
    createData('Verstorben', (values?.IsDead === true) ? 'Ja' : 'Nein'),
    createData('Sterbedatum', getDate(values?.Deathdate)),
    createData('Pensionist', (values?.IsPensioner === true) ? 'Ja' : 'Nein')
  ];

  useEffect(() => {
    if (isEditMode) {
      values.isModified = true;
    }
  }, [values]);

  useEffect(() => {
    let delay;

    document.querySelectorAll('.personBaseItem').forEach((item) => {
      const isNotEmpty = item.getElementsByTagName("td")[1].innerText;

      if (isNotEmpty) {
        item.addEventListener('touchstart', () => {
          delay = setTimeout(check, 1500);

          function check() {
            setIsDataItemActionsPopupOpen(true);
          }
        }, true);

        item.addEventListener('touchend', () => {
          clearTimeout(delay);
        });
      }
    });
  });

  const resetWarning = () => {
    values.isModified = false;
    setShowWarning(false);
    setIsEditMode(false);
  }

  const onDataItemActionsPopupClose = () => {
    setIsDataItemActionsPopupOpen(false);
  };

  return (
    <Box >
      <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            p: 2,
          }}
        >
          <Typography
            color="#732382"
            fontSize={18}
            fontWeight={500}
          >
            Stammdaten
          </Typography>

          {showWarning &&
              <Typography color="primary">{ Messages.WarningUnsafed }</Typography>}

          {isEditMode ? (
          <>
            <IconButton aria-label="close" onClick={() =>
              { if (values?.isModified)
                  setShowWarning(true);
                setIsEditMode(false)
              } }>
              <Close />
            </IconButton>
          </>
        ) : (
          <IconButton
            aria-label="edit"
            color="primary"
            onClick={() => setIsEditMode(!isEditMode)}
          >
            <Edit />
          </IconButton>
        )}
      </Box>

      {isEditMode ? (
        <FormControl fullWidth>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Table className={cssClass.twoColumnDataTable}>
              <TableBody className={cssClass.customTableBody}>
                <TableRow>
                  <TableCell className={cssClass.shrinkCell}>Akad. Titel:</TableCell>
                  <TableCell>
                    <FormTextField
                      name="Title_Front"
                      label="Akad. Titel"
                      control={control}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={cssClass.shrinkCell}>Berufl. Titel</TableCell>
                  <TableCell>
                    <FormTextField
                      name="Title_Business"
                      label="Berufl. Titel"
                      control={control}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={cssClass.shrinkCell}>Vorname</TableCell>
                  <TableCell>
                    <FormTextField
                      name="Firstname"
                      label="Vorname"
                      control={control}
                      required
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={cssClass.shrinkCell}>Nachname</TableCell>
                  <TableCell>
                    <FormTextField
                      name="Lastname"
                      label="Nachname"
                      control={control}
                      required
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={cssClass.shrinkCell}>Akad. Titel nachg.</TableCell>
                  <TableCell>
                    <FormTextField
                      name="Title_Back"
                      label="Akad. Titel nachg."
                      control={control}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={cssClass.shrinkCell}>Geschlecht</TableCell>
                  <TableCell>
                    <FormSelect
                      name="Gender"
                      label="Geschlecht"
                      control={control}
                      value={values.Gender}
                      required
                    >
                      <MenuItem value="M">Männlich</MenuItem>
                      <MenuItem value="W">Weiblich</MenuItem>
                      <MenuItem value="D">Divers</MenuItem>
                    </FormSelect>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={cssClass.shrinkCell}>Geburtsdatum</TableCell>
                  <TableCell>
                    <FormDatePicker
                      name="Birthdate"
                      label="Geburtsdatum"
                      control={control}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={cssClass.shrinkCell}>SWV Familie</TableCell>
                  <TableCell>
                    <FormBooleanSelect
                      name="IsFamily"
                      label="SWV Familie"
                      control={control}
                      value={values.IsFamily}
                      defaultValues={values.IsFamily}
                      required
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={cssClass.shrinkCell}>Verstorben</TableCell>
                  <TableCell>
                    <FormBooleanSelect
                      name="IsDead"
                      label="Verstorben"
                      control={control}
                      value={values.IsDead}
                      defaultValues={values.IsDead}
                      required
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={cssClass.shrinkCell}>Sterbedatum</TableCell>
                  <TableCell>
                    <FormDatePicker
                      name="Deathdate"
                      label="Sterbedatum"
                      control={control}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={cssClass.shrinkCell}>Pensionist</TableCell>
                  <TableCell>
                    <FormBooleanSelect
                      name="IsPensioner"
                      label="Pensionist"
                      control={control}
                      value={values.IsPensioner}
                      defaultValues={values.IsPensioner}
                      required
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <BoxCenter sx={{ pb: '25px' }}>
              <CustomButton
                value="Verwerfen"
                onClick={() => {
                  setIsEditMode(false);
                }}
                sx={{ width: '180px' }}
                light
                isClose
              />
              <CustomButton
                disabled={disabled}
                value="Speichern"
                onClick={() => {
                  values.ID ? updatePerson(resetWarning) : insertPerson(resetWarning);
                }}
                sx={{ width: '180px' }}
              />
            </BoxCenter>
          </LocalizationProvider>
        </FormControl>
      ) : (
        <Container sx={{ paddingLeft: '0px !important', paddingRight: '0px !important' }}>
          <Table>
            <TableBody>
              {tableRows?.map((row) => (showRow(row) &&
                <TableRow key={row.name} className='personBaseItem' onTouchStart={() => setChosenDataItem(row)}>
                  <TableCell >
                    {row.name}
                  </TableCell>
                  <TableCell align="left">{row.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <BoxCenter sx={{ pb: '25px'}}>
            {!companyData?.ID && onPersonApply && (
              <Button
                disabled={disabled}
                onClick={() => { updatePerson(); onPersonApply(); }}
              >
                Übernehmen
              </Button>
            )}
            {companyData?.ID && onPersonApply && chosenPersonCaller && (
              <Button
                disabled={disabled}
                onClick={() => { updatePerson(); onPersonApply(); }}
              >
                Übernehmen
              </Button>
            )}
          </BoxCenter>
        </Container>
      )}
      <DataItemActionsPopup
        isOpen={isDataItemActionsPopupOpen}
        onClose={onDataItemActionsPopupClose}
        chosenDataItem={chosenDataItem}
      />
    </Box>
  );
};
