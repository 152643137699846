export const styles = {
  rootContainer: {
    background: '#E9E9E9',
    p: 2, borderRadius: '4px'
  },
  favoriteItemDeleteLine: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px'
  },
  deleteIcon: {
    color: '#F50000',
    fontSize: '20px',
    p: '3px',
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: '#f1f1f1',
    }
  }
}