import React, { useEffect, useContext } from 'react';
import { Controller, useWatch, useForm } from 'react-hook-form';

import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Container from '@mui/material/Container';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import FormSelect from '../../../../common-components/FormSelect';

import { useSnackbarHelpers } from '../../../../../helpers/utils';

import { AdminGoalContext } from '../../AdminGoalContext';
import * as GoalService from '../../../../../services/requests/goal';

import BoxCenter from '../../../../common-components/BoxCenter';
import FormTextField from '../../../../common-components/FormTextField';
import FormDatePicker from '../../../../common-components/FormDatePicker';

import { useStyles } from './styles';

export default function GoalDetails() {
  const classes = useStyles();
  const { currentGoal, getGoalList, setCurrentGoal } = useContext(AdminGoalContext);
  const { } = useSnackbarHelpers();

  const { control, reset } = useForm({
    defaultValues: {
      Hard: '',
      Soft: '',
      Value: '',
      Start: '',
      End: '',
    },
  });

  const values = useWatch({ control });

  useEffect(() => {
    if (currentGoal) {
      reset({
        Hard: currentGoal.Hard || '',
        Soft: currentGoal.Soft || '',
        Value: currentGoal.Value || '',
        Start: currentGoal.Start || '',
        End: currentGoal.End || '',
      });
    }
  }, [currentGoal]);

  useEffect(() => {
    return () => {
      reset({
        Hard: '',
        Soft: '',
        Value: '',
        Start: '',
        End: '',
      });
    };
  }, []);

  const onSaveClicked = async () => {
    const preload = {
      ID: currentGoal?.ID,
      Hard: values?.Hard || null,
      Soft: values?.Soft || null,
      Value: values?.Value,
      Start: values?.Start,
      End: values?.End,
    };

    const success = async (data) => {
      setCurrentGoal(data);
    };

    if (currentGoal?.ID) {
      await GoalService.updateOne(preload, success);
    } else {
      await GoalService.insertOne(preload, success);
    }
  };

  const disabled = !values?.Value ||
    !values?.Start ||
    !values?.End;

  return (
    <Container maxWidth="sm">
      <FormControl>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <FormSelect
            label="Hart"
            name="Hard"
            control={control}
            value={values.Hard}
            disabled={values?.Soft}
          >
            <MenuItem value={1}>Aufnahme Ruhende Gewerbeberechtigungen</MenuItem>
            <MenuItem value={2}>Wahlkartenanträge</MenuItem>
            <MenuItem value={3}>Kandidaten für Wahlvorschlag</MenuItem>
            <MenuItem value={4}>Unterstützer für Wahlvorschlag</MenuItem>
            <MenuItem value={5}>Mitgliederwerbung</MenuItem>
          </FormSelect>
          <FormTextField
            name="Soft"
            label="Weich"
            control={control}
            disabled={Boolean(values?.Hard)}
          />
          <FormTextField
            name="Value"
            label="Ziel"
            control={control}
            required
            type="number"
          />
          <FormDatePicker
            name="Start"
            label="Anfang"
            control={control}
            required
          />
          <FormDatePicker
            name="End"
            label="Ende"
            control={control}
            required
          />
          <BoxCenter>
            <Button
              disabled={disabled}
              onClick={() => onSaveClicked()}
            >
              Speichern
            </Button>
          </BoxCenter>
        </LocalizationProvider>
      </FormControl>
    </Container>
  );
}