import React, { useState, useEffect, useCallback } from 'react';

import { useForm, useWatch } from 'react-hook-form';

import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';

import FormTextField from '../../common-components/FormTextField';
import BoxCenter from '../../common-components/BoxCenter';
import ModalWrapper from '../../common-components/ModalWrapper';
import FormBooleanSelect from '../../common-components/FormBooleanSelect';

import * as OrganisationService from '../../../services/requests/organisation';

import { useStyles } from './styles';
import { useGlobalStyles } from '../../../customStyles';

const styles = {
  buttons: {
    minWidth: '180px',
  }
};

export default function OrganisationPopUp({
  isOpen,
  onClose,
  currentOrganisation,
  handleApply,
}) {
  const classes = useStyles();
  const cssClass = useGlobalStyles();

  const [organisationList, setOrganisationList] = useState(null);
  const [chosenOrganisation, setChosenOrganisation] = useState(null);

  const { control, reset } = useForm({
    defaultValues: {
      name: '',
      type: '',
      isFamily: '',
    }
  });

  const values = useWatch({ control });

  useEffect(() => {
    setChosenOrganisation(null);
    setOrganisationList(null);

    if (isOpen) {
      reset({
        name: currentOrganisation?.Name || '',
        type: currentOrganisation?.Type || '',
        isFamily: currentOrganisation?.IsFamily || '',
      });
    }
  }, [isOpen]);

  const getOrganisationList = async () => {
    setChosenOrganisation(null);

    await OrganisationService.getList({
      name: values.name,
      type: values.type,
      isFamily: values.isFamily,
    }, setOrganisationList);
  };

  return (
    <ModalWrapper isOpen={isOpen} onClose={onClose} title="Organisation">
        <FormControl>
            <FormTextField
              name="name"
              label="Name"
              control={control}
            />
            <FormTextField
              name="type"
              label="Typ"
              control={control}
            />
            <FormBooleanSelect
              label="Family"
              name="isFamily"
              control={control}
              value={values.isFamily}
            />

          <div className={cssClass.listContainer}>
            { organisationList?.map((row) => <p
              key={row.ID}
              className={ chosenOrganisation?.ID == row.ID ? cssClass.chosen : cssClass.notchosen }
              onClick={() => setChosenOrganisation(row)}
            >
              {row.Name}
            </p>)}
          </div>
        </FormControl>

        <BoxCenter>
          <Button
            onClick={() => handleApply(chosenOrganisation)}
            disabled={!chosenOrganisation}
            sx={styles.buttons}
          >
            Übernehmen
          </Button>
          <Button
              onClick={() => getOrganisationList()}
              disabled={!values?.type && !values?.name}
              sx={styles.buttons}
            >
              Suchen
          </Button>
        </BoxCenter>
    </ModalWrapper>
  );
}