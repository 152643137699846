import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import TabPanel from '../../common-components/TabPanel';
import ModalWrapper from '../../common-components/ModalWrapper';

import AddressRegistrationList from './components/AddressRegistrationList';
import AddressRegistrationDetails from './components/AddressRegistrationDetails';
import ContactPerson from './components/ContactPerson';
import BusinessRegistrationList from './components/BusinessRegistrationList';
import BusinessRegistrationDetails from './components/BusinessRegistrationDetails';

import { CompanyLocationContext } from './CompanyLocationContext';


import * as AddressRegistrationService from '../../../services/requests/addressRegistration';
import { CompanyContext } from '../../pages/Company/CompanyContext';
import * as BusinessRegistrationService from '../../../services/requests/businessRegistration';
import * as PersonService from '../../../services/requests/person';

import { useStyles, styles } from './styles';
import { useGlobalStyles } from '../../../customStyles';
import BoxCenter from '../../common-components/BoxCenter';
import { Button } from '@mui/material';
import { useSnackbarHelpers } from '../../../helpers';
import { Messages } from '../../../constants/messages';
import { Message } from '@mui/icons-material';

export default function CompanyLocationPopUp({
  isOpen,
  onClose,
}) {
  const classes = useStyles();
  const cssClass = useGlobalStyles();

  const { showInfoSnackbar, showErrorSnackbar } = useSnackbarHelpers()
  const { companyData, chosenBusinessRegistrationCompany, getBusinessRegistrationListCompany } = useContext(CompanyContext);

  const [currentTab, setCurrentTab] = useState(0);
  const [currentBusinessRegistration, setCurrentBusinessRegistration] = useState(null);

  const [chosenPerson, setChosenPerson] = useState(null);
  const [locationDataSaveable, setLocationDataSaveable] = useState(false);

  const [currentAddressRegistration, setCurrentAddressRegistration] = useState(null);
  const [chosenContactPerson, setChosenContactPerson] = useState(null);
  const [addressRegistrationList, setAddressRegistrationList] = useState([]);
  const [businessRegistrationList, setBusinessRegistrationList] = useState([]);

  const [companyLocationData, setCompanyLocationData] = useState([]);

  useEffect(() => {
    const addressRegistration = chosenBusinessRegistrationCompany?.AddressRegistration;

    if (chosenBusinessRegistrationCompany) {
      setCurrentBusinessRegistration(chosenBusinessRegistrationCompany);
      setCurrentAddressRegistration(addressRegistration);
      setChosenContactPerson(addressRegistration?.ContactPerson);
      setChosenPerson(addressRegistration?.ContactPerson?.Person);
      setCurrentTab(1);
    } else {
      setCurrentTab(0);
    }

  }, [isOpen, chosenBusinessRegistrationCompany]);

  const getBusinessRegistrationList = async () => {
    await BusinessRegistrationService.getList(companyData.ID, null, setBusinessRegistrationList);
  }

  const handleTabChange = (_, newValue) => {
    setCurrentTab(newValue);
  };

  async function getPerson(id, setChosenPerson) {
    await PersonService.getOne(id, setChosenPerson);
  }

  const getAddressRegistrationList = useCallback(async () => {
    await AddressRegistrationService.getList(
      companyData?.ID,
      setAddressRegistrationList,
    );
  }, [addressRegistrationList, companyData?.ID]);

  async function insertAddressRegistration(values) {
    const data = {
      FK_Company: companyData?.ID,
      County: values.County,
      Zipcode: values.Zipcode,
      City: values.City,
      Street: values.Street,
      Number: values.Number,
      Latitude: values.Latitude,
      Longitude: values.Longitude,
    };

    const result = await AddressRegistrationService.insertOne(data)
    if (result.isSuccess) {
      if (result.data) {
        setCurrentAddressRegistration(data);
        await getBusinessRegistrationList();
      } else {
        showInfoSnackbar(Messages.ChangeProposalCreated);
      }
    } else {
      showErrorSnackbar(Messages.Error);
    }

  }

  useEffect(() => {
    if (!isOpen) {
      setCurrentBusinessRegistration(null);
      setCurrentAddressRegistration(null);
      setChosenPerson(null);
      setChosenContactPerson(null);
      setAddressRegistrationList(null);
      setBusinessRegistrationList(null);
    }
  }, [isOpen]);

  async function updateAddressRegistration(id, values) {
    const data = {
      ID: id,
      County: values.County,
      Zipcode: values.Zipcode,
      City: values.City,
      Street: values.Street,
      Number: values.Number,
      Latitude: values.Latitude,
      Longitude: values.Longitude,
      FK_ContactPerson: values.FK_ContactPerson,
    };
    const result = await AddressRegistrationService.updateOne(data, getBusinessRegistrationList);
    if (result.isSuccess) {
      if (result.data) {
        showInfoSnackbar(Messages.Updated)
      } else {
        showInfoSnackbar(Messages.ChangeProposalCreated)
      }
    } else {
      showErrorSnackbar(Messages.Error)
    }
  }

  const handleCompanyLocationSave = async (values) => {
    const valueData = values

    valueData.FK_ContactPerson = chosenContactPerson.ID ?? null;

    valueData.ID
      ? await updateAddressRegistration(valueData.ID, valueData)
      : await insertAddressRegistration(valueData);

    await getBusinessRegistrationListCompany();
  };

  return (
    <CompanyLocationContext.Provider
      value={{
        isOpen,
        onClose,
        setCurrentTab,

        currentAddressRegistration,
        setCurrentAddressRegistration,
        addressRegistrationList,
        getAddressRegistrationList,

        setCompanyLocationData,

        setLocationDataSaveable,
        locationDataSaveable,

        currentBusinessRegistration,
        setCurrentBusinessRegistration,

        getPerson,
        chosenPerson,
        setChosenPerson,

        chosenContactPerson,
        setChosenContactPerson,

        getBusinessRegistrationList,
        businessRegistrationList,
      }}
    >
      <ModalWrapper
        isOpen={isOpen}
        onClose={onClose}
        title="Standort"
        className={cssClass.swvModalResponsiveMods + " " + classes.wrapper}
      >
        <FormProvider>
          <TabPanel value={currentTab} index={0}>
            <AddressRegistrationList />
          </TabPanel>

          <TabPanel value={currentTab} index={1} sx={{ ...styles.locationSelectedContainer }}>
            <AddressRegistrationDetails />

            <ContactPerson />

            <BusinessRegistrationList />

            <BoxCenter sx={{ margin: 0 }}>
              <Button
                onClick={() => handleCompanyLocationSave(companyLocationData)}
                disabled={!locationDataSaveable || !chosenContactPerson?.ID}
              >
                Speichern
              </Button>
            </BoxCenter>
          </TabPanel>

          <TabPanel value={currentTab} index={2}>
            <BusinessRegistrationDetails />
          </TabPanel>
        </FormProvider>
      </ModalWrapper>
    </CompanyLocationContext.Provider>
  );
}
