import React, { useContext, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import { useStyles } from './styles';

import { CompanyContext } from '../../../../pages/Company/CompanyContext';
import { CompanyLocationContext } from '../../CompanyLocationContext';
import { getPerson, getAddressRegistration } from '../../../../../helpers/database';
import BoxCenter from '../../../../common-components/BoxCenter';

export default function AddressRegistrationList() {
  const classes = useStyles();

  const { companyData } = useContext(CompanyContext);

  const {
    setCurrentTab,
    getAddressRegistrationList,
    addressRegistrationList,
    setCurrentAddressRegistration,
    currentAddressRegistration,
    setChosenContactPerson,
    setChosenPerson,
  } = useContext(CompanyLocationContext);

  useEffect(() => {
    if (companyData?.ID) {
      getAddressRegistrationList();
    }
  }, [companyData?.ID]);

  const handleRowClick = (row) => {
    setCurrentAddressRegistration(row);
    setChosenContactPerson(row?.ContactPerson);
    setChosenPerson(row?.ContactPerson?.Person);
    setCurrentTab(1);
  }

  const handleNewClick = () => {
    setCurrentAddressRegistration(null);
    setChosenContactPerson(null);
    setChosenPerson(null);
  }

  return (
    <Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Ansprechperson</TableCell>
              <TableCell width='400px'>Adresse</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {addressRegistrationList?.map((row) => (
                <TableRow
                  key={row.ID}
                  selected={currentAddressRegistration?.ID === row.ID}
                  onClick={() => handleRowClick(row)}
                >
                  <TableCell>{getPerson(row.ContactPerson?.Person)}</TableCell>
                  <TableCell>{getAddressRegistration(row)}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <BoxCenter>
        <Button
          onClick={() => handleNewClick()}
        >
          Neu
        </Button>
      </BoxCenter>
    </Box>
  );
}