import React from 'react';
import SearchIcon from "@mui/icons-material/Search";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';

import { useStyles } from './styles';

export default function SearchInput({ placeholder, onChange, value }) {
  const classes = useStyles();

  return (
    <div className={classes.mainContainer}>
      {value && <div className={classes.labelContainer2}>
         <div className={classes.labelContainer}>
          <label htmlFor="my-select" className={classes.label}>{placeholder}</label>
        </div>
      </div>}

      <OutlinedInput
        id="outlined-adornment-password"
        value={value}
        onChange={onChange}
        type={'text'}
        fullWidth
        startAdornment={
          <InputAdornment position="start"> 
            <SearchIcon />
          </InputAdornment>
        }
        placeholder={placeholder}
      />
    </div>
  )
}
