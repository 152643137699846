import React, { useState } from 'react';
import {
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    IconButton,
    Tooltip,
    Collapse,
    Box,
    Typography,
} from '@mui/material';
import ChangeProposalRow from '../ChangeProposalRow';

export default function ChangeProposalTable({ rows }: any) {



    return (
        <TableContainer sx={{ maxHeight: '70vh' }}>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell style={{ minWidth: '40px', width: '40px' }} />
                        <TableCell width='18%'>Typ</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell width='20%'>Zu</TableCell>
                        <TableCell width='15%'>Von</TableCell>
                        <TableCell width='8%'>Datum</TableCell>
                        <TableCell width='7%'>Änderung</TableCell>
                        <TableCell style={{ minWidth: '80px', maxWidth: '80px', width: '80px' }}>Aktion</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows?.map((row: any) => {
                        let displayName = '';
                        let referenceName = '';
                        switch (row.EntityType) {
                            case 'Kontaktperson': {
                                displayName = `${row.Person?.Lastname} ${row.Person?.Firstname}`
                                referenceName = `${row.Company?.Name}`
                                break;
                            }
                            case 'Adresse zu Person': {
                                displayName = `${row.NewObject?.Street} ${row.NewObject?.Number}`
                                referenceName = `${row.Person?.Lastname} ${row.Person?.Firstname}`
                                break;
                            }
                            case 'Adresse zu Kontaktperson': {
                                displayName = `${row.NewObject?.Street} ${row.NewObject?.Number}`
                                referenceName = `${row.Person?.Lastname} ${row.Person?.Firstname}`
                                break;
                            }
                            case 'Adresse zu Firma': {
                                displayName = `${row.NewObject?.Street} ${row.NewObject?.Number}`
                                referenceName = `${row.Company?.Name}`
                                break;
                            }
                            case 'Adresse zu Firmastandort': {
                                displayName = `${row.NewObject?.Street} ${row.NewObject?.Number}`
                                referenceName = `${row.Company?.Name}`
                                break;
                            }
                            case 'Firma': {
                                displayName = `${row.NewObject?.Name}`
                                referenceName = '---'
                                break;
                            }
                            case 'Hauptansprechperson zu Firma': {
                                displayName = '---';
                                referenceName = `${row.Company?.Name}`;
                                break;
                            }
                            case 'Kommunikationsdaten zu Firma': {
                                displayName = '---'
                                referenceName = `${row.Company?.Name}`
                                break;
                            }
                            case 'Kommunikationsdaten zu Person': {
                                displayName = `---`
                                referenceName = `${row.Person?.Lastname} ${row.Person?.Firstname}`
                                break;
                            }
                            default:
                                displayName = `---`
                                referenceName = `---`
                                break;
                        }
                        return (
                            <ChangeProposalRow key={row.ID} row={row} displayName={displayName} referenceName={referenceName} />
                        );
                    })}

                </TableBody>
            </Table>
        </TableContainer>
    );


}