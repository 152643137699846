import React, { useCallback, useContext } from 'react';
import { useForm, useWatch, Controller } from 'react-hook-form';

import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';

import * as OrganisationService from '../../../../../services/requests/organisation';

import { useSnackbarHelpers } from '../../../../../helpers/utils';

import FormBooleanSelect from '../../../../common-components/FormBooleanSelect';
import FormTextField from '../../../../common-components/FormTextField';
import CountySelect from '../../../../common-components/CountySelect';
import BoxCenter from '../../../../common-components/BoxCenter';
import { AdminOrganisationContext } from '../../AdminOrganisationContext';

import { useStyles } from './styles';


export default function OrganisationDetails() {
  const classes = useStyles();
  const { chosenOrganisation, getOrganisationList, setChosenOrganisation } = useContext(AdminOrganisationContext);
  const { } = useSnackbarHelpers();

  const { control } = useForm({
    defaultValues: {
      name: chosenOrganisation?.Name || '',
      type: chosenOrganisation?.Type || '',
      family: typeof chosenOrganisation?.IsFamily === 'boolean' ? chosenOrganisation?.IsFamily : '',
      County: chosenOrganisation?.County || '',
    },
  });

  const values = useWatch({ control });

  const onSaveClicked = async () => {
    const preload = {
      ID: chosenOrganisation?.ID,
      Name: values.name,
      Type: values.type,
      IsFamily: values.family,
      County: values.County,
    };

    const success = async (data) => {
      setChosenOrganisation(data);
      await getOrganisationList();
    };

    if (chosenOrganisation?.ID) {
      await OrganisationService.updateOne(preload, success);
    } else {
      await OrganisationService.insertOne(preload, success);
    }
  };

  const disabled =
    !values.name ||
    values.family === '';

  return (
    <Container maxWidth="sm">
      <FormControl fullWidth>
        <FormTextField
          name="name"
          label="Name"
          control={control}
          required
        />
        <FormTextField
          name="type"
          label="Typ"
          control={control}
        />
        <FormBooleanSelect
          name="family"
          label="Familie"
          control={control}
          value={values.family}
          required
        />
        <CountySelect
          name="county"
          control={control}
          value={values.County}
        />
        <BoxCenter>
          <Button
            disabled={disabled}
            onClick={() => onSaveClicked()}
          >
            Speichern
          </Button>
        </BoxCenter>
      </FormControl>
     </Container>
 );
}
