export const styles = {
  searchButton: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '13px 12px !important',
  },
  searchButtonIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};
