import React, { useCallback, useState, useContext, useEffect, Dispatch, SetStateAction } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';

import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Container from '@mui/material/Container';

import { useSnackbarHelpers, deletePopup } from '../../../../../helpers/utils';

import * as OrganisationService from '../../../../../services/requests/organisation';
import { AdminElectionProposalContext } from '../../AdminElectionProposalContext';

import DeleteCell from '../../../../common-components/DeleteCell';
import CheckCell from '../../../../common-components/CheckCell';
import FilterTop from '../../../../common-components/FilterTop';
import FormTextField from '../../../../common-components/FormTextField';

import { useStyles } from './styles';
import { useGlobalStyles } from '../../../../../customStyles';
import { IElectionProposal } from '../../../../../redux/slices/electionProposalSlice';
import CustomFormSelect from '../../../../common-components/CustomFormSelect/CustomFormSelect';
import { counties } from '../../../../../constants/counties';
import { useCreateElectionProposalMutation, useDeleteElectionProposalMutation } from '../../../../../redux/services/ElectionProposalService';
import { Messages } from '../../../../../constants/messages';
import { Delete, Edit } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';

export default function ElectionProposalList({ year }: { year: number }) {
  const classes = useStyles();
  const cssClass = useGlobalStyles();
  const { } = useSnackbarHelpers();

  const {
    setCurrentTab,
    getOrganisationList,
    setPanelList,
    setChosenPanel,
    electionProposalTab,
    chosenElectionProposal,
    setChosenElectionProposal,

    electionProposals
  }: any = useContext(AdminElectionProposalContext);


  type Inputs = {
    ID: number
    title: string
    electionYear: number
    county: string
  }

  const { register, handleSubmit, reset, control, formState: { errors } } = useForm<Inputs>({
    defaultValues: {
      ID: 0,
      electionYear: year,
      county: '',
      title: ''
    },
    //resolver: yupResolver(schema)
  });

  const onRowClick = (row: IElectionProposal) => {
    setChosenElectionProposal(row);
    setCurrentTab(electionProposalTab);
  };

  const deleteOrganisation = async (id: number) => {
    return await OrganisationService.deleteOne(id, () => {
      if (chosenElectionProposal?.ID == id) {
        setChosenElectionProposal(null);
      }
      getOrganisationList();
    });
  };

  const [createElectionProposal, { error: createError, isLoading: isCreating }] = useCreateElectionProposalMutation();
  const [deleteElectionProposal, { error: deleteError, isLoading: isDeleting }] = useDeleteElectionProposalMutation();

  return (
    <Container maxWidth="xl">
      <FormControl>
        <FilterTop>
          <FormTextField
            label="Titel"
            name="title"
            register={register}
            errors={errors}
            required
            //@ts-ignore
            validationSchema={{
              required: Messages.ErrorTitle
            }}
          />
          <FormTextField
            label="Wahljahr"
            readOnly={true}
            register={register}
            name="electionYear"
            required
          />
          {/*@ts-ignore*/}
          <CustomFormSelect
            name="county"
            label="Bundesland"
            disabled={false}
            options={counties}
            valueKey='name'
            titleKey='name'
            required
            control={control}
            register={register}
            errors={errors}
            validationSchema={{
              required: Messages.ErrorCounty
            }}
          />
          <Button
            sx={{ height: "3.5em" }}
            onClick={handleSubmit((newElectionProposal) => createElectionProposal(newElectionProposal).then(() => { reset() }))}
          >
            {isCreating ? <><CircularProgress /></> : <>Neu</>}
          </Button>
        </FilterTop>

        <TableContainer
          sx={{
            maxHeight: '55vh',
            overflowY: 'auto',
            overflowX: 'hidden',
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={cssClass.mediumCell}>Bundesland</TableCell>
                <TableCell className={cssClass.titleCell}>Titel</TableCell>
                <TableCell className={cssClass.smallCell}>Bearbeiten</TableCell>
                <TableCell className={cssClass.smallCell}>Löschen</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {electionProposals?.filter((row: IElectionProposal) => row.ElectionYear === year).map((row: IElectionProposal) => (
                <TableRow
                  key={row.ID}
                  selected={chosenElectionProposal?.ID == row.ID}
                >
                  <TableCell>{row.County}</TableCell>
                  <TableCell>{row.Title}</TableCell>
                  <TableCell><Button onClick={() => onRowClick(row)}><Edit fontSize='inherit' /></Button></TableCell>
                  <TableCell><Button onClick={() => { deleteElectionProposal(row) }}><Delete fontSize='inherit' /></Button></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </FormControl>
    </Container>
  );
}