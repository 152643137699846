import React, { useContext, useCallback } from 'react';
import { useForm, useWatch, Controller } from 'react-hook-form';

import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';

import { useSnackbarHelpers } from '../../../../../helpers/utils';

import { AdminOrganisationContext } from '../../AdminOrganisationContext';
import * as PanelService from '../../../../../services/requests/panel';

import BoxCenter from '../../../../common-components/BoxCenter';
import FormTextField from '../../../../common-components/FormTextField';

import { useStyles } from './styles';

export default function PanelDetails() {
  const classes = useStyles();

  const {
    chosenPanel,
    getPanelList,
    chosenOrganisation,
    setChosenPanel
  } = useContext(AdminOrganisationContext);

  const { } = useSnackbarHelpers();

  const { control } = useForm({
    defaultValues: {
      name: chosenPanel?.Name || '',
    },
  });

  const values = useWatch({ control });

  const onSaveClicked = async () => {
    const preload = {
      ID: chosenPanel?.ID,
      Name: values?.name,
      FK_Organisation: chosenOrganisation?.ID,
    };

    const success = async (data) => {
      setChosenPanel(data);
      await getPanelList({ FK_Organisation: chosenOrganisation?.ID });
    };

    if (chosenPanel?.ID) {
      await PanelService.updateOne(preload, success);
    } else {
      await PanelService.insertOne(preload, success);
    }
  };

  const disabled = !values.name;

  return (
    <Container maxWidth="sm">
      <FormControl>
        <FormTextField
          name="name"
          label="Name"
          control={control}
          required
        />
        <BoxCenter>
          <Button
            disabled={disabled}
            onClick={() => onSaveClicked()}
          >
            Speichern
          </Button>
        </BoxCenter>
      </FormControl>
    </Container>
  );
}