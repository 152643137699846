import * as endpoints from '../../constants/endpoints';
import { apiClient } from '../apiClient';

export const getOne = async (success, error) => {
  const response = await apiClient.getOne(endpoints.getProfile, null, success, error);
  return response.data;
};

export const updateOne = async (data, success, error) => {
  const response = await apiClient.updateOne(endpoints.updateProfile, data, success, error);
  return response.data;
};

export const checkEmail = async (data, success, error) => {
  const response = await apiClient.post(endpoints.checkProfileEmail, data, success, error);
  return response.data;
};

export const checkUsername = async (data, success, error) => {
  const response = await apiClient.post(endpoints.checkProfileUsername, data, success, error);
  return response.data;
};
