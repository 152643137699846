import React, { useTheme } from '@mui/material/styles';
import {
  Box,
  Container, Grid
} from '@mui/material';

//@ts-ignore
import { useGlobalStyles } from '../../../customStyles';
//@ts-ignore
import SectionHeading from '../../common-components/SectionHeading';
//@ts-ignore

// @ts-ignore
import { swvTheme as swvThemeImport } from '../../../swvTheme';
import Search from '../../common-components/Search/Search';
import SearchList from '../../common-components/SearchList/SearchList';
import { FormSendingContext } from './FormSendingContext';
import { useState } from 'react';
import DataCard from '../../common-components/DataCard';
import { getAddressRegistration, getBoolean, getBusinessRegistrationListOverview, getPhotoUrl } from '../../../helpers';
import { useForm, useWatch } from 'react-hook-form';


import * as CompanyService from '../../../services/requests/company';
import * as PersonService from '../../../services/requests/person';
import { ICompany } from '../../../interfaces/ICompany';
import { Add, Remove } from '@mui/icons-material';
import { onlyUnique } from '../../../helpers/helperFunctions';
import CustomButton from '../../common-components/CustomButton';
import Printer from '../../common-components/Icons/Printer';
import CreateFormForMultipleCompaniesPopUp from '../../popups/CreateFormForMultipleCompanies/CreateFormForMultipleCompanies';
import { useGetListBySubcategoryMutation } from '../../../redux/services/CompanyService';


//import { useStyles } from './styles';

const infoFiles = [
  'Antrag_Wirtschaftsparlament.docx',
  'Fragen_Millionärssteuer.pdf',
  'Grafik_Frauen_Neugründungen.png',
  'WK_Broschüre_ALLE.pdf',
  'Grafik_EPUundKMU.png',
];

export const FormSending = () => {
  const theme = useTheme();
  //const classes = useStyles();
  const cssClass = useGlobalStyles();

  // this supresses ts errors related to swvTheme 
  const swvTheme: any = swvThemeImport;

  const { control, setValue: setSearchedSubcategoryValue } = useForm({
    defaultValues: {
      searchedSubcategories: '',
    },
  });

  const subcategoryValue = useWatch({ control });

  const pageSize = 100;

  const [selectedCompanies, setSelectedCompanies] = useState<Array<ICompany>>([])

  const [getCompanyListBySubcategory] = useGetListBySubcategoryMutation()

  const [searchText, setSearchText] = useState('');
  const [lists, setLists] = useState({
    company: [],
    person: [],
    contactPerson: [],
    address: []
  })

  const [isCreateFormPopUpOpen, setIsCreateFormPopUpOpen] = useState(false);
  const onCreateFormPopUpOpen = () => {
    setIsCreateFormPopUpOpen(true);
  };
  const onCreateFormPopUpClose = () => {
    setIsCreateFormPopUpOpen(false);
  };

  const changePageOfList = (listname: string, newPage: number) => {
    setSearchData((old: any) => ({ ...old, [listname]: { ...old[listname], page: newPage - 1 } }))
  }

  const handleData = (listname: string, data: any, fromSearch: boolean) => {
    if (data?.resultList?.length) {
      setLists((old: any) => ({ ...old, [listname]: data.resultList }));
      if (fromSearch) {
        setSearchData((old: any) => ({ ...old, [listname]: { ...old[listname], resultCount: data.totalResultCount, listLoading: false } }))
      }
    }
  }

  const getCompanyList = async (searchText: string, isEnterClicked: boolean, fromSearch: boolean, page?: number, subcategoryValue?: any) => {
    if (fromSearch) {
      setSearchData((old: any) => ({ ...old, company: { ...old.company, listLoading: true } }))
    }

    if (subcategoryValue && subcategoryValue?.searchedSubcategories?.length > 0) {
      const data = await getCompanyListBySubcategory({ searchText: searchText, subcategoryText: subcategoryValue.searchedSubcategories!, page: page ?? 0, pageSize: pageSize }).unwrap();
      handleData('company', data, fromSearch)
    } else {
      await CompanyService.getList(
        {
          mode: 'Company',
          text: isEnterClicked ? searchText : '',
          fromSearch: fromSearch,
          page: page ?? 0,
          pageSize: pageSize,
          IsFamily: searchData.other.familyChecked,
          IsMember: searchData.other.memberChecked,
        }, (data: any) => handleData('company', data, fromSearch)
      )
    }
  };

  const getContactPersonList = async (searchText: string, isEnterClicked: boolean, fromSearch: boolean, page?: number) => {
    if (fromSearch) {
      setSearchData((old: any) => ({ ...old, contactPerson: { ...old.contactPerson, listLoading: true } }))
    }
    await CompanyService.getList(
      {
        mode: 'Person',
        text: isEnterClicked ? searchText : '',
        fromSearch: fromSearch,
        page: page ?? 0,
        pageSize: pageSize,
        IsFamily: searchData.other.familyChecked,
        IsMember: searchData.other.memberChecked,
      }, (data: any) => handleData('contactPerson', data, fromSearch));
  };

  const getPersonList = async (searchText: string, isEnterClicked: boolean, fromSearch: boolean, page?: number) => {
    if (fromSearch) {
      setSearchData((old: any) => ({ ...old, person: { ...old.person, listLoading: true } }))
    }

    await PersonService.getList(
      {
        text: isEnterClicked ? searchText : '',
        page: page ?? 0,
        pageSize: pageSize,
        IsFamily: searchData.other.familyChecked,
        IsMember: searchData.other.memberChecked,
      },
      (data: any) => handleData('person', data, fromSearch)
    );
  };

  const getAddressList = async (searchText: string, isEnterClicked: boolean, fromSearch: boolean, page?: number) => {
    if (fromSearch) {
      setSearchData((old: any) => ({ ...old, address: { ...old.address, listLoading: true } }))
    }
    await CompanyService.getList(
      {
        mode: 'Address',
        text: isEnterClicked ? searchText : '',
        fromSearch: fromSearch,
        page: page ?? 0,
        pageSize: pageSize,
        IsFamily: searchData.other.familyChecked,
        IsMember: searchData.other.memberChecked,
      },
      (data: any) => handleData('address', data, fromSearch)
    );
  };

  const [searchData, setSearchData] = useState({
    company: {
      isEnabled: true,
      resultCount: 0,
      page: 0,
      checked: true,
      listOpen: false,
      listLoading: false,
      renderDataCard: (row: any) => {
        return <>
          <DataCard
            key={row.ID}
            lineOne={row.Name}
            lineTwo={`FG Nr.: ${getBusinessRegistrationListOverview(row)}`}
            placeholderImage='company'
            image={getPhotoUrl(row)}
            truncate
            onClick={() => { setSelectedCompanies(old => ([row, ...old].filter(onlyUnique))) }}
            customIcon={<Add fontSize="small"
              sx={{
                alignSelf: 'center',
                marginRight: '36px',
              }} />}
          />
        </>
      },
      handlePageChange: (listname: string, newPage: number, searchText: string, subcategoryValue?: any) => {
        changePageOfList(listname, newPage)
        getCompanyList(searchText, true, false, newPage - 1, subcategoryValue)
      },
      getList: (searchText: string, isEnterClicked: boolean, fromSearch: boolean, page?: number, subcategoryValue?: any) => {
        return getCompanyList(searchText, isEnterClicked, fromSearch, page, subcategoryValue)
      }
    },
    person: {
      isEnabled: true,
      resultCount: 0,
      page: 0,
      checked: true,
      listOpen: false,
      listLoading: false,
      renderDataCard: (row: any) => {
        return <>
          <DataCard
            key={row.ID}
            lineOne={row.Firstname + ' ' + row.Lastname}
            lineTwo={'Mitglied: ' + getBoolean(row.Member) + ' - Familie: ' + getBoolean(row.IsFamily)}
            placeholderImage='person'
            image={getPhotoUrl(row)}
            truncate
          />
        </>
      },
      handlePageChange: (listname: string, newPage: number, searchText: string) => {
        changePageOfList(listname, newPage)
        getPersonList(searchText, true, false, newPage - 1)
      },
      getList: (searchText: string, isEnterClicked: boolean, fromSearch: boolean, page?: number) => {
        return getPersonList(searchText, isEnterClicked, fromSearch, page)
      }
    },
    contactPerson: {
      isEnabled: true,
      resultCount: 0,
      page: 0,
      checked: true,
      listOpen: false,
      listLoading: false,
      renderDataCard: (row: any) => {
        return <>
          <DataCard
            key={row.ID}
            lineOne={row.Name}
            lineTwo={`${row.Master.Person.Firstname} ${row.Master.Person.Lastname}`}
            placeholderImage='company'
            image={getPhotoUrl(row)}
            truncate
          />
        </>
      },
      handlePageChange: (listname: string, newPage: number, searchText: string) => {
        changePageOfList(listname, newPage)
        getContactPersonList(searchText, true, false, newPage - 1)
      },
      getList: (searchText: string, isEnterClicked: boolean, fromSearch: boolean, page?: number) => {
        return getContactPersonList(searchText, isEnterClicked, fromSearch, page)
      }
    },
    address: {
      isEnabled: true,
      resultCount: 0,
      page: 0,
      checked: true,
      listOpen: false,
      listLoading: false,
      renderDataCard: (row: any) => {
        return <>
          <DataCard
            key={row.ID}
            lineOne={row.Name}
            lineTwo={getAddressRegistration(row.StandardWKO)}
            placeholderImage='company'
            image={getPhotoUrl(row)}
            truncate
          />
        </>
      },
      handlePageChange: (listname: string, newPage: number, searchText: string) => {
        changePageOfList(listname, newPage)
        getAddressList(searchText, true, false, newPage - 1)
      },
      getList: (searchText: string, isEnterClicked: boolean, fromSearch: boolean, page?: number) => {
        return getAddressList(searchText, isEnterClicked, fromSearch, page)
      }
    },
    other: {
      familyChecked: false,
      memberChecked: false,
      isSearchClicked: false,
      searchInputError: false,
    }
  })

  return (
    <>
      <FormSendingContext.Provider
        value={{
          searchText,
          setSearchText,

          lists,
          setLists,
          searchData,
          setSearchData,

          control,
          setSearchedSubcategoryValue,
          subcategoryValue,


          selectedCompanies,
          setSelectedCompanies
        }}
      >
        <SectionHeading title="Formularversand" />
        <Container maxWidth="xl" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <div style={{ width: "66%", display: "flex", flexDirection: "column", gap: "0.5em" }}>
            <Search listnames={["company"/*, "person", "contactPerson", "address" */]} />
            <SearchList listname="company" />
            {/*<SearchList listname="person" />
            <SearchList listname="contactPerson" />
      <SearchList listname="address" />*/}
          </div>
          <div style={{ width: "33%", display: "flex", flexDirection: "column", justifyContent: "start", alignItems: "center", gap: "0.5em" }}>
            <SectionHeading title="Ausgewählte Firmen" />
            <Grid container spacing={2} columns={1}>
              <Grid
                key={-1}
                item
                xs={2}
                sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
                md={1}
                onClick={() => /* ToDo: onRowClicked(row) */ { }}
              >
                {/* @ts-ignore */}
                <CustomButton
                  value='Formulare erstellen'
                  largeBorderRadius
                  sx={{ width: '66% !important', fontSize: '14px' }}
                  disabled={selectedCompanies.length === 0}
                  startIcon={
                    <>
                      <Printer
                        //@ts-ignore
                        sx={{
                          height: '30px',
                          width: 'auto',
                        }}
                      />
                    </>
                  }
                  onClick={onCreateFormPopUpOpen}
                />
              </Grid>
              {selectedCompanies.map((row) =>
                <Grid
                  key={row.ID}
                  item
                  xs={2}
                  md={1}
                  onClick={() => /* ToDo: onRowClicked(row) */ { }}
                >
                  <DataCard
                    key={row.ID}
                    lineOne={row.Name}
                    lineTwo={`FG Nr.: ${getBusinessRegistrationListOverview(row)}`}
                    placeholderImage='company'
                    image={getPhotoUrl(row)}
                    truncate
                    customIcon={<Remove fontSize="small"
                      sx={{
                        alignSelf: 'center',
                        marginRight: '36px',
                      }} />}
                    onClick={() => { setSelectedCompanies(old => (old.filter(el => el.ID !== row.ID))) }}
                  />
                </Grid>
              )}
            </Grid>

          </div>
        </Container >
        <CreateFormForMultipleCompaniesPopUp
          isOpen={isCreateFormPopUpOpen}
          onClose={onCreateFormPopUpClose}
        />
      </FormSendingContext.Provider>
    </>
  );
};
