import React, { useState, useContext, useEffect } from 'react';
import { useWatch, useFormContext } from 'react-hook-form';
import { Messages } from '../../../../../constants/messages';
import FormSelect from '../../../../common-components/FormSelect';
import Button from '@mui/material/Button';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Box,
  IconButton,
} from '@mui/material';

import { Edit, Close } from '@mui/icons-material/';

import Grid from '@mui/material/Grid';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ContactPersonContext } from '../../PersonContext';

import BoxCenter from '../../../../common-components/BoxCenter';
import FormBooleanSelect from '../../../../common-components/FormBooleanSelect';
import { useGlobalStyles } from '../../../../../customStyles';
import CustomButton from '../../../../common-components/CustomButton';

export const ContactPermissionsPerson = ({
  updateCommunicationDataPerson,
  insertCommunicationDataPerson,
}) => {
  const cssClass = useGlobalStyles();

  const [isEditMode, setIsEditMode] = useState(false);
  const [showWarning, setShowWarning] = useState(false);

  const { showRow } = useContext(ContactPersonContext);
  const { control } = useFormContext();
  const values = useWatch({ control });

  const onSaveClicked = async () => {
    if (values.CommunicationDataIDPerson)
      await updateCommunicationDataPerson(resetWarning);
    else await insertCommunicationDataPerson(resetWarning);
  };

  const createData = (name, value, renderKey) => {
    return { name, value, renderKey };
  };

  const mapBoolean = (boolean) => {
    if (boolean) {
      return 'Ja';
    } else {
      return 'Nein';
    }
  };

  const tableRows = [
    createData(
      'Brief:',
      mapBoolean(values?.IsLetterApprovalPerson),
      'IsLetterApprovalPerson',
    ),
    createData(
      'E-Mail:',
      mapBoolean(values?.IsEmailApprovalPerson),
      'IsEmailApprovalPerson',
    ),
    createData(
      'Telefon:',
      mapBoolean(values?.IsTelephoneApprovalPerson),
      'IsTelephoneApprovalPerson',
    ),
    createData(
      'Mobil:',
      mapBoolean(values?.IsMobileApprovalPerson),
      'IsMobileApprovalPerson',
    ),
    createData(
      'SMS:',
      mapBoolean(values?.IsSMSApprovalPerson),
      'IsSMSApprovalPerson',
    ),
    createData(
      'Messenger:',
      mapBoolean(values?.IsMessengerApprovalPerson),
      'IsMessengerApprovalPerson',
    ),
    createData(
      'Newsletter:',
      mapBoolean(values?.IsNewsletterApprovalPerson),
      'IsNewsletterApprovalPerson',
    ),
    createData(
      'Social Media:',
      mapBoolean(values?.IsSocialMediaApprovalPerson),
      'IsSocialMediaApprovalPerson',
    ),
  ];

  useEffect(() => {
    if (isEditMode) {
      values.isModified = true;
    }
  }, [values]);

  const resetWarning = () => {
    values.isModified = false;
    setShowWarning(false);
    setIsEditMode(false);
  };

  return (
    <Box>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            p: 2,
          }}
        >
          <Typography color="#732382" fontSize={18} fontWeight={500} py={2}>
            Kontakterlaubnis
          </Typography>

          {showWarning && (
            <Typography color="primary">{Messages.WarningUnsafed}</Typography>
          )}

          {isEditMode ? (
            <IconButton
              aria-label="close"
              onClick={() => {
                if (values?.isModified) setShowWarning(true);
                setIsEditMode(false);
              }}
            >
              <Close />
            </IconButton>
          ) : (
            <IconButton
              aria-label="edit"
              color="primary"
              onClick={() => setIsEditMode(!isEditMode)}
            >
              <Edit />
            </IconButton>
          )}
        </Box>

        {isEditMode ? (
          <>
            <Table className={cssClass.twoColumnDataTable}>
              <TableBody className={cssClass.customTableBody}>
                <TableRow>
                  <TableCell className={cssClass.shrinkCell}>Brief:</TableCell>
                  <TableCell>
                    <FormBooleanSelect
                      label="Brief"
                      name="IsLetterApprovalPerson"
                      control={control}
                      value={values.IsLetterApprovalPerson ?? true}
                      required
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={cssClass.shrinkCell}>Email:</TableCell>
                  <TableCell>
                    <FormBooleanSelect
                      label="Email"
                      name="IsEmailApprovalPerson"
                      control={control}
                      value={values.IsEmailApprovalPerson ?? true}
                      required
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={cssClass.shrinkCell}>
                    Telefon:
                  </TableCell>
                  <TableCell>
                    <FormBooleanSelect
                      label="Telefon"
                      name="IsTelephoneApprovalPerson"
                      control={control}
                      value={values.IsTelephoneApprovalPerson ?? true}
                      required
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={cssClass.shrinkCell}>Mobil:</TableCell>
                  <TableCell>
                    <FormBooleanSelect
                      label="Mobil"
                      name="IsMobileApprovalPerson"
                      control={control}
                      value={values.IsMobileApprovalPerson ?? true}
                      required
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={cssClass.shrinkCell}>SMS:</TableCell>
                  <TableCell>
                    <FormBooleanSelect
                      label="SMS"
                      name="IsSMSApprovalPerson"
                      control={control}
                      value={values.IsSMSApprovalPerson ?? true}
                      required
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={cssClass.shrinkCell}>
                    Messenger:
                  </TableCell>
                  <TableCell>
                    <FormBooleanSelect
                      label="Messenger"
                      name="IsMessengerApprovalPerson"
                      control={control}
                      value={values.IsMessengerApprovalPerson ?? true}
                      required
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={cssClass.shrinkCell}>
                    Newsletter:
                  </TableCell>
                  <TableCell>
                    <FormBooleanSelect
                      label="Newsletter"
                      name="IsNewsletterApprovalPerson"
                      control={control}
                      value={values.IsNewsletterApprovalPerson ?? true}
                      required
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={cssClass.shrinkCell}>
                    Soziale Medien:
                  </TableCell>
                  <TableCell>
                    <FormBooleanSelect
                      label="Soziale Medien"
                      name="IsSocialMediaApprovalPerson"
                      control={control}
                      value={values.IsSocialMediaApprovalPerson ?? true}
                      required
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <BoxCenter sx={{ pb: '25px'}}>
              <CustomButton
                value="Verwerfen"
                onClick={() => {
                  setIsEditMode(false);
                }}
                sx={{ width: '180px' }}
                light
                isClose
              />
              <CustomButton
                value="Speichern"
                onClick={() => onSaveClicked()}
                sx={{ width: '180px' }}
              />
            </BoxCenter>

          </>
        ) : (
          <Table>
            <TableBody>
              {tableRows?.map(
                (row) =>
                  showRow(row) && (
                    <TableRow key={row.renderKey}>
                      <TableCell>{row.name}</TableCell>
                      <TableCell align="left">{row.value}</TableCell>
                    </TableRow>
                  ),
              )}
            </TableBody>
          </Table>
        )}
      </LocalizationProvider>
    </Box>
  );
};
