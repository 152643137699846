import * as endpoints from '../../constants/endpoints';
import { apiClient } from '../apiClient';

export const getList = async (companyId, addressRegistrationId, success, error) => {
  const response = await apiClient.getList(endpoints.getBusinessRegistrationList, { companyId, addressRegistrationId }, success, error);
  return response.data;
};

export const insertOne = async (data, success, error) => {
  const response = await apiClient.insertOne(endpoints.insertBusinessRegistration, data, success, error);
  return response.data;
};

export const updateOne = async (data, success, error) => {
  const response = await apiClient.updateOne(endpoints.updateBusinessRegistration, data, success, error);
  return response.data;
};

export const deleteOne = async (id, success, error) => {
  const response = await apiClient.deleteOne(endpoints.deleteBusinessRegistration, id, success, error);
  return response.data;
};
