import React, { useState, useCallback, useEffect } from 'react';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Container from '@mui/material/Container';

import { a11yProps } from '../../common-components/TabPanel/tabsProps';
import TabPanel from '../../common-components/TabPanel';
import SectionHeading from '../../common-components/SectionHeading';

import Navbar from '../Navbar';

import ElectionProposalList from './components/ElectionProposalList';

import * as OrganisationService from '../../../services/requests/organisation';

import { AdminElectionProposalContext } from './AdminElectionProposalContext';
import dayjs from 'dayjs';
import { useGetElectionProposalQuery } from '../../../redux/services/ElectionProposalService';
import ElectionProposalDetails from './components/ElectionProposalDetails/ElectionProposalDetails';
import { getElectionYears } from '../../../helpers/electionYear';

export default function AdminElectionProposal() {
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [chosenElectionProposal, setChosenElectionProposal] = useState(null);
  const [chosenPanel, setChosenPanel] = useState(null);



  const handleTabChange = (_: any, newValue: number) => {
    setCurrentTab(newValue);
  };

  const tabs = getElectionYears();

  const { data, error, isLoading } = useGetElectionProposalQuery({});

  const [electionProposalTab, setElectionProposalTab] = useState<number>(tabs?.length)
  useEffect(() => {
    setElectionProposalTab(tabs?.length)
  }, [tabs])

  useEffect(() => { console.log("CurrentTab", currentTab, electionProposalTab) }, [currentTab])

  return (
    <AdminElectionProposalContext.Provider
      value={{
        electionProposals: data?.data,
        chosenElectionProposal,
        setChosenElectionProposal,
        electionProposalTab,
        
        setCurrentTab,
        chosenPanel,
        setChosenPanel,
      }}
    >
      <SectionHeading title={"Wahlvorschläge"} />
      <Container maxWidth="xl">
        <Tabs value={currentTab} onChange={handleTabChange}>
          {tabs.map((year: number, index: number) => {
            return <Tab label={`Wahl ${year}`} {...a11yProps(index)} key={`T${index}`} />
          })}
          {electionProposalTab &&
            <Tab label={`Wahlvorschlag`} {...a11yProps(electionProposalTab)} key={`T${electionProposalTab}`} disabled={currentTab !== electionProposalTab} />
          }
        </Tabs>
      </Container>

      {tabs.map((year: number, index: number) => {
        return <TabPanel value={currentTab} index={index} key={`TP${index}`}>
          {/*@ts-ignore*/}
          <ElectionProposalList year={year} />
        </TabPanel>
      })}
      <TabPanel value={currentTab} index={electionProposalTab} key={`TP${electionProposalTab}`}>
        <ElectionProposalDetails />
      </TabPanel>

    </AdminElectionProposalContext.Provider>
  );
}
