import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';

import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Container from '@mui/material/Container';

import { useSnackbarHelpers, deletePopup } from '../../../../../helpers/utils';

import { AdminOrganisationContext } from '../../AdminOrganisationContext';
import * as PanelService from '../../../../../services/requests/panel';

import DeleteCell from '../../../../common-components/DeleteCell';
import FilterTop from '../../../../common-components/FilterTop';
import FormTextField from '../../../../common-components/FormTextField';

import { useStyles } from './styles';
import { useGlobalStyles } from '../../../../../customStyles';

export default function PanelList() {
  const classes = useStyles();
  const cssClass = useGlobalStyles();
  const { showErrorSnackbar } = useSnackbarHelpers();

  const {
    setCurrentTab,
    panelList,
    getPanelList,
    chosenPanel,
    setChosenPanel,
    chosenOrganisation,
  } = useContext(AdminOrganisationContext);

  const { control } = useForm({
    defaultValues: {
      name: '',
    },
  });

  const values = useWatch({ control });

  const onRowClick = (row) => {
    setChosenPanel(row);
    setCurrentTab(3);
  };

  const deletePanel = async (id) => {
    return await PanelService.deleteOne(id, () => {
      if (chosenPanel?.ID == id) {
        setChosenPanel(null);
      }
      getPanelList({
        FK_Organisation: chosenOrganisation?.ID,
        name: values?.name,
      });
    });
  };

  useEffect(() => {
    getPanelList({
      ...values,
      FK_Organisation: chosenOrganisation?.ID,
    })
  }, [chosenOrganisation?.ID])

  const handleSearch = () => {
    getPanelList({
      ...values,
      FK_Organisation: chosenOrganisation?.ID,
    })
  };

  const handleNew = () => {
    setChosenPanel({ Name: values.name });
    setCurrentTab(3);
  };

  return (
    <Container maxWidth="lg">
      <FormControl>
        <FilterTop>
          <FormTextField
            name="name"
            label="Name"
            control={control}
          />
          <Button onClick={() => handleSearch()}>
            Suchen
          </Button>
          <Button onClick={() => handleNew()}>
            Neu
          </Button>
        </FilterTop>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell className={cssClass.btnCell}>Löschen</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {panelList?.map((row) => (
                <TableRow
                  key={row.ID}
                  selected={chosenPanel?.ID == row.ID}
                  onClick={() => onRowClick(row)}
                >
                  <TableCell>{row.Name}</TableCell>
                  <DeleteCell func={deletePanel} id={row.ID} />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </FormControl>
    </Container>
  );
}