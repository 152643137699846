import { createUseStyles } from 'react-jss';
import { swvTheme } from '../../../../../swvTheme';

export const useStyles = createUseStyles({
  title: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    '& .MuiSvgIcon-root': {
      margin: '1rem',
    },
    color: swvTheme.palette.primary.main,
  },

  CompanyCarouselContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    backgroundColor: '#FFFFFF',
    boxShadow: swvTheme.boxShadow,
    borderRadius: '5px',

    [swvTheme.breakpoints.down('sm')]: {
      display: 'block',
      marginBottom: '32px',
    },
  },

  imageCaption: {
    textAlign: "center",
  },

  ImgCard: {
    display: 'block',
    overflow: 'hidden',
    width: '100%',
    aspectRatio: '1/1',
    borderRadius: '5px',
    boxShadow: '2px 5px 9px 0px rgba(0,0,0,0.71)',
    objectFit: 'contain',
  },

  BrokenImgCard: {
    display: 'flex',

    width: '100%',
    aspectRatio: '1/1',

    justifyContent: 'center',
    alignItems: 'center',

    boxShadow: '2px 5px 9px 0px rgba(0,0,0,0.71)',

    '& svg': {
      width: '80%',
      height: '80%',
    },
  },

  AddImageCard: {
    '& svg': {
      width: '71px',
      height: '71px',
    },
  },

  EditRow: {
    position: 'absolute',
    bottom: '0',
    right: '0',
    backgroundColor: swvTheme.palette.background.default,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'fit-content',
    height: 'fit-content',

    borderRadius: '5px',

    "& svg": {
      width: '20.25px',
      height: '20.25px',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  },

  CompanyCarouselWrapper: {
    width: '400px',
    flexGrow: 1,

    [swvTheme.breakpoints.down('sm')]: {
      width: 'unset',
      maxWidth: 'unset',
    },

    '& .MuiMobileStepper-dots': {
      [swvTheme.breakpoints.down('sm')]: {
        width: '100%',
        justifyContent: 'center',
      },
    },
    '& .MuiMobileStepper-dotActive': {
      backgroundColor: swvTheme.palette.button.light
    },
  },

  nextButton: {
    marginLeft: 10,
    backgroundColor: swvTheme.palette.button.light,

    '&:hover': {
      backgroundColor: swvTheme.palette.button.dark,
    },

    [swvTheme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  backButton: {
    marginRight: 10,
    backgroundColor: swvTheme.palette.button.light,

    "&:hover": {
      backgroundColor: swvTheme.palette.button.dark,
    },

    [swvTheme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
});
