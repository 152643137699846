import { createUseStyles } from 'react-jss';
import { swvTheme } from '../../../swvTheme';

export const useStyles = createUseStyles({
  wrapper: {
    width: '100%',
    [swvTheme.breakpoints.up("md")]: {
        width: '880px !important',
      }
  }
});
