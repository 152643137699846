import React from 'react';
import { useContext, useState } from 'react';
import { useForm, Controller, useWatch } from 'react-hook-form';

import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Container from '@mui/material/Container';

import FilterTop from '../../../../common-components/FilterTop';
import BoxCenter from '../../../../common-components/BoxCenter';
import FormTextField from '../../../../common-components/FormTextField';

import * as CompanyService from '../../../../../services/requests/company';
import { getAddressRegistration } from '../../../../../helpers/database';

import { MunicipalityContext } from '../../AdminMunicipalityContext';
import { useGlobalStyles } from '../../../../../customStyles';
import { Box } from '@mui/material';
import { useStyles } from './styles';

export default function MunicipalityCompaniesSearch() {
  const classes = useStyles()
  const cssClass = useGlobalStyles();

  const {
    chosenCompany,
    setChosenCompany,
    companyList,
    setCompanyList,
    chosenMunicipality,
    onCompanySearch,
    getMunicipalityCompanies,
  } = useContext(MunicipalityContext);

  const { control } = useForm({
    defaultValues: {
      CompanyName: '',
    },
  });

  const values = useWatch({ control });

  async function addCompanyToMunicipality() {
    if (chosenCompany) {
      await CompanyService.updateOne(
        {
          ID: chosenCompany.ID,
          FK_Municipality: chosenMunicipality.ID,
        },
        async (data) => {
          setCompanyList((list) => {
            const chosenCompanyId = list.findIndex(
              (company) => company.ID == chosenCompany.ID,
            );

            const newCompanyList = [...list];
            newCompanyList[chosenCompanyId] = data;

            return newCompanyList;
          });

          setChosenCompany(null);
          await getMunicipalityCompanies();
        },
      );
    }
  }

  const onRowClick = async (row) => {
    setChosenCompany(row);
  };

  return (
    <Container maxWidth="lg">
      <FormControl fullWidth>
        <FilterTop>
          <FormTextField
            name="CompanyName"
            label="Name"
            control={control}
            className={classes.searchField}
            fullWidth
          />
          <Button
            disabled={!values.CompanyName}
            onClick={() => onCompanySearch(values?.CompanyName)}
          >
            Suchen
          </Button>
        </FilterTop>
        <TableContainer>
          <Table sx={{ minWidth: 450 }}>
            <TableHead>
              <TableRow>
                <TableCell>Firmenname</TableCell>
                <TableCell className={cssClass.largeCell}>Adresse</TableCell>
                <TableCell>Gemeinde</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {companyList?.map((row) => (
                <TableRow
                  key={row.ID}
                  selected={chosenCompany?.ID == row.ID}
                  onClick={() => onRowClick(row)}
                >
                  <TableCell>{row.Name}</TableCell>
                  <TableCell>
                    {getAddressRegistration(row.DeliveryWKO)}
                  </TableCell>
                  <TableCell>{row.Municipality?.Name}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <BoxCenter>
          <Button
            disabled={
              !chosenCompany ||
              chosenCompany.FK_Municipality == chosenMunicipality.ID
            }
            onClick={() => addCompanyToMunicipality()}
            sx={{ minWidth: '200px', mt: '30px' }}
          >
            Zuweisen
          </Button>
        </BoxCenter>
      </FormControl>
    </Container>
  );
}
