import React, { useCallback, useEffect, useState } from 'react';
import { useForm, Controller, useWatch } from 'react-hook-form';

import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import PersonPopUp from '../../../../popups/Person/Person';

import * as PersonService from '../../../../../services/requests/person';
import * as HonorService from '../../../../../services/requests/honor';

import { useSnackbarHelpers } from '../../../../../helpers/utils';
import { getPerson as getPersonEx } from '../../../../../helpers/database';

import BoxCenter from '../../../../common-components/BoxCenter';
import FormTextField from '../../../../common-components/FormTextField';
import FormDatePicker from '../../../../common-components/FormDatePicker';

import { styles } from './styles';

import CustomSelect from '../../../../common-components/CustomSelect';
import OrganisationPopUp from '../../../../popups/Organisation';

export default function HonorDetails({
  chosenHonor,
  onSaveSuccess
}) {
  const [isPersonPopUpOpen, setIsPersonPopUpOpen] = useState(false);
  const [chosenPerson, setChosenPerson] = useState(chosenHonor?.User || null);
  const [chosenSubmitter,  setChosenSubmitter] = useState(null);
  const [chosenAwarder, setChosenAwarder] = useState(null);
  const [isOrganisationModalOpen, setIsOrganisationModalOpen] = useState(false);
  const [currentOrganisation, setCurrentOrganisation] = useState(null);
  const [chosenType, setChosenType] = useState(null);

  const { } = useSnackbarHelpers();

  const { control, reset } = useForm({
    defaultValues: {
      Name: '',
      Person: '',
      FK_Person: '',
      Application: '',
      Submitter: '',
      FK_Submitter: '',
      Awarding: '',
      Rejection: '',
      FK_Awarder: '',
      Awarder: '',
    },
  });

  const values = useWatch({ control });

  useEffect(() => {
    chosenHonor?.Submitter
      ?  setChosenSubmitter(chosenHonor?.Submitter)
      :  setChosenSubmitter(null);
  }, [chosenHonor?.Submitter]);

  useEffect(() => {
    chosenHonor?.Awarder
      ? setChosenAwarder(chosenHonor?.Awarder)
      : setChosenAwarder(null);
  }, [chosenHonor?.Awarder]);

  useEffect(() => {
    if (chosenHonor) {
      const defaultValues = {
        ID: chosenHonor.ID,
        Name: chosenHonor.Name,
        Application: chosenHonor.Application,
        Person: getPersonEx(chosenHonor.User),
        FK_Person: chosenHonor.User?.ID,
        Awarding: chosenHonor.Awarding,
        Rejection: chosenHonor.Rejection,
        Submitter: chosenSubmitter,
        FK_Submitter: chosenHonor.Submitter?.ID,
        Awarder: chosenAwarder,
        FK_Awarder: chosenHonor.Awarder?.ID,
      };

      reset(defaultValues);
    }
  }, [chosenHonor, chosenSubmitter, chosenAwarder]);

  useEffect(() => {
    if (values?.Submitter) {
      reset({
        ID: values?.ID,
        Name: values?.Name,
        Application: values?.Application,
        Person: getPersonEx(chosenPerson),
        FK_Person: chosenPerson?.ID,
        Awarding: values?.Awarding,
        Rejection: values?.Rejection,
        Submitter: values?.Submitter,
        FK_Submitter: chosenSubmitter.ID,
        Awarder: values?.Awarder,
        FK_Awarder: values?.FK_Awarder,
      });
    }
  }, [values?.Submitter]);

  useEffect(() => {
    if (values?.Awarder) {
      reset({
        ID: values?.ID,
        Name: values?.Name,
        Application: values?.Application,
        Person: getPersonEx(chosenPerson),
        FK_Person: chosenPerson?.ID,
        Awarding: values?.Awarding,
        Rejection: values?.Rejection,
        Submitter: values?.Submitter,
        FK_Submitter: values?.FK_Submitter,
        Awarder: values?.Awarder,
        FK_Awarder: chosenAwarder.ID,
      });
    }
  }, [values?.Awarder]);

  useEffect(() => {
    if (chosenSubmitter) {
      reset({
        ID: values?.ID,
        Name: values?.Name,
        Application: values?.Application,
        Person: getPersonEx(chosenPerson),
        FK_Person: chosenPerson?.ID,
        Awarding: values?.Awarding,
        Rejection: values?.Rejection,
        Submitter: chosenSubmitter,
        FK_Submitter: chosenSubmitter.ID,
        Awarder: values?.Awarder,
        FK_Awarder: values.FK_Awarder,
      });
    }
  }, [chosenSubmitter]);

  useEffect(() => {
    if (chosenAwarder) {
      reset({
        ID: values?.ID,
        Name: values?.Name,
        Application: values?.Application,
        Person: getPersonEx(chosenPerson),
        FK_Person: chosenPerson?.ID,
        Awarding: values?.Awarding,
        Rejection: values?.Rejection,
        Submitter: values?.Submitter,
        FK_Submitter: values?.FK_Submitter,
        Awarder: chosenAwarder,
        FK_Awarder: chosenAwarder.ID,
      });
    }
  }, [chosenAwarder]);

  async function getPerson(id) {
    await PersonService.getOne(id, setChosenPerson);
  }

  const onPersonModalOpen = async (person) => {
    if (person) {
      await getPerson(person.ID);
    } else
      setChosenPerson(null);

    setIsPersonPopUpOpen(true);
  };

  const onPersonModalClose = async () => {
    setIsPersonPopUpOpen(false);
  };

  const onHonorSave = async () => {
    const preload = {
      ID: chosenHonor?.ID,
      Name: values?.Name,
      FK_Person: values?.FK_Person,
      Application: values?.Application || null,
      FK_Submitter: values?.FK_Submitter || null,
      Awarding: values?.Awarding || null,
      Rejection: values?.Rejection || null,
      FK_Awarder: values?.Awarding ? values?.FK_Awarder : null,
    };

    const success = async (data) => {
      await onSaveSuccess()
    };

    if (chosenHonor?.ID) {
      await HonorService.updateOne(preload, success);
    } else {
      await HonorService.insertOne(preload, success);
    }
  };

  const onPersonApply = async () => {
    reset({
      ID: values?.ID,
      Name: values?.Name,
      Application: values?.Application,
      Person: getPersonEx(chosenPerson),
      Awarding: values?.Awarding,
      Rejection: values?.Rejection,
      FK_Person: chosenPerson?.ID,
      FK_Submitter: values?.FK_Submitter,
      FK_Awarder: values?.FK_Awarder,
      Submitter: values?.Submitter,
      Awarder: values?.Awarder,
    });

    setIsPersonPopUpOpen(false);
  };

  const onOrganisationModalOpen = (value) => {
    if (value == 'chosenSubmitter') {
      setCurrentOrganisation(chosenSubmitter);
      setChosenType('chosenSubmitter');
    } else {
      setCurrentOrganisation(chosenAwarder);
      setChosenType('chosenAwarder');
    }
    setIsOrganisationModalOpen(true);
  }

  const onOrganisationModalClose = () => {
    setIsOrganisationModalOpen(false);
  }

  const onOrganisationApply = (organisation) => {
    if (chosenType == 'chosenSubmitter') {
       setChosenSubmitter(organisation);
    } else {
      setChosenAwarder(organisation);
    }
    setIsOrganisationModalOpen(false);
  }

  const disabled = !values.Name || !values.FK_Person;

  return (
    <>
      <Container maxWidth="sm">
        <FormControl>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <FormTextField
              name="Name"
              label="Name"
              control={control}
              required
            />
            <CustomSelect
              required
              placeholder="Person"
              value={values.Person}
              selectedRecord={chosenPerson}
              onClick={onPersonModalOpen}
            />
            <FormDatePicker
              name="Application"
              label="Antrag"
              control={control}
              required
            />
            <CustomSelect
              placeholder="Einreicher"
              value={chosenSubmitter ? chosenSubmitter.Name : ''}
              onClick={onOrganisationModalOpen}
              selectedRecord={'chosenSubmitter'}
            />
            <FormDatePicker
              name="Awarding"
              label="Verleihung"
              control={control}
              disabled={values?.Rejection}
            />
            <CustomSelect
              placeholder="Verleiher"
              disabled={values?.Rejection}
              value={chosenAwarder ? chosenAwarder.Name : ''}
              onClick={onOrganisationModalOpen}
            />
            <FormDatePicker
              name="Rejection"
              label="Ablehnung"
              control={control}
              disabled={values?.Awarding}
              selectedRecord={'chosenAwarder'}
            />
            <BoxCenter sx={styles.submitButtonContainer}>
              <Button
                onClick={() => onHonorSave()}
                disabled={disabled}
                sx={styles.submitButton}
              >
                Speichern
              </Button>
            </BoxCenter>
          </LocalizationProvider>
        </FormControl>
      </Container>
      <PersonPopUp
        isOpen={isPersonPopUpOpen}
        onClose={onPersonModalClose}
        chosenPerson={chosenPerson}
        setChosenPerson={setChosenPerson}
        onPersonApply={onPersonApply}
      />
      <OrganisationPopUp
        currentOrganisation={currentOrganisation}
        isOpen={isOrganisationModalOpen}
        onClose={onOrganisationModalClose}
        handleApply={onOrganisationApply}
      />
    </>
  );
}
