import * as endpoints from '../../constants/endpoints';
import { apiClient } from '../apiClient';

export const getList = async (query, success, error) => {
  const response = await apiClient.getList(endpoints.getOrganisationList, query, success, error);
  return response.data;
};

export const insertOne = async (data, success, error) => {
  const response = await apiClient.insertOne(endpoints.insertOrganisation, data, success, error);
  return response.data;
}

export const updateOne = async (data, success, error) => {
  const response = await apiClient.updateOne(endpoints.updateOrganisation, data, success, error);
  return response.data;
}

export const deleteOne = async (id, success, error) => {
  const response = await apiClient.deleteOne(endpoints.deleteOrganisation, id, success, error);
  return response.data;
}
