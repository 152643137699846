import React, { useContext } from 'react';
import { Button, IconButton, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';

import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';

import Paper from '@mui/material/Paper';
import AddIcon from '@mui/icons-material/Add';

import { chooseContactPersonPosition, getDate, getPerson } from '../../../../../helpers/database';

import { CompanyContext } from '../../CompanyContext';

import DeleteCell from '../../../../common-components/DeleteCell';
import BoxLeft from '../../../../common-components/BoxLeft';
import { useStyles } from './styles';
import { useGlobalStyles } from '../../../../../customStyles';
import { swvTheme } from '../../../../../swvTheme';
import { Edit, DeleteForever, Bookmark, BookmarkBorderOutlined } from '@mui/icons-material/';

export default function ContactPersonTable({ onPersonModalOpen, deleteContactPerson, setContactPersonMaster }) {
  const classes = useStyles();
  const cssClass = useGlobalStyles();

  const { contactPersonList } = useContext(CompanyContext);

  return (
    <Grid xs={12} mt={3}>
      <Box sx={{ alignItems: 'center' }}>
        <Paper sx={{ borderRadius: '8px' }}>
          <Typography color={swvTheme.typography.lilac} fontSize={18} fontWeight={500} p={2}>
            Ansprechpersonen
          </Typography>
          <div className={classes.spaceBetweenTables}>
            {contactPersonList?.map((row) => (
              <Table key={row.ID} sx={{ borderBottom: '1px dashed #C7C7C7;' }}>
                <TableBody className={cssClass.customTableBody}>
                  <TableRow>
                    <TableCell className={cssClass.shrinkCell}>Name
                      {row.Master != null &&
                        <IconButton
                          color="primary"
                        >
                          <Bookmark />
                        </IconButton>
                      }

                      {row.Master == null &&
                        <IconButton
                          color="primary"
                          onClick={() => setContactPersonMaster(row.ID)}
                        >
                          <BookmarkBorderOutlined />
                        </IconButton>
                      }
                    </TableCell>
                    <TableCell>{getPerson(row.Person)}</TableCell>
                    <TableCell align="right" sx={{ paddingLeft: '0 !important', maxWidth: 'none', width: '44px' }}>
                      <IconButton
                        color="primary"
                        onClick={() => deleteContactPerson(row.ID)}
                      >
                        <DeleteForever />
                      </IconButton>
                      <IconButton
                        color="primary"
                        onClick={() => onPersonModalOpen(row)}
                      >
                        <Edit />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Position</TableCell>
                    <TableCell colSpan={2}>{chooseContactPersonPosition(row.Position)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Anfang</TableCell>
                    <TableCell colSpan={2}>{getDate(row.Start)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Ende</TableCell>
                    <TableCell colSpan={2}>{getDate(row.End)}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            ))}
          </div>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 2,
              justifyContent: 'flex-end',
              padding: 2,
              cursor: 'pointer',
            }}
          >
            <AddIcon sx={{ fill: '#F50000' }} onClick={() => onPersonModalOpen()} />
          </Box>
        </Paper>
      </Box>
    </Grid>
  );
}
