import { Middleware } from '@reduxjs/toolkit';
import {
    isAsyncThunkAction,
    isPending,
    isFulfilled,
    isRejected,
    isRejectedWithValue
} from '@reduxjs/toolkit';
import { closeSpinner, openSpinner } from '../../helpers';

const isSilentAction = isAsyncThunkAction(/* list of silent actions */);

export const loaderMiddleware: Middleware = (store) => (next) => async (action) => {
    //if (!isSilentAction(action)) {
        if (isPending(action)) {
            // Dispatch a "show loader" action
            openSpinner()
        }
        if (isFulfilled(action) || isRejected(action) || isRejectedWithValue(action)) {
            // Dispatch a "hide loader" action
            closeSpinner();
        }
    //}
    return next(action);
};