import { createUseStyles } from 'react-jss';
import { swvTheme } from '../../../swvTheme';

export const useStyles = createUseStyles({
  mainContainer: {
    maxWidth: '35rem',
  },
  customLabel: {
    color: "rgba(0, 0, 0, 0.6)",
    lineHeight: "1.4375em",
    padding: "0",
    display: "block",
    transformOrigin: "top left",
    whiteSpace: "nowrap",
    overflow: "visible",
    textOverflow: "ellipsis",
    maxWidth: "calc(133% - 24px)",
    position: "absolute",
    left: "0",
    top: "0",
    transform: "translate(14px, -9px) scale(0.75)",
    transition: "color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
    zIndex: "1",
    pointerEvents: "auto",

    "&:before": {
        content: "''",
        width: "calc(100% + 10px)",
        height: "100%",
        background: swvTheme.palette.primary.white,
        position: "absolute",
        left: "-5px",
        zIndex: "-1",
    },

  },
  customSelect: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    fontSize: '16px',
    color: swvTheme.palette.secondary.gray,
    borderRadius: '4px',
    border: '1px solid #bbb',
    padding: '10px',
    margin: '8px 0 4px 0',
    height: '18px',
    cursor: 'pointer',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',

    backgroundColor: swvTheme.palette.primary.white,

    '&:hover': {
      borderColor: swvTheme.palette.primary.dark,
    },

    '&:focus': {
      borderColor: swvTheme.palette.primary.dark,
      outline: 'none',
    },

    '&[disabled]': {
      opacity: '0.5',
      pointerEvents: 'none',
    },
  },
});
