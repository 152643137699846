import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./basequery";
import * as endpoints from '../../constants/endpoints';
import { IChangeProposal } from "../../interfaces/IChangeProposal";


/* TODO: more optimal to change all endpoints to use injectEndpoints() into baseApiSlice 
         as createApi usually is only used once per App */
export const ChangeProposalApi = createApi({
    reducerPath: "ChangeProposal",
    baseQuery,
    tagTypes: ['ChangeProposalList'],
    endpoints: (builder) => ({
        getAllChangeProposals: builder.query<{ rows: IChangeProposal[], count: number }, { page?: number, pageSize?: number }>({
            query: ({ page = 0, pageSize = 25 }) => ({
                url: endpoints.getAllChangeProposal,
                method: "GET",
                params: { page, pageSize }
            }),
            providesTags: ['ChangeProposalList']
        }),
        updateChangeProposal: builder.mutation({
            query: (updatedEntity: IChangeProposal) => ({
                url: endpoints.updateChangeProposal,
                method: 'PUT',
                body: updatedEntity
            }),
            invalidatesTags: ['ChangeProposalList']
        })
    })
})

export const { useGetAllChangeProposalsQuery, useUpdateChangeProposalMutation } = ChangeProposalApi