export const counties = [
    { name: "Burgenland" },
    { name: "Kaernten" },
    { name: "Niederösterreich" },
    { name: "Salzburg" },
    { name: "Steiermark" },
    { name: "Oberösterreich" },
    { name: "Tirol" },
    { name: "Vorarlberg" },
    { name: "Wien" }
]

export const countiesWithValues = [
    {value: "B", name: "Burgenland"},
    {value: "K", name: "Kärnten"},
    {value: "N", name: "Niederösterreich"},
    {value: "O", name: "Oberösterreich"},
    {value: "S", name: "Salzburg"},
    {value: "M", name: "Steiermark"},
    {value: "T", name: "Tirol"},
    {value: "V", name: "Vorarlberg"},
    {value: "W", name: "Wien"}
]