import React, { useContext, useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';

import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';

import ModalWrapper from '../../common-components/ModalWrapper';
import BoxCenter from '../../common-components/BoxCenter';
import FormCheckbox from '../../common-components/FormCheckbox';
import FormMemoField from '../../common-components/FormMemoField';

import { useStyles } from './styles';
import { useGlobalStyles } from '../../../customStyles';

export default function CommentPopUp({
  isOpen,
  onClose,
  onSubmit,
  CommentContext = [],
}) {
  const classes = useStyles();
  const cssClass = useGlobalStyles();

  const { chosenComment } = useContext(CommentContext);

  const { control, reset } = useForm({
    defaultValues: {
      ID: '',
      Creation: Date.now(),
      Content: '',
      IsPrivate: false,
    },
  });

  const values = useWatch({ control });

  useEffect(() => {
    if (isOpen) {
      if (chosenComment) {
        reset(chosenComment);
      }
    } else {
      const defaultValues = {
        ID: '',
        Creation: Date.now(),
        Content: '',
        IsPrivate: false,
      };
      reset(defaultValues);
    }
  }, [isOpen]);

  const disabled = !values?.Content;

  return (
    <ModalWrapper isOpen={isOpen} onClose={onClose} title="Notiz" className={cssClass.swvModalResponsiveMods + " " + classes.wrapper}>
        <FormControl>
            <FormMemoField
              name="Content"
              label="Inhalt"
              control={control}
              rows={6}
              required
            />
            <FormCheckbox
              name="IsPrivate"
              control={control}
              label="Privat"
            />
        </FormControl>
        <BoxCenter>
          <Button
            onClick={() => onSubmit(values)}
            disabled={disabled}
          >
            Speichern
          </Button>
        </BoxCenter>
    </ModalWrapper>
  );
}