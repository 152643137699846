import React, { useEffect, useState } from 'react';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Container from '@mui/material/Container';

import * as MunicipalityService from '../../../services/requests/municipality';
import * as CompanyService from '../../../services/requests/company';

import { a11yProps } from '../../common-components/TabPanel/tabsProps';
import TabPanel from '../../common-components/TabPanel';
import SectionHeading from '../../common-components/SectionHeading';

import { useSnackbarHelpers, deletePopup } from '../../../helpers/utils';

import Navbar from '../Navbar';
import MunicipalitySearch from './components/MunicipalitySearch/MunicipalitySearch';
import MunicipalityDetails from './components/MunicipalityDetails/MunicipalityDetails';
import MunicipalityCompaniesList from './components/MunicipalityCompaniesList/MunicipalityCompaniesList';
import MunicipalityCompaniesSearch from './components/MunicipalityCompaniesSearch/MunicipalityCompaniesSearch';
import { MunicipalityContext } from './AdminMunicipalityContext';

export default function AdminMunicipality() {
  const [currentTab, setCurrentTab] = useState(0);
  const [chosenMunicipality, setChosenMunicipality] = useState(null);
  const [chosenCompany, setChosenCompany] = useState(null);
  const [municipalityList, setMunicipalityList] = useState(null);
  const [chosenPerson, setChosenPerson] = useState(null);
  const [chosenDeputy, setChosenDeputy] = useState(null);
  const [municipalityCompanyList, setMunicipalityCompanyList] = useState(null);
  const [countrySearchFilter, setCountrySearchFilter] = useState(null);
  const [nameSearchFilter, setNameSearchFilter] = useState(null);

  const [companyList, setCompanyList] = useState(null);
  const {} = useSnackbarHelpers();

  const handleChange = (_, newValue) => {
    setCurrentTab(newValue);
  };

  const onMunicipalitySearch = async () => {
    await MunicipalityService.getList(
      {
        Name: nameSearchFilter,
        County: countrySearchFilter,
      },
      setMunicipalityList,
    );
  };

  const deleteMunicipality = async (id) => {
    await MunicipalityService.deleteOne(id, onMunicipalitySearch);
  };

  const getMunicipalityCompanies = async () => {
    await CompanyService.getMunicipalityCompanyList(
      chosenMunicipality.ID,
      setMunicipalityCompanyList,
    );
  };

  async function onCompanySearch(companyName) {
    setChosenCompany(null);

    await CompanyService.getList({ text: companyName }, setCompanyList);
  }

  useEffect(() => {
    return () => {
      setChosenMunicipality(null);
    };
  }, []);

  return (
    <MunicipalityContext.Provider
      value={{
        currentTab,
        setCurrentTab,

        onMunicipalitySearch,
        municipalityList,
        setMunicipalityList,
        chosenMunicipality,
        setChosenMunicipality,
        deleteMunicipality,
        getMunicipalityCompanies,
        setCountrySearchFilter,
        setNameSearchFilter,

        onCompanySearch,
        companyList,
        setCompanyList,
        chosenCompany,
        setChosenCompany,

        chosenPerson,
        setChosenPerson,

        chosenDeputy,
        setChosenDeputy,

        municipalityCompanyList,
        setMunicipalityCompanyList,
      }}
    >
      <SectionHeading title="Gemeinde" />
      <Container maxWidth="lg">
        <Tabs value={currentTab} onChange={handleChange}>
          <Tab label="Suche" {...a11yProps(0)} />
          <Tab
            label="Gemeinde"
            disabled={!chosenMunicipality}
            {...a11yProps(1)}
          />
          <Tab
            label="Betriebe der Gemeinde"
            disabled={!chosenMunicipality?.ID}
            {...a11yProps(2)}
          />
          <Tab
            label="Suche Betriebe"
            disabled={!chosenMunicipality?.ID}
            {...a11yProps(3)}
          />
        </Tabs>
      </Container>

      <TabPanel keepMounted value={currentTab} index={0}>
        <MunicipalitySearch />
      </TabPanel>

      <TabPanel value={currentTab} index={1}>
        <MunicipalityDetails />
      </TabPanel>

      <TabPanel value={currentTab} index={2}>
        <MunicipalityCompaniesList />
      </TabPanel>

      <TabPanel keepMounted value={currentTab} index={3}>
        <MunicipalityCompaniesSearch />
      </TabPanel>
    </MunicipalityContext.Provider>
  );
}
