import React, { useContext, useEffect, useState, useCallback } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Container from '@mui/material/Container';
import { Button } from '@mui/material';

import * as CompanyService from '../../../../../services/requests/company';

import { useSnackbarHelpers, questionPopup } from '../../../../../helpers/utils';

import { getAddressRegistration } from '../../../../../helpers/database';

import { useStyles } from './styles';
import { useGlobalStyles } from '../../../../../customStyles';
import { MunicipalityContext } from '../../AdminMunicipalityContext';

import DeleteCell from '../../../../common-components/DeleteCell';
import BoxCenter from '../../../../common-components/BoxCenter';
import { Messages } from '../../../../../constants/messages';

export default function MunicipalityCompaniesList() {
  const classes = useStyles();
  const cssClass = useGlobalStyles();
  const { } = useSnackbarHelpers();

  const {
    setCurrentTab,
    currentTab,

    chosenMunicipality,
    municipalityCompanyList,
    getMunicipalityCompanies,
    chosenCompany,
  } = useContext(MunicipalityContext);

  async function handleDeleteCompanyFromMunicipality(companyId) {
    if (await questionPopup())
      await CompanyService.removeMunicipality(companyId, getMunicipalityCompanies);
  }

  useEffect(() => {
    if (chosenMunicipality && currentTab == 2) {
      getMunicipalityCompanies();
    }
  }, [chosenMunicipality, currentTab]);

  const onRowClick = async (row) => {
    setCurrentTab(2);
  };

  return (
    <Container maxWidth="lg" sx={{ mt: '20px' }}>
      <TableContainer sx={{ mb: '50px' }}>
        <Table sx={{ minWidth: 450 }}>
          <TableHead>
            <TableRow>
              <TableCell>Firmenname</TableCell>
              <TableCell className={cssClass.largeCell}>Adresse</TableCell>
              <TableCell>Wahlberechtigter</TableCell>
              <TableCell className={cssClass.btnCell}>Löschen</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {municipalityCompanyList?.map((row) => (
              <TableRow
                key={row.ID}
                selected={chosenCompany?.ID == row.ID}
                onClick={() => onRowClick(row)}
              >
                <TableCell>{row.Name}</TableCell>
                <TableCell>{getAddressRegistration(row.DeliveryWKO)}</TableCell>
                <TableCell>{row.Name}</TableCell>
                <DeleteCell func={handleDeleteCompanyFromMunicipality} id={row.ID} />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <BoxCenter>
        <Button
          sx={{ minWidth: '200px' }}
          onClick={() => setCurrentTab(3)}
        >
          Neu
        </Button>
      </BoxCenter>
    </Container>
  );
}