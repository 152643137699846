import React, { useContext, useEffect, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import ModalWrapper from "../../../common-components/ModalWrapper";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Autocomplete, Box, Button, Chip, CircularProgress, Container, Stack, TextField } from "@mui/material";
import { businessCategories } from "../../../../constants/businessCategories";
import { countiesWithValues } from "../../../../constants/counties";
import { UserContext } from "../../../pages/User/UserContext";
import { useGetBusinessSubcategoriesByCountyQuery } from "../../../../redux/services/BusinessSubCategoryService";
import { IBusinessSubcategory } from "../../../../interfaces/IBusinessSubcategory";
import { useGlobalStyles } from "../../../../customStyles";
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { useGetBusinessSubcategoryUserPermissionsByUserIdQuery, useInsertBusinessSubcategoryUserPermissionsMutation } from "../../../../redux/services/UserPermissionsService";

interface IBusinessSubcategoryPermissionAssistant {
    isOpen: boolean
    onClose: () => void
}

export default function BusinessSubcategoryPermissionAssistant({ isOpen, onClose }: IBusinessSubcategoryPermissionAssistant) {

    const cssClass = useGlobalStyles();
    const { currentUser } = useContext(UserContext);

    const [insertBusinessSubcategoryUserPermissions] = useInsertBusinessSubcategoryUserPermissionsMutation();

    const { data: businessSubcategories, isLoading: subcategoriesLoading, isFetching: subcategoriesFetching } = useGetBusinessSubcategoriesByCountyQuery();
    const { data: userPermissions, isLoading: permissionsLoading, isFetching: permissionsFetching } = useGetBusinessSubcategoryUserPermissionsByUserIdQuery(currentUser ? currentUser.ID : 0);

    const [filteredBusinessSubcategories, setFilteredBusinessSubcategories] = useState<IBusinessSubcategory[]>([]);
    const [selectedBusinessCategoryNumbers, setSelectedBusinessCategoryNumbers] = useState<number[]>([]);
    const [selectedCounties, setSelectedCounties] = useState<string[]>([]);

    const [selectedBusinessSubcategoriesForUser, setSelectedBusinessSubcategoriesForUser] = useState<IBusinessSubcategory[]>([]);
    const [uniqueBusinessSubcategories, setUniqueBusinessSubcategories] = useState<string[]>([]);
    const [selectedBusinessSubcategoriesFromFilter, setSelectedBusinessSubcategoriesForFilter] = useState<string[]>([]);

    useEffect(() => {
        setFilteredBusinessSubcategories(businessSubcategories ? getFilteredBusinessSubcategories() : [])
        setUniqueBusinessSubcategories(getUniqueBusinessSubcategories());
    }, [businessSubcategories, selectedBusinessCategoryNumbers, selectedCounties, selectedBusinessSubcategoriesFromFilter])

    useEffect(() => {
        const userSubcategoryIds = userPermissions ? userPermissions?.map(userPermissions => userPermissions.BusinessSubcategoryId) : [];
        setSelectedBusinessSubcategoriesForUser(businessSubcategories ? businessSubcategories.filter(subcategory => userSubcategoryIds?.includes(subcategory.ID)) : []);
    }, [userPermissions])

    const handleBusinessCategoryChange = (selectedOptions: { Number: number, Name: string }[]) => {
        setSelectedBusinessCategoryNumbers(selectedOptions.map(option => option.Number));
    }

    const handleCountyChange = (selectedOptions: { value: string, name: string }[]) => {
        setSelectedCounties(selectedOptions.map(county => county.value));
    }

    const handleBusinessSubcategorySelect = (clickedSubcategory: IBusinessSubcategory) => {
        const index = selectedBusinessSubcategoriesForUser.indexOf(clickedSubcategory);

        if (index === -1) {
            setSelectedBusinessSubcategoriesForUser((old) => [...old, clickedSubcategory]);
        }
        else {
            const newArr = [...selectedBusinessSubcategoriesForUser];
            newArr.splice(index, 1);
            setSelectedBusinessSubcategoriesForUser(newArr);
        }
    }

    const handleSubcategoryFilterChange = (selectedOptions: string[]) => {
        setSelectedBusinessSubcategoriesForFilter(selectedOptions);
    }

    const handleSelectAll = () => {
        setSelectedBusinessSubcategoriesForUser(filteredBusinessSubcategories);
    }

    const handleDeselectAll = () => {
        setSelectedBusinessSubcategoriesForUser([]);
    }

    const handleSave = async () => {
        const data = {
            UserId: currentUser.ID,
            BusinessSubcategoryIds: selectedBusinessSubcategoriesForUser.map(subcategory => subcategory.ID)
        }
        console.log("data handleSave", data);
        const result = await insertBusinessSubcategoryUserPermissions(data).unwrap();
    }

    return (
        <ModalWrapper isOpen={isOpen} onClose={onClose} title={currentUser ? `Berechtigungen für ${currentUser.Firstname} ${currentUser.Lastname} verwalten` : `Berechtigungen verwalten`}>
            <Container sx={{ maxHeight: "80vh", minHeight: "80vh" }}>
                <Autocomplete
                    id="businessCategories"
                    multiple
                    options={businessCategories}
                    getOptionLabel={(option) => `${option.Number}: ${option.Name}`}
                    onChange={(event, selectedOptions) => handleBusinessCategoryChange(selectedOptions)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Sparten"
                            placeholder="Sparten"
                        />
                    )}
                />
                <Autocomplete
                    id="uniqueBusinessSubcategories"
                    multiple
                    options={uniqueBusinessSubcategories}
                    getOptionLabel={(option) => option}
                    onChange={(event, selectedOptions) => { handleSubcategoryFilterChange(selectedOptions) }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Fachgruppennummern"
                            placeholder="Fachgruppennummern"
                        />
                    )}
                />
                <Autocomplete
                    id="counties"
                    multiple
                    options={countiesWithValues}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, selectedOptions) => handleCountyChange(selectedOptions)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Bundesland"
                            placeholder="Bundesland"
                        />
                    )}
                />
                <Box className={cssClass.listContainer} marginBottom={2}>
                    {filteredBusinessSubcategories.map((row: IBusinessSubcategory) =>
                        <span
                            key={row.ID}
                            className={`${cssClass.pointerCursor} ${selectedBusinessSubcategoriesForUser.some(subcategory => subcategory.ID === row.ID) ? cssClass.chosen : cssClass.notchosen}`}
                            onClick={() => { handleBusinessSubcategorySelect(row) }}
                            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                        >
                            {row.County}{row.Number}: {row.Name}
                            <CheckOutlinedIcon fontSize='small' style={{ color: '#732382', visibility: selectedBusinessSubcategoriesForUser.some(subcategory => subcategory.ID === row.ID) ? 'visible' : 'hidden' }} />
                        </span>)}
                </Box>
                <Box display='flex' justifyContent='space-between' marginBottom={2}>
                    <Button onClick={handleSelectAll} disabled={filteredBusinessSubcategories.length <= 0}>
                        Alle auswählen
                    </Button>
                    <Button onClick={handleDeselectAll} disabled={selectedBusinessSubcategoriesForUser.length <= 0}>
                        Alle abwählen
                    </Button>
                </Box>

                <Autocomplete
                    readOnly
                    multiple
                    options={filteredBusinessSubcategories}
                    getOptionLabel={(option) => `${option.County}${option.Number}: ${option.Name}`}
                    value={selectedBusinessSubcategoriesForUser}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Ausgewählte Fachgruppen"
                            placeholder="Ausgewählte Fachgruppen"
                        />
                    )}
                />
                <Box display="flex" justifyContent="flex-end" marginTop={1}>
                    <Button onClick={handleSave}>Speichern</Button>
                </Box>
            </Container>
        </ModalWrapper>
    )

    function getFilteredBusinessSubcategories() {

        if (!businessSubcategories || businessSubcategories.length === 0) {
            return [];
        }

        let tempBusinessSubcategories: IBusinessSubcategory[] = businessSubcategories;

        if (selectedBusinessCategoryNumbers.length > 0) {
            tempBusinessSubcategories = tempBusinessSubcategories.filter(businessSubcategory => selectedBusinessCategoryNumbers.includes(businessSubcategory.FK_BusinessCategory))
        }

        if (selectedCounties.length > 0) {
            tempBusinessSubcategories = tempBusinessSubcategories.filter(businessSubcategory => selectedCounties.includes(businessSubcategory.County));
        }

        if (selectedBusinessSubcategoriesFromFilter.length > 0) {
            tempBusinessSubcategories = tempBusinessSubcategories.filter(businessSubcategory => selectedBusinessSubcategoriesFromFilter.includes(businessSubcategory.Number))
        }

        return tempBusinessSubcategories;
    }

    function getUniqueBusinessSubcategories() {
        const uniqueBusinessSubcategoryNumbers = new Set<string>();

        if (!businessSubcategories || businessSubcategories.length === 0) {
            return [];
        }

        if (selectedBusinessCategoryNumbers.length > 0) {
            for (const subcategory of businessSubcategories) {
                if (selectedBusinessCategoryNumbers.includes(subcategory.FK_BusinessCategory)) {
                    uniqueBusinessSubcategoryNumbers.add(subcategory.Number);
                }
            }
        } else {
            for (const subcategory of businessSubcategories) {
                uniqueBusinessSubcategoryNumbers.add(subcategory.Number);
            }
        }


        return [...uniqueBusinessSubcategoryNumbers];
    }
}