import { createBrowserHistory } from 'history';
import { history } from '../redux/store';

// Odd, but we have no redux
export const HistoryAction = {
  PUSH: 'PUSH',
  POP: 'POP',
};

export const goTo = (path, state = null) => history.push(path, state);
export const goBack = (depth = -1) => history.go(depth);
