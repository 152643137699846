import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

export default function TabPanel(props) {
  const { children, value, index, keepMounted = false, ...other } = props;
  
  const isActive = value === index;
  
  const visuallyHidable = {
    width: isActive ? 'unset' : '0px',
    height: isActive ? 'unset' : '0px',
    overflow: isActive ? 'unset' : 'hidden',
  }
  
  return (
    <Box
      role="tabpanel"
      hidden={!isActive}
      sx={{ p: 3}}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      { ...other }
    >
      {keepMounted && (
        <Box sx={visuallyHidable}>
          {children}
        </Box>
      )}
      {!keepMounted && isActive && children}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  keepMounted: PropTypes.bool,
};
