import * as endpoints from '../../constants/endpoints';
import { apiClient } from '../apiClient';

export const insertOne = async (data, success, error) => {
  const response = await apiClient.insertOne(endpoints.insertFavorite, data, success, error);
  return response.data;
};

export const getList = async (success, error) => {
  const response = await apiClient.getList(endpoints.getFavoriteList, null, success, error);
  return response.data;
};

export const deleteOne = async (id, success, error) => {
  const response = await apiClient.deleteOne(endpoints.deleteFavorite, id, success, error);
  return response.data;
};

export const deleteOneEx = async (companyId, success, error) => {
  const response = await apiClient.delete(endpoints.deleteFavorite, { companyId }, null, success, error);
  return response.data;
};
