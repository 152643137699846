export const styles = {
  noUploadsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    padding: {
      xs: '5px',
      sm: '10px',
    },
  },
  noUploadsIcons: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '26px',
    mb: {
      xs: '10px',
      sm: '38px',
    },
    
    '& svg': {
      width: {
        xs: '35px',
        sm: '53px'
      }
    }
  },
  mainCaption: {
    fontSize: {
      xs: '1.3rem',
      sm: '1.5rem'
    },
    fontWeight: '500',
  },
  subCaption: {
    fontSize: {
      xs: '16px',
      sm: '18px'
    },
    mb: {
      xs: '5px',
      sm: '21px'
    },
  },
  buttonWrapper: {
    mt: {
      xs: '25px' ,
      sm: '54px'
    }
  },
  openFileBrowserButton: {
    width: '200px',
    minWidth: 'unset',
    maxWidth: '100%',
    fontSize: { xs: '12px', sm: '13px' },
    fontWeight: '500',
    lineHeight: '120%',
    padding: '16px !important',
    boxSizing: 'border-box',
  },
  dragOverOverlay: {
    background: 'rgba(217, 217, 217, 0.5)',
    borderRadius: '38px',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1,
  }
};
