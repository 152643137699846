import React, { useEffect, useState, useCallback } from 'react';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import FormSelect from '../../common-components/FormSelect';
import CountySelect from '../../common-components/CountySelect';
import DistrictSelect from '../../common-components/DistrictSelect';
import FormDatePicker from '../../common-components/FormDatePicker';
import SearchInput from '../../common-components/SearchInput';
import BusinessSubcategoryPopUp from '../BusinessSubcategory';
import CustomSelect from '../../common-components/CustomSelect';
import ModalWrapper from '../../common-components/ModalWrapper';
import BoxCenter from '../../common-components/BoxCenter';
import FormTextField from '../../common-components/FormTextField';
import FormBooleanSelect from '../../common-components/FormBooleanSelect';

import { useSnackbarHelpers } from '../../../helpers/utils';
import { Messages } from '../../../constants/messages';

import * as CompanyService from '../../../services/requests/company';
import { setCompanies } from '../../../redux/slices/companySlice';

import { useStyles } from './styles';
import { useGlobalStyles } from '../../../customStyles';

import { useRequestGeoLocation } from '../../../hooks/geoLocation';
import { BusinessCategoryAPI } from '../../../redux/services/BusinessCategoryService';
import { swvTheme } from '../../../swvTheme';

export default function SearchFilterPopUp({ isOpen, onClose, handleApply }) {
  const classes = useStyles();
  const cssClass = useGlobalStyles();
  const dispatch = useDispatch();
  const handleRequestWithGeo = useRequestGeoLocation();
  const previousQuery = JSON.parse(localStorage.getItem('searchFilterQuery'));
  const [currentAddressLat, setCurrentAddressLat] = useState(previousQuery?.addressLat);
  const [currentAddressLng, setCurrentAddressLng] = useState(previousQuery?.addressLng);

  const [isBusinessSubcategoryModalOpen, setIsBusinessSubcategoryModalOpen] = useState(false);
  const [isReseted, setIsReseted] = useState(false);
  const [formId, setFormId] = useState((Math.random() + 1).toString(36).substring(7));

  const { showErrorSnackbar, showInfoSnackbar } = useSnackbarHelpers();

  const { data: businessCategoryListResponse } = BusinessCategoryAPI.useGetListQuery(undefined, { skip: !isOpen });

  const { control, reset, setValue } = useForm({
    defaultValues: {
      // company
      form: '',
      isLarge: '',
      isCompanyFamily: '',
      companyNewbe: '',
      companyAgeMin: '',
      companyAgeMax: '',
      companyText: '',

      // contactPerson
      contactPersonList: '',
      gender: '',
      isFamily: '',
      isMember: '',
      birthdateMin: '',
      birthdateMax: '',
      contactPersonText: '',

      // address
      addressType: '',
      addressCounty: '',
      addressLat: '',
      addressLng: '',
      addressText: '',

      // business
      businessCategory: '',
      businessCounty: '',
      subcategoryName: '',
      subcategoryNumber: '',
      businessSubcategory: '',
      businessSubcategoryNumber: '',
      businessSubcategoryName: '',
      status: '',
      businessFoundation: '',
      businessRegistrationsMin: '',
      businessRegistrationsMax: '',
      businessText: '',
    },
  });

  const values = useWatch({ control });

  const disabled = !values?.businessCategory || !values?.businessCounty;

  useEffect(() => {
    if (!isOpen) {
      setIsReseted(false);
    }

    if (previousQuery && isOpen && !isReseted) {
      reset({
        // company
        form: previousQuery.form,
        isLarge: previousQuery.isLarge,
        isCompanyFamily: previousQuery.isCompanyFamily,
        companyNewbe: previousQuery.companyNewbe,
        companyAgeMin: previousQuery.companyAgeMin,
        companyAgeMax: previousQuery.companyAgeMax,
        companyText: previousQuery.companyText,

        // contactPerson
        contactPersonList: previousQuery.contactPersonList,
        gender: previousQuery.gender,
        isFamily: previousQuery.isFamily,
        isMember: previousQuery.isMember,
        birthdateMin: previousQuery.birthdateMin,
        birthdateMax: previousQuery.birthdateMax,
        contactPersonText: previousQuery.contactPersonText,

        // address
        addressType: previousQuery.addressType,
        addressCounty: previousQuery.addressCounty,
        addressLat: previousQuery.addressLat,
        addressLng: previousQuery.addressLng,
        addressText: previousQuery.addressText,

        // business
        businessCategory: previousQuery.businessCategory,
        businessCounty: previousQuery.businessCounty,
        subcategoryName: previousQuery.subcategoryName,
        subcategoryNumber: previousQuery.subcategoryNumber,
        businessSubcategory: previousQuery.businessSubcategory,
        businessSubcategoryNumber: previousQuery.businessSubcategoryNumber,
        businessSubcategoryName: previousQuery.businessSubcategoryName,
        status: previousQuery.status,
        businessFoundation: previousQuery.businessFoundation,
        businessRegistrationsMin: previousQuery.businessRegistrationsMin,
        businessRegistrationsMax: previousQuery.businessRegistrationsMax,
      });

      setCurrentAddressLat(previousQuery.addressLat);
      setCurrentAddressLng(previousQuery.addressLng);

      setIsReseted(true);
    }
  }, [previousQuery, isOpen, isReseted]);

  const locationNotEnabled = async () => {
    const status = await swal({
      title: 'Standort ist nicht aktiviert!',
      text: 'Bitte aktivieren Sie Standorterlaubnis in Ihrem Browser und aktualisieren Sie die Seite!',
      icon: 'error',
      buttons: [false, 'Ok'],
      dangerMode: true,
      closeOnClickOutside: false,
    });

    if (status) {
      await locationNotEnabled();
    }
  };

  const onGeoSearchClicked = async () => {
    // const lat = 48.0017;
    // const lng = 16.2439;

    // values.addressLat = lat;
    // values.addressLng = lng;

    // setCurrentAddressLat(lat);
    // setCurrentAddressLng(lng);

    // return;

    const result = await swal({
      title: 'Aktuelle Lokation ermitteln',
      text: 'Zugriff auf GPS Lokation benötigt',
      icon: 'warning',
      buttons: ['Nein', 'Ja'],
      dangerMode: true,
    });

    if (result) {
      handleRequestWithGeo(successHandler, errorHandler);
    }
  };

  const successHandler = async (location) => {
    const lat = parseFloat(location.lat.toFixed(4));
    const lng = parseFloat(location.lng.toFixed(4));

    values.addressLat = lat;
    values.addressLng = lng;

    setCurrentAddressLat(lat);
    setCurrentAddressLng(lng);
  };

  const errorHandler = async (e) => {
    console.log('errorHandler', e);
  };

  const getCompanyList = async (query) => {
    await CompanyService.getListEx(query, (data) => {
      if (data) {
        if (data.length) {
          handleApply(data);
        } else {
          showInfoSnackbar(Messages.EmptySearchResult);
        }
      }
    });
  };

  const onSearchClicked = useCallback(async () => {
    //console.log('onSearchClicked', currentAddressLat, currentAddressLng);

    localStorage.setItem(
      'searchFilterQuery',
      JSON.stringify({
        ...values,
        addressLat: !currentAddressLat ? '' : parseFloat(currentAddressLat),
        addressLng: !currentAddressLng ? '' : parseFloat(currentAddressLng),
      }),
    );

    const query = {
      ...values,
      addressLat: !currentAddressLat ? '' : parseFloat(currentAddressLat),
      addressLng: !currentAddressLng ? '' : parseFloat(currentAddressLng),
    };

    await getCompanyList(query);
  }, [values]);

  const onClearClicked = () => {
    localStorage.removeItem('searchFilterQuery');

    setCurrentAddressLat(null);
    setCurrentAddressLng(null);

    // reset(); // fixme

    reset({
      // company
      form: '',
      isLarge: '',
      isCompanyFamily: '',
      companyNewbe: '',
      companyAgeMin: '',
      companyAgeMax: '',
      companyText: '',

      // contactPerson
      contactPersonList: '',
      gender: '',
      isFamily: '',
      isMember: '',
      birthdateMin: '',
      birthdateMax: '',
      contactPersonText: '',

      // address
      addressType: '',
      addressCounty: '',
      addressLat: '',
      addressLng: '',
      addressText: '',

      // business
      businessCategory: '',
      businessCounty: '',
      subcategoryName: '',
      subcategoryNumber: '',
      businessSubcategory: '',
      businessSubcategoryNumber: '',
      businessSubcategoryName: '',
      status: '',
      businessFoundation: '',
      businessRegistrationsMin: '',
      businessRegistrationsMax: '',
      businessText: '',
    });

    setFormId((Math.random() + 1).toString(36).substring(7));
  };

  const onBusinessCategoryClicked = () => {
    setValue('businessSubcategory', '');
    setValue('businessSubcategoryNumber', '');
    setValue('businessSubcategoryName', '');
  };

  const onBusinessCountyClicked = () => {
    setValue('businessSubcategory', '');
    setValue('businessSubcategoryNumber', '');
    setValue('businessSubcategoryName', '');
  };

  const onBusinessSubcategoryModalOpen = useCallback(() => {
    if (values?.businessCategory && values?.businessCounty) {
      setIsBusinessSubcategoryModalOpen(true);
    } else {
      showErrorSnackbar(Messages.BusinessSubcategoryModalCantBeOpened);
    }
  }, [values]);

  const onBusinessSubcategoryModalClose = () => {
    setIsBusinessSubcategoryModalOpen(false);
  };

  const onBusinessSubcategoryApply = (businessSubcategory) => {
    setValue('businessSubcategory', businessSubcategory.ID);
    setValue('businessSubcategoryNumber', businessSubcategory.Number);
    setValue('businessSubcategoryName', businessSubcategory.Name);
    setIsBusinessSubcategoryModalOpen(false);
  };

  return (
    <>
      <ModalWrapper
        isOpen={isOpen}
        onClose={onClose}
        title="Spezialsuche"
        className={cssClass.swvModalResponsiveMods + ' ' + classes.popupWrapper}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            columns={{ xs: 4, md: 12 }}
          >
            <Grid xs={6} mt={3} key={`firma-${formId}`}>
              <Typography
                variant="h2"
                color={swvTheme.typography.lilac}
                fontSize={18}
                fontWeight={500}
                mb={2}
              >
                Firma
              </Typography>
              <Box className={cssClass.flexColumn}>
                <FormBooleanSelect
                  label="Form"
                  name="form"
                  control={control}
                  value={values.form}
                  trueText="Juristisch"
                  falseText="Natürlich"
                />
                <FormBooleanSelect
                  label="Großunternehmen"
                  name="isLarge"
                  control={control}
                  value={values.isLarge}
                />
                <FormBooleanSelect
                  label="SWV Familie ???"
                  name="isCompanyFamily"
                  control={control}
                  value={values.isCompanyFamily}
                />
                <FormDatePicker
                  name="companyNewbe"
                  label="Neugründer"
                  control={control}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 1,
                  marginTop: '-4px',
                }}
              >
                <FormTextField
                  name="companyAgeMin"
                  label="Alter min"
                  type="number"
                  control={control}
                />
                <FormTextField
                  name="companyAgeMax"
                  label="Alter max"
                  type="number"
                  control={control}
                />
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Controller
                  name="companyText"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <SearchInput
                      placeholder="Suche"
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
              </Box>
            </Grid>
            <Grid xs={6} mt={3} key={`Adresse-${formId}`}>
              <Typography
                variant="h2"
                color={swvTheme.typography.lilac}
                fontSize={18}
                fontWeight={500}
                mb={2}
              >
                Adresse
              </Typography>
              <Box className={cssClass.flexColumn}>
                <FormSelect
                  name="addressType"
                  label="Typ"
                  control={control}
                  value={values.addressType || ''}
                >
                  <MenuItem value={'StandardadresseWKO'}>Standard WKO</MenuItem>
                  <MenuItem value={'ZustelladdresseWKO'}>Zustell WKO</MenuItem>
                  <MenuItem value={'ZustelladdresseSWV'}>Zustell SWV</MenuItem>
                  <MenuItem value={'Standorte'}>Standorte</MenuItem>
                </FormSelect>
                <CountySelect
                  name="addressCounty"
                  control={control}
                  value={values?.addressCounty}
                />
                <DistrictSelect
                  name="FK_District"
                  control={control}
                  value={values.FK_District}
                  county={values.addressCounty}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 1,
                  marginTop: '-4px',
                }}
              >
                <TextField
                  placeholder="Breite"
                  type="number"
                  value={currentAddressLat}
                  onChange={(e) => {
                    setCurrentAddressLat(e.target.value);
                  }}
                />
                <TextField
                  placeholder="Länge"
                  type="number"
                  value={currentAddressLng}
                  onChange={(e) => {
                    setCurrentAddressLng(e.target.value);
                  }}
                />
                <Button
                  sx={{ margin: 'auto', marginTop: '7px', width: '150px', py: 1.5 }}
                  onClick={() => onGeoSearchClicked()}
                >
                  GPS
                </Button>
              </Box>
              <Controller
                name="addressText"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <SearchInput
                    placeholder="Suche"
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            columns={{ xs: 4, md: 12 }}
          >
            <Grid xs={6} mt={3} key={`Kontaktperson-${formId}`}>
              <Typography
                variant="h2"
                color={swvTheme.typography.lilac}
                fontSize={18}
                fontWeight={500}
                mb={2}
              >
                Kontaktperson
              </Typography>
              <Box className={cssClass.flexColumn}>
                <FormSelect
                  label="List"
                  name="contactPersonList"
                  control={control}
                  value={values.contactPersonList || ''}
                >
                  <MenuItem value="Alle">Alle</MenuItem>
                  <MenuItem value="Primär">Primär</MenuItem>
                </FormSelect>
                <FormSelect
                  label="Geschlecht"
                  name="gender"
                  control={control}
                  value={values.gender || ''}
                >
                  <MenuItem value="M">Männlich</MenuItem>
                  <MenuItem value="W">Weiblich</MenuItem>
                  <MenuItem value="D">Divers</MenuItem>
                </FormSelect>
                <FormBooleanSelect
                  label="SWV Familie"
                  name="isFamily"
                  control={control}
                  value={values.isFamily}
                />
                <FormBooleanSelect
                  label="SWV Mitglied"
                  name="isMember"
                  control={control}
                  value={values.isMember}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 1,
                  marginTop: '-4px',
                }}
              >
                <FormTextField
                  name="birthdateMin"
                  label="Alter min"
                  type="number"
                  control={control}
                />
                <FormTextField
                  name="birthdateMax"
                  label="Alter max"
                  type="number"
                  control={control}
                />
              </Box>
              <Controller
                name="contactPersonText"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <SearchInput
                    placeholder="Suche"
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Grid>
            <Grid xs={6} mt={3} key={`Gewerbe-${formId}`}>
              <Typography
                variant="h2"
                color={swvTheme.typography.lilac}
                fontSize={18}
                fontWeight={500}
                mb={2}
              >
                Gewerbe
              </Typography>
              <Box className={cssClass.flexColumn}>
                <FormSelect
                  label="Sparte"
                  name="businessCategory"
                  control={control}
                  onClick={onBusinessCategoryClicked}
                  value={values?.businessCategory || ''}
                  defaultValue={previousQuery?.businessCategory}
                >
                  {businessCategoryListResponse?.data?.map((row) => {
                    return (
                      <MenuItem key={row.ID} value={row.ID}>
                        {row.Name}
                      </MenuItem>
                    );
                  })}
                </FormSelect>
                <CountySelect
                  name="businessCounty"
                  control={control}
                  onClick={onBusinessCountyClicked}
                  defaultValue={previousQuery?.businessCounty}
                  value={values?.businessCounty || ''}
                />
                <CustomSelect
                  disabled={disabled}
                  placeholder={'Fachgruppe'}
                  value={values.businessSubcategory}
                  valueToDisplay={
                    values?.businessSubcategory
                      ? values?.businessSubcategoryNumber +
                        ', ' +
                        values?.businessSubcategoryName
                      : ''
                  }
                  onClick={onBusinessSubcategoryModalOpen}
                />
                <FormSelect
                  label="Status"
                  name="status"
                  control={control}
                  value={values?.status ?? ''}
                >
                  <MenuItem value={1}>Aktiv</MenuItem>
                  <MenuItem value={0}>Inaktiv</MenuItem>
                  <MenuItem value={2}>Ruhend</MenuItem>
                </FormSelect>
                <FormDatePicker
                  name="businessFoundation"
                  label="Neugründer"
                  control={control}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 1,
                  marginTop: '-4px',
                }}
              >
                <FormTextField
                  name="businessRegistrationsMin"
                  label="Anzahl min"
                  type="number"
                  control={control}
                />
                <FormTextField
                  name="businessRegistrationsMax"
                  label="Anzahl max"
                  type="number"
                  control={control}
                />
              </Box>
            </Grid>
          </Grid>
        </LocalizationProvider>
        <BoxCenter>
          <Button onClick={() => onClearClicked()}>Zurücksetzen</Button>
          <Button onClick={() => onSearchClicked()}>Suchen</Button>
        </BoxCenter>
      </ModalWrapper>

      <BusinessSubcategoryPopUp
        isOpen={isBusinessSubcategoryModalOpen}
        onClose={onBusinessSubcategoryModalClose}
        currentBusinessCategory={values?.businessCategory}
        currentCounty={values?.businessCounty}
        currentBusinessSubcategoryNumber={values.businessSubcategoryNumber}
        currentBusinessSubcategoryName={values.businessSubcategoryName}
        handleApply={onBusinessSubcategoryApply}
      />
    </>
  );
}
