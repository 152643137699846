import React, { useState, useContext, useEffect } from 'react';

import { CompanyContext } from '../../pages/Company/CompanyContext';

import BoxCenter from '../BoxCenter';
import ModalWrapper from '../ModalWrapper';

import { useStyles } from './styles';
import { useGlobalStyles } from '../../../customStyles';
import CustomButton from '../CustomButton';
import { Checkbox, Container, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import dayjs from 'dayjs';
import { IForm } from '../../../redux/slices/formSlice';
import { chooseCounty } from '../../../helpers';

export default function AlreadySendModal({ isOpen, onClose, forms, handleSubmit, mode }: { mode?: "single" | "multiple", isOpen: boolean, onClose: () => void, forms: Array<IForm>, handleSubmit: (forceSubmit: boolean, selectedGoalIds?: Array<number>) => void }) {
  const classes = useStyles();
  const cssClass = useGlobalStyles();

  //mode = undefined || "single"
  const companyContext = useContext<any>(CompanyContext);
  
  const companyData = companyContext?.companyData ?? undefined;
  const businessRegistrationList = companyContext?.businessRegistrationList ?? undefined;

  const hasStatus2 = businessRegistrationList && businessRegistrationList.some((entry:any) => entry.Status === 2);

  const [goalIds, setGoalIds] = useState<Array<{ goalId: number, forms: Array<IForm>, chosen: boolean, companyName: string }>>([]);

  
  console.log("GoalIDS: "+JSON.stringify(goalIds))
  console.log("businessRegistrationList: "+JSON.stringify(businessRegistrationList))
  


  useEffect(() => {
    if (forms) {
      let tempGoalIds: Array<{ goalId: number, forms: Array<IForm>, chosen: boolean, companyName: string }> = [];
      for (let i = 0; i < forms.length; i++) {
        if (forms[i] && !tempGoalIds.find(element => element.goalId === forms[i].FK_Goal!)) {
          //@ts-ignore
          tempGoalIds.push({ goalId: forms[i].FK_Goal!, forms: forms.filter(element => element.FK_Goal === forms[i].FK_Goal), chosen: true, companyName: forms[i].companyName });
        }
      }
      setGoalIds(tempGoalIds);

      console.log("GOALIDS", tempGoalIds)

    }
  }, [isOpen])


  return (
    <ModalWrapper isOpen={isOpen} onClose={onClose} title={`${(forms?.length ?? 0) > 1 ? "Anträge wurden" : "Antrag wurde"} bereits zugestellt`}>
      <Container maxWidth="xl">
        {goalIds && goalIds.length > 0 &&
          <div style={{ display: "flex", flexDirection: "column", gap: "2em" }}>
            {goalIds.map((goalId) =>
              <Container maxWidth="xl" key={goalId.goalId}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Checkbox checked={goalId.chosen} onChange={(event, checked) => setGoalIds((old: any) => old.map((element: any) => {
                    if (element.goalId === goalId.goalId) {
                      return { ...element, chosen: checked }
                    }
                    return element
                  }
                  )
                  )} />
                  <h3 style={{ fontWeight: "400" }}>{goalId.forms[0].Title} für {goalId.forms[0].ElectionProposal?.Title ? (`${goalId.forms[0].ElectionProposal?.Title} in ${goalId.forms[0].BusinessSubcategory?.Number ? 'Fachgruppe:' : 'Sparte:'} ${goalId.forms[0].BusinessSubcategory?.Number ?? goalId.forms[0].BusinessCategory?.Name}`) : `${chooseCounty(goalId.forms[0].County)}`} für {goalId.companyName ?? goalId.forms[0].Company?.Name}</h3>
                </div>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ width: "15%" }}>Datum</TableCell>
                        <TableCell style={{ width: "20%" }}>Firma</TableCell>
                        <TableCell style={{ width: "15%" }}>E-Mail Zustellung</TableCell>
                        <TableCell style={{ width: "10%" }}>Status</TableCell>
                        <TableCell style={{ width: "15%" }}>Ersteller</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {goalId.forms?.map((row: IForm) => (
                        <TableRow
                          key={row.ID}
                          selected={undefined}
                          onClick={() => { }}
                          style={{ height: "6vh" }}
                        >
                          <TableCell>{row.Date ? dayjs(row.Date).format('DD.MM.YYYY HH:mm') : ""}</TableCell>
                          <TableCell>{row.Company?.Name}</TableCell>
                          <TableCell>{row.EmailDelivery !== undefined ? (row.EmailDelivery === false ? ("Nicht ausgewählt") : row.EmailDeliveryFailed === false ? ("Erfolgreich") : ("Fehlgeschlagen")) : ""} </TableCell>
                          <TableCell>{row.Goal ? `${row.Goal.ActualValueAll}/${row.Goal.Setpoint}` : ``}</TableCell>
                          <TableCell>{row.User ? (row.User?.Firstname + " " + row.User?.Lastname) : " "}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                {/*forms?.length > 1 &&
                <BoxCenter>
                  <Pagination
                    shape="rounded"
                  //count={Math.ceil(resultCount / pageSize)}
                  //onChange={handlePageChange}
                  //page={page + 1}
                  />

                </BoxCenter>
                    */}
                {/**/}
              </Container>
            )}
            <Container maxWidth='xl'>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Checkbox checked={goalIds.filter(element => element.chosen === true).length === goalIds.length} onChange={(event, checked) => setGoalIds(old => old.map(el => ({ ...el, chosen: checked })))} />
                <h3 style={{ fontWeight: "400" }}>Alle wählen</h3>
              </div>
            </Container>
            <BoxCenter>
              <CustomButton
                disabled={false}
                isClose={false}
                largeBorderRadius={false}
                light={false}
                startIcon={undefined}

                //disabled={disabled}
                value="Ausgewählte Erneut Senden"
                onClick={() => { onClose(); handleSubmit(true, goalIds.filter(el => el.chosen === true).map(el => el.goalId)) }}
                sx={{ mb: 10, width: '300px !important' }}
              />
            </BoxCenter>
          </div>
        }
        {!(goalIds.length > 0) &&
          <Container>
            <BoxCenter>
              <Container maxWidth="xl">
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ width: "15%" }}>Datum</TableCell>
                        <TableCell style={{ width: "20%" }}>Firma</TableCell>
                        <TableCell style={{ width: "15%" }}>E-Mail Zustellung</TableCell>
                        <TableCell style={{ width: "10%" }}>Status</TableCell>
                        <TableCell style={{ width: "15%" }}>Ersteller</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {forms?.map((row) => (
                        <TableRow
                          key={row.ID}
                          selected={undefined}
                          style={{ height: "6vh" }}
                        >
                          <TableCell>{row.Date ? dayjs(row.Date).format('DD.MM.YYYY HH:mm') : ""}</TableCell>
                          <TableCell>{row.Company?.Name}</TableCell>
                          <TableCell>{row.EmailDelivery !== undefined ? (row.EmailDelivery === false ? ("Nicht ausgewählt") : row.EmailDeliveryFailed === false ? ("Erfolgreich") : ("Fehlgeschlagen")) : ""} </TableCell>
                          <TableCell>{row.Goal ? `${row.Goal.ActualValueAll}/${row.Goal.Setpoint}` : ``}</TableCell>
                          <TableCell>{row.User ? (row.User?.Firstname + " " + row.User?.Lastname) : " "}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                {forms?.length > 1 &&
                  <BoxCenter>
                    <Pagination
                      shape="rounded"
                    //count={Math.ceil(resultCount / pageSize)}
                    //onChange={handlePageChange}
                    //page={page + 1}
                    />

                  </BoxCenter>
                }
                <BoxCenter>
                  <CustomButton
                    disabled={false}
                    isClose={false}
                    largeBorderRadius={false}
                    light={false}
                    startIcon={undefined}

                    //disabled={disabled}
                    value="Erneut Senden"
                    onClick={() => { onClose(); handleSubmit(true, [forms[0].FK_Goal!]) }}
                    sx={{ mb: 10, width: '300px !important' }}
                  />
                </BoxCenter>
              </Container>
            </BoxCenter>
          </Container>
        }
      </Container>
    </ModalWrapper>
  );
}