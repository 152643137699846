import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./basequery";
import { IBusinessSubcategory } from "../../interfaces/IBusinessSubcategory";

export interface CreateUserPermissionBusinessSubcategories {
    UserId: number
    BusinessSubcategoryIds: number[]
}

export interface CreateUserPermissionsForBusinessSubcategoryInDistrict {
    UserId: number
    DistrictIds: number[]
    BusinessSubcategoryId: number
}

interface CreateUserPermissionsCounty {
    UserId: number
    Counties: string[]
}

interface UserPermissionBusinessSubcategory {
    Id: number
    UserId: number
    BusinessSubcategoryId: number
    DistrictId: number
}

interface UserPermissionInDistrict {
    Id: number
    UserId: number
    DistrictId: number
}

interface CreateGeneralUserPermissionsDistricts {
    UserId: number
    DistrictIds: number[]
}

interface GeneralPermissionsCounty {
    Id: number
    UserId: number
    County: string
}

export const UserPermissionsApi = createApi({
    reducerPath: "UserPermissionsBusinessSubcategory",
    baseQuery,
    tagTypes: ['UserPermissions', 'UserGeneralPermittedCounties', 'UserPermittedCounties', 'UserPermissionsBusinessSubcategory', 'GeneralPermittedDistricts', 'UserPermittedBusinessSubcategories', 'BusinessSubcategoryPermittedDistricts'],
    endpoints: (builder) => ({
        insertGeneralUserPermissions: builder.mutation({
            query: (data: CreateUserPermissionsCounty) => ({
                url: "/UserPermissionCounty",
                method: "POST",
                body: data
            }),
            invalidatesTags: (result, error, arg: CreateUserPermissionsCounty) => [{ type: 'UserPermissions', id: arg.UserId }, { type: "UserGeneralPermittedCounties", id: arg.UserId }, { type: "GeneralPermittedDistricts", id: arg.UserId }]
        }),
        insertBusinessSubcategoryUserPermissions: builder.mutation({
            query: (data: CreateUserPermissionBusinessSubcategories) => ({
                url: "/UserPermissionsBusinessSubCategory",
                method: "POST",
                body: data
            }),
            invalidatesTags: (result, error, arg: CreateUserPermissionBusinessSubcategories) => [{ type: 'UserPermissionsBusinessSubcategory', id: arg.UserId }, { type: 'UserPermittedCounties', id: arg.UserId }, { type: 'BusinessSubcategoryPermittedDistricts', id: arg.UserId }, { type: "UserPermittedBusinessSubcategories", id: arg.UserId }]
        }),
        insertGeneralUserPermissionsDistrict: builder.mutation({
            query: (data: CreateGeneralUserPermissionsDistricts) => ({
                url: "/UserPermissionDistrict",
                method: "POST",
                body: data
            }),
            invalidatesTags: (result, error, arg: CreateGeneralUserPermissionsDistricts) => [{ type: "GeneralPermittedDistricts", id: arg.UserId }]
        }),
        insertBusinessSubcategoryUserPermissionsDistrict: builder.mutation({
            query: (data: CreateUserPermissionsForBusinessSubcategoryInDistrict) => ({
                url: "/UserPermissionsBusinessSubcategory/district",
                method: "POST",
                body: data
            }),
            invalidatesTags: (result, error, arg: CreateUserPermissionsForBusinessSubcategoryInDistrict) => [{ type: 'BusinessSubcategoryPermittedDistricts', id: arg.UserId }]
        }),
        getGeneralUserPermissions: builder.query<GeneralPermissionsCounty[], number>({
            query: (userId: number) => `/UserPermissionCounty/${userId}`,
            providesTags: (result, error, userId: number) => [{ type: 'UserPermissions', id: userId }]
        }),
        getGeneralPermittedCountiesForUser: builder.query<string[], number>({
            query: (userId: number) => `/UserPermissionCounty/permittedCounties/${userId}`,
            providesTags: (result, error, userId: number) => [{ type: 'UserGeneralPermittedCounties', id: userId }]
        }),
        getBusinessSubcategoryUserPermissionsByUserId: builder.query<UserPermissionBusinessSubcategory[], number>({
            query: (userId: number) => `/UserPermissionsBusinessSubcategory/${userId}`,
            providesTags: (result, error, userId: number) => [{ type: 'UserPermissionsBusinessSubcategory', id: userId }]
        }),
        getPermittedCountiesForUser: builder.query<string[], number>({
            query: (userId: number) => `/UserPermissionsBusinessSubcategory/counties/${userId}`,
            providesTags: (result, error, userId: number) => [{ type: "UserPermittedCounties", id: userId }]
        }),
        getPermittedBusinessSubcategoriesForUser: builder.query<IBusinessSubcategory[], number>({
            query: (userId: number) => `/UserPermissionsBusinessSubcategory/permitted/${userId}`,
            providesTags: (result, error, userId: number) => [{ type: "UserPermittedBusinessSubcategories", id: userId }]
        }),
        getGeneralUserPermissionsDistrictsByUserId: builder.query<UserPermissionInDistrict[], number>({
            query: (userId: number) => `/UserPermissionDistrict/${userId}`,
            providesTags: (result, error, userId: number) => [{ type: "GeneralPermittedDistricts", id: userId }]
        }),
        getBusinessSubcategoryPermittedDistrictsByUserId: builder.query<UserPermissionInDistrict[], number>({
            query: (userId: number) => `/UserPermissionsBusinessSubcategory/${userId}`,
            providesTags: (result, error, userId: number) => [{ type: 'BusinessSubcategoryPermittedDistricts', id: userId }]
        })
    })
})

export const {
    useInsertGeneralUserPermissionsMutation,
    useInsertBusinessSubcategoryUserPermissionsMutation,
    useInsertBusinessSubcategoryUserPermissionsDistrictMutation,
    useInsertGeneralUserPermissionsDistrictMutation,
    useGetGeneralUserPermissionsQuery,
    useGetGeneralUserPermissionsDistrictsByUserIdQuery,
    useGetGeneralPermittedCountiesForUserQuery,
    useGetBusinessSubcategoryUserPermissionsByUserIdQuery,
    useGetPermittedCountiesForUserQuery,
    useGetPermittedBusinessSubcategoriesForUserQuery,
    useGetBusinessSubcategoryPermittedDistrictsByUserIdQuery,
} = UserPermissionsApi
