import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQuery } from "./basequery";
import * as endpoints from "../../constants/endpoints";

export const PersonAPI = createApi({
    reducerPath: 'Person',
    baseQuery,
    tagTypes: ["person", "personList"],
    endpoints: (build) => ({
        getOne: build.query({
            query: (id) => ({
                url: `${endpoints.getPerson}/${id}`,
            }),
            providesTags: () => ['person'],
            transformResponse(baseQueryReturnValue, meta, arg) {

                return(baseQueryReturnValue.data)
            }
        })
    })
});
