import { createSlice } from '@reduxjs/toolkit';

const initialAppState = {
  geoLocation: {},
  userData: {},
  jwtInfo: {}
};

export interface IUser {
  ID?: number;
  Firstname?: string;
  Lastname?: string;
  Email?: string;
  Username?: string;
  Mobile?: string;
  Password?: string;
  Photo?: string;
  IsAdmin?: boolean;
  Login?: string;
  SMS_Code?: number;
  Password_Reset?: string;
  JWT?: string;
  IsActive?: boolean;
  County?: string;
  UserRole?: string;
}

const AppSlice = createSlice({
  name: 'app',
  initialState: initialAppState,
  reducers: {
    setGeoLocation(state, action) {
      state.geoLocation = action.payload;
    },
    setUserData(state, action) {
      state.userData = action.payload;
    },
    setJWTInfo(state, action) {
      state.jwtInfo = action.payload
    }
  },
});

export default AppSlice.reducer;
export const { setGeoLocation, setUserData, setJWTInfo } = AppSlice.actions;
