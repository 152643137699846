import React from 'react';

export default function CompanyIcon() {
  return (
    <svg
      width="54"
      height="54"
      viewBox="0 0 54 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="27" cy="27" r="27" fill="#732382" />
      <g clipPath="url(#clip0_590_5642)">
        <path
          d="M18.9814 26.9965H20.1255V40.7467H18.9814V26.9965Z"
          fill="white"
        />
        <path
          d="M28.1445 26.9965H29.2886V40.7467H28.1445V26.9965Z"
          fill="white"
        />
        <path
          d="M29.8663 27.5704H18.4078C18.0902 27.5704 17.834 27.3141 17.834 26.9965C17.834 26.679 18.0902 26.4227 18.4078 26.4227H29.8663C30.1839 26.4227 30.4401 26.679 30.4401 26.9965C30.4401 27.3141 30.1803 27.5704 29.8663 27.5704Z"
          fill="white"
        />
        <path
          d="M25.8569 40.7467C25.5393 40.7467 25.2831 40.4905 25.2831 40.1729V33.8716H22.9914V40.1729C22.9914 40.4905 22.7352 40.7467 22.4176 40.7467C22.1 40.7467 21.8438 40.4905 21.8438 40.1729V33.2978C21.8438 32.9802 22.1 32.724 22.4173 32.724H25.8566C26.1742 32.724 26.4304 32.9802 26.4304 33.2978V40.1729C26.4271 40.4905 26.1706 40.7467 25.8569 40.7467Z"
          fill="white"
        />
        <path
          d="M40.1769 40.7467C39.8774 40.7467 39.6247 40.5157 39.6067 40.2126L37.9249 14.3977H36.7051L35.0233 40.2126C35.0017 40.5265 34.7382 40.7575 34.4134 40.7467C34.0994 40.725 33.8576 40.4544 33.8793 40.1368L35.5968 13.7841C35.6149 13.4846 35.8675 13.25 36.1671 13.25H38.4588C38.7619 13.25 39.0109 13.4846 39.029 13.7841L40.7468 40.1368C40.7685 40.4544 40.5267 40.725 40.2127 40.7467C40.2019 40.7431 40.1874 40.7467 40.1766 40.7467H40.1769Z"
          fill="white"
        />
        <path
          d="M40.1765 40.7467H13.8238C13.5062 40.7467 13.25 40.4905 13.25 40.1729V22.4132C13.25 22.2111 13.3547 22.027 13.5279 21.9223C16.0722 20.3994 18.6201 18.8692 21.1645 17.339C21.3413 17.2343 21.5651 17.2307 21.7419 17.3317C21.9223 17.4328 22.0342 17.6241 22.0342 17.8298V21.4026C23.5789 20.4787 25.1199 19.5512 26.6645 18.6274L28.8082 17.339C28.9851 17.2343 29.2052 17.2307 29.3857 17.3317C29.5661 17.4328 29.6744 17.6241 29.6744 17.8298V21.4026C31.616 20.2406 33.5576 19.0713 35.4956 17.9056C35.7663 17.7432 36.12 17.8298 36.2824 18.1004C36.4448 18.3711 36.3582 18.7248 36.0875 18.8872C33.8572 20.2297 31.6232 21.5687 29.3965 22.904C29.2197 23.0086 28.9995 23.0122 28.8191 22.9112C28.6386 22.8101 28.5304 22.6189 28.5304 22.4132V18.8439L27.2528 19.609C25.4194 20.7097 23.5897 21.8105 21.7527 22.904C21.5759 23.0086 21.3557 23.0122 21.1753 22.9112C20.9948 22.8101 20.8866 22.6189 20.8866 22.4132V18.8439C18.7248 20.1431 16.5594 21.4423 14.3977 22.738V39.599H40.1765C40.4941 39.599 40.7503 39.8553 40.7503 40.1729C40.7503 40.4905 40.4941 40.7467 40.1768 40.7467H40.1765Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_590_5642">
          <rect
            width="27.5"
            height="27.4973"
            fill="white"
            transform="translate(13.25 13.25)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
