import React from 'react';
import { useContext, useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';

import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import FormSelect from '../../common-components/FormSelect';
import ModalWrapper from '../../common-components/ModalWrapper';
import BoxCenter from '../../common-components/BoxCenter';
import FormTextField from '../../common-components/FormTextField';
import FormMemoField from '../../common-components/FormMemoField';
import FormDatePicker from '../../common-components/FormDatePicker';

import { useStyles } from './styles';
import { useGlobalStyles } from '../../../customStyles';

export default function ActivityPopUp({
  isOpen,
  onClose,
  onSubmit,
  ActivityContext = [],
}) {
  const cssClass = useGlobalStyles();
  const classes = useStyles();

  const { chosenActivity } = useContext(ActivityContext);

  const defaultValues = {
    ID: chosenActivity ? chosenActivity.ID : '',
    Creation: chosenActivity ? chosenActivity.Creation : Date.now(),
    Type: chosenActivity ? chosenActivity.Type : '',
    Telephone: chosenActivity ? chosenActivity.Telephone : '',
    ContactPerson: chosenActivity ? chosenActivity.ContactPerson : '',
    Comment: chosenActivity ? chosenActivity.Comment : '',
  }

  const { control, reset } = useForm({
    defaultValues: {
      ...defaultValues,
    },
  });

  const values = useWatch({ control });

  useEffect(() => {
    if (isOpen) {
      reset(defaultValues);
    }
  }, [isOpen]);

  const disabled = !values?.Creation || !values?.Type;

  return (
    <ModalWrapper isOpen={isOpen} onClose={onClose} title="Aktivität" className={cssClass.swvModalResponsiveMods + " " + classes.wrapper}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <FormControl>
            <FormDatePicker
              name="Creation"
              label="Datum"
              control={control}
              required
              />
            <FormSelect
              required
              label="Typ"
              name="Type"
              control={control}
              value={values.Type || ''}
            >
              <MenuItem value={'1'}>Rückruf durch LO erbeten</MenuItem>
              <MenuItem value={'2'}>Rückruf durch LO durchgeführt</MenuItem>
              <MenuItem value={'3'}>Betriebsbesuch erbeten</MenuItem>
              <MenuItem value={'4'}>Betriebsbesuch durchgeführt</MenuItem>
              <MenuItem value={'5'}>Ansprechperson nicht erreicht/neuer Versuch</MenuItem>
              <MenuItem value={'6'}>Ansprechperson falsch/Bearbeitung beendet</MenuItem>
              <MenuItem value={'7'}>Antrag Löschung nach DSGVO</MenuItem>
              <MenuItem value={'8'}>Wird bearbeitet durch</MenuItem>
            </FormSelect>
            <FormTextField
              name="Telephone"
              label="Telefon"
              control={control}
            />
            <FormTextField
              name="ContactPerson"
              label="Ansprechpartner"
              control={control}
            />
            <FormMemoField
              name="Comment"
              label="Kommentar"
              control={control}
              rows={4}
            />
          </FormControl>
          <BoxCenter>
            <Button
              disabled={disabled}
              onClick={() => onSubmit(values)}
            >
              Speichern
            </Button>
          </BoxCenter>
        </LocalizationProvider>
     </ModalWrapper>
  );
}