import React, { useEffect, useState } from 'react';
import {
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    IconButton,
    Tooltip,
    Collapse,
    Box,
    Typography,
    Container,
} from '@mui/material';
import { ExpandLess, ExpandMore, Check, Clear } from '@mui/icons-material';
import dayjs from 'dayjs';
import { getActionTypeForAbbreviation, getDisplayedTextForField } from '../../../../../helpers/helperFunctions';
import { useStyles } from './styles';
import { useSnackbarHelpers } from '../../../../../helpers';
import { useUpdateChangeProposalMutation } from '../../../../../redux/services/ChangeProposalService';
import { useGetContactPersonQuery, useInsertContactPersonMutation } from '../../../../../redux/services/ContactPersonService';
import * as ContactPersonService from '../../../../../services/requests/contactPerson';
import * as CompanyService from '../../../../../services/requests/company';
import * as CommunicationDataService from '../../../../../services/requests/communicationData';
import * as AddressRegistrationService from '../../../../../services/requests/addressRegistration';
import { ChangeProposalActionTypes, ChangeProposalStatus } from '../../../../../constants/changeProposals';
import { Messages } from '../../../../../constants/messages';
import { useGetAddressRegistrationQuery } from '../../../../../redux/services/AddressRegistrationService';
import { useGetCommunicationDataQuery } from '../../../../../redux/services/CommunicationDataService';
import { useGetCompanyBaseDataQuery, useGetCompanyMasterContactPersonInfoQuery } from '../../../../../redux/services/CompanyService';

interface IChangeProposalRow {
    row: any;
    displayName: string;
    referenceName: string;
}

const ChangeProposalRow = ({ row, displayName, referenceName }: IChangeProposalRow) => {
    const cssClass = useStyles()

    const [open, setOpen] = useState(false);
    const [oldObject, setOldObject] = useState<any>(undefined)

    let objectEntries: [string, string | number][] = [];
    if (row.NewObject) {
        objectEntries = Object.entries(row.NewObject);
    }

    const [updateChangeProposal, { error: updateError, isLoading: isUpdating }] = useUpdateChangeProposalMutation();
    const [insertContactPerson, { error: insertContactPersonError, isLoading: isInsertingContactPerson }] = useInsertContactPersonMutation();

    const { data: contactPerson } = useGetContactPersonQuery(row?.EntityID, { skip: determineSkip("Kontaktperson") });
    const { data: addressRegistration } = useGetAddressRegistrationQuery(row?.EntityID, { skip: determineSkip("Adresse") });
    const { data: communicationData } = useGetCommunicationDataQuery(row?.EntityID, { skip: determineSkip("Kommunikationsdaten") })
    const { data: company } = useGetCompanyBaseDataQuery(row?.EntityID, { skip: determineSkip("Firma") })
    const { data: contactPersonMaster } = useGetCompanyMasterContactPersonInfoQuery(row?.EntityID, { skip: determineSkip("Hauptansprechperson") })

    const { showErrorSnackbar, showInfoSnackbar } = useSnackbarHelpers();

    const handleAcceptChange = async (event: any, row: any) => {
        event.stopPropagation()
        const result = await determineEndpointForEntityType(row)
        if (result.data.isSuccess || result.isSuccess) {
            const payload = {
                ID: row.ID,
                ActionType: row.ActionType,
                CreatedDate: row.CreatedDate,
                EntityID: row.EntityID,
                EntityType: row.EntityType,
                FK_FirmaID: row.FK_FirmaID,
                FK_PersonID: row.FK_PersonID,
                FK_UserID: row.FK_UserID,
                NewObject: row.NewObject,
                Status: ChangeProposalStatus.Accepted,
            }

            await updateChangeProposal(payload)
                .then(() => {
                    showInfoSnackbar(Messages.Success)
                }).catch(() => {
                    showErrorSnackbar(Messages.Error)
                })

        } else {
            showErrorSnackbar(Messages.Error)
        }
    }

    const handleDeclineChange = async (event: any, row: any) => {
        event.stopPropagation()
        const payload = {
            ID: row.ID,
            ActionType: row.ActionType,
            CreatedDate: row.CreatedDate,
            EntityID: row.EntityID,
            EntityType: row.EntityType,
            FK_FirmaID: row.FK_FirmaID,
            FK_PersonID: row.FK_PersonID,
            FK_UserID: row.FK_UserID,
            NewObject: row.NewObject,
            Status: ChangeProposalStatus.Declined,
        }

        await updateChangeProposal(payload)
            .then(() => {
                showInfoSnackbar(Messages.Success)
            }).catch(() => {
                showErrorSnackbar(Messages.Error)
            })
    }

    async function determineEndpointForEntityType(row: any) {
        switch (true) {
            case (row.EntityType === 'Kontaktperson'): {
                if (row.ActionType === ChangeProposalActionTypes.Create) {
                    return await insertContactPerson(row.NewObject)
                } else if (row.ActionType === ChangeProposalActionTypes.Delete) {
                    return await ContactPersonService.deleteOne(row.EntityID)
                }
                break;
            }
            case (row.EntityType === 'Firma' || row.EntityType === 'Hauptansprechperson zu Firma'): {
                if (row.ActionType === ChangeProposalActionTypes.Create) {
                    return await CompanyService.insertOne(row.NewObject)
                } else if (row.ActionType === ChangeProposalActionTypes.Delete) {
                    return await CompanyService.deleteOne(row.EntityID)
                } else if (row.ActionType === ChangeProposalActionTypes.Update) {
                    return await CompanyService.updateOne(row.NewObject)
                }
                break;
            }
            case (row.EntityType.startsWith('Adresse')): {
                if (row.ActionType === ChangeProposalActionTypes.Create) {
                    return await AddressRegistrationService.insertOne(row.NewObject)
                } else if (row.ActionType === ChangeProposalActionTypes.Delete) {
                    return await AddressRegistrationService.deleteOne(row.EntityID)
                } else if (row.ActionType === ChangeProposalActionTypes.Update) {
                    return await AddressRegistrationService.updateOne(row.NewObject)
                }
                break;
            }
            case (row.EntityType.startsWith('Kommunikationsdaten')): {
                if (row.ActionType === ChangeProposalActionTypes.Create) {
                    return await CommunicationDataService.insertOne(row.NewObject)
                } else if (row.ActionType === ChangeProposalActionTypes.Update) {
                    return await CommunicationDataService.updateOne(row.NewObject)
                }
                break;
            }
            default:
                showErrorSnackbar(Messages.Error)
                break;
        }
    }

    function determineSkip(entityType: string,) {
        return !row.EntityType.startsWith(entityType) || !row.EntityID
    }

    async function getEntityForRow(row: any) {
        switch (true) {
            case (row.EntityType === 'Kontaktperson'): {
                setOldObject(contactPerson)
                break;
            }
            case (row.EntityType === 'Firma'): {
                setOldObject(company)
                break;
            }
            case (row.EntityType.startsWith('Adresse')): {
                setOldObject(addressRegistration)
                break;
            }
            case (row.EntityType.startsWith('Kommunikationsdaten')): {
                setOldObject(communicationData)
                break;
            }
            case (row.EntityType.startsWith('Hauptansprechperson')): {
                setOldObject(contactPersonMaster)
                break;
            }
            default:
                showErrorSnackbar(Messages.Error)
                break;
        }
    }

    useEffect(() => {
        if (!oldObject) {
            getEntityForRow(row)
        }
    }, [open])

    return (
        <>
            <TableRow
                key={row.ID}
                onClick={() => setOpen(!open)}
            >
                <TableCell>
                    <IconButton onClick={() => setOpen(!open)}>
                        {open ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                </TableCell>
                <TableCell>{row.EntityType}</TableCell>
                <TableCell>{displayName}</TableCell>
                <TableCell >{referenceName}</TableCell>
                <TableCell>{row.User.Lastname} {row.User.Firstname}</TableCell>
                <TableCell>{dayjs(new Date(row.CreatedDate)).format('DD.MM.YYYY')}</TableCell>
                <TableCell>{getActionTypeForAbbreviation(row.ActionType)}</TableCell>
                <TableCell>
                    <Tooltip title='Änderung akzeptieren'>
                        <IconButton onClick={(event) => { handleAcceptChange(event, row) }}>
                            <Check color='success'></Check>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title='Änderung ablehnen'>
                        <IconButton key={row.ID}
                            onClick={(event) => { handleDeclineChange(event, row) }}
                        >
                            <Clear color='error'></Clear>
                        </IconButton>
                    </Tooltip>
                </TableCell>
            </TableRow>
            <TableRow className={cssClass.noHoverRow}>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: '75px' }} colSpan={8}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1, paddingBottom: '20px' }}>
                            <Typography variant="h2" gutterBottom component="div">
                                Änderungen
                            </Typography>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Feld</TableCell>
                                        <TableCell>Alter Wert</TableCell>
                                        <TableCell>Neuer Wert</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {objectEntries?.length > 0 ? (
                                        objectEntries.map(([key, value]) => {
                                            if (!key.endsWith('ID') && !key.endsWith('Id') && !key.startsWith('FK') && oldObject?.data?.[key] != value && !key.includes('_')) {
                                                return (
                                                    <TableRow key={key}>
                                                        <TableCell>{getDisplayedTextForField(key, key)}</TableCell>
                                                        <TableCell>{getDisplayedTextForField(oldObject?.data?.[key], key)}</TableCell>
                                                        <TableCell>{getDisplayedTextForField(value?.toString(), key)}</TableCell>
                                                    </TableRow>
                                                )
                                            }
                                        })
                                    ) : (
                                        oldObject?.data && Object.entries(oldObject.data).map(([key, value]: any) => {
                                            if (!key.endsWith('ID') && !key.endsWith('Id') && !key.startsWith('FK') && row.NewObject?.[key] != value && !key.includes('_')) {
                                                return (
                                                    <TableRow key={key}>
                                                        <TableCell>{getDisplayedTextForField(key, key)}</TableCell>
                                                        <TableCell>{getDisplayedTextForField(oldObject?.data?.[key], key)}</TableCell>
                                                        <TableCell>{getDisplayedTextForField(row.NewObject?.[key], key)}</TableCell>
                                                    </TableRow>
                                                )
                                            }
                                        })
                                    )}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

export default ChangeProposalRow;