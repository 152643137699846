import React from 'react';

export default function Document() {
	return (
		<svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_1_579)">
				<path d="M12.7911 22C12.952 22 13.1028 21.9465 13.2184 21.8394L17.824 17.3761C17.9346 17.2642 18 17.123 18 16.9673V2.5115C18 1.1146 16.8335 0 15.3905 0H2.6095C1.16648 0 0 1.11947 0 2.5115V19.4885C0 20.8805 1.16648 22 2.6095 22H12.7911ZM13.3743 20.0239V17.9407C13.3743 17.7265 13.5754 17.5708 13.7966 17.5708H15.9486L13.3743 20.0239ZM1.2067 19.4885V2.51637C1.2067 1.76681 1.83017 1.17301 2.6095 1.17301H15.3905C16.1648 1.17301 16.7933 1.76681 16.7933 2.51637V16.4027H13.7966C12.9117 16.4027 12.1676 17.0841 12.1676 17.9407V20.8319H2.6095C1.83017 20.8319 1.2067 20.2381 1.2067 19.4885Z" fill="currentColor"/>
				<path d="M7.48648 11.0974H4.88704C4.5552 11.0974 4.28369 11.3602 4.28369 11.6814C4.28369 12.0027 4.5552 12.2655 4.88704 12.2655H7.48648C7.81833 12.2655 8.08984 12.0027 8.08984 11.6814C8.08984 11.3602 7.82336 11.0974 7.48648 11.0974Z" fill="currentColor"/>
				<path d="M4.89207 9.19909H13.6105C13.9424 9.19909 14.2139 8.93625 14.2139 8.61502C14.2139 8.29378 13.9424 8.03094 13.6105 8.03094H4.88704C4.5552 8.03094 4.28369 8.29378 4.28369 8.61502C4.28369 8.93625 4.5552 9.19909 4.89207 9.19909Z" fill="currentColor"/>
				<path d="M4.89207 6.13274H13.6105C13.9424 6.13274 14.2139 5.86991 14.2139 5.54867C14.2139 5.22743 13.9424 4.9646 13.6105 4.9646H4.88704C4.5552 4.9646 4.28369 5.22743 4.28369 5.54867C4.28369 5.86991 4.5552 6.13274 4.89207 6.13274Z" fill="currentColor"/>
			</g>
			<defs>
				<clipPath id="clip0_1_579">
					<rect width="18" height="22" fill="none"/>
				</clipPath>
			</defs>
		</svg>
	)
}