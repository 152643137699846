import React from "react";
import { MouseEvent } from "react";
import { goTo } from "../../../router/routerHistory";
import DataCard from "../DataCard";
import { getPhotoUrl } from "../../../helpers";
import { useStyles } from "./styles";

interface ICompanyDataCardProps {
    company?: any;
    lineOne?: string;
    lineTwo?: string;
    truncate?: boolean;
}

const CompanyDataCard = ({ company, lineOne, lineTwo, truncate }: ICompanyDataCardProps) => {
    const styles = useStyles();

    const onRowClicked = (e: MouseEvent<any>) => {
        e.preventDefault();
        e.stopPropagation();
        goTo(`/firma/${company.UniqueWKO}`); 
    }

    return (
        <a href={`/firma/${company.UniqueWKO}`} onClick={(e) => onRowClicked(e)} className={styles.link}>
            <DataCard 
            key={company.ID}
            lineOne={lineOne}
            lineTwo={lineTwo}
            placeholderImage='company'
            image={getPhotoUrl(company)}
            truncate={truncate}
            />
        </a>
    );
}

export default CompanyDataCard;