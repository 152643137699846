import React from 'react';

export default function NoResultsIcon() {
  return (
    <svg
      width="70"
      height="58"
      viewBox="0 0 70 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M42.5684 44.2231C42.5684 46.9479 43.3728 49.6115 44.8799 51.8771C46.387 54.1426 48.5291 55.9084 51.0353 56.9512C53.5415 57.9939 56.2992 58.2667 58.9598 57.7352C61.6204 57.2036 64.0643 55.8915 65.9825 53.9647C67.9006 52.038 69.2069 49.5832 69.7361 46.9108C70.2654 44.2384 69.9937 41.4683 68.9556 38.9509C67.9175 36.4336 66.1596 34.2819 63.904 32.7681C61.6485 31.2543 58.9967 30.4463 56.284 30.4463C52.6464 30.4463 49.1578 31.8978 46.5856 34.4814C44.0134 37.0651 42.5684 40.5692 42.5684 44.2231ZM66.0809 44.2231C66.0809 46.1694 65.5063 48.0719 64.4298 49.6902C63.3533 51.3085 61.8233 52.5698 60.0331 53.3146C58.243 54.0594 56.2731 54.2543 54.3727 53.8746C52.4723 53.4949 50.7267 52.5576 49.3566 51.1814C47.9864 49.8052 47.0534 48.0518 46.6754 46.1429C46.2973 44.234 46.4914 42.2554 47.2329 40.4573C47.9744 38.6591 49.2301 37.1222 50.8412 36.0409C52.4522 34.9597 54.3464 34.3825 56.284 34.3825C58.8823 34.3825 61.3742 35.4193 63.2115 37.2648C65.0487 39.1102 66.0809 41.6132 66.0809 44.2231Z"
        fill="url(#paint0_linear_564_46079)"
      />
      <path
        d="M53.4238 38.3188C53.2534 38.1095 53.042 37.9376 52.8026 37.814C52.5633 37.6904 52.3011 37.6176 52.0325 37.6003C51.7639 37.5829 51.4946 37.6213 51.2414 37.7131C50.9883 37.8049 50.7566 37.9481 50.561 38.1337C50.3654 38.3194 50.2099 38.5436 50.1043 38.7923C49.9987 39.041 49.9453 39.3088 49.9473 39.5792C49.9494 39.8495 50.0068 40.1165 50.1161 40.3636C50.2254 40.6107 50.3843 40.8324 50.5827 41.0151L53.8156 44.479L50.5827 47.9429C50.3249 48.222 50.1532 48.5703 50.0885 48.9455C50.0238 49.3207 50.0689 49.7067 50.2182 50.0566C50.3675 50.4066 50.6147 50.7056 50.9298 50.9173C51.2449 51.129 51.6144 51.2443 51.9934 51.2493C52.2607 51.251 52.5254 51.1977 52.7714 51.0928C53.0174 50.9879 53.2394 50.8335 53.4238 50.6392L56.5196 47.3525L59.6154 50.6392C59.7975 50.8311 60.0164 50.9841 60.2588 51.0889C60.5012 51.1937 60.7622 51.2483 61.0262 51.2493C61.4069 51.2481 61.779 51.1355 62.097 50.9253C62.415 50.715 62.6652 50.4163 62.8169 50.0656C62.9687 49.7148 63.0154 49.3273 62.9514 48.9503C62.8874 48.5733 62.7154 48.2233 62.4565 47.9429L59.2039 44.42L62.4565 40.9561C62.7138 40.5665 62.8246 40.0976 62.7691 39.6334C62.7136 39.1692 62.4954 38.74 62.1535 38.4227C61.8117 38.1054 61.3686 37.9207 60.9035 37.9017C60.4384 37.8826 59.9818 38.0305 59.6154 38.3188L56.5196 41.6056L53.4238 38.3188Z"
        fill="url(#paint1_linear_564_46079)"
      />
      <path
        d="M34.7304 45.2663C35.8723 45.2634 37.0122 45.1712 38.1398 44.9908C38.3971 44.952 38.6443 44.8627 38.8672 44.728C39.0902 44.5933 39.2845 44.4157 39.4392 44.2056C39.5938 43.9954 39.7058 43.7567 39.7686 43.5031C39.8314 43.2495 39.8438 42.9859 39.8052 42.7274C39.7666 42.469 39.6777 42.2207 39.5436 41.9967C39.4095 41.7728 39.2328 41.5776 39.0235 41.4222C38.8143 41.2669 38.5766 41.1545 38.3241 41.0914C38.0716 41.0283 37.8093 41.0158 37.552 41.0545C36.618 41.1958 35.6749 41.2682 34.7304 41.271C31.044 41.2944 27.4337 40.2178 24.3572 38.1776C21.2807 36.1374 18.8766 33.2255 17.4497 29.8112C16.0228 26.3969 15.6373 22.6339 16.3422 18.9993C17.0471 15.3646 18.8106 12.0221 21.4091 9.39545C24.0076 6.76879 27.324 4.97631 30.938 4.24525C34.5519 3.5142 38.3006 3.8775 41.7088 5.2891C45.1169 6.70071 48.031 9.09703 50.0816 12.1743C52.1322 15.2515 53.2269 18.871 53.227 22.574C53.2314 23.4699 53.1659 24.3649 53.0311 25.2506C52.9584 25.7673 53.093 26.2919 53.4053 26.709C53.7176 27.126 54.1821 27.4014 54.6965 27.4746C55.211 27.5476 55.7332 27.4123 56.1484 27.0986C56.5636 26.7849 56.8378 26.3184 56.9106 25.8017C57.0676 24.733 57.1461 23.6542 57.1457 22.574C57.1341 18.095 55.7996 13.7203 53.3114 10.0035C50.8232 6.28678 47.293 3.39513 43.1678 1.69465C39.0426 -0.00582121 34.5078 -0.438665 30.1376 0.450916C25.7674 1.3405 21.7582 3.5125 18.6175 6.69198C15.4768 9.87145 13.346 13.9154 12.4946 18.312C11.6432 22.7085 12.1097 27.26 13.8349 31.3901C15.5601 35.5202 18.4665 39.0433 22.1861 41.5135C25.9058 43.9836 30.2714 45.2896 34.7304 45.2663Z"
        fill="url(#paint2_linear_564_46079)"
      />
      <path
        d="M1.97078 57.2519C2.4848 57.2497 2.97739 57.0447 3.34234 56.6811L20.6458 39.2549C20.8285 39.0714 20.9734 38.8535 21.0723 38.6138C21.1712 38.374 21.2221 38.117 21.2221 37.8575C21.2221 37.598 21.1712 37.341 21.0723 37.1013C20.9734 36.8615 20.8285 36.6437 20.6458 36.4602C20.4631 36.2767 20.2462 36.1311 20.0075 36.0318C19.7688 35.9325 19.513 35.8813 19.2547 35.8813C18.9963 35.8813 18.7405 35.9325 18.5018 36.0318C18.2631 36.1311 18.0462 36.2767 17.8635 36.4602L0.579616 53.8864C0.303287 54.1617 0.114831 54.5134 0.038279 54.8967C-0.0382733 55.28 0.000544935 55.6775 0.149785 56.0385C0.299024 56.3996 0.551924 56.7078 0.87624 56.9239C1.20056 57.14 1.5816 57.2542 1.97078 57.2519Z"
        fill="url(#paint3_linear_564_46079)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_564_46079"
          x1="56.284"
          y1="34.5526"
          x2="56.284"
          y2="57.9999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F50000" />
          <stop offset="1" stopColor="#732382" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_564_46079"
          x1="56.4633"
          y1="39.6309"
          x2="56.4633"
          y2="51.2494"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F50000" />
          <stop offset="1" stopColor="#732382" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_564_46079"
          x1="34.6129"
          y1="6.74614"
          x2="34.6129"
          y2="45.2666"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F50000" />
          <stop offset="1" stopColor="#732382" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_564_46079"
          x1="10.611"
          y1="39.0662"
          x2="10.611"
          y2="57.2519"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F50000" />
          <stop offset="1" stopColor="#732382" />
        </linearGradient>
      </defs>
    </svg>
  );
}
