import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Grid } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';

import * as CompanyService from '../../../services/requests/company';
import { chooseAction, getDate, getPhotoUrl } from '../../../helpers/database';
import DataCard from '../DataCard';
import FormLink from '../FormLink';
import BoxCenter from '../BoxCenter';
import { swvTheme } from '../../../swvTheme';
import { useStyles } from './styles';
import CompanyDataCard from '../CompanyDataCard';
import PersonPopUp from '../../popups/Person';
import * as PersonService from '../../../services/requests/person';

const ActivityHistory = ({historyList,setHistoryList}) => {
  const classes = useStyles();

  const navigate = useNavigate();
  const [maxActivities, setMaxActivities] = useState(6);
  const [isPersonModalOpen, setIsPersonModalOpen] = useState(false);
  const [chosenPerson, setChosenPerson] = useState(null);

  useEffect(() => {
    CompanyService.getHistoryList(null, setHistoryList);
    console.log("HistoryList:",historyList)
  }, []);

  const handleRowClick = (row) => {
    navigate(`/firma/${row.Company.UniqueWKO}`, { replace: false });
  };


  const onPersonClicked = async (row) => {
    setChosenPerson(null);
    await getPerson(row.FK_Person);
    setIsPersonModalOpen(true);
  };

  const onPersonModalClose = async () => {
    setIsPersonModalOpen(false);
  };

  async function getPerson(id) {
    await PersonService.getOne(id, setChosenPerson);
  }
  
  return (
    <Box my={3} p={2} borderRadius="4px" backgroundColor="#EDE5EE">
      <Typography mb={2} color={swvTheme.typography.p} fontSize={18}>
        Zuletzt bearbeitete Datensätze
      </Typography>
      <Grid
        container
        spacing={2}
        columns={2}
        sx={{
          [swvTheme.breakpoints.down('sm')]: {
            flexDirection: 'column',
          },
        }}
      >
        {historyList?.slice(0, maxActivities).map((row) => (
          <Grid
            key={row.ID}
            item
            xs={2}
            md={1}
            
          >
            {row.FK_Company ?(
              <CompanyDataCard
              key={row.ID}
              company={row.Company}
              lineOne={row.Company.Name}
              lineTwo={`${getDate(row.Creation)} - ${chooseAction(row.Action)}`}
              truncate
              onClick={() => handleRowClick(row)}
            />
            ):(
             <DataCard 
             key={row.ID}
             lineOne={`${row.Person.Firstname} ${row.Person.Lastname}`}
             lineTwo={`${getDate(row.Creation)} - ${chooseAction(row.Action)}`}
             placeholderImage='person'
             image={getPhotoUrl(row.Person)}
             truncate='truncate'
             onClick={()=> onPersonClicked(row)}
             />
            )
            }
            
          </Grid>
        ))}
      </Grid>
      {maxActivities >= historyList?.length ? null : (
        <BoxCenter sx={{ flexDirection: 'row' }}>
          <FormLink
            onClick={() => {
              setMaxActivities(historyList.length);
            }}
            color={swvTheme.typography.p}
          >
            Alle bearbeiteten Datensätze ansehen
          </FormLink>
          <ArrowForward fontSize="small" />
        </BoxCenter>
      )}

    <PersonPopUp
    isOpen={isPersonModalOpen}
    onClose={onPersonModalClose}
    chosenPerson={chosenPerson}
    setChosenPerson={setChosenPerson}         
    />
    </Box>
  );
  
};




export default ActivityHistory;
