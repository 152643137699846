import { useDispatch } from 'react-redux';
import { setGeoLocation } from '../redux/slices/appSlice';

const LOCATION_STATUS_ERROR = {
  PERMISSION_DENIED: 1,
  POSITION_UNAVAILABLE: 2,
  TIMEOUT: 3,
};

/**
 * Function wrapper.
 * Requests current geolocation data and sets it in the store.
 * @returns {(function(callback: (location: { lat: number, lng: number }) => void, errorHandler: void): void)|*}
 */
export const useRequestGeoLocation = () => {
  const dispatch = useDispatch();

  return (callback, errorCallback) => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const location = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          dispatch(setGeoLocation(location));
          callback(location);
        },
        (err) => {
          if (err.code == LOCATION_STATUS_ERROR.PERMISSION_DENIED) {
            errorCallback();
          }
        },
        { timeout: 2000 },
      );
    }
  };
};
