import React, { useContext, useEffect, useState } from 'react';
import { useForm, Controller, useWatch } from 'react-hook-form';

import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';

import CountySelect from '../../../../common-components/CountySelect';
import DistrictSelect from '../../../../common-components/DistrictSelect';
import PersonPopUp from '../../../../popups/Person/Person';

import * as PersonService from '../../../../../services/requests/person';
import * as MunicipalityService from '../../../../../services/requests/municipality';

import { useSnackbarHelpers } from '../../../../../helpers/utils';
import { getPerson as getPersonEx } from '../../../../../helpers/database';

import { MunicipalityContext } from '../../AdminMunicipalityContext';
import { useStyles } from './styles';
import CustomSelect from '../../../../common-components/CustomSelect';
import BoxCenter from '../../../../common-components/BoxCenter';
import FormTextField from '../../../../common-components/FormTextField';
import FormMemoField from '../../../../common-components/FormMemoField';

export default function MunicipalityDetails() {
  const classes = useStyles();

  const [isPersonPopUpOpen, setIsPersonPopUpOpen] = useState(false);
  const [isPersonPopUpOpen2, setIsPersonPopUpOpen2] = useState(false);
  const { } = useSnackbarHelpers();

  const {
    onMunicipalitySearch,
    chosenMunicipality,
    setChosenMunicipality,
    chosenPerson,
    setChosenPerson,
    chosenDeputy,
    setChosenDeputy,
  } = useContext(MunicipalityContext);

  const { control, reset } = useForm({
    defaultValues: {
      Name: '',
      County: '',
      Mayor: '',
      FK_Mayor: '',
      Party: '',
      Deputy: '',
      FK_Deputy: '',
      Comment: '',
    },
  });

  const values = useWatch({ control });

  const onMunicipalitySave = async () => {
    const preload = {
      ID: chosenMunicipality?.ID,
      County: values.County,
      Name: values.Name,
      FK_Mayor: values.FK_Mayor || null,
      Party: values.Party,
      FK_Deputy: values.FK_Deputy || null,
      Comment: values.Comment,
    };

    const success = async (data) => {
      setChosenMunicipality(data);
      await onMunicipalitySearch();
    }

    if (chosenMunicipality?.ID) {
      await MunicipalityService.updateOne(preload, success);
    } else {
      await MunicipalityService.insertOne(preload, success);
    }
  };

  const onPersonApply = async () => {
    reset({
      ID: values.ID,
      Name: values.Name,
      County: values.County,
      Mayor: getPersonEx(chosenPerson),
      FK_Mayor: chosenPerson?.ID,
      Party: values.Party,
      Deputy: values.Deputy,
      FK_Deputy: values.FK_Deputy,
      Comment: values.Comment,
    });

    setChosenPerson(null);
    setIsPersonPopUpOpen(false);
  };

  const onDeputyApply = async () => {
    reset({
      ID: values.ID,
      Name: values.Name,
      County: values.County,
      Mayor: values.Mayor,
      FK_Mayor: values.FK_Mayor,
      Party: values.Party,
      Deputy: getPersonEx(chosenDeputy),
      FK_Deputy: chosenDeputy?.ID,
      Comment: values.Comment,
    });

    setChosenDeputy(null);
    setIsPersonPopUpOpen2(false);
  };

  useEffect(() => {
    if (chosenMunicipality) {
      const defaultValues = {
        ID: chosenMunicipality.ID,
        Name: chosenMunicipality.Name,
        County: chosenMunicipality.County,
        Mayor: getPersonEx(chosenMunicipality.Mayor),
        FK_Mayor: chosenPerson?.ID,
        Party: chosenMunicipality.Party,
        Deputy: getPersonEx(chosenMunicipality.Deputy),
        FK_Deputy: chosenDeputy?.ID,
        Comment: chosenMunicipality.Comment,
      };

      reset(defaultValues);
    }
  }, [chosenMunicipality]);

  // PersonPopup

  const onPersonModalOpen = async () => {
    if (chosenMunicipality?.Mayor?.ID || chosenPerson?.ID) {
      await PersonService.getOne(chosenMunicipality?.Mayor?.ID || chosenPerson?.ID, setChosenPerson);
    }
    setIsPersonPopUpOpen(true);
  };

  const onPersonModalClose = async () => {
    setChosenPerson(null);
    setIsPersonPopUpOpen(false);
  };

  // PersonPopup2

  const onPersonModalOpen2 = async () => {
    if (chosenMunicipality?.Deputy?.ID || chosenDeputy?.ID) {
      await PersonService.getOne(chosenMunicipality?.Deputy?.ID || chosenDeputy?.ID, setChosenDeputy);
    }
    setIsPersonPopUpOpen2(true);
  };

  const onPersonModalClose2 = async () => {
    setChosenDeputy(null);
    setIsPersonPopUpOpen2(false);
  };

  const disabled = !values?.Name || !values?.County;

  return (
    <Container maxWidth="sm" sx={{ mt: '10px'}}>
      <FormControl fullWidth>
        <CountySelect
          required
          name="County"
          control={control}
          value={values.County}
        />
        <DistrictSelect
          name="FK_District"
          control={control}
          value={values.FK_District}
          county={values.County}
        />
        <FormTextField
          name="Name"
          label="Name"
          control={control}
          required
        />
        <CustomSelect
          placeholder="Bürgermeister"
          value={values.Mayor}
          onClick={onPersonModalOpen}
        />
        <FormTextField
          name="Party"
          label="Partei"
          control={control}
        />
        <CustomSelect
          placeholder="Ansprechperson"
          value={values.Deputy}
          onClick={onPersonModalOpen2}
        />
        <FormMemoField
          name="Comment"
          label="Kommentar"
          control={control}
          rows={4}
          />
        <BoxCenter>
          <Button
            onClick={() => onMunicipalitySave()}
            disabled={disabled}
            sx={{
              minWidth: '200px',
              mt: '15px',
            }}
          >
            Speichern
          </Button>
        </BoxCenter>
      </FormControl>

      <PersonPopUp
        isOpen={isPersonPopUpOpen}
        onClose={onPersonModalClose}
        chosenPerson={chosenPerson}
        setChosenPerson={setChosenPerson}
        onPersonApply={onPersonApply}
      />

      <PersonPopUp
        isOpen={isPersonPopUpOpen2}
        onClose={onPersonModalClose2}
        chosenPerson={chosenDeputy}
        setChosenPerson={setChosenDeputy}
        onPersonApply={onDeputyApply}
      />
    </Container>
  );
}
