import { createUseStyles } from 'react-jss';
import { swvTheme } from '../../../../../swvTheme';

export const useStyles = createUseStyles({
  personTableBottom: {
    backgroundColor: swvTheme.palette.secondary.gray,
    gap: "1rem",
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: "1rem",
    padding: "0.5rem",
  },

  personEntry: {
    backgroundColor: swvTheme.palette.background.default,
    display: 'flex',
    borderRadius: '6px',

    "&:hover": {
      cursor: "pointer",
    },

    "& svg": {
      color: swvTheme.palette.button.dark,
      margin: "auto",
    },
  },

  forwardButton: {
    marginY: 'auto',
    marginLeft: 'auto',
    display: 'flex',
    justifyContent: 'center',
  },
});
