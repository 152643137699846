import React, { useState, useContext } from 'react';

import ModalWrapper from '../../common-components/ModalWrapper';
import BoxCenter from '../../common-components/BoxCenter';
import CustomButton from '../../common-components/CustomButton';

import ElectorialListPopup from '../ElectorialList';
import VotingCardPopUp from '../VotingCard';
import SupportStatementPopUp from '../SupportStatement';
import ConsentSupportStatementPopUp from '../ConsentSupportStatement';

import { CompanyContext } from '../../pages/Company/CompanyContext';
import { useGlobalStyles } from '../../../customStyles';
import { useStyles } from './styles';
import { Grid } from '@mui/material';

export default function CreateFormPopUp({ isOpen, onClose }) {
  const classes = useStyles();
  const cssClass = useGlobalStyles();

  const { companyData,businessRegistrationList} = useContext(CompanyContext);

  const hasStatus2 = businessRegistrationList && businessRegistrationList.some(entry => entry.Status === 2);

  
  // ElectorialListModal

  const [isElectorialListModalOpen, setIsElectorialListModalOpen] =
    useState(false);

  const onElectorialListModalOpen = async () => {
    setIsElectorialListModalOpen(true);
  };

  const onElectorialListModalClose = async () => {
    setIsElectorialListModalOpen(false);
  };

  // VotingCardModal

  const [isVotingCardModalOpen, setIsVotingCardModalOpen] = useState(false);

  const onVotingCardModalOpen = async () => {
    setIsVotingCardModalOpen(true);
  };

  const onVotingCardModalClose = async () => {
    setIsVotingCardModalOpen(false);
  };

  // SupportStatementModal

  const [isSupportStatementModalOpen, setIsSupportStatementModalOpen] =
    useState(false);

  const onSupportStatementModalOpen = async () => {
    setIsSupportStatementModalOpen(true);
  };

  const onSupportStatementModalClose = async () => {
    setIsSupportStatementModalOpen(false);
  };

  // onConsentSupportStatementModal

  const [
    isConsentSupportStatementModalOpen,
    setIsConsentSupportStatementModalOpen,
  ] = useState(false);

  const onConsentSupportStatementModalOpen = async () => {
    setIsConsentSupportStatementModalOpen(true);
  };

  const onConsentSupportStatementModalClose = async () => {
    setIsConsentSupportStatementModalOpen(false);
  };

  return (
    <>
      <ModalWrapper
        isOpen={isOpen}
        onClose={onClose}
        title="Formular erstellen"
        className={cssClass.swvModalResponsiveMods + " " + classes.wrapper}
      >
        {hasStatus2 && (
          <BoxCenter sx={{ gap: 2, mt: 4 }}>
          <CustomButton
            value="Wählerliste"
            largeBorderRadius
            sx={{ width: '300px !important', fontSize: '14px' }}
            onClick={() =>
              onElectorialListModalOpen(companyData.Master?.Person)
            }
            />
        </BoxCenter>
        )}
        

        <BoxCenter sx={{ gap: 2, mt: 4 }}>
          <CustomButton
            value="Wahlkarte"
            largeBorderRadius
            sx={{ width: '300px !important', fontSize: '14px' }}
            onClick={() => onVotingCardModalOpen(companyData.Master?.Person)}
          />
        </BoxCenter>

        <BoxCenter sx={{ gap: 2, my: 4 }}>
          <CustomButton
            value="Unterstützungserklärung"
            largeBorderRadius
            sx={{ width: '300px !important', fontSize: '14px' }}
            onClick={() =>
              onSupportStatementModalOpen(companyData.Master?.Person)
            }
          />
        </BoxCenter>

        <BoxCenter sx={{ gap: 2, my: 4 }}>
          <CustomButton
            value="Zustimmungserklärung"
            largeBorderRadius
            sx={{ width: '300px !important', fontSize: '14px' }}
            onClick={() =>
              onConsentSupportStatementModalOpen(companyData.Master?.Person)
            }
          />
        </BoxCenter>
      </ModalWrapper>
      <ElectorialListPopup
        isOpen={isElectorialListModalOpen}
        onClose={onElectorialListModalClose}
      />

      <VotingCardPopUp
        isOpen={isVotingCardModalOpen}
        onClose={onVotingCardModalClose}
      />

      <SupportStatementPopUp
        isOpen={isSupportStatementModalOpen}
        onClose={onSupportStatementModalClose}
      />

      <ConsentSupportStatementPopUp
        isOpen={isConsentSupportStatementModalOpen}
        onClose={onConsentSupportStatementModalClose}
      />
    </>
  );
}
