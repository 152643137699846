export const styles = {
  filterTextField: {
    minWidth: '283px'
  },
  filterSelect: {
    minWidth: '186px'
  },
  filterButton: {
    minWidth: '180px'
  }
};