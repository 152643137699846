import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  mainContainer:
  {
    margin: "8px 0 4px 0",
  },
  label: {
    display: "inline-block",
    fontSize: "12px",
    fontWeight: 400,
    color: "rgba(0, 0, 0, 0.6)",
    lineHeight: 1.25,
    letterSpacing: "0.00938em",
    position: "relative",
    top: "9px",
    width: "fit-content",
    backgroundColor: "white",
    padding: "0 5px",
  },
  labelContainer2: {
    height: "15px",
  },
  labelContainer: {
    display: "flex",
    justifyContent: "flex-start",
    position: "relative",
    zIndex: 10,
    margin: "0 0 -1px 8px",
    height: "1rem",
  },
});
