import { createUseStyles } from 'react-jss';
import { swvTheme } from '../../../swvTheme';

export const useStyles = createUseStyles({
  popupWrapper: {
    minWidth: 'unset !important',
    width: 'clamp(150px, 100svw, 950px) !important',
    marginTop: "40px",

    '& .MuiButton-containedPrimary': {
      backgroundColor: swvTheme.palette.button.light,
    },

    '& .MuiOutlinedInput-root': {
      maxWidth: 'unset',
      width: '100%',
      backgroundColor: '#FFFFFF',
    },
  },

  mainContainer: {
    margin: '8px 0 4px 0',
    position: 'relative',
  },

  label: {
    display: 'inline-block',
    fontSize: '12px',
    fontWeight: 400,
    color: 'rgba(0, 0, 0, 0.6)',
    lineHeight: 1.25,
    letterSpacing: '0.00938em',
    position: 'relative',
    top: '-8px',
    zIndex: '9',
    width: 'fit-content',
    backgroundColor: 'white',
    padding: '0 5px',
  },

  labelContainer2: {
    height: '15px',
    position: 'absolute',
  },

  labelContainer: {
    display: 'flex',
    justifyContent: 'flex-start',

    zIndex: 10,
    margin: '0 0 -1px 8px',
    height: '1rem',
  },
});
