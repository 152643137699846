import React, { useState } from 'react';
import { useStyles } from './styles';
import { useGlobalStyles } from '../../../../../customStyles';
import { Box, IconButton, MobileStepper, Typography } from '@mui/material';
import {
  Add,
  DeleteForever,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from '@mui/icons-material';
import Button from '@mui/material/Button';

import SwipeableViews from 'react-swipeable-views';

import { swvTheme } from '../../../../../swvTheme';
import {
  generateUrlFromImageReference,
  urlIsLoadable,
} from '../../../../../helpers/utils';

import HideImageIcon from '@mui/icons-material/HideImage';
import ImageWithFallback from '../../../../common-components/ImageWithFallback/ImageWithFallback';
import AddIcon from '@mui/icons-material/Add';

export default function CompanyImageCarousel({
  imageList,
  imageUriList,
  onPhotoModalOpen,
  deletePhoto,
}) {
  const classes = useStyles();
  const cssClass = useGlobalStyles();

  const [activeStep, setActiveStep] = React.useState(0);

  const addImageImage = <div key={'add'}>
    <Box m={2}>
      <ImageWithFallback
        className={classes.ImgCard}
        alt={"add new image"}
        src={""}
      >
        <Box
          className={classes.BrokenImgCard + " " + classes.AddImageCard}
          onClick={() => onPhotoModalOpen()}>
          <Add sx={{ color: swvTheme.palette.primary.main }} />
        </Box>
      </ImageWithFallback>
    </Box>
  </div>

  const renderedImageList = imageList?.map(renderImage) || [];
  renderedImageList?.push(addImageImage)

  const maxSteps = renderedImageList?.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  function renderImage(imageReference, index) {
    if (!imageReference.ID) {
      return;
    }

    const imageReferenceId = imageReference.ID;

    const imageCaption = imageReference?.Caption ?? ``;

    const src = imageUriList.find(element => element.ID === imageReference.ID)?.imageUri;

    return (
      <div key={imageReferenceId}>
        {Math.abs(activeStep - index) <= 2 ? (
          <Box m={2}>
            <Box sx={{ position: 'relative' }}>
              <ImageWithFallback
                className={classes.ImgCard}
                alt={imageReferenceId}
                src={src}
              >
                <Box className={classes.BrokenImgCard}>
                  <HideImageIcon />
                </Box>
              </ImageWithFallback>

              <Box
                className={classes.EditRow}
                p={1}
                m={1}
                gap={1}
              >
                <DeleteForever
                  sx={{ color: swvTheme.palette.primary.main }}
                  onClick={() => deletePhoto(imageReferenceId)}
                />

                {/* <Edit
                  onClick={() => onPhotoModalOpen(imageReference)}
                  sx={{ color: swvTheme.palette.primary.main }}
                /> */}
              </Box>
            </Box>
            <Typography
              color={swvTheme.palette.grey.dark}
              fontSize={16}
              fontWeight={500}
              p={2}
              className={classes.imageCaption}
            >
              {imageCaption}
            </Typography>
          </Box>
        ) : null}
      </div>
    );
  }

  return (
    <Box
      mt={3}
      pb={3}
      className={classes.CompanyCarouselContainer}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          py: 2,
          width: '100%',
        }}
      >
        <Typography
          color={swvTheme.typography.lilac}
          fontSize={18}
          fontWeight={500}
          pl={2}
        >
          Fotos
        </Typography>
        <IconButton
          aria-label="edit"
          color="primary"
          sx={{
            mr: '8px'
          }}
          onClick={() => onPhotoModalOpen()}
        >
          <Add />
        </IconButton>
      </Box>
      <Box className={classes.CompanyCarouselWrapper}>
        <SwipeableViews
          axis={swvTheme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {renderedImageList}
        </SwipeableViews>
        {imageList !== null && imageList.length !== 0 &&
          <MobileStepper
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            sx={{
              borderRadius: '8px',
            }}
            nextButton={
              <Button
                size="small"
                onClick={handleNext}
                disabled={activeStep === maxSteps - 1}
                className={classes.nextButton}
              >
                Nächstes
                {swvTheme.direction === 'rtl' ? (
                  <KeyboardArrowLeft />
                ) : (
                  <KeyboardArrowRight />
                )}
              </Button>
            }
            backButton={
              <Button
                size="small"
                onClick={handleBack}
                disabled={activeStep === 0}
                className={classes.backButton}
              >
                {swvTheme.direction === 'rtl' ? (
                  <KeyboardArrowRight />
                ) : (
                  <KeyboardArrowLeft />
                )}
                Vorheriges
              </Button>
            }
          />
        }
      </Box>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'right',
          cursor: 'pointer',
          mr: 4,
        }}
      >
      </Box>
    </Box>
  );
}
