import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
    noHoverRow: {
        '&:hover': {
            backgroundColor: 'unset !important',
            cursor: 'unset !important',
        },
    },

});