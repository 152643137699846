import React, { useState, useEffect } from 'react';
import Navbar from '../Navbar';
import { Collapse, Icon, IconButton, TablePagination, Tooltip, Typography } from '@mui/material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';

import SectionHeading from '../../common-components/SectionHeading';
import { useGlobalStyles } from '../../../customStyles';
import { useStyles } from './styles';
import { useGetAllChangeProposalsQuery, useUpdateChangeProposalMutation } from '../../../redux/services/ChangeProposalService';
import ChangeProposalTable from './components/ChangeProposalTable';


export default function AdminChangeProposalOverview() {
    const cssClass = useGlobalStyles();

    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(25);

    const { data: { rows, count } = {}, error, isLoading, isFetching } = useGetAllChangeProposalsQuery({ page, pageSize });


    const onPageChange = (_: any, newPage: React.SetStateAction<number>) => {
        setPage(newPage);
    };

    const onRowsPerPageChange = (event: any) => {
        setPageSize(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <>
            <Container maxWidth="xl">
                <Box sx={{
                    position: 'relative',
                    padding: '77px 0 44px',
                }}>
                    <SectionHeading title="Änderungsvorschläge" sx={{ margin: '0 auto' }} />
                </Box>
                <ChangeProposalTable rows={rows} />
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    component="div"
                    count={count ?? pageSize}
                    rowsPerPage={pageSize}
                    page={page}
                    onPageChange={onPageChange}
                    onRowsPerPageChange={onRowsPerPageChange}
                    labelRowsPerPage="Zeilen pro Seite"
                    labelDisplayedRows={({ from, to, count }) =>
                        `${from}–${to} von ${count !== -1 ? count : `mehr als ${to}`}`
                    }
                />
            </Container >
        </>
    )

}


