import React, { useContext, useState, useEffect } from 'react';
import { Box, Typography, Card, CardContent, Avatar, IconButton } from '@mui/material';
import { AddOutlined, ArrowForward } from '@mui/icons-material';

import CompanyIcon from '../CompanyIcon/CompanyIcon';
import PersonIcon from '../PersonIcon/PersonIcon';
import { useStyles } from './styles';
import { CompanyContext } from '../../pages/Company/CompanyContext';

const DataCard = (props) => {
  const classes = useStyles();

  const companyContext = useContext(CompanyContext)
  const [mode, setMode] = useState(null)

  useEffect(() => {
    if (companyContext)
      setMode(companyContext.mode)
  }, [companyContext])

  return (
    <Card
      sx={{
        borderRadius: '8px',
        display: 'flex',
        justifyContent: 'space-between',
        cursor: 'pointer',
        ...props.sx,
      }}
      onClick={props.onClick ?? (() => { })}
    >
      <Box display="flex" alignItems="center">
        <Box sx={{ maxHeight: '54px', padding: '12px' }}>
          <Avatar style={{ height: '54px', width: '54px' }}
            src={props.image}
          >
            {props.placeholderImage === 'person' ? (
              <PersonIcon />
            ) : (
              <CompanyIcon />
            )}
          </Avatar>
        </Box>
        <Box display="flex" flexDirection="column">
          <CardContent
            sx={{
              '&.MuiCardContent-root': {
                padding: '0',
              },
            }}
          >
            <Typography fontWeight={700} className={props.truncate && classes.truncate}>{props.lineOne}</Typography>
            <Typography className={classes.truncate}>{props.lineTwo}</Typography>
          </CardContent>
        </Box>
      </Box>
      <Box display='flex' alignItems='center' marginRight='5px' marginLeft='auto'>
        {(mode === 'contactPersonSelect') && (
          <IconButton
            onClick={(event) => {
              event.stopPropagation()
              companyContext.onContactPersonAdd(props.object);
            }}
          >
            <AddOutlined style={{ color: 'green' }} />
          </IconButton>
        )}
      </Box>
      {props.customIcon ? (<>{props.customIcon}</>) : (<ArrowForward
        fontSize="small"
        color="#732382"
        sx={{
          alignSelf: 'center',
          marginRight: '36px',
        }}
      />)}

    </Card>
  );
};

export default DataCard;
