import React from 'react';

export default function Image() {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_858_4637)">
        <path d="M1.74307 19.3023L1.72723 19.2925L1.71072 19.2839C1.21012 19.0228 0.901855 18.5136 0.901855 17.9641V1.99601C0.901855 1.17678 1.57811 0.5 2.42247 0.5H18.5874C19.4318 0.5 20.108 1.17678 20.108 1.99601V17.9641C20.108 18.514 19.7994 19.0235 19.3016 19.2844L19.2856 19.2928L19.2702 19.3023C19.062 19.4315 18.8169 19.5 18.5672 19.5H2.44605C2.19634 19.5 1.95129 19.4315 1.74307 19.3023ZM18.928 12.4189L19.761 13.1625V12.0459V1.99601C19.761 1.34817 19.2282 0.830672 18.5874 0.830672H2.42247C1.78168 0.830672 1.24893 1.34817 1.24893 1.99601V15.4923V16.6085L2.08181 15.8654L5.52697 12.7916L5.527 12.7916L5.5322 12.7868C6.05492 12.307 6.8624 12.307 7.38512 12.7868L7.38509 12.7869L7.38999 12.7913L8.72887 13.9882C9.12421 14.3686 9.74487 14.3635 10.1352 13.9887L14.4785 10.1101L14.4785 10.1102L14.4835 10.1055C15.0063 9.62572 15.8137 9.62572 16.3365 10.1055L16.3364 10.1056L16.3416 10.1102L18.928 12.4189ZM1.84312 18.9678L1.8956 19.0086L1.95688 19.0342C2.09948 19.0938 2.25574 19.1294 2.42247 19.1294H18.5874C18.7644 19.1294 18.9234 19.0858 19.0561 19.0281L19.1138 19.003L19.1634 18.9645C19.5209 18.687 19.7297 18.2562 19.7172 17.8001V14.2605C19.7255 13.8546 19.5609 13.4641 19.2724 13.1841L19.2651 13.177L19.2574 13.1701L16.1135 10.3598C15.7182 9.97944 15.0976 9.98446 14.7072 10.3592L10.364 14.2344L10.364 14.2343L10.3588 14.2391C9.83607 14.7189 9.0286 14.7189 8.50588 14.2391L8.50591 14.2391L8.50101 14.2347L7.16216 13.0378C6.7668 12.6574 6.14609 12.6624 5.75572 13.0373L1.75261 16.6131L1.74652 16.6186L1.74062 16.6242C1.4454 16.9058 1.28107 17.2981 1.28935 17.7069V17.8034C1.27683 18.2596 1.4856 18.6904 1.84312 18.9678Z" fill="currentColor" stroke="currentColor"/>
        <path d="M15.4453 8.16367C17.0349 8.16367 18.3213 6.88955 18.3179 5.31935C18.3179 3.74916 17.0281 2.47837 15.4386 2.4817C13.8524 2.4817 12.5659 3.75581 12.5659 5.32268C12.5659 6.89287 13.8557 8.16367 15.4453 8.16367ZM15.4453 3.81237C16.2906 3.81237 16.9742 4.49101 16.9709 5.32601C16.9709 6.161 16.2839 6.83632 15.4386 6.83299C14.5966 6.83299 13.913 6.15435 13.913 5.32268C13.913 4.48768 14.6 3.81237 15.4453 3.81237Z" fill="currentColor"/>
      </g>
      <defs>
        <clipPath id="clip0_858_4637">
          <rect width="20.2062" height="20" fill="currentColor" transform="translate(0.401855)"/>
        </clipPath>
      </defs>
    </svg>
  )
}