import { createUseStyles } from 'react-jss';
import { swvTheme } from '../../../swvTheme';

export const useStyles = createUseStyles({
    companyInfoTable: {
        "& .MuiTableRow-root:last-child": {
            borderBottom: "none",
        },
    },

    personAvatar: {
        width: '120px',
        height: '120px',
        '&:hover': {
            cursor: 'pointer',
        },
    },
});
