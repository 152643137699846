import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { useStyles } from './styles';
import { swvTheme } from '../../../swvTheme';

const FilterBottom = (props) => {
  const classes = useStyles();
  
  return (
    <Box
        sx={{ 
          '&>*:not(button)': {
            width: '100%',
          },

          display: 'flex', 
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2,
          mt: 2,

          [swvTheme.breakpoints.up('md')]: {
            flexDirection: 'row',
          },

          '& .MuiFormControl-root': {
            m: 0
          }
        }}
    >
      {props.children}
    </Box>
)};

export default FilterBottom