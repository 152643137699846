import React, { useState, useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { Box, Button, FormControl } from '@mui/material';

import * as BusinessSubcategoryService from '../../../services/requests/businessSubcategory';

import { useStyles } from './styles';
import { useGlobalStyles } from '../../../customStyles';
import { chooseCounty } from '../../../helpers/database';

import BoxCenter from '../../common-components/BoxCenter';
import ModalWrapper from '../../common-components/ModalWrapper';
import FormTextField from '../../common-components/FormTextField';
import { BusinessCategoryAPI } from "../../../redux/services/BusinessCategoryService";

export default function BusinessSubcategoryPopUp({
  isOpen,
  onClose,
  currentBusinessCategory,
  currentCounty,
  currentBusinessSubcategoryNumber,
  currentBusinessSubcategoryName,
  handleApply,
}) {
  const classes = useStyles();
  const cssClass = useGlobalStyles();

  const { data: businessCategoryListResponse } = BusinessCategoryAPI.useGetListQuery(undefined, { skip: !isOpen });
  const [businessSubcategoryList, setBusinessSubcategoryList] = useState(null);
  const [chosenBusinessSubcategory, setChosenBusinessSubcategory] = useState(null);

  const { control, reset } = useForm({
    defaultValues: {
      number: '',
      name: '',
    }
  });

  const values = useWatch({ control });

  useEffect(() => {
    if (isOpen) {
      reset({
        number: currentBusinessSubcategoryNumber || '',
        name: currentBusinessSubcategoryName || '',
      });
    } else {
      setChosenBusinessSubcategory(null);
      setBusinessSubcategoryList(null);
    }
  }, [isOpen])

  const getBusinessSubcategoryList = async () => {
    await BusinessSubcategoryService.getList({
      categoryId: currentBusinessCategory,
      county: currentCounty,
      number: values.number,
      name: values.name,
    }, setBusinessSubcategoryList);
  };

  return (
    <ModalWrapper isOpen={isOpen} onClose={onClose} title="Fachgruppe">
      <FormControl>
        <Box className={cssClass.formFlexColumn}>
          <strong>Sparte:</strong>
          <span>{businessCategoryListResponse?.data?.find(o => o.ID === currentBusinessCategory)?.Name}</span>
        </Box>
        <Box className={cssClass.formFlexColumn}>
          <strong>Bundesland:</strong>
          <span>{chooseCounty(currentCounty)}</span>
        </Box>
        <FormTextField
          name="number"
          label="Nummer"
          control={control}
        />
        <FormTextField
          name="name"
          label="Name"
          control={control}
        />
        <Box className={cssClass.listContainer}>
          {businessSubcategoryList?.map((row) => <p
            key={row.ID}
            className={chosenBusinessSubcategory?.ID == row.ID ? cssClass.chosen : cssClass.notchosen}
            onClick={() => setChosenBusinessSubcategory(row)}
          >
            {row.Number}, {row.Name}
          </p>)}
        </Box>
      </FormControl>
      <BoxCenter>
        <Button
          onClick={() => handleApply(chosenBusinessSubcategory)}
          disabled={!chosenBusinessSubcategory}
        >
          Übernehmen
        </Button>
        <Button
            onClick={() => getBusinessSubcategoryList()}
            disabled={!values?.number && !values?.name}
          >
          Suchen
        </Button>
      </BoxCenter>
    </ModalWrapper>
  );
}
