import { createUseStyles } from 'react-jss';
import { swvTheme } from '../../../swvTheme';

export const useStyles = createUseStyles({
  personWrapper: {
    width: 'clamp(150px, 100svw, 1050px) !important',
    minWidth: 'unset !important',

    height: '95svh',
    marginTop: '24px',
    marginBottom: '0 !important',

    '& .MuiButton-containedPrimary': {
      backgroundColor: swvTheme.palette.button.light,
    },

    '& .Mui-disabled': {
      backgroundColor: swvTheme.palette.grey['500'],
    },
  },

  changeImgButton: {
    color: swvTheme.palette.button.dark,
    textAlign: "center",
    cursor: "pointer",
    width: "100%",

    "&:hover": {
      textDecoration: "underline"
    }
  },

  personInfoTable: {
    padding: '8px 8px',
    background: '#E9E9E9',
    borderRadius: '4px',

    [swvTheme.breakpoints.up("md")]: {
      padding: '35px 39px',
    },

    "& .MuiTableRow-root:last-child": {
      borderBottom: "none",
    },
  },
  personAvatarContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },

  backToSearchButton: {
    margin: '-50px 20px 0 -57px',
  },

  backToSearchButtonIcon: {
    position: 'relative',
    left: '-2px'
  },

  personAvatarInnerFlexWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },

  personAvatar: {
    width: '54px',
    height: '54px',
    marginRight: '18px',
    '&:hover': {
      cursor: 'pointer',
    },
  },

  personDetailsSection: {
    marginBottom: '20px',
    padding: '0 !important',
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
    boxShadow: swvTheme.boxShadow,
  }

});
