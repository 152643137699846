import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./basequery";
import * as endpoints from '../../constants/endpoints';
import { IUser } from "../slices/appSlice";

export const UserApi = createApi({
    reducerPath: "User",
    baseQuery,
    tagTypes: ['CurrentUser'],
    endpoints: (builder) => ({
        getCurrentUser: builder.query<IUser, void>({
            query: () => endpoints.getCurrentUser,
            providesTags: ['CurrentUser']
        })
    })
})

export const { useGetCurrentUserQuery } = UserApi