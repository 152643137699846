import React, { useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';

import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import MenuItem from '@mui/material/MenuItem';
import Container from '@mui/material/Container';

import { getDate, getPerson } from '../../../../../helpers/database';

import FormSelect from '../../../../common-components/FormSelect';
import DeleteCell from '../../../../common-components/DeleteCell';
import FilterTop from '../../../../common-components/FilterTop';
import FormTextField from '../../../../common-components/FormTextField';

import { useGlobalStyles } from '../../../../../customStyles';
import { styles } from './styles';


export default function HonorSearch({
  setCurrentTab,
  chosenHonor,
  setChosenHonor,
  honorList,
  deleteHonor,
  onSearchClicked,
  name,
  status,
  setName,
  setStatus,
}) {
  const cssClass = useGlobalStyles();

  const { control } = useForm({
    defaultValues: {
      Name: name || '',
      Status: status || '',
    },
  });

  const values = useWatch({ control });

  useEffect(() => {
    setName(values.Name);
    setStatus(values.Status);
  }, [values]);

  const onRowClick = (row) => {
    setChosenHonor(row);
    setCurrentTab(1);
  };

  const onNewClicked = () => {
    setChosenHonor(null);
    setCurrentTab(1);
  };

  return (
    <Container maxWidth="xl">
      <FormControl>
        <FilterTop>
          <FormTextField
            name="Name"
            label="Bezeichnung"
            control={control}
            sx={styles.filterTextField}
          />
          <FormSelect
            label="Status"
            name="Status"
            control={control}
            value={values.Status}
            sx={styles.filterSelect}
          >
            <MenuItem value="Antrag">Antrag</MenuItem>
            <MenuItem value="Ablehnung">Ablehnung</MenuItem>
            <MenuItem value="Verleihung">Verleihung</MenuItem>
          </FormSelect>

          <Button sx={styles.filterButton} onClick={() => onSearchClicked(values)}>
            Suchen
          </Button>
          <Button sx={styles.filterButton} onClick={() => onNewClicked()}>
            Anlegen
          </Button>
        </FilterTop>

        <Box>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Bezeichnung</TableCell>
                  <TableCell>Person</TableCell>
                  <TableCell className={cssClass.dateCell}>Antrag</TableCell>
                  <TableCell>Einreicher</TableCell>
                  <TableCell className={cssClass.dateCell}>Verleihung</TableCell>
                  <TableCell className={cssClass.dateCell}>Ablehnung</TableCell>
                  <TableCell>Verleiher</TableCell>
                  <TableCell className={cssClass.btnCell}>Löschen</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {honorList?.map((row) => (
                  <TableRow
                    key={row.ID}
                    selected={chosenHonor?.ID == row.ID}
                    onClick={() => onRowClick(row)}
                  >
                    <TableCell>{row.Name}</TableCell>
                    <TableCell>{getPerson(row.User)}</TableCell>
                    <TableCell>{getDate(row.Application)}</TableCell>
                    <TableCell>{row.Submitter?.Name}</TableCell>
                    <TableCell>{getDate(row.Awarding)}</TableCell>
                    <TableCell>{getDate(row.Rejection)}</TableCell>
                    <TableCell>{row.Awarder?.Name}</TableCell>
                    <DeleteCell func={deleteHonor} id={row.ID} />
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </FormControl>
    </Container>
  );
}