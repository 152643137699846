import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
    // TODO: move class into styles object
    wrapper: {
        width: "clamp(150px, 100%, 1000px) !important"
    }
});

export const styles = {
    locationSelectedContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 2
    }
}