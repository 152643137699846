import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

//@ts-ignore
import { getCompanyListByFilter, getCompanyListNew } from '../../services/requests/company';

const initialCompanyState = {
  companies: [],
};

const CompanySlice = createSlice({
  name: 'company',
  initialState: initialCompanyState,
  reducers: {
    setCompanies(state, action) {
      state.companies = action.payload;
    },
  },
});

export default CompanySlice.reducer;
export const { setCompanies } = CompanySlice.actions;
