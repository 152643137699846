import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialPersonState = {
  persons: [],
};



const PersonSlice = createSlice({
  name: 'person',
  initialState: initialPersonState,
  reducers: {
    setPersons(state, action) {
      state.persons = action.payload;
    },
  },
});

export default PersonSlice.reducer;
export const { setPersons } = PersonSlice.actions;
