import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { useStyles } from './styles';
import { swvTheme } from '../../../swvTheme';

const BoxCenter = (props) => {
    const classes = useStyles();

    return (
      <Box
          className={props.className}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            mt: 3,
            mx: 1,
            gap: 2,
            [swvTheme.breakpoints.up("md")]: {
                flexDirection: 'row',
            },
            ...props.sx,
          }}
      >
        {props.children}
      </Box>
    )};

export default BoxCenter