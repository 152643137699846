import React, { useContext, useEffect, useState } from 'react';
import { useForm, Controller, useWatch } from 'react-hook-form';

import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Container from '@mui/material/Container';

import CountySelect from '../../../../common-components/CountySelect';
import FilterTop from '../../../../common-components/FilterTop';
import FormTextField from '../../../../common-components/FormTextField';
import DeleteCell from '../../../../common-components/DeleteCell';

import { chooseCounty, getPerson } from '../../../../../helpers/database';

import { useGlobalStyles } from '../../../../../customStyles';

import { MunicipalityContext } from '../../AdminMunicipalityContext';
import { Box } from '@mui/material';

export default function MunicipalitySearch() {
  const cssClass = useGlobalStyles();

  const {
    onMunicipalitySearch,
    municipalityList,
    chosenMunicipality,
    setChosenMunicipality,
    setCurrentTab,
    deleteMunicipality,
    setCountrySearchFilter,
    setNameSearchFilter,
  } = useContext(MunicipalityContext);

  const { control } = useForm({
    defaultValues: {
      ID: '',
      County: '',
      Name: '',
      Party: '',
      Mayor: '',
    },
  });

  const values = useWatch({ control });

  useEffect(() => {
    setCountrySearchFilter(values.County);
    setNameSearchFilter(values.Name);
  }, [values.Name, values.County])

  const onRowClick = async (row) => {
    setChosenMunicipality(row);
    setCurrentTab(1);
  };

  const onNewMunicipalityButtonClick = () => {
    setChosenMunicipality({
      ID: '',
      County: '',
      Name: values?.Name,
      Mayor: '',
      Membership: '',
      Party: '',
    });

    setCurrentTab(1);
  };

  return (
    <Container maxWidth="lg">
      <FormControl fullWidth>
        <FilterTop>
          <CountySelect
            name="County"
            control={control}
            value={values?.County || ''}
          />
          <Box>
            <FormTextField
              fullWidth
              name="Name"
              label="Name"
              control={control}
              sx={{ width: "100%" }}
            />
          </Box>
          <Button
            onClick={() => onMunicipalitySearch()}
          >
            Suchen
          </Button>
          <Button
            onClick={() => onNewMunicipalityButtonClick()}
          >
            Anlegen
          </Button>
        </FilterTop>
        <TableContainer>
          <Table sx={{ minWidth: 450 }}>
            <TableHead>
              <TableRow>
                <TableCell>Bundesland</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Bürgermeister</TableCell>
                <TableCell>Partei</TableCell>
                <TableCell className={cssClass.btnCell}>Löschen</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {municipalityList?.map((row) => (
                <TableRow
                  key={row.ID}
                  selected={chosenMunicipality?.ID == row.ID}
                  onClick={() => onRowClick(row)}
                >
                  <TableCell>{chooseCounty(row.County)}</TableCell>
                  <TableCell>{row.Name}</TableCell>
                  <TableCell>{getPerson(row.Mayor)}</TableCell>
                  <TableCell>{row.Party}</TableCell>
                  <DeleteCell func={deleteMunicipality} id={row.ID} />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </FormControl>
    </Container>
  );
}
