export const styles = {
  rootContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '30px',
    p: {
      xs: '0 22px',
      sm: '50px 30px'
    }
  },
  decorativeCorners: {
    maxWidth: '660px',
    width: '100%',
    p: {
      xs:'20px',
      sm: '35px'
    },
    '& video': {
      maxWidth: '660px',
      width: '100%',
    }
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: {
      xs: '10px',
      sm: '20px'
    },
    flexWrap: 'wrap'
  },
  captureButton: {
    backgroundColor: 'button.light',
    width: '200px',
    minWidth: 'unset',
    maxWidth: '100%',
    fontSize: {xs: '12px', sm: '13px'},
    fontWeight: '500',
    lineHeight: '120%',
    padding: '16px !important',
    boxSizing: 'border-box',
  },
  secondaryActionButton: {
    borderWidth: '1px',
    backgroundColor: '#fff',
    color: 'button.light',
    borderColor: 'button.light',
    width: '200px',
    maxWidth: '100%',
    fontSize: {xs: '12px', sm: '13px'},
    fontWeight: '500',
    lineHeight: '120%',
    padding: '16px !important',
    minWidth: 'unset',
    boxSizing: 'border-box',
    '&:hover': {
      backgroundColor: '#f0f0f0',
      color: 'button.light',
      borderColor: 'button.light',
    },
  },
};
