import * as endpoints from '../../constants/endpoints';
import { apiClient } from '../apiClient';

export const getList = async (userId, success, error) => {
  const response = await apiClient.getList(endpoints.getGoalStatusList, { userId }, success, error);
  return response.data;
};

export const updateMany = async (data, success, error) => {
  const response = await apiClient.updateOne(endpoints.updateGoalStatus, data, success, error);
  return response.data;
};
