import React, { useTheme } from '@mui/material/styles';
import {
  Box,
  Container, Grid
} from '@mui/material';

import { useGlobalStyles } from '../../../../../customStyles';
import SectionHeading from '../../../../common-components/SectionHeading';

import { swvTheme as swvThemeImport } from '../../../../../swvTheme';
import { useForm, useWatch } from 'react-hook-form';
import { useContext, useState } from 'react';
import { ICompany } from '../../../../../interfaces/ICompany';
import * as CompanyService from '../../../../../services/requests/company'
import * as PersonService from '../../../../../services/requests/person'
import DataCard from '../../../../common-components/DataCard';
import { getBoolean, getBusinessRegistrationListOverview, getPhotoUrl } from '../../../../../helpers';
import { Add, Remove } from '@mui/icons-material';
import { onlyUnique } from '../../../../../helpers/helperFunctions';
import Search from '../../../../common-components/Search/Search';
import SearchList from '../../../../common-components/SearchList/SearchList';
import Printer from '../../../../common-components/Icons/Printer';
import CustomButton from '../../../../common-components/CustomButton';
import { FormSendingContext } from '../../../FormSending/FormSendingContext';
import { OrganizationAPI } from '../../../../../redux/services/OrganizationService';
import { AdminOrganisationContext } from '../../AdminOrganisationContext';


export default function PanelDetailsMember() {
  const theme = useTheme();
  //const classes = useStyles();
  const cssClass = useGlobalStyles();

  const {
    chosenPanel,
    getPanelList,
    chosenOrganisation,
    setChosenPanel
  } = useContext<any>(AdminOrganisationContext);


  const organizationAPI = OrganizationAPI;
  const [insertMember, { error: insertError, isLoading: isInserting }] = organizationAPI.useInsertMemberMutation();
  const [removeMember, { error: removeError, isLoading: isRemoving }] = organizationAPI.useRemoveMemberMutation();

  const { data: membersOfPanel } = organizationAPI.useGetMembersListQuery({ panelId: chosenPanel.ID }, { refetchOnMountOrArgChange: true })

  // this supresses ts errors related to swvTheme 
  const swvTheme: any = swvThemeImport;

  const { control, setValue } = useForm({
    defaultValues: {
      searchedSubcategories: '',
    },
  });
  const subcategoryValue = useWatch({ control });

  const pageSize = 100;

  const [selectedCompanies, setSelectedCompanies] = useState<Array<ICompany>>([])

  const [searchText, setSearchText] = useState('');
  const [lists, setLists] = useState({
    company: [],
    person: [],
    contactPerson: [],
    address: []
  })

  const [isCreateFormPopUpOpen, setIsCreateFormPopUpOpen] = useState(false);
  const onCreateFormPopUpOpen = () => {
    setIsCreateFormPopUpOpen(true);
  };
  const onCreateFormPopUpClose = () => {
    setIsCreateFormPopUpOpen(false);
  };

  const changePageOfList = (listname: string, newPage: number) => {
    setSearchData((old: any) => ({ ...old, [listname]: { ...old[listname], page: newPage - 1 } }))
  }

  const handleAddCompany = (row: ICompany) => {
    if (!membersOfPanel || membersOfPanel?.filter((el: any) => el.FK_Company === row.ID).length > 0) {
      return
    }

    insertMember({ FK_Company: row.ID, FK_Organization: chosenOrganisation.ID, FK_Panel: chosenPanel.ID })
  }

  const handleRemoveCompany = (row: ICompany) => {
    if (!membersOfPanel || membersOfPanel?.filter((el: any) => el.FK_Company === row.ID).length <= 0) {
      return
    }

    removeMember({ FK_Company: row.ID, FK_Organization: chosenOrganisation.ID, FK_Panel: chosenPanel.ID })
  }

  const handleData = (listname: string, data: any, fromSearch: boolean) => {
    if (data?.resultList?.length) {
      setLists((old: any) => ({ ...old, [listname]: data.resultList }));
      if (fromSearch) {
        setSearchData((old: any) => ({ ...old, [listname]: { ...old[listname], resultCount: data.totalResultCount, listLoading: false } }))
      }
    } else {
      setSearchData((old: any) => ({ ...old, [listname]: { ...old[listname], resultCount: -1, listLoading: false } }))
    }
  }

  const getCompanyList = async (searchText: string, isEnterClicked: boolean, fromSearch: boolean, page?: number) => {
    if (fromSearch) {
      setSearchData((old: any) => ({ ...old, company: { ...old.company, listLoading: true } }))
    }

    if (subcategoryValue && (subcategoryValue?.searchedSubcategories?.length ?? 0) > 0) {
      //@ts-ignore
      const data = await getCompanyListBySubcategory({ searchText: searchText, subcategoryText: subcategoryValue.searchedSubcategories!, page: page, pageSize: pageSize }).unwrap();
      handleData('company', data, fromSearch)
    } else {
      await CompanyService.getList(
        {
          mode: 'Company',
          text: isEnterClicked ? searchText : '',
          fromSearch: fromSearch,
          page: page ?? 0,
          pageSize: pageSize,
          IsFamily: searchData.other.familyChecked,
          IsMember: searchData.other.memberChecked,
        }, (data: any) => handleData('company', data, fromSearch)
      )
    }
  };

  const getPersonList = async (searchText: string, isEnterClicked: boolean, fromSearch: boolean, page?: number) => {
    if (fromSearch) {
      setSearchData((old: any) => ({ ...old, person: { ...old.person, listLoading: true } }))
    }

    await PersonService.getList(
      {
        text: isEnterClicked ? searchText : '',
        page: page ?? 0,
        pageSize: pageSize,
        IsFamily: searchData.other.familyChecked,
        IsMember: searchData.other.memberChecked,
      },
      (data: any) => handleData('person', data, fromSearch)
    );
  };

  const [searchData, setSearchData] = useState({
    company: {
      isEnabled: true,
      resultCount: 0,
      page: 0,
      checked: true,
      listOpen: false,
      listLoading: false,
      renderDataCard: (row: any) => {
        return <>
          <DataCard
            key={row.ID}
            lineOne={row.Name}
            lineTwo={`FG Nr.: ${getBusinessRegistrationListOverview(row)}`}
            placeholderImage='company'
            image={getPhotoUrl(row)}
            truncate
            onClick={() => { handleAddCompany(row) }}
            customIcon={<Add fontSize="small"
              sx={{
                alignSelf: 'center',
                marginRight: '36px',
              }} />}
          />
        </>
      },
      handlePageChange: (listname: string, newPage: number, searchText: string) => {
        changePageOfList(listname, newPage)
        getCompanyList(searchText, true, false, newPage - 1)
      },
      getList: (searchText: string, isEnterClicked: boolean, fromSearch: boolean, page?: number) => {
        return getCompanyList(searchText, isEnterClicked, fromSearch, page)
      }
    },
    person: {
      isEnabled: true,
      resultCount: 0,
      page: 0,
      checked: true,
      listOpen: false,
      listLoading: false,
      renderDataCard: (row: any) => {
        return <>
          <DataCard
            key={row.ID}
            lineOne={row.Firstname + ' ' + row.Lastname}
            lineTwo={'Mitglied: ' + getBoolean(row.Member) + ' - Familie: ' + getBoolean(row.IsFamily)}
            placeholderImage='person'
            image={getPhotoUrl(row)}
            truncate
          />
        </>
      },
      handlePageChange: (listname: string, newPage: number, searchText: string) => {
        changePageOfList(listname, newPage)
        getPersonList(searchText, true, false, newPage - 1)
      },
      getList: (searchText: string, isEnterClicked: boolean, fromSearch: boolean, page?: number) => {
        return getPersonList(searchText, isEnterClicked, fromSearch, page)
      }
    },
    other: {
      familyChecked: false,
      memberChecked: false,
      isSearchClicked: false,
      searchInputError: false,
    }
  })

  return (
    <FormSendingContext.Provider
      value={{
        searchText,
        setSearchText,

        lists,
        setLists,
        searchData,
        setSearchData,

        control,
        subcategoryValue,


        selectedCompanies,
        setSelectedCompanies
      }}
    >
      <Container maxWidth="xl" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        <div style={{ width: "48%", display: "flex", flexDirection: "column", gap: "0.5em" }}>
          <Search listnames={["company"/*, "person", "contactPerson", "address" */]} />
          <SearchList listname="company" />
          {/*<SearchList listname="person" />
            <SearchList listname="contactPerson" />
      <SearchList listname="address" />*/}
        </div>
        <div style={{ width: "48%", display: "flex", flexDirection: "column", justifyContent: "start", alignItems: "center", gap: "0.5em" }}>
          <SectionHeading title="Mitglieder" />
          <Grid container spacing={2} columns={1}>
            {membersOfPanel?.map((tRow: any) => {
              const row = tRow.Company;

              return <Grid
                key={row.ID}
                item
                xs={2}
                md={1}
                onClick={() => /* ToDo: onRowClicked(row) */ { }}
              >
                <DataCard
                  key={row.ID}
                  lineOne={row.Name}
                  lineTwo={`FG Nr.: ${getBusinessRegistrationListOverview(row)}`}
                  placeholderImage='company'
                  image={getPhotoUrl(row)}
                  truncate
                  customIcon={<Remove fontSize="small"
                    sx={{
                      alignSelf: 'center',
                      marginRight: '36px',
                    }} />}
                  onClick={() => { handleRemoveCompany(row) }}
                />
              </Grid>
            }
            )}
          </Grid>

        </div>
      </Container>
    </FormSendingContext.Provider>
  );
}