import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { AUTH_TOKEN_KEY } from '../constants/appConstants';
import Navbar from '../components/pages/Navbar';
import { useMediaQuery } from '@mui/material';
const PrivateRoutes = () => {
  const token = localStorage.getItem(AUTH_TOKEN_KEY);
  const isMobile = useMediaQuery("(max-width: 850px)")

  if (isMobile) {
    return token ? <><Navbar><Outlet /></Navbar></> : <Navigate to={'/login'} />;
  } else {
    return token ? <><Navbar><Outlet /></Navbar></> : <Navigate to={'/login'} />;
  }
};

export default PrivateRoutes;
