import * as endpoints from '../../constants/endpoints';
import { apiClient } from '../apiClient';

export const insertOne = async (data, success, error) => {
  const response = await apiClient.insertOne(endpoints.insertActivity, data, success, error);
  return response.data;
};

export const updateOne = async (data, success, error) => {
  const response = await apiClient.updateOne(endpoints.updateActivity, data, success, error);
  return response.data;
};

export const approveOne = async (id, success, error) => {
  const response = await apiClient.putOne(endpoints.approveActivity, id, success, error);
  return response.data;
};

export const getList = async (companyId, success, error) => {
  const response = await apiClient.getList(endpoints.getActivityList, { companyId }, success, error);
  return response.data.data;
};

export const getListEx = async (query, success, error) => {
  const response = await apiClient.getList(endpoints.getActivityListEx, query, success, error);
  return response.data.data;
};

export const deleteOne = async (id, success, error) => {
  const response = await apiClient.deleteOne(endpoints.deleteActivity, id, success, error);
  return response.data;
};
