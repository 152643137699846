import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import ModalWrapper from '../../common-components/ModalWrapper';

import { testImageUpload } from '../../../helpers/validateEmail';
import { useSnackbarHelpers, transformFileToBase64 } from '../../../helpers';

import { NoUploads, ImagePreview, WebcamImageCapture } from './components';
import { PopupModes } from './constants';

import { styles } from './styles';
import { DragAndDropArea } from '../../common-components/DragAndDropArea';

export const ImageUploadPopup = ({
  onClose,
  onSave,
  multipleChoice = false,
  isOpen,
  isScanMode = false,
  showAdditionalInfoFields = false,
  companyData = null,
}) => {
  const [selectedBase64Images, setSelectedBase64Images] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [scannedData, setScannedData] = useState(null);
  const hiddenFileInput = useRef();
  const [photoUploadMode, setPhotoUploadMode] = useState(PopupModes.FILE_SELECTION);
  const { showErrorSnackbar } = useSnackbarHelpers();

  useEffect(() => {
    if (!isOpen) {
      setSelectedBase64Images([]);
      setSelectedFiles([]);
      setPhotoUploadMode(PopupModes.FILE_SELECTION);
    }
  }, [isOpen]);

  const onFilesSelected = async (files) => {
    const fileList = Array.from(files);

    setSelectedFiles(fileList);

    if (fileList.some((file) => !testImageUpload(file))) {
      showErrorSnackbar(Messages.ErrorUpload);
    } else {
      const base64Images = await Promise.all(
        fileList.map((file) => transformFileToBase64(file)),
      );

      setSelectedBase64Images(base64Images);
    }
  };

  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={onClose}
      modalWidth="1121px !important"
      sx={styles.modalWrapper}
    >
      {!selectedBase64Images.length ? (
        photoUploadMode === PopupModes.FILE_SELECTION ? (
          <DragAndDropArea onFilesSelected={onFilesSelected}>
            <NoUploads
              hiddenFileInputRef={hiddenFileInput}
              onFilesSelected={onFilesSelected}
              setPhotoUploadMode={setPhotoUploadMode}
            />
          </DragAndDropArea>
        ) : (
          <WebcamImageCapture
            onFilesSelected={onFilesSelected}
            setPhotoUploadMode={setPhotoUploadMode}
          />
        )
      ) : (
        <ImagePreview
          hiddenFileInputRef={hiddenFileInput}
          onSave={onSave}
          base64Images={selectedBase64Images}
          onRecapture={() => {
            setSelectedBase64Images([]);
            setSelectedFiles([]);
          }}
          mode={photoUploadMode}
          setPhotoUploadMode={setPhotoUploadMode}
          isScanMode={isScanMode}
          showAdditionalInfoFields={showAdditionalInfoFields}
          selectedFiles={selectedFiles}
          scannedData={scannedData}
          setScannedData={setScannedData}
          companyData={companyData}
        />
      )}
      <input
        accept="image/*"
        type="file"
        multiple={multipleChoice}
        ref={hiddenFileInput}
        onChange={(event) => {
          onFilesSelected(event.target.files);
        }}
        style={styles.fileInput}
      />
    </ModalWrapper>
  );
};

ImageUploadPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  multipleChoice: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  isScanMode: PropTypes.bool,
  showAdditionalInfoFields: PropTypes.bool,
};
