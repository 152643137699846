import React, { useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { TablePagination } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Navbar from '../Navbar';
import * as PersonService from '../../../services/requests/person';
import {
  chooseCounty,
  chooseGender,
  getPerson,
  getAddressRegistration,
} from '../../../helpers/database';

import { useForm, useWatch } from 'react-hook-form';
import CountySelect from '../../common-components/CountySelect';
import FilterTop from '../../common-components/FilterTop';
import SectionHeading from '../../common-components/SectionHeading';
import FormTextField from '../../common-components/FormTextField';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
}));

export default function ExportPerson() {
  const [page, setPage] = React.useState(0);
  const [data, setData] = useState([]);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(1);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const { control, reset } = useForm({
    defaultValues: {
      County: '',
      Firstname: '',
      Lastname: '',
    },
  });

  const values = useWatch({ control });

  const onSearchClicked = useCallback(async () => {
    await PersonService.getListExport(
      { ...values, limit, page },
      (data, count) => {
        setData(data);
        setCount(count);
      },
    );
  }, [values, limit, page]);

  const onExportClicked = useCallback(async () => {
    await PersonService.getListExportCSV(values, (data) => {
      const exportData = () => {
        const link = document.createElement('a');
        link.href = `data:text/csv;charset=utf-8,${data}`;
        link.download = 'Personen.csv';
        link.click();
      };

      exportData();
    });
  }, [values]);

  useEffect(() => {
    return () => {
      setData([]);
      setCount(0);
      reset({
        County: '',
        Firstname: '',
        Lastname: '',
      });
    };
  }, []);

  return (
    <>
      <Container maxWidth="lg">
        <SectionHeading title="Personen" />
        <FormControl>
          <FilterTop>
            <CountySelect
              name="County"
              control={control}
              value={values?.County}
            />

            <FormTextField name="Firstname" label="Vorname" control={control} />
            <FormTextField name="Lastname" label="Nachname" control={control} />

            <Button onClick={onSearchClicked}>Suchen</Button>
            <Button onClick={() => onExportClicked(values)}>Exportieren</Button>
          </FilterTop>
        </FormControl>
        <Box>
          <TableContainer>
            <Table sx={{ minWidth: 700 }}>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Geschlecht</StyledTableCell>
                  <StyledTableCell>Person</StyledTableCell>
                  <StyledTableCell>Adresse</StyledTableCell>
                  <StyledTableCell>Firma</StyledTableCell>
                  <StyledTableCell>Adresse</StyledTableCell>
                  <StyledTableCell>Bundesland</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((row, idx) => {
                  return (
                    <StyledTableRow key={`${row.name}-${idx}`}>
                      <StyledTableCell>
                        {chooseGender(row.Person.Gender)}
                      </StyledTableCell>
                      <StyledTableCell>{getPerson(row.Person)}</StyledTableCell>
                      <StyledTableCell>
                        {getAddressRegistration(row.Person.AddressRegistration)}
                      </StyledTableCell>
                      <StyledTableCell>{row.Company.Name}</StyledTableCell>
                      <StyledTableCell>
                        {getAddressRegistration(row.Company.DeliveryWKO)}
                      </StyledTableCell>
                      <StyledTableCell>
                        {chooseCounty(row.Company.County)}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[2, 5, 10, 25]}
            component="div"
            count={count}
            rowsPerPage={limit}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Zeilen pro Seite"
            labelDisplayedRows={({ from, to, count }) =>
              `${from}–${to} von ${count !== -1 ? count : `more than ${to}`}`
            }
          />
        </Box>
      </Container>
    </>
  );
}
