import * as endpoints from '../../constants/endpoints';
import { apiClient } from '../apiClient';

export const authenticate = async (email, password, success, error) => {
  const response = await apiClient.post(endpoints.authenticate, { email, password }, success, error);
  return response.data;
};

export const resendTFA = async (email, success, error) => {
  const response = await apiClient.get(endpoints.resendTFA, { email }, null, null, success, error);
  return response.data;
};

export const submitTFA = async (data, success, error) => {
  const response = await apiClient.post(endpoints.submitTFA, data, success, error);
  return response.data;
};

export const forgotPassword = async (email, success, error) => {
  const response = await apiClient.get(endpoints.forgotPassword, { email }, null, null, success, error);
  return response.data;
};

export const setPassword = async (data, success, error) => {
  const response = await apiClient.post(endpoints.setPassword, data, success, error);
  return response.data;
};
