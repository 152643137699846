import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';

import {
  Container,
  SvgIcon,
  Typography,
  Link,
  Box,
  Button,
  Grid,
  Hidden,
} from '@mui/material';
import QuickActionButtons from '../../common-components/QuickActionButtons';
import SectionHeading from '../../common-components/SectionHeading';
import Navbar from '../Navbar';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download.svg';
import DownloadIconV2 from '../../../assets/icons/download-2.svg';
import Grafik from '../../../assets/images/Grafik_EPUundKMU.jpg';
import { styles } from './styles';
import * as PersonService from '../../../services/requests/person';
import DataCard from '../../common-components/DataCard';
import { ArrowForward } from '@mui/icons-material';
const infoFiles = [
  'Antrag_Wirtschaftsparlament.docx',
  'Fragen_Millionärssteuer.pdf',
  'Grafik_Frauen_Neugründungen.png',
  'WK_Broschüre_ALLE.pdf',
  'Grafik_EPUundKMU.png',
];

export const SWVInfo = () => {
  const theme = useTheme();

  const [windowWidth, setWindowWidth] = useState(0);
  return (
    <>
      {/*<QuickActionButtons />*/}
      <Container >
        <Hidden smDown>
          <Box
            sx={{
              '@media (max-width: 685px)': {
                boxShadow: 2,
                background: "white",
                marginY: "1.5rem",
                padding: '0.8rem'
              }
            }}
            paddingTop={'1.5rem'}
            style={{ borderRadius: '10px' }}
            display={'flex'}
            flexDirection={'column'}
          >
            <Typography variant="h1" fontWeight={'400'}>
              Informationen des SWV
            </Typography>
            <Typography paddingTop={'10px'} sx={styles.infoText}>
              Willkommen im Info-Bereich des Sozialdemokratischen
              Wirtschaftsverbands Österreich! Hier findest du aktuelle Updates
              und Einblicke in die Wirtschaftswelt. Wir setzen uns für kleine
              und mittlere Unternehmen ein. Entdecke Trends, erhalte Tipps und
              bleib immer einen Schritt voraus!
            </Typography>
          </Box>
        </Hidden>
        <Box

          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'start',
            '@media (min-width: 0px) and (max-width: 685px)': {
              gap: '1rem',
            },
            justifyContent: 'space-between',
            paddingY: 'initial',
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column',
              paddingY: '1.5rem',

            },
          }}
        >
          <Hidden smUp>
            <Box

              boxShadow={2}
              padding={'0.8rem'}
              style={{ borderRadius: '10px', background: "white" }}
              display={'flex'}
              flexDirection={'column'}
            >
              <Typography variant="h1" fontWeight={'400'}>
                Informationen des SWV
              </Typography>
              <Typography paddingTop={'10px'} sx={styles.infoText}>
                Willkommen im Info-Bereich des Sozialdemokratischen
                Wirtschaftsverbands Österreich! Hier findest du aktuelle Updates
                und Einblicke in die Wirtschaftswelt. Wir setzen uns für kleine
                und mittlere Unternehmen ein. Entdecke Trends, erhalte Tipps und
                bleib immer einen Schritt voraus!
              </Typography>
            </Box>
          </Hidden>
          <Hidden smUp>
            <Box
              width={{ sm: "33%", xs: "100%" }}
              boxSizing={'border-box'}
              padding={'0.8rem'}
              boxShadow={2}

              style={{ background: 'white', borderRadius: '10px' }}
              display={'flex'}
              flexDirection={'column'}
            >
              <Box component={"img"} src={Grafik} width={"100%"} />
              <Typography
                style={{ margin: 0 }}
                paddingY={'1rem'}
                fontWeight={'500'}
                color={'rgba(115, 35, 130, 1)'}
                fontSize={'20px'}
                variant="span"
                sx={styles.infoText}
              >
                Wir sind 99,6 Prozent!
              </Typography>
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
              >
                <Box display={'flex'} gap={'10px'} alignItems={'center'}>
                  <CalendarMonthIcon sx={{ color: 'rgba(0,0,0,0.6)' }} />
                  <Typography
                    style={{ margin: 0 }}
                    fontSize={'13px'}
                    variant="body1"
                    sx={styles.infoText}
                  >
                    23. September 2021
                  </Typography>
                </Box>
                <button
                  display={'flex'}
                  style={{
                    fontSize: 13,
                    background: 'rgba(115, 35, 130, 1)',
                    appearance: 'none',
                    padding: '15px 1rem',
                    color: 'white',
                    fontWeight: 600,
                    borderRadius: '5px',
                    width: 'auto',
                    border: 'none',
                    outline: 'none',

                  }}

                >
                  Mehr lesen
                </button>
              </Box>
            </Box>
          </Hidden>
          <Box
            width={{ sm: "38%" }}
            sx={{
              '@media (min-width: 600px) and (max-width: 685px)': {
                width: "100%",
              },
              '@media (min-width: 685px) and (max-width: 1100px)': {
                width: "48%",
              },
              '@media (min-width: 1100px)': {
                minWidth: 400,
              },

            }}
            boxShadow={2}
            padding={'0.8rem'}
            style={{ background: 'white', borderRadius: '10px' }}
            display={'flex'}
            flexDirection={'column'}
          >
            <Typography variant="h1" fontWeight={'400'}>
              Downloadbereich des SWV
            </Typography>
            <Typography paddingTop={'10px'} sx={styles.infoText}>
              Willkommen im Info-Bereich des Sozialdemokratischen
              Wirtschaftsverbands Österreich! Hier findest du aktuelle Updates
              und Einblicke in die Wirtschaftswelt. Wir setzen uns für kleine
              und mittlere Unternehmen ein. Entdecke Trends, erhalte Tipps und
              bleib immer einen Schritt voraus!
            </Typography>
            <Box

              justifyContent={'space-evenly'}
              gap={2}
              display={'flex'}
              flexWrap={'wrap'}

            >
              {infoFiles.map(
                (file, i) =>
                  i !== infoFiles.length - 1 && (
                    <Box
                    sx={{
                      '@media (max-width: 381px)': {
                        width: "100%",
                      }
                    }}
                      textAlign={'center'}
                      padding={2}
                      minWidth={'100px'}
                      width={"33%"}
                      key={file}
                      item
                      xs={6}
                      style={{
                        background: 'rgba(217, 217, 217, 0.5)',
                        borderRadius: '8px',
                        border: '1.5px solid rgba(115, 35, 130, 1)',
                      }}
                    >
                      <Link
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'center'}
                        style={{ wordBreak: 'break-all' }}
                        sx={styles.link}
                        href={`/assets/informationFiles/${file}`}
                        download
                        key={file}
                      >
                        <Box
                          style={{
                            background: 'rgba(115, 35, 130, 1)',
                            borderRadius: '100%',
                            padding: '10px',
                            color: 'white',

                          }}
                        >
                          <Box component={"img"} src={DownloadIconV2} width={"35px"} />
                        </Box>
                        <span>{file}</span>
                      </Link>
                    </Box>
                  ),
              )}
            </Box>
          </Box>
          <Hidden smDown>
            <Box
              sx={{
                '@media (min-width: 600px) and (max-width: 685px)': {
                  width: "100%",
                },
                '@media (min-width: 685px) and (max-width: 1100px)': {
                  width: "48%",

                },
              }}
              width={{ sm: "27%", xs: "100%" }}
              minWidth={300}
              boxSizing={'border-box'}
              padding={'0.8rem'}
              boxShadow={2}

              style={{ background: 'white', borderRadius: '10px' }}
              display={'flex'}
              flexDirection={'column'}
            >
              <Box component={"img"} src={Grafik} width={"100%"} />
              <Typography
                style={{ margin: 0 }}
                paddingY={'1rem'}
                fontWeight={'500'}
                color={'rgba(115, 35, 130, 1)'}
                fontSize={'20px'}
                variant="span"
                sx={styles.infoText}
              >
                Wir sind 99,6 Prozent!
              </Typography>
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
              >
                <Box display={'flex'} gap={'10px'} alignItems={'center'}>
                  <CalendarMonthIcon sx={{ color: 'rgba(0,0,0,0.6)' }} />
                  <Typography
                    style={{ margin: 0 }}
                    fontSize={'13px'}
                    variant="body1"
                    sx={styles.infoText}
                  >
                    23. September 2021
                  </Typography>
                </Box>
                <button
                  display={'flex'}
                  style={{
                    fontSize: 13,
                    background: 'rgba(115, 35, 130, 1)',
                    appearance: 'none',
                    padding: '15px 1rem',
                    color: 'white',
                    fontWeight: 600,
                    borderRadius: '5px',
                    width: 'auto',
                    border: 'none',
                    outline: 'none',

                  }}

                >
                  Mehr lesen
                </button>
              </Box>
            </Box>
          </Hidden>
          <Hidden smDown>
            <Box
              sx={{
                '@media (min-width: 600px) and (max-width: 685px)': {
                  width: "100%",
                },
                '@media (min-width: 685px) and (max-width: 1100px)': {
                  width: "48%",
                  marginTop: "1.5rem"
                },

              }}
              style={{ background: 'rgba(115, 35, 130, 0.1)', borderRadius: '10px' }}
              boxShadow={2}
              padding={'0.8rem'}
              marginBottom={'4.10rem'}
              display={'flex'}
              flexDirection={'column'}
              width={"27%"}
              minWidth={300}
            >
              <Typography
                paddingTop={'10px'}
                style={{ marginBottom: '15px' }}
                sx={styles.infoText}
              >
                Neue Mitglieder in deinem Bezirk:
              </Typography>
              <Box display={'flex'} flexDirection={'column'} gap={'1rem'}>
                <DataCard
                  lineOne={'XYZ Firma gmbH ❤️'}
                  lineTwo="1060 - FG Nr. : 606036"
                  placeholderImage="person"
                />
                <DataCard
                  lineOne={'Harry Mustermann'}
                  lineTwo="1060 - FG Nr. : 606036"
                  placeholderImage="person"
                />
                <DataCard
                  lineOne={'Musterfirma GmbH'}
                  lineTwo="1060 - FG Nr. : 606036"
                  placeholderImage="person"
                />
              </Box>
              <Box width={"100%"} display={"flex"} alignItems={"center"} gap={2} paddingY={3} justifyContent={"center"} >
                <Typography

                  style={{ marginBottom: 0 }}
                  sx={styles.infoText}
                >
                  Alle neuen Mitglieder ansehen
                </Typography>
                <ArrowForward fontSize="small"
                  color="#732382"
                />
              </Box>
            </Box>
          </Hidden>
        </Box>

      </Container>
      <Hidden smUp>
        <Box
          style={{ background: 'rgba(115, 35, 130, 0.1)' }}
          padding={'0.8rem'}
          marginBottom={'4.10rem'}
          display={'flex'}
          flexDirection={'column'}
        >
          <Typography
            paddingTop={'10px'}
            style={{ marginBottom: '15px' }}
            sx={styles.infoText}
          >
            Neue Mitglieder in deinem Bezirk:
          </Typography>
          <Box display={'flex'} flexDirection={'column'} gap={'1rem'}>
            <DataCard
              lineOne={'XYZ Firma gmbH ❤️'}
              lineTwo="1060 - FG Nr. : 606036"
              placeholderImage="person"
            />
            <DataCard
              lineOne={'Harry Mustermann'}
              lineTwo="1060 - FG Nr. : 606036"
              placeholderImage="person"
            />
            <DataCard
              lineOne={'Musterfirma GmbH'}
              lineTwo="1060 - FG Nr. : 606036"
              placeholderImage="person"
            />
          </Box>
          <Box width={"100%"} display={"flex"} alignItems={"center"} gap={2} paddingY={3} justifyContent={"center"} >
            <Typography

              style={{ marginBottom: 0 }}
              sx={styles.infoText}
            >
              Alle neuen Mitglieder ansehen
            </Typography>
            <ArrowForward fontSize="small"
              color="#732382"
            />
          </Box>
        </Box>
      </Hidden>
    </>
  );
};
