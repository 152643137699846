import { createUseStyles } from 'react-jss';
import { swvTheme } from '../../../swvTheme';

export const useStyles = createUseStyles({
  wrapper: {
    maxWidth: '550px',

    [swvTheme.breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
  },
  contentWrapper: {
    gap: '1rem',
    display: 'grid',

    [swvTheme.breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },

    '& .MuiGrid-item': {
      display: 'flex',
      justifyContent: 'center',
      width: '100% !important',

        [swvTheme.breakpoints.up('md')]: {
          '&:nth-child(odd)': {
            justifyContent: 'end',
          },
          "&:nth-child(even)": {
            justifyContent: 'start',
          },
      },
    },
  },
});
