import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import * as HonorService from '../../../services/requests/honor';

import { a11yProps } from '../../common-components/TabPanel/tabsProps';
import TabPanel from '../../common-components/TabPanel';
import SectionHeading from '../../common-components/SectionHeading';

import Navbar from '../Navbar';

import HonorSearch from './components/HonorSearch/HonorSearch';
import HonorDetails from './components/HonorDetails/HonorDetails';

export default function AdminHonor() {
  const [currentTab, setCurrentTab] = useState(0);
  const [chosenHonor, setChosenHonor] = useState(null);
  const [honorList, setHonorList] = useState(null);
  const [name, setName] = useState('');
  const [status, setStatus] = useState('');

  const handleTabChange = (_, newValue) => {
    setCurrentTab(newValue);
  };

  const onSearchClicked = async (data, reset) => {
    await HonorService.getList(
      {
        name: data?.Name,
        status: data?.Status,
      },
      (data) => {
        setHonorList(data);

        setChosenHonor(null);

        if (reset) {
          setName('');
          setStatus('');
        }
      },
    );
  };

  const onSaveSuccess = () => {
    onSearchClicked({
      Name: name,
      Status: status,
    });

    setChosenHonor(null);
  }

  const deleteHonor = async (id) => {
    await HonorService.deleteOne(id, () => {
      onSearchClicked({ Name: name, Status: status });
    });
  };

  return (
    <>
      <SectionHeading title="Ehrung" />
      <Box>
        <Tabs value={currentTab} onChange={handleTabChange}>
          <Tab label="Suche" {...a11yProps(0)} />
          <Tab label="Ehrung" {...a11yProps(1)} />
        </Tabs>
      </Box>

      <TabPanel value={currentTab} index={0}>
        <HonorSearch
          setCurrentTab={setCurrentTab}
          chosenHonor={chosenHonor}
          setChosenHonor={setChosenHonor}
          honorList={honorList}
          deleteHonor={deleteHonor}
          onSearchClicked={onSearchClicked}
          name={name}
          status={status}
          setName={setName}
          setStatus={setStatus}
        />
      </TabPanel>

      <TabPanel value={currentTab} index={1}>
        <HonorDetails
          chosenHonor={chosenHonor}
          onSaveSuccess={onSaveSuccess}
        />
      </TabPanel>
    </>
  );
}
