import React from 'react';
import { useState } from 'react';
import { Control, Controller } from 'react-hook-form';

import {
  InputAdornment,
  IconButton,
  InputLabel,
  Input,
  FormControl,
  FormHelperText,
  TextField,
  SxProps,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { useStyles } from './styles';
import { useGlobalStyles } from '../../../customStyles';
import {
  testEmailString,
  testPasswordString,
  testMobileString,
} from '../../../helpers/validateEmail';

interface IFormTextField {
  label: string
  name: string,
  fullWidth?: boolean,
  placeholder?: string,
  control?: Control<any>,
  required?: boolean,
  type?: string,
  disabled?: boolean,
  onKeyUp?: (e: any) => void,
  func?: any,
  light?: boolean,
  sx?: SxProps,
  readOnly?: boolean,
  multiline?: boolean,
  rows?: any,
  errors?: any,
  register?: any,
  errorMessage?: any,
  validationSchema?: any
  value?: any,
  autoFocus?: boolean
}

export default function FormTextField({
  label,
  name,
  fullWidth,
  control,
  required,
  type,
  disabled,
  func,
  light,
  sx,
  readOnly,
  multiline,
  errors,
  register,
  placeholder,
  onKeyUp,
  errorMessage,
  validationSchema,
  rows,
  value,
  autoFocus
}: IFormTextField) {
  const classes = useStyles();
  const cssGlobalClass = useGlobalStyles();

  const [emailError, setEmailError] = useState(false);
  const [mobileError, setMobileError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const onShowPasswordClicked = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  let inputProps = {};

  if (type === 'number') {
    inputProps = { inputMode: 'numeric', min: 0 };
  }

  if (type === 'email') {
    inputProps = { inputMode: 'email' };
  }

  if (type === 'tel') {
    inputProps = { inputMode: 'tel' };
  }

  return (
    control ?
      <Controller
        name={name}
        control={control}

        //ToDo: check if this property even exists
        //@ts-ignore
        required={required}

        render={({
          field: { value, onChange },
          fieldState: { error },
          formState
        }) =>
          light ? (
            <FormControl
              fullWidth={fullWidth}
              required={required}
              disabled={disabled}
              error={emailError || mobileError || passwordError}
              className={classes.labelLight}
              sx={sx}
            >
              <InputLabel
                className={cssGlobalClass.customInputLabel}
              >
                {label}
              </InputLabel>
              <Input
                value={value || ''}
                onChange={onChange}
                type={type == 'mobile' ? 'tel' : showPassword ? 'text' : type}
                readOnly={readOnly}
                inputProps={{
                  autoComplete: 'new-password',
                  ...inputProps,
                }}
                autoFocus={autoFocus}
                className={cssGlobalClass.customInput}
                endAdornment={
                  type === 'password' && (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={onShowPasswordClicked}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }
              />
              <FormHelperText
                className={cssGlobalClass.customHelperText}
              >
                {type === 'email'
                  ? testEmailString(value, setEmailError, func)
                  : type === 'mobile'
                    ? testMobileString(value, setMobileError, func)
                    : type === 'password'
                      ? testPasswordString(value, setPasswordError, func)
                      : ''}
              </FormHelperText>
            </FormControl>
          ) : (
            <TextField
              fullWidth={fullWidth}
              label={label}
              placeholder={placeholder}
              onChange={onChange}
              value={value || ''}
              required={required}
              autoFocus={autoFocus}
              multiline={multiline}
              rows={rows}
              onKeyUp={onKeyUp}
              name={name}
              disabled={disabled}
              type={type == 'mobile' ? 'tel' : showPassword ? 'text' : type}
              autoComplete="off"
              InputProps={{ ...inputProps, readOnly: readOnly }}
              error={emailError || mobileError || passwordError}
              sx={sx}
              helperText={
                type === 'email'
                  ? testEmailString(value, setEmailError, func)
                  : type === 'mobile'
                    ? testMobileString(value, setMobileError, func)
                    : type === 'password'
                      ? testPasswordString(value, setPasswordError, func)
                      : ''
              }
            />
          )
        }
      />
      :
      <>
        {register && <TextField
          fullWidth={fullWidth}
          label={label}
          value={value}
          name={name}
          placeholder={placeholder}
          required={required}
          onKeyUp={onKeyUp}
          autoFocus={autoFocus}
          multiline={multiline}
          rows={rows}
          {...register(name, validationSchema)}
          disabled={disabled}
          type={type == 'mobile' ? 'tel' : showPassword ? 'text' : type}

          autoComplete="off"
          //error={emailError || mobileError || passwordError}
          sx={sx}
          error={errors && errors[name]}
          //@ts-ignore
          helperText={((errors && errors[name]?.type === "required") ? errors[name]?.message : "")}
        />}
      </>
  );
}
