import React, { useContext, useState } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { chooseActivityType, getDate, getPerson } from '../../../../../helpers/database';
import { AdminCheckContext } from '../../AdminCheckContext';
import { useStyles } from './styles';
import { useGlobalStyles } from '../../../../../customStyles';

export default function ActivityTable() {
  const classes = useStyles();
  const cssClass = useGlobalStyles();

  const {
    activityList,
    chosenActivity,
    setChosenActivity,
    setChosenComment,
    handleAdminCheckModalOpen,
  } = useContext(AdminCheckContext);

  const handleRowClick = (row) => {
    setChosenComment(null);
    setChosenActivity(row);
    handleAdminCheckModalOpen();
  };

  return (
    <TableContainer>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
          <TableCell className={cssClass.dateCell}>Erstellt</TableCell>
            <TableCell className={cssClass.mediumCell}>Benutzer</TableCell>
            <TableCell className={cssClass.mediumCell}>Firma</TableCell>
            <TableCell>Aktivität</TableCell>
            <TableCell className={cssClass.dateCell}>Freigabe</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {activityList?.map((row) => (
              <TableRow
                key={row.ID}
                selected={chosenActivity?.ID == row.ID}
                onClick={() => handleRowClick(row)}
              >
                <TableCell>{getDate(row.Creation)}</TableCell>
                <TableCell>{getPerson(row.User)}</TableCell>
                <TableCell>{row.Company.Name}</TableCell>
                <TableCell>{chooseActivityType(row.Type)}</TableCell>
                <TableCell>{getDate(row.Approval)}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}