import React, { useState, useContext, useEffect } from 'react';
import { useWatch, useFormContext } from 'react-hook-form';
import { Messages } from '../../../../../constants/messages';
import Button from '@mui/material/Button';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Box,
  IconButton,
} from '@mui/material';

import { Edit, Close } from '@mui/icons-material/';

import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import FormSelect from '../../../../common-components/FormSelect';
import { ContactPersonContext } from '../../PersonContext';
import { useStyles } from './styles';
import { useGlobalStyles } from '../../../../../customStyles';

import FormDatePicker from '../../../../common-components/FormDatePicker';
import BoxCenter from '../../../../common-components/BoxCenter';
import FormTextField from '../../../../common-components/FormTextField';
import FormBooleanSelect from '../../../../common-components/FormBooleanSelect';
import { swvTheme } from '../../../../../swvTheme';
import { useSnackbarHelpers } from '../../../../../helpers/utils';
import DataItemActionsPopup from '../../../DataItemActions';
import CustomButton from '../../../../common-components/CustomButton';
import { ImageUploadPopup } from '../../../ImageUploadPopup';

export const CommunicationDataDetailsPerson = ({
  updateCommunicationDataPerson,
  insertCommunicationDataPerson,
}) => {
  const classes = useStyles();
  const cssClass = useGlobalStyles();
  const [isEditMode, setIsEditMode] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const { showErrorSnackbar } = useSnackbarHelpers();

  const { chosenContactPerson, showRow } = useContext(ContactPersonContext);
  const { control, setValue } = useFormContext();
  const values = useWatch({ control });

  const [emailError, setEmailError] = useState(false);
  const [emailEventError, setEmailEventError] = useState(false);
  const [emailFinancialError, setEmailFiancialError] = useState(false);
  const [emailNewsletterError, setEmailNewsletterError] = useState(false);

  const [isScanCardPopupOpen, setIsScanCardPopupOpen] = useState(false);

  const [isDataItemActionsPopupOpen, setIsDataItemActionsPopupOpen] = useState(false);
  const [chosenDataItem, setChosenDataItem] = useState(null);

  const disabled = emailError ||
    emailEventError ||
    emailFinancialError ||
    emailNewsletterError;

  const onScanCardModalOpen = async () => {
    setIsScanCardPopupOpen(true);
  };

  const onScanCardModalClose = async () => {
    setIsScanCardPopupOpen(false);
  };

  const onSaveClicked = async () => {
    if (values.CommunicationDataIDPerson)
      await updateCommunicationDataPerson(resetWarning)
    else
      await insertCommunicationDataPerson(resetWarning);
  };

  const createData = (name, value, renderKey) => {
    return { name, value, renderKey };
  };

  const mapBoolean = (boolean) => {
    if (boolean) {
      return 'Ja';
    } else {
      return 'Nein';
    }
  }

  const tableRows = [
    createData('Telefon:', values?.TelephonePerson, 'TelephonePerson'),
    createData('Telefon (privat):', values?.TelephonePrivatePerson, 'TelephonePrivatePerson'),
    createData('Mobil:', values?.MobilePerson, 'MobilePerson'),
    createData('Mobil (privat):', values?.MobilePrivatePerson, 'MobilePrivatePerson'),
    createData('E-Mail:', values?.EmailPerson, 'EmailPerson'),
    createData('E-Mail (Veranstaltungen):', values?.EmailEventPerson, 'EmailEventPerson'),
    createData('E-Mail (Finanzen):', values?.EmailFinancialPerson, 'EmailFinancialPerson'),
    createData('E-Mail (Newsletter):', values?.EmailNewsletterPerson, 'EmailNewsletterPerson'),
    createData('Homepage:', values?.HomepagePerson, 'HomepagePerson'),
    createData('Fax:', values?.FaxPerson, 'FaxPerson'),
    createData('WhatsApp:', values?.WhatsAppPerson, 'WhatsAppPerson'),
    createData('Telegram:', values?.TelegramPerson, 'TelegramPerson'),
    createData('Messenger:', values?.MessengerPerson, 'MessengerPerson'),
    createData('Signal:', values?.SignalPerson, 'SignalPerson'),
    createData('Xing:', values?.XingPerson, 'XingPerson'),
    createData('LinkedIn:', values?.LinkedInPerson, 'LinkedInPerson'),
    createData('Facebook:', values?.FacebookPerson, 'FacebookPerson'),
    createData('Instagram:', values?.InstagramPerson, 'InstagramPerson'),
    createData('Twitter:', values?.TwitterPerson, 'TwitterPerson'),
    createData('TikTok:', values?.TikTokPerson, 'TikTokPerson')
  ];

  useEffect(() => {
    if (isEditMode) {
      values.isModified = true;
    }
  }, [values]);

  useEffect(() => {
    let delay;

    document.querySelectorAll('.personCommunicationItem').forEach((item) => {
      const isNotEmpty = item.getElementsByTagName("td")[1].innerText;

      if (isNotEmpty) {
        item.addEventListener('touchstart', () => {
          delay = setTimeout(check, 1500);

          function check() {
            setIsDataItemActionsPopupOpen(true);
          }
        }, true);

        item.addEventListener('touchend', () => {
          clearTimeout(delay);
        });
      }
    });
  });

  const resetWarning = () => {
    values.isModified = false;
    setShowWarning(false);
    setIsEditMode(false);
  }

  const onDataItemActionsPopupClose = () => {
    setIsDataItemActionsPopupOpen(false);
  };

  return (
    <Box >
    <ImageUploadPopup
      isOpen={isScanCardPopupOpen}
      onSave={(data) => {
        console.log('Scanned business card data:', data);

        for(let i = 0; i < data.email.length; i++){
          if(typeof data.email[i] === 'string'){
            setValue(`EmailPerson`, data.email[i]);
          } else {
            setValue(data.email[i].type, data.email[i].value);
          }
        }

        for(let i = 0; i < data.telephone.length; i++){
          if(typeof data.telephone[i] === 'string'){
            setValue(`TelephonePerson`, data.telephone[i]);
          } else {
            setValue(data.telephone[i].type, data.telephone[i].value);
          }
        }



        onScanCardModalClose();
      }}
      onClose={onScanCardModalClose}
      isScanMode
      />
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: 2,
        }}
      >
        <Typography
          color="#732382"
          fontSize={18}
          fontWeight={500}
          py={2}
        >
          Kontaktdaten
        </Typography>

        {showWarning &&
          <Typography color="primary">{ Messages.WarningUnsafed }</Typography>}

        {isEditMode ? (
          <IconButton aria-label="close" onClick={() =>
            { if (values?.isModified)
                setShowWarning(true);
              setIsEditMode(false)
            } }>
            <Close />
          </IconButton>
        ) : (
          <IconButton
            aria-label="edit"
            color="primary"
            onClick={() => setIsEditMode(!isEditMode)}
          >
            <Edit />
          </IconButton>
        )}
      </Box>

      {isEditMode ? (
        <>
          <Table className={cssClass.twoColumnDataTable}>
            <TableBody className={cssClass.customTableBody}>
              <TableRow>
                <TableCell className={cssClass.shrinkCell}>Telefon</TableCell>
                <TableCell>
                  <FormTextField
                    name="TelephonePerson"
                    label="Telefon"
                    control={control}
                    type="tel"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={cssClass.shrinkCell}>Telefon (privat)</TableCell>
                <TableCell>
                  <FormTextField
                    name="TelephonePrivatePerson"
                    label="Telefon (privat)"
                    control={control}
                    type="tel"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={cssClass.shrinkCell}>Mobil</TableCell>
                <TableCell>
                  <FormTextField
                    name="MobilePerson"
                    label="Mobil"
                    control={control}
                    type="tel"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={cssClass.shrinkCell}>Mobil (privat)</TableCell>
                <TableCell>
                  <FormTextField
                    name="MobilePrivatePerson"
                    label="Mobil (privat)"
                    control={control}
                    type="tel"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={cssClass.shrinkCell}>E-Mail</TableCell>
                <TableCell>
                  <FormTextField
                    name="EmailPerson"
                    label="E-Mail"
                    control={control}
                    type="email"
                    func={setEmailError}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={cssClass.shrinkCell}>E-Mail (Veranstaltungen)</TableCell>
                <TableCell>
                  <FormTextField
                    name="EmailEventPerson"
                    label="E-Mail (Veranstaltungen)"
                    control={control}
                    type="email"
                    func={setEmailEventError}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={cssClass.shrinkCell}>E-Mail (Finanzen)</TableCell>
                <TableCell>
                  <FormTextField
                    name="EmailFinancialPerson"
                    label="E-Mail (Finanzen)"
                    control={control}
                    type="email"
                    func={setEmailFiancialError}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={cssClass.shrinkCell}>E-Mail (Newsletter)</TableCell>
                <TableCell>
                  <FormTextField
                    name="EmailNewsletterPerson"
                    label="E-Mail (Newsletter)"
                    control={control}
                    type="email"
                    func={setEmailNewsletterError}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={cssClass.shrinkCell}>Homepage</TableCell>
                <TableCell>
                  <FormTextField
                    name="HomepagePerson"
                    label="Homepage"
                    control={control}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={cssClass.shrinkCell}>Fax</TableCell>
                <TableCell>
                  <FormTextField
                    name="FaxPerson"
                    label="Fax"
                    control={control}
                    type="tel"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={cssClass.shrinkCell}>WhatsApp</TableCell>
                <TableCell>
                  <FormTextField
                    name="WhatsAppPerson"
                    label="WhatsApp"
                    control={control}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={cssClass.shrinkCell}>Telegram</TableCell>
                <TableCell>
                  <FormTextField
                    name="TelegramPerson"
                    label="Telegram"
                    control={control}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={cssClass.shrinkCell}>Messenger</TableCell>
                <TableCell>
                  <FormTextField
                    name="MessengerPerson"
                    label="Messenger"
                    control={control}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={cssClass.shrinkCell}>Signal</TableCell>
                <TableCell>
                  <FormTextField
                    name="SignalPerson"
                    label="Signal"
                    control={control}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={cssClass.shrinkCell}>Xing</TableCell>
                <TableCell>
                  <FormTextField
                    name="XingPerson"
                    label="Xing"
                    control={control}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={cssClass.shrinkCell}>LinkedIn</TableCell>
                <TableCell>
                  <FormTextField
                    name="LinkedInPerson"
                    label="LinkedIn"
                    control={control}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={cssClass.shrinkCell}>Facebook</TableCell>
                <TableCell>
                  <FormTextField
                    name="FacebookPerson"
                    label="Facebook"
                    control={control}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={cssClass.shrinkCell}>Instagram</TableCell>
                <TableCell>
                  <FormTextField
                    name="InstagramPerson"
                    label="Instagram"
                    control={control}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={cssClass.shrinkCell}>Twitter</TableCell>
                <TableCell>
                  <FormTextField
                    name="TwitterPerson"
                    label="Twitter"
                    control={control}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={cssClass.shrinkCell}>Twitter</TableCell>
                <TableCell>
                  <FormTextField
                    name="TikTokPerson"
                    label="TikTok"
                    control={control}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <BoxCenter sx={{ pb: '25px' }}>
            <CustomButton
              value="Verwerfen"
              onClick={() => {
                setIsEditMode(false);
              }}
              sx={{ width: '180px' }}
              light
              isClose
            />
            <CustomButton
              value="Scannen"
              onClick={() => onScanCardModalOpen()}
              sx={{ width: '180px' }}
            />
            <CustomButton
              disabled={disabled}
              value="Speichern"
              onClick={() => onSaveClicked()}
              sx={{ width: '180px' }}
            />
          </BoxCenter>
        </>
      ) : (
        <Table>
          <TableBody>
            {tableRows?.map((row) => (showRow(row) &&
              <TableRow key={row.renderKey} className='personCommunicationItem' onTouchStart={() => setChosenDataItem(row)}>
                <TableCell>
                  {row.name}
                </TableCell>
                <TableCell align="left">{row.value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}

      <DataItemActionsPopup
        isOpen={isDataItemActionsPopupOpen}
        onClose={onDataItemActionsPopupClose}
        chosenDataItem={chosenDataItem}
      />
    </LocalizationProvider>
     </Box>
  );
};