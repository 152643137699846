import React, { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useGetListBySubcategoryMutation } from '../../../redux/services/CompanyService'
import {
  Box,
  Button,
  Container,
  IconButton,
  Grid,
  Input,
  InputLabel,
  FormControl,
  InputAdornment,
  Typography,
  Popper,
  Fade,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  TextField,
  Pagination,
  Collapse,
  useMediaQuery,
  Tooltip
} from '@mui/material';
import {
  Close,
  Clear,
  Search,
  FilterAltOutlined,
  ArrowForward,
  KeyboardArrowDownOutlined,
  KeyboardArrowUpOutlined,
  AssistantOutlined
} from '@mui/icons-material';

import FormLink from '../../common-components/FormLink';
import QuickActionButtons from '../../common-components/QuickActionButtons';
import ActivityHistory from '../../common-components/ActivityHistory';
import GoalList from '../../common-components/GoalList';
import DataCard from '../../common-components/DataCard';
import CustomButton from '../../common-components/CustomButton';
import BusinessCard from '../../common-components/Icons/BusinessCard';
import { Download } from '@mui/icons-material/';

import { useDebounce } from '../../../helpers';
import Navbar from '../Navbar';
import * as CompanyService from '../../../services/requests/company';
import * as PersonService from '../../../services/requests/person';
import { goTo } from '../../../router/routerHistory';

import {
  getAddressRegistration,
  getBoolean,
  getBusinessRegistrationListOverview,
  getDate,
  getPhotoUrl,
} from '../../../helpers/database';

import { informationPopup, showInfoSnackbar, useSnackbarHelpers } from '../../../helpers/utils';

import { Messages } from '../../../constants/messages';
import { swvTheme } from '../../../swvTheme';
import { useStyles } from './styles';
import { useGlobalStyles } from '../../../customStyles';
import BoxCenter from '../../common-components/BoxCenter';
import SearchFilterPopUp from '../../popups/SearchFilter';
import NoResultsIcon from '../../common-components/NoResultsIcon/NoResultsIcon';
import FavoritePopUp from '../../popups/Favorite';
import Printer from '../../common-components/Icons/Printer';
import Document from '../../common-components/Icons/Document';
import { ImageUploadPopup } from '../../popups/ImageUploadPopup';
import PersonPopUp from '../../popups/Person';
import FormTextField from '../../common-components/FormTextField';
import BusinessSubcategoryPopUp from '../../popups/BusinessSubcategory';
import BusinessSubcategoryAssistantPopup from '../../popups/BusinessSubcategoryAssistant/BusinessSubcategoryAssistant';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CompanyDataCard from '../../common-components/CompanyDataCard';
import dayjs from 'dayjs';
import * as CommunicationDataService from '../../../services/requests/communicationData';


export default function Dashboard() {
  const cssClass = useGlobalStyles();
  const FK_Language = 40;
  const { showErrorSnackbar, showInfoSnackbar } = useSnackbarHelpers();
  const [historyList, setHistoryList] = useState(null);

  const paginationStyles = {
    ul: {
      '& .MuiPaginationItem-root': {
        '&.Mui-selected': {
          backgroundColor: swvTheme.typography.lilac,
          color: 'white',
        },
      },
    },
  }

  const { control, setValue } = useForm({
    defaultValues: {
      searchedSubcategories: '',
    },
  });

  const handlePersonScanCard = async (data) => {
    const newData = {};

    Object.entries(data).forEach(([key, value]) => {
      const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
      if (Array.isArray(value)) {
        newData[capitalizedKey] = (key === 'email' || key === 'telephone') && value[0].value === '' ? '' : value[0].value;
      } else {
        newData[capitalizedKey] = value[0];
      }
    });
    newData.FK_Language = FK_Language;
    const response = await PersonService.insertOne(newData);
    const dataWithPerson = { ...newData, PersonId: response.data.ID }
    const result = await CommunicationDataService.insertOne(dataWithPerson);
    if (result.isSuccess) {
      showInfoSnackbar(Messages.Success)
      CompanyService.getHistoryList(null, setHistoryList);
    } else {
      showErrorSnackbar(Messages.Error)
    }
  }

  const subcategoryValue = useWatch({ control });

  const [getCompanyListBySubcategory] = useGetListBySubcategoryMutation()

  const isMobile = useMediaQuery("(max-width: 850px)")

  const [searchText, setSearchText] = useState('');
  const [lastSearchedText, setLastSearchedText] = useState('');
  const [lastSearchedSubcategories, setLastSearchedSubcategories] = useState('');
  const [filterPopupAnchorEl, setFilterPopupAnchorEl] = useState(null);
  const [isSearchClicked, setIsSearchClicked] = useState(false);

  const [listsLoading, setListsLoading] = useState({ companyList: false, personList: false, contactPersonList: false, addressList: false });

  const [listsOpened, setListsOpened] = useState({ address: false, company: false, contactPerson: false, person: false });

  const [searchInputError, setSearchInputError] = useState(false);

  const [companyResultCount, setCompanyResultCount] = useState(0);
  const [contactPersonResultCount, setContactPersonResultCount] = useState(0);
  const [personResultCount, setPersonResultCount] = useState(0);
  const [addressResultCount, setAddressResultCount] = useState(0);

  const [maxSpecials, setMaxSpecials] = useState(0);

  const [companyChecked, setCompanyChecked] = useState(true);
  const [personChecked, setPersonChecked] = useState(true);
  const [addressChecked, setAddressChecked] = useState(JSON.parse(localStorage.getItem('addressChecked')));
  const [contactPersonChecked, setContactPersonChecked] = useState(true);

  const [memberChecked, setMemberChecked] = useState(true);
  const [familyChecked, setFamilyChecked] = useState(true);

  const [noCompanies, setNoCompanies] = useState(null);
  const [noPersons, setNoPersons] = useState(null);
  const [noAddresses, setNoAddresses] = useState(null);
  const [noContactPersons, setNoContactPersons] = useState(null);
  const [noSpecials, setNoSpecials] = useState(null);

  const [isSearchFilterModalOpen, setIsSearchFilterModalOpen] = useState(false);
  const [isFavoriteModalOpen, setIsFavoriteModalOpen] = useState(false);
  const [isScanCardPopupOpen, setIsScanCardPopupOpen] = useState(false);
  const [isBusinessSubcategoryAssistantOpen, setIsBusinessSubcategoryAssistantOpen] = useState(false)

  const [companyList, setCompanyList] = useState([]);
  const [personList, setPersonList] = useState([]);
  const [addressList, setAddressList] = useState([]);
  const [contactPersonList, setContactPersonList] = useState([]);
  const [specialList, setSpecialList] = useState([]);

  const [debouncedSearch, setDebouncedSearch] = useDebounce(searchText, 1000);
  const [isSpecialClicked, setIsSpecialClicked] = useState(false);

  const [isPersonModalOpen, setIsPersonModalOpen] = useState(false);
  const [chosenPerson, setChosenPerson] = useState(false);

  const [companyPage, setCompanyPage] = useState(0);
  const [personPage, setPersonPage] = useState(0);
  const [contactPersonPage, setContactPersonPage] = useState(0);
  const [addressPage, setAddressPage] = useState(0);

  const pageSize = 100;

  const searchQuery = localStorage.getItem('searchQuery');

  useEffect(() => {
    const companyChecked = JSON.parse(localStorage.getItem('companyChecked'));
    const personChecked = JSON.parse(localStorage.getItem('personChecked'));
    const contactPersonChecked = JSON.parse(localStorage.getItem('contactPersonChecked'));
    const addressChecked = JSON.parse(localStorage.getItem('addressChecked'));

    const memberChecked = JSON.parse(localStorage.getItem('memberChecked'));
    const familyChecked = JSON.parse(localStorage.getItem('familyChecked'));

    if (companyChecked !== null) {
      setCompanyChecked(companyChecked);
    }

    if (personChecked !== null) {
      setPersonChecked(personChecked);
    }

    if (contactPersonChecked !== null) {
      setContactPersonChecked(contactPersonChecked);
    }

    if (addressChecked !== null) {
      setAddressChecked(addressChecked);
    }

    if (memberChecked !== null) {
      setMemberChecked(memberChecked);
    }

    if (familyChecked !== null) {
      setFamilyChecked(familyChecked);
    }
  });

  useEffect(() => {
    if (searchQuery) {
      setSearchText(searchQuery);
    }
  }, [searchQuery]);

  useEffect(() => {
    if (searchText?.length > 2) {
      setDebouncedSearch(searchText);
    } else {
      setDebouncedSearch(null);
      setCompanyList([]);
      setPersonList([]);
      setAddressList([]);
      setContactPersonList([]);
      setSpecialList([]);
    }

    //moved setting local storage item as this caused text to flicker
    //localStorage.setItem('searchQuery', searchText);
  }, [searchText]);

  const handleCompanyData = (data, fromSearch) => {
    if (data?.resultList?.length) {
      setCompanyList(data.resultList);
      if (fromSearch) {
        setCompanyResultCount(data.totalResultCount)
      }
    } else {
      setNoCompanies(true);
    }
    setListsLoading((old) => ({ ...old, companyList: false }))
  }

  const getCompanyList = async (isEnterClicked, fromSearch, page) => {
    if (fromSearch) {
      setListsLoading((old) => ({ ...old, companyList: true }))
    }

    if (subcategoryValue && subcategoryValue.searchedSubcategories.length > 0) {
      const data = await getCompanyListBySubcategory({ searchText: searchText, subcategoryText: subcategoryValue?.searchedSubcategories, page: page, pageSize: pageSize }).unwrap();
      handleCompanyData(data, fromSearch)
    } else {
      await CompanyService.getList(
        {
          mode: 'Company',
          text: isEnterClicked ? searchText : debouncedSearch || '',
          fromSearch: fromSearch,
          page: page ?? 0,
          pageSize: pageSize,
          IsFamily: familyChecked,
          IsMember: memberChecked,
        }, (data) => handleCompanyData(data, fromSearch)
      )
    }

  };

  const getPersonList = async (isEnterClicked, fromSearch, page) => {
    if (searchText.trim().length > 0) {
      if (fromSearch) {
        setListsLoading((old) => ({ ...old, personList: true }))
      }

      await PersonService.getList(
        {
          text: isEnterClicked ? searchText : debouncedSearch || '',
          page: page ?? 0,
          pageSize: pageSize,
          IsFamily: familyChecked,
          IsMember: memberChecked,
        },
        (data) => {

          if (data?.resultList?.length) {
            setPersonList(data.resultList);
            setPersonResultCount(data.totalResultCount);

          } else {
            setNoPersons(true);
          }
          setListsLoading((old) => ({ ...old, personList: false }));
        },
      );
    }
  };

  const getAddressList = async (isEnterClicked, fromSearch, page) => {
    if (searchText.trim().length > 0) {
      if (fromSearch) {
        setListsLoading((old) => ({ ...old, addressList: true }));
      }
      await CompanyService.getList(
        {
          mode: 'Address',
          text: isEnterClicked ? searchText : debouncedSearch || '',
          fromSearch: fromSearch,
          page: page ?? 0,
          pageSize: pageSize,
          IsFamily: familyChecked,
          IsMember: memberChecked,
        },
        (data) => {
          if (data?.resultList?.length) {
            setAddressList(data.resultList);
            if (fromSearch) {
              setAddressResultCount(data.totalResultCount)
            }
          } else {
            setNoAddresses(true);
          }
          setListsLoading((old) => ({ ...old, addressList: false }));
        },
      );
    }
  };

  const getContactPersonList = async (isEnterClicked, fromSearch, page) => {
    if (searchText.trim().length > 0) {
      if (fromSearch) {
        setListsLoading((old) => ({ ...old, contactPersonList: true }))
      }
      await CompanyService.getList(
        {
          mode: 'Person',
          text: isEnterClicked ? searchText : debouncedSearch || '',
          fromSearch: fromSearch,
          page: page ?? 0,
          pageSize: pageSize,
          IsFamily: familyChecked,
          IsMember: memberChecked,
        },
        (data) => {

          if (data?.resultList?.length) {
            setContactPersonList(data.resultList);
            if (fromSearch) {
              setContactPersonResultCount(data.totalResultCount);
            }
          } else {
            setNoContactPersons(true);
          }
          setListsLoading((old) => ({ ...old, contactPersonList: false }))
        },
      );
    }
  };

  const onCompanyChecked = (event) => {
    const checked = event.target.checked;

    setCompanyChecked(checked);
    localStorage.setItem('companyChecked', checked);
  };

  const onPersonChecked = (event) => {
    const checked = event.target.checked;

    setPersonChecked(checked);
    localStorage.setItem('personChecked', checked);
  };

  const onAddressChecked = (event) => {
    const checked = event.target.checked;

    setAddressChecked(checked);
    localStorage.setItem('addressChecked', checked);
  };

  const onContactPersonChecked = (event) => {
    const checked = event.target.checked;

    setContactPersonChecked(checked);
    localStorage.setItem('contactPersonChecked', checked);
  };

  const onMemberChecked = (event) => {
    const checked = event.target.checked;

    setMemberChecked(checked);
    localStorage.setItem('memberChecked', checked);
  };

  const onFamilyChecked = (event) => {
    const checked = event.target.checked;

    setFamilyChecked(checked);
    localStorage.setItem('familyChecked', checked);
  };

  const onClearClick = () => {
    setSearchText('');
    setIsSearchClicked(false);
  };

  const onSearchClicked = async (isEnterClicked = false) => {
    localStorage.setItem('searchQuery', searchText);
    setLastSearchedText(searchText)
    setLastSearchedSubcategories(subcategoryValue.searchedSubcategories)
    setContactPersonResultCount(0);
    setCompanyResultCount(0);
    setPersonResultCount(0);
    setAddressResultCount(0);

    setAddressPage(0);
    setCompanyPage(0);
    setContactPersonPage(0);
    setPersonPage(0);

    setListsOpened({ company: false, address: false, contactPerson: false, person: false });

    setIsSpecialClicked(false);
    setFilterPopupAnchorEl(null);

    setCompanyList([]);
    setPersonList([]);
    setAddressList([]);
    setContactPersonList([]);
    setSpecialList([]);

    setNoCompanies(null);
    setNoPersons(null);
    setNoAddresses(null);
    setNoContactPersons(null);
    setNoSpecials(null);

    setSearchInputError(false);

    if (!companyChecked && !personChecked && !addressChecked && !contactPersonChecked) {
      await informationPopup(Messages.ErrorSearchMissingSelection);
    }

    if (companyChecked) getCompanyList(isEnterClicked, true);
    if (personChecked) getPersonList(isEnterClicked, true);
    if (addressChecked) getAddressList(isEnterClicked, true);
    if (contactPersonChecked) getContactPersonList(isEnterClicked, true);

    setIsSearchClicked(true);
  };

  const onCompanyExportClicked = async () => {
    let params = {
      mode: 'Company',
      text: lastSearchedText
    }

    if (lastSearchedSubcategories.length > 0) {
      params.subcategoryText = lastSearchedSubcategories
    }

    const result = await CompanyService.getExportList(params);

    createLinkAndDownloadFile(result, "Firmenexport_" + dayjs().format("DDMMYYYY-HHmm") + ".csv");
  };

  const onContactPersonExportClicked = async () => {
    const result = await CompanyService.getExportList({ mode: 'Person', text: lastSearchedText });

    createLinkAndDownloadFile(result, "Kontaktpersonenexport_" + dayjs().format("DDMMYYYY-HHmm") + ".csv");
  }

  const onAddressExportClicked = async () => {
    const result = await CompanyService.getExportList({ mode: 'Address', text: lastSearchedText });

    createLinkAndDownloadFile(result, "Adressenexport_" + dayjs().format("DDMMYYYY-HHmm") + ".csv");
  }

  const onPersonExportClicked = async () => {
    const result = await PersonService.getSearchExport({ text: lastSearchedText });

    createLinkAndDownloadFile(result, "Personenexport_" + dayjs().format("DDMMYYYY-HHmm") + ".csv");
  }

  // Search filter popup
  const handleFilterClick = (event) => {
    onSearchFilterModalOpen();
    // setFilterPopupAnchorEl(filterPopupAnchorEl ? null : event.currentTarget);
  };

  const handleAssistantClick = (event) => {
    setIsBusinessSubcategoryAssistantOpen(true)
  }

  const open = Boolean(filterPopupAnchorEl);
  const id = open ? 'bottom-end' : undefined;

  // SearchFilter

  const onSearchFilterModalOpen = () => {
    setFilterPopupAnchorEl(null);
    onClearClick();
    setIsSearchFilterModalOpen(true);
  };

  const onSearchFilterModalApply = (data) => {
    setIsSpecialClicked(true);
    setIsSearchFilterModalOpen(false);
    setMaxSpecials(data.length);
    setSpecialList(data);
    setNoSpecials(data.length);
  };

  const onBusinessSubcategoryAssistantApply = (appliedBusinessSubcategories) => {
    setIsBusinessSubcategoryAssistantOpen(false)
    let subcategoryText = '';

    appliedBusinessSubcategories.map((subcategory) => {
      subcategoryText = subcategoryText.concat(subcategory.County, subcategory.Number, ' ')
    })

    setValue('searchedSubcategories', subcategoryText.trim())
  }

  const onSearchFilterModalClose = () => {
    setIsSearchFilterModalOpen(false);
  };

  const onBusinessSubcategoryAssistantClose = () => {
    setIsBusinessSubcategoryAssistantOpen(false)
  }

  const navigate = useNavigate();

  const enterPressed = (event) => {
    const code = event.keyCode || event.which;
    const { value } = event.target;

    if (code === 13) {
      event.preventDefault();
      //ensure input is atleast 3 characters long that arent only whitespaces or subcategory value with atleast 3 characters has been entered
      if (value?.trim().length > 2 || subcategoryValue.searchedSubcategories.trim().length > 2) {
        setContactPersonResultCount(0);
        setCompanyResultCount(0);
        setPersonResultCount(0);
        setAddressResultCount(0);

        setCompanyPage(0);
        setAddressPage(0);
        setContactPersonPage(0);
        setPersonPage(0);

        setSearchInputError(false);

        //if(event.target.name === 'Suche')
        //setSearchText(value);
        onSearchClicked(true);
        localStorage.setItem('searchQuery', searchText);
        setLastSearchedText(searchText);
        setLastSearchedSubcategories(subcategoryValue.searchedSubcategories);
      } else {
        setSearchInputError(true);
      }
    }
  };

  const handleCompanyPageChange = (event, page) => {
    setCompanyPage(page - 1);
    getCompanyList(true, false, page - 1)
  }

  const handleContactPersonPageChange = (event, page) => {
    setContactPersonPage(page - 1);
    getContactPersonList(true, false, page - 1)
  }

  const handleAddressPageChange = (event, page) => {
    setAddressPage(page - 1)
    getAddressList(true, false, page - 1)

  }

  const handlePersonPageChange = (event, page) => {
    setPersonPage(page - 1)
    getPersonList(true, false, page - 1)
  }

  // Favorites modal
  const onFavoriteClicked = () => {
    setIsFavoriteModalOpen(true);
  };

  const onFavoriteModalClose = () => {
    setIsFavoriteModalOpen(false);
  };

  // Scan Card modal
  const onScanCardModalOpen = async () => {
    setIsScanCardPopupOpen(true);
  };

  const onScanCardModalClose = async () => {
    setIsScanCardPopupOpen(false);
  };

  // Person
  const onPersonClicked = async (row) => {
    setChosenPerson(null);
    await getPerson(row.ID);
    setIsPersonModalOpen(true);
  };

  const onPersonModalClose = async () => {
    setIsPersonModalOpen(false);
  };

  function createLinkAndDownloadFile(result, filename = 'Datenexport.csv') {
    if (result.isSuccess) {
      const link = document.createElement('a');
      const BOM = "\uFEFF";
      link.href = `data:text/csv;charset=utf-8,${encodeURIComponent(BOM + result.data)}`;
      link.download = filename;
      link.click();
    }
  }

  async function getPerson(id) {
    await PersonService.getOne(id, setChosenPerson);
  }

  return (
    <>
      {/*<QuickActionButtons />*/}
      <Container maxWidth='xl' sx={{ paddingBottom: 1 }}>
        <Typography pt={6} mb={3} variant='h1'>
          Willkommen bei DaVe
        </Typography>
        <Box
          display='flex'
          gap={4}
          sx={{
            [swvTheme.breakpoints.down('md')]: {
              flexDirection: 'column',
            },
          }}
        >
          {/* Beginn Suche */}
          <Box
            width='68%'
            sx={{
              [swvTheme.breakpoints.down('md')]: {
                width: 'auto',
              },
            }}
          >
            <Box display='flex' alignItems='center' gap={1}>
              <Box width='100%'>
                <FormControl>
                  <Box display='flex' justifyContent='space-between'>
                    <InputLabel
                      shrink
                      className={cssClass.customInputLabel}
                      sx={{ color: '#732382 !important' }}
                    >
                      Suche
                    </InputLabel>
                    <FormLink
                      sx={{ textDecoration: 'underline', color: '#732382', pr: 5 }}
                      onClick={onFavoriteClicked}
                    >
                      Favoriten
                    </FormLink>
                  </Box>
                  <Box display='flex' justifyContent='space-between'>
                    <Input
                      value={searchText}
                      onChange={(e) => {
                        const { value } = e.target;
                        setSearchText(value || '');
                      }}
                      name='Suche'
                      onKeyUp={(e) => enterPressed(e)}
                      endAdornment={
                        <>
                          <InputAdornment>
                            <IconButton
                              onClick={onClearClick}
                              sx={{
                                visibility: searchText ? 'visible' : 'hidden',
                                marginRight: '38px',
                              }}
                            >
                              <Clear />
                            </IconButton>
                          </InputAdornment>
                          <InputAdornment>
                            <IconButton
                              id='search-button'
                              onClick={onSearchClicked}
                              disabled={(!searchText || searchText.trim().length < 3) && subcategoryValue.searchedSubcategories.trim().length < 3}
                              sx={{
                                backgroundColor: '#732382',
                                borderRadius: 'unset',
                                height: '100%',
                                position: 'absolute',
                                right: '0',
                                width: '44px',
                                '&:hover': {
                                  backgroundColor: '#732382',
                                },
                                '&.Mui-disabled': {
                                  backgroundColor: '#732382',
                                  pointerEvents: 'initial',
                                  opacity: '0.5',
                                },
                              }}
                            >
                              <Search sx={{ color: '#FFFFFF' }} />
                            </IconButton>
                          </InputAdornment>
                        </>
                      }
                      className={cssClass.customInput}
                      borderColor='#3E004D'
                      sx={{ maxHeight: '46px', width: '100%' }}
                    />
                    <IconButton aria-describedby={id} onClick={handleFilterClick}>
                      <FilterAltOutlined sx={{ color: '#3E004D' }} />
                    </IconButton>
                  </Box>
                  {searchInputError &&
                    <Typography color={swvTheme.palette.error.main}>
                      Suche muss mindestens 3 Zeichen enthalten
                    </Typography>
                  }

                </FormControl>

                <Box mt={1} display='flex' justifyContent='space-between' sx={{
                  marginBottom: 1,
                  flexDirection: 'column',
                  marginX: '3px',

                  [swvTheme.breakpoints.up('sm')]: {
                    flexDirection: 'row',
                    marginBottom: 2,
                    marginX: '3px',
                  },
                }}>
                  <Box display='flex' sx={{
                    [swvTheme.breakpoints.down('sm')]: {
                      borderBottom: '1px dotted #000000',
                      marginY: '10px',
                      padding: '10px 0',
                      width: '100%',
                    },
                  }}>
                    <Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={companyChecked}
                            onChange={onCompanyChecked}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        }
                        label='Firma'
                        className={cssClass.customCheckbox}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={contactPersonChecked}
                            onChange={onContactPersonChecked}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        }
                        label='Kontaktperson'
                        className={cssClass.customCheckbox}
                      />
                    </Box>

                    <Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={addressChecked}
                            onChange={onAddressChecked}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        }
                        label='Adresse'
                        className={cssClass.customCheckbox}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={personChecked}
                            onChange={onPersonChecked}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        }
                        label='Person'
                        className={cssClass.customCheckbox}
                      />
                    </Box>
                  </Box>

                  <Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={memberChecked}
                          onChange={onMemberChecked}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label='Nur SWV Mitglieder'
                      className={cssClass.customCheckbox}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={familyChecked}
                          onChange={onFamilyChecked}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label='Nur SWV Familie'
                      className={cssClass.customCheckbox}
                    />

                  </Box>
                </Box>
                <Box display='flex' alignItems='center' sx={{ maxWidth: "100%" }}>
                  <FormTextField
                    name='searchedSubcategories'
                    label='Fachgruppen (mehrere mit Abstand möglich)'
                    placeholder='z.B. "W704 W106"'
                    control={control}
                    type='text'
                    onKeyUp={(e) => enterPressed(e)}
                    sx={{ minWidth: !isMobile ? '340px' : '80%', [swvTheme.breakpoints.down('sm')]: {} }}
                  />
                  <Tooltip title="Fachgruppenassistent öffnen" arrow>
                    <IconButton style={{ color: swvTheme.typography.lilac, textAlign: "center", marginLeft: "0.5em" }}
                      onClick={handleAssistantClick}
                    >
                      <AssistantOutlined />
                    </IconButton>
                  </Tooltip>
                </Box>

              </Box>

              <Popper
                id={id}
                open={open}
                placement='bottom-end'
                anchorEl={filterPopupAnchorEl}
                transition
              >
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={350}>
                    <Box
                      p={2}
                      bgcolor='#DFCFE2'
                      borderRadius='4px'
                      width='260px'
                    >
                      <Button
                        onClick={onSearchFilterModalOpen}
                        endIcon={<ArrowForward />}
                        size='large'
                        className={[cssClass.buttonDark]}
                        sx={{ width: '100%', mt: 2 }}
                      >
                        Zur Spezialsuche
                      </Button>
                      <BoxCenter>
                        <IconButton onClick={handleFilterClick}>
                          <Close />
                        </IconButton>
                      </BoxCenter>
                    </Box>
                  </Fade>
                )}
              </Popper>
            </Box>

            {!isSearchClicked && !isSpecialClicked && (
              <>
                <ActivityHistory
                  historyList={historyList}
                  setHistoryList={setHistoryList}
                />
                <Box
                  sx={{
                    display: 'flex',
                    gap: 3,
                    [swvTheme.breakpoints.down('md')]: {
                      flexDirection: 'column',
                      justifyContent: 'center',
                    },
                  }}
                >
                  <CustomButton
                    value='Visitenkarte scannen'
                    onClick={() => onScanCardModalOpen()}
                    largeBorderRadius
                    sx={{
                      width: '100%',
                      [swvTheme.breakpoints.up('md')]: {
                        display: 'none',
                      },
                    }}
                    startIcon={
                      <Box sx={{ height: '30px', width: 'auto' }}>
                        <BusinessCard />
                      </Box>
                    }
                  />
                </Box>
              </>
            )}

            {companyList.length || personList.length || addressList.length || contactPersonList?.length || specialList?.length ? (
              <Box
                mt={3}
                mb={6}
                sx={{
                  gap: 2,
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {(!isSpecialClicked && ((noCompanies === true && companyList?.length !== undefined) || companyList.length > 0) || listsLoading.companyList) ? (
                  <Box p={2} sx={{ backgroundColor: '#E9E9E9', borderRadius: '4px' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        cursor: 'pointer',
                      }}
                    >

                      <Typography
                        color={swvTheme.typography.lilac}
                        fontSize={18}
                        fontWeight={500}
                        gap={1}
                        display='flex'
                        alignItems='center'
                        onClick={() => setListsOpened((old) => ({ ...old, company: !listsOpened.company }))}
                      >
                        {listsOpened.company ? (
                          <KeyboardArrowUpOutlined />
                        ) : (
                          <KeyboardArrowDownOutlined />
                        )}
                        Firma
                      </Typography>

                      {listsLoading.companyList ? (
                        <Box display='flex' alignItems='center' height={40}>
                          <Typography color='#F50000' fontWeight={700} mr={1}>
                            Suche...
                          </Typography>
                          <Typography color={swvTheme.typography.p}>
                            Ergebnisse
                          </Typography>
                          <CircularProgress size={20} style={{ marginLeft: "10px", marginRight: "7px", color: swvTheme.typography.lilac }} />
                        </Box>
                      ) : (
                        <Box display='flex' alignItems='center'>
                          <Typography color='#F50000' fontWeight={700} mr={1}>
                            {companyResultCount}
                          </Typography>
                          <Typography color={swvTheme.typography.p}>
                            Ergebnisse
                          </Typography>
                          <IconButton onClick={onCompanyExportClicked}>
                            <Download sx={{ color: swvTheme.typography.lilac }} />
                          </IconButton>
                        </Box>
                      )}
                    </Box>
                    <Collapse in={listsOpened.company} unmountOnExit>
                      <Grid container spacing={2} columns={2}>
                        {companyList?.slice(0, pageSize).map((row) => (
                          <Grid
                            key={row.ID}
                            item
                            xs={2}
                            md={1}
                          >
                            <CompanyDataCard
                              key={row.ID}
                              company={row}
                              lineOne={row.Name}
                              lineTwo={`FG Nr.: ${getBusinessRegistrationListOverview(row)}`}
                              truncate
                            />
                          </Grid>
                        ))}
                      </Grid>
                      {(companyList.length !== 0) &&
                        <Grid display='flex' justifyContent='center' marginTop={'10px'}>
                          <Pagination
                            shape="rounded"
                            count={Math.ceil(companyResultCount / pageSize)}
                            onChange={handleCompanyPageChange}
                            page={companyPage + 1}
                            siblingCount={isMobile ? 0 : 1}
                            sx={paginationStyles}
                          />
                        </Grid>
                      }
                    </Collapse>
                  </Box>

                ) : null}

                {(!isSpecialClicked && ((noContactPersons === true && contactPersonList?.length !== undefined) || contactPersonList.length > 0) || listsLoading.contactPersonList) ? (
                  <Box p={2} sx={{ backgroundColor: '#E9E9E9', borderRadius: '4px' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        cursor: 'pointer',
                      }}
                    >
                      <Typography
                        color={swvTheme.typography.lilac}
                        fontSize={18}
                        fontWeight={500}
                        gap={1}
                        display='flex'
                        alignItems='center'
                        onClick={() =>
                          setListsOpened((old) => ({ ...old, contactPerson: !listsOpened.contactPerson }))
                        }
                      >
                        {listsOpened.contactPerson ? (
                          <KeyboardArrowUpOutlined />
                        ) : (
                          <KeyboardArrowDownOutlined />
                        )}
                        Kontaktperson
                      </Typography>
                      {listsLoading.contactPersonList ? (
                        <Box display='flex' alignItems='center' height={40}>
                          <Typography color='#F50000' fontWeight={700} mr={1}>
                            Suche...
                          </Typography>
                          <Typography color={swvTheme.typography.p}>
                            Ergebnisse
                          </Typography>
                          <CircularProgress size={20} style={{ marginLeft: "10px", marginRight: "7px", color: swvTheme.typography.lilac }} />
                        </Box>
                      ) : (
                        <Box display='flex' alignItems='center'>
                          <Typography color='#F50000' fontWeight={700} mr={1}>
                            {contactPersonResultCount}
                          </Typography>
                          <Typography color={swvTheme.typography.p}>
                            Ergebnisse
                          </Typography>
                          <IconButton onClick={onContactPersonExportClicked}>
                            <Download sx={{ color: swvTheme.typography.lilac }} />
                          </IconButton>
                        </Box>
                      )}

                    </Box>
                    <Collapse in={listsOpened.contactPerson} unmountOnExit>
                      <Grid container spacing={2} columns={2}>
                        {contactPersonList?.slice(0, pageSize).map((row) => (
                          <Grid
                            key={row.ID}
                            item
                            xs={2}
                            md={1}
                            onClick={() => onRowClicked(row)}
                          >
                            <CompanyDataCard
                              key={row.ID}
                              company={row}
                              lineOne={row.Name}
                              lineTwo={`${row.Master.Person.Firstname} ${row.Master.Person.Lastname}`}
                              truncate
                            />
                          </Grid>
                        ))}
                      </Grid>
                      {(contactPersonList.length !== 0) &&
                        <Box display='flex' justifyContent='center' marginTop={'10px'}>
                          <Pagination
                            shape="rounded"
                            count={Math.ceil(contactPersonResultCount / pageSize)}
                            onChange={handleContactPersonPageChange}
                            page={contactPersonPage + 1}
                            siblingCount={isMobile ? 0 : 1}
                            sx={paginationStyles}
                          />
                        </Box>
                      }
                    </Collapse>
                  </Box>
                ) : null}

                {(!isSpecialClicked && ((noAddresses === true && addressList?.length !== undefined) || addressList.length > 0) || listsLoading.addressList) ? (

                  <Box p={2} sx={{ backgroundColor: '#E9E9E9', borderRadius: '4px' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        cursor: 'pointer',
                      }}
                    >
                      <Typography
                        color={swvTheme.typography.lilac}
                        fontSize={18}
                        fontWeight={500}
                        gap={1}
                        display='flex'
                        alignItems='center'
                        onClick={() =>
                          setListsOpened((old) => ({ ...old, address: !listsOpened.address }))
                        }
                      >
                        {listsOpened.address ? (
                          <KeyboardArrowUpOutlined />
                        ) : (
                          <KeyboardArrowDownOutlined />

                        )}
                        Adresse
                      </Typography>

                      {listsLoading.addressList ? (
                        <Box display='flex' alignItems='center' height={40}>
                          <Typography color='#F50000' fontWeight={700} mr={1}>
                            Suche...
                          </Typography>
                          <Typography color={swvTheme.typography.p}>
                            Ergebnisse
                          </Typography>
                          <CircularProgress size={20} style={{ marginLeft: "10px", marginRight: "7px", color: swvTheme.typography.lilac }} />
                        </Box>
                      ) : (
                        <Box display='flex' alignItems='center'>
                          <Typography color='#F50000' fontWeight={700} mr={1}>
                            {addressResultCount}
                          </Typography>
                          <Typography color={swvTheme.typography.p}>
                            Ergebnisse
                          </Typography>
                          <IconButton onClick={() => {
                            onAddressExportClicked();
                          }}>
                            <Download sx={{ color: swvTheme.typography.lilac }} />
                          </IconButton>
                        </Box>
                      )}
                    </Box>
                    <Collapse in={listsOpened.address} unmountOnExit>
                      <Grid container spacing={2} columns={2}>
                        {addressList?.slice(0, pageSize).map((row) => (

                          <Grid
                            key={row.StandardWKO.ID}
                            item
                            xs={2}
                            md={1}
                            onClick={() => onRowClicked(row)}
                          >
                            <CompanyDataCard
                              key={row.ID}
                              company={row}
                              lineOne={row.Name}
                              lineTwo={getAddressRegistration(row.StandardWKO)}
                              truncate
                            />
                          </Grid>

                        ))}
                      </Grid>

                      {(addressList.length !== 0) &&
                        <Box display='flex' justifyContent='center' marginTop={'10px'}>
                          <Pagination
                            shape="rounded"
                            count={Math.ceil(addressResultCount / pageSize)}
                            onChange={handleAddressPageChange}
                            page={addressPage + 1}
                            siblingCount={isMobile ? 0 : 1}
                            sx={paginationStyles}
                          />
                        </Box>
                      }
                    </Collapse>
                  </Box>

                ) : null}

                {(!isSpecialClicked && ((noPersons === true && personList?.length !== undefined) || personList.length > 0) || listsLoading.personList) ? (
                  <Box p={2} sx={{ backgroundColor: '#E9E9E9', borderRadius: '4px' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        cursor: 'pointer',
                      }}
                    >
                      <Typography
                        color={swvTheme.typography.lilac}
                        fontSize={18}
                        fontWeight={500}
                        gap={1}
                        display='flex'
                        alignItems='center'
                        onClick={() => setListsOpened((old) => ({ ...old, person: !listsOpened.person }))}
                      >
                        {listsOpened.person ? (

                          <KeyboardArrowUpOutlined />
                        ) : (
                          <KeyboardArrowDownOutlined />
                        )}
                        Person
                      </Typography>
                      {listsLoading.personList ? (
                        <Box display='flex' alignItems='center' height={40}>
                          <Typography color='#F50000' fontWeight={700} mr={1}>
                            Suche...
                          </Typography>
                          <Typography color={swvTheme.typography.p}>
                            Ergebnisse
                          </Typography>
                          <CircularProgress size={20} style={{ marginLeft: "10px", marginRight: "7px", color: swvTheme.typography.lilac }} />
                        </Box>
                      ) : (
                        <Box display='flex' alignItems='center'>
                          <Typography color='#F50000' fontWeight={700} mr={1}>
                            {personResultCount}
                          </Typography>
                          <Typography color={swvTheme.typography.p}>
                            Ergebnisse
                          </Typography>
                          <IconButton onClick={() => {
                            onPersonExportClicked();
                          }}>
                            <Download sx={{ color: swvTheme.typography.lilac }} />
                          </IconButton>
                        </Box>
                      )}
                    </Box>
                    <Collapse in={listsOpened.person} unmountOnExit>
                      <Grid container spacing={2} columns={2}>
                        {personList?.slice(0, pageSize).map((row) => (
                          <Grid
                            key={row.ID}
                            item
                            xs={2}
                            md={1}
                            onClick={() => onPersonClicked(row)}
                          >
                            <DataCard
                              key={row.ID}
                              lineOne={row.Firstname + ' ' + row.Lastname}
                              lineTwo={'Mitglied: ' + getBoolean(row.Member) + ' - Familie: ' + getBoolean(row.IsFamily)}
                              placeholderImage='person'
                              image={getPhotoUrl(row)}
                              truncate
                            />
                          </Grid>
                        ))}
                      </Grid>
                      {(personList.length !== 0) &&
                        <Box display='flex' justifyContent='center' marginTop={'10px'}>
                          <Pagination
                            shape="rounded"
                            count={Math.ceil(personResultCount / pageSize)}
                            onChange={handlePersonPageChange}
                            page={personPage + 1}
                            siblingCount={isMobile ? 0 : 1}
                            sx={paginationStyles}
                          />
                        </Box>
                      }
                    </Collapse>
                  </Box>
                ) : null}

                {isSpecialClicked && (noSpecials || specialList?.length) && (
                  <Box p={2} sx={{ backgroundColor: '#E9E9E9', borderRadius: '4px', paddingBottom: 0 }}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        mb: 2,
                        cursor: 'pointer',
                      }}
                    >
                      <Typography
                        color={swvTheme.typography.lilac}
                        fontSize={18}
                        fontWeight={500}
                        gap={1}
                        display='flex'
                        alignItems='center'
                        onClick={() =>
                          maxSpecials
                            ? setMaxSpecials(0)
                            : setMaxSpecials(specialList?.length)
                        }
                      >
                        {maxSpecials ? (

                          <KeyboardArrowUpOutlined />
                        ) : (
                          <KeyboardArrowDownOutlined />
                        )}
                        Spezialsuche
                      </Typography>
                      <Box display='flex' alignItems='center'>
                        <Typography color='#F50000' fontWeight={700} mr={1}>
                          {specialList?.length}
                        </Typography>
                        <Typography color={swvTheme.typography.p}>
                          Ergebnisse
                        </Typography>
                        <IconButton onClick={() => {
                          onExportClicked(specialList);
                        }}>
                          <Download sx={{ color: swvTheme.typography.lilac }} />
                        </IconButton>
                      </Box>
                    </Box>
                    <Grid container spacing={2} columns={2}>
                      {specialList?.slice(0, maxSpecials).map((row) => (
                        <Grid
                          key={row.ID}
                          item
                          xs={2}
                          md={1}
                          onClick={() => onRowClicked(row)}
                        >
                          <DataCard
                            key={row.ID}
                            lineOne={row.Name}
                            lineTwo={`${row.WKO} - FG Nr.: ${getBusinessRegistrationListOverview(row)}`}
                            placeholderImage='company'
                            image={getPhotoUrl(row)}
                            truncate
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                )}

              </Box>
            ) : isSearchClicked ? (
              <Box display='flex' alignItems='center' mt={6}>
                <NoResultsIcon />
                <Typography color='#202020' ml={3}>
                  Keine Ergebnisse für Ihre Suchanfrage
                </Typography>
              </Box>
            ) : null}
          </Box>

          {!isSearchClicked && !isSpecialClicked && (
            <GoalList />
          )}
        </Box >
        {!isSearchClicked && (
          <Box
            display='flex'
            gap={4}
            mb={5}
            sx={{
              [swvTheme.breakpoints.down('md')]: {
                flexDirection: 'column',
              },
            }}
          >
            <Box
              width='68%'
              sx={{
                display: 'flex',
                gap: 3,
                [swvTheme.breakpoints.down('md')]: {
                  width: 'auto',
                  flexDirection: 'column',
                },
              }}
            >
              <CustomButton
                value='Formulare erstellen'
                largeBorderRadius
                sx={{ width: '100%' }}
                onClick={() => {
                  navigate('/formularversand')
                }}
                startIcon={
                  <Printer
                    sx={{
                      height: '30px',
                      width: 'auto',
                    }}
                  />
                }
              />
              <CustomButton
                value='Dokumente herunterladen'
                largeBorderRadius
                sx={{ width: '100%' }}
                onClick={() => { navigate('/swv-info') }}
                startIcon={
                  <Document
                    sx={{
                      height: '30px',
                      width: 'auto',
                    }}
                  />
                }
              />
            </Box>
            <Box
              width='32%'
              sx={{
                [swvTheme.breakpoints.down('sm')]: {
                  width: 'auto',
                },
              }}
            >
            </Box>
          </Box>
        )
        }
      </Container >

      <BusinessSubcategoryAssistantPopup
        isOpen={isBusinessSubcategoryAssistantOpen}
        onClose={onBusinessSubcategoryAssistantClose}
        handleApply={onBusinessSubcategoryAssistantApply}
      />

      <SearchFilterPopUp
        isOpen={isSearchFilterModalOpen}
        onClose={onSearchFilterModalClose}
        handleApply={onSearchFilterModalApply}
      />

      <FavoritePopUp
        isOpen={isFavoriteModalOpen}
        onClose={onFavoriteModalClose}
      />

      <ImageUploadPopup
        isOpen={isScanCardPopupOpen}
        onSave={(data) => {
          handlePersonScanCard(data);
          onScanCardModalClose();
        }}
        onClose={onScanCardModalClose}
        isScanMode
      />

      <PersonPopUp
        isOpen={isPersonModalOpen}
        onClose={onPersonModalClose}
        chosenPerson={chosenPerson}
        setChosenPerson={setChosenPerson}
      />
    </>
  );
}
