import React, { useState, useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';

import { Box, Button, Container, FormControl, Avatar, Typography } from '@mui/material';
import { Person as PersonIcon } from '@mui/icons-material';
import * as ProfileService from '../../../services/requests/profile';

import { subject } from '../../../service';

import ModalWrapper from '../../common-components/ModalWrapper';
import BoxCenter from '../../common-components/BoxCenter';
import FormTextField from '../../common-components/FormTextField';
import CountySelect from '../../common-components/CountySelect';

import { getHeaderHeight, urlIsLoadable, useSnackbarHelpers } from '../../../helpers/utils';
import { getPhotoUrl } from '../../../helpers/database';
import { testImageUpload } from '../../../helpers/validateEmail';
import { Messages } from '../../../constants/messages';
import { ImageUploadPopup, PopupModes as PhotoUploadPopupModes } from '../../popups/ImageUploadPopup';

import { styles } from './styles';
import { useGlobalStyles } from '../../../customStyles';
import { ImagePreviewPopup } from '../ImagePreviewPopup';

export default function ProfilePopUp({ isOpen, onClose }) {
  const cssClass = useGlobalStyles();

  const { showErrorSnackbar } = useSnackbarHelpers();

  const [photoURL, setPhotoURL] = useState(null);
  const [isPhotoUploadModalOpen, setIsPhotoUploadModalOpen] = useState(false);
  const openPhotoUploadPopup = () => setIsPhotoUploadModalOpen(true);
  const closePhotoUploadPopup = () => setIsPhotoUploadModalOpen(false);
  const [isImagePreviewPopupOpen, setIsImagePreviewPopupOpen] = useState(false);


  const openImagePreviewPopup = () => {
    setIsImagePreviewPopupOpen(true);
  };

  const closeImagePreviewPopup = () => {
    setIsImagePreviewPopupOpen(false);
  };



  const userData = JSON.parse(localStorage.getItem('userData'));

  const { control, reset } = useForm({
    defaultValues: {
      County: '',
      Photo: '',
      Email: '',
      Username: '',
      Firstname: '',
      Lastname: '',
      Mobile: '',
      Password: '',
      Confirmation: '',
      EmailEmail: '',
      EmailPassword: '',
    },
  });

  const values = useWatch({ control });

  useEffect(() => {
    if (isOpen) {
      getProfileData();
    }
  }, [isOpen]);

  const getProfileData = async () => {
    await ProfileService.getOne((data) => {
      const preload = {
        County: data.County || '',
        Photo: data.Photo,
        Email: data.Email,
        Username: data.Username,
        Firstname: data.Firstname,
        Lastname: data.Lastname,
        Mobile: data.Mobile,
        EmailEmail: '',
        EmailPassword: '',
      };

      console.warn(preload);

      reset(preload);

      urlIsLoadable(getPhotoUrl(userData), setPhotoURL);
    });
  };

  const updatePhoto = async (base64EncodedPhotos) => {
    setPhotoURL(base64EncodedPhotos ? base64EncodedPhotos[0] : '');
    values.Photo = base64EncodedPhotos ? base64EncodedPhotos[0] : '';

    closePhotoUploadPopup();
  };

  const deletePhoto = () => {
    updatePhoto(null);
  };

  const onSaveClicked = async () => {
    var check = true;

    if (values.Username !== userData.Username) {
      await ProfileService.checkUsername(
        { username: values.Username },
        (data) => {
          check = check && data;

          if (!data) showErrorSnackbar(Messages.UsernameInUse);
        },
      );
    }

    if (values.Email !== userData.Email) {
      await ProfileService.checkEmail({ email: values.Email }, (data) => {
        check = check && data;

        if (!data) showErrorSnackbar(Messages.UsernameInUse);
      });
    }

    if (values.Mobile !== userData.Mobile) {
      // TODO: add check for Phone-number
    }

    if (!check) return;

    if (values.Password !== values.Confirmation) {
      showErrorSnackbar(Messages.ErrorPasswords);
    } else {
      await ProfileService.updateOne({ ...values, ID: userData.ID }, (data) => {
        localStorage.setItem('userData', JSON.stringify(data));
        subject.next(true);
        onClose();
      });
    }
  };

  const disabled =
    !values.Firstname || !values.Lastname || !values.Email || !values.Username || !values.Mobile;

  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={onClose}
      title="Profil"
      sx={{
        ...styles.profileContainer,
      }}
    >
      <FormControl>
        <Container>
          <Box pb={2} sx={{ ...styles.profileForm }}>
            <BoxCenter sx={{
              flexDirection: 'row'
            }}>
              <Avatar style={{ height: '100px', width: '100px' }}
                src={photoURL}
                onClick={openImagePreviewPopup}
              // sx={styles.personAvatar}
              >
                <PersonIcon />
              </Avatar>
            </BoxCenter>
            {/* <Typography onClick={openPhotoUploadPopup} sx={{...styles.changeImgButton}}>Bild ändern</Typography> */}
            <ImageUploadPopup
              isOpen={isPhotoUploadModalOpen}
              onClose={closePhotoUploadPopup}
              onSave={updatePhoto}
            />
            <ImagePreviewPopup
              onClose={closeImagePreviewPopup}
              isOpen={isImagePreviewPopupOpen}
              imageToPreview={photoURL}
              fallbackImage={<PersonIcon />}
              onDeleteImage={deletePhoto}
              onChangeImage={openPhotoUploadPopup}
            />
            <CountySelect
              name="County"
              control={control}
              value={values?.County}
              disabled
            />
            <FormTextField
              name="Email"
              label="Email"
              control={control}
              required
            />
            <FormTextField
              name="Username"
              label="Benutzername"
              control={control}
              required
            />
            <FormTextField
              name="Firstname"
              label="Vorname"
              control={control}
              required
            />
            <FormTextField
              name="Lastname"
              label="Nachname"
              control={control}
              required
            />
            <FormTextField
              name={"Mobile"}
              label={"Telefonnummer"}
              control={control}
              required
            />
            <FormTextField
              name="Password"
              label="Neues Passwort"
              control={control}
              type="password"
            />
            <FormTextField
              name="Confirmation"
              label="Neues Passwort bestätigen"
              control={control}
              type="password"
            />
            <BoxCenter sx={{ mx: 0, mt: 2 }}>
              <Button
                onClick={onSaveClicked}
                disabled={disabled}
                sx={{ ...styles.saveButton }}
              >
                Speichern
              </Button>
            </BoxCenter>

            {/*<Box my={2} sx={{ borderTop: '1px dotted grey' }} />
            <Typography variant="span" fontSize={'20px'}>
              Brevo E-Mail Einstellungen
            </Typography>

            Achtung: Bitte diese Einstellungen leer lassen, wenn kein eigener Brevo Account vorhanden ist.

            <Box my={1} sx={{ borderTop: '1px dotted white' }} />

            <FormTextField
              name="EmailEmail"
              label="Brevo Email"
              control={control}
              disabled
            />
            <FormTextField
              name="EmailPassword"
              label="Brevo Passwort"
              control={control}
              disabled
            />*/}
          </Box>
        </Container>
      </FormControl>
    </ModalWrapper>
  );
}
