import React, { useContext, useEffect } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';

import { useSnackbarHelpers, deletePopup } from '../../../../../helpers/utils';

import { useStyles } from './styles';
import { useGlobalStyles } from '../../../../../customStyles';

import { AdminGoalContext } from '../../AdminGoalContext';
import * as GoalService from '../../../../../services/requests/goal';

import { chooseHardGoal, getDate } from '../../../../../helpers/database';

import DeleteCell from '../../../../common-components/DeleteCell';
import BoxCenter from '../../../../common-components/BoxCenter';
import UserGoalRow from '../../../UserGoalRow/UserGoalRow';
import { useUpdateGoalStatusMutation } from '../../../../../redux/services/GoalStatusService';
import { useMediaQuery } from '@mui/material';

export default function GoalTable() {
  const classes = useStyles();
  const cssClass = useGlobalStyles();
  const { } = useSnackbarHelpers();

  const {
    goalList,
    setCurrentGoal,
    currentGoal,

    setCurrentTab,
  } = useContext(AdminGoalContext);

  const handleRowClick = (row) => {
    setCurrentGoal(row);
    setCurrentTab(1);
  };

  const onNewClick = () => {
    setCurrentGoal(null);
    setCurrentTab(1);
  };

  const deleteGoal = async (id) => {
    return await GoalService.deleteOne(id, () => {
      if (currentGoal?.ID == id) {
        setCurrentGoal(null);
      }
      getGoalList();
    });
  };

  const [updateGoalStatus, { error: updateError, isLoading: isUpdating }] = useUpdateGoalStatusMutation();
  const onChange = (goal) => {
    updateGoalStatus({ ...goal, goalId: goal.Goal.ID })
  }

  const isMobile = useMediaQuery("(max-width: 850px)")

  return (
    <Container maxWidth={isMobile ? 'xl' : 'xl'} >
      <TableContainer >
        <Table style={isMobile ? { width: "300vw" } : {}}>
          <TableHead>
            <TableRow style={{ width: "300%" }}>
              <TableCell style={{ width: !isMobile ? "45%" : "3000px" }}>Name</TableCell>
              <TableCell style={{ width: "1000px" }}>Anfang</TableCell>
              {!isMobile && <TableCell style={{ width: "1000px" }}>Ende</TableCell>}
              <TableCell style={{ width: "1500px" }}>Ziel</TableCell>
              <TableCell style={{ width: "2000px" }}>Ist</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {goalList?.map((row) => (
              <UserGoalRow key={row.ID} selectedGoal={row} onChange={onChange} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* TODO: <BoxCenter>
        <Button
          onClick={onNewClick}
        >
          Neu
        </Button>
            </BoxCenter>*/}
    </Container>
  );
}