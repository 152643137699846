import * as endpoints from '../../constants/endpoints';
import { apiClient } from '../apiClient';

export const insertOne = async (data, success, error) => {
  const response = await apiClient.insertOne(endpoints.insertAddressRegistration, data, success, error);
  return response.data;
};

export const updateOne = async (data, success, error) => {
  const response = await apiClient.updateOne(endpoints.updateAddressRegistration, data, success, error);
  return response.data;
};

export const deleteOne = async (id, success, error) => {
  const response = await apiClient.deleteOne(endpoints.deleteAddressRegistration, id, success, error);
  return response.data;
};

export const getList = async (companyId, success, error) => {
  const response = await apiClient.getList(endpoints.getAddressRegistrationList, { companyId }, success, error);
  return response.data;
}
