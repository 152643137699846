import React from "react";
import { MenuItem, SxProps } from "@mui/material";
import FormSelect from "../FormSelect";
import { Control } from "react-hook-form";
import { UserRoleValues } from "../../../helpers/helperFunctions";

interface UserRoleSelect {
    control: Control<any>
    value: UserRoleValues
    required?: boolean
    sx?: SxProps
    fullWidth?: boolean
}

export default function UserRoleSelect({ control, value, required, sx, fullWidth }: UserRoleSelect) {
    return (
        <FormSelect
            name='UserRole'
            label="Benutzerrolle"
            control={control}
            value={value}
            sx={sx}
            fullWidth={fullWidth}
        >
            <MenuItem value='B'>Bund</MenuItem>
            <MenuItem value='L'>Landesorganisation</MenuItem>
            <MenuItem value='F'>Funktionär</MenuItem>
        </FormSelect>
    )
}