import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { useStyles } from './styles';

export default function SWVIcon({ text }) {
  const classes = useStyles();

  return (
    <svg width="65" height="24" viewBox="0 0 95 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.0425 23.285C4.43225 25.5123 6.8495 26.6522 9.18425 26.6522C10.9855 26.6522 12.1075 25.6613 12.2972 24.3255C12.8142 20.6602 2.4825 20.1662 3.87675 10.2633C4.47625 6.0046 7.76525 0.112 15.1765 0.112C18.493 0.112 21.0423 1.2022 23.4705 3.7276L22.2138 12.6396C19.9368 9.6202 17.9622 8.23192 15.8722 8.23192C14.2332 8.23192 13.0453 9.1234 12.8418 10.5586C12.2615 14.6682 22.97 15.1154 21.617 24.7202C20.9322 29.5722 17.3765 34.7721 10.0863 34.7721C6.481 34.7721 3.3515 34.0296 0.75 32.4454L2.03975 23.285" fill="white"/>
        <path d="M24.0012 0.556366H33.1725L34.2725 15.7088H34.355L39.9705 0.556366H47.0545L48.6055 15.7088H48.688L53.8525 0.556366H63.0265L48.809 34.3277H42.9515L41.2162 17.5911H41.1337L34.927 34.3277H29.0695L24.0012 0.556366Z" fill="white"/>
        <path d="M62.6992 0.556366H73.262L76.5702 21.5517H76.6527L85.5902 0.556366H94.5167L78.1707 34.3277H69.6925L62.6992 0.556366Z" fill="white"/>
    </svg>
  );
}
