import React from 'react';
import { Control, Controller } from 'react-hook-form';

import FullwidthDatePicker from '../FullwidthDatePicker';

interface IFormDatePicker {
  label: string,
  name: string,
  control: Control<any>,
  required?: boolean,
  disabled?: boolean,
}

export default function FormDatePicker({
  label,
  name,
  control,
  required,
  disabled,
}: IFormDatePicker) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <FullwidthDatePicker
          label={label}
          value={value}
          onChange={onChange}
          disabled={disabled}
          required={required}
        />
      )}
    />
  );
}
