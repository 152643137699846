import React, { useContext } from 'react';

import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/Add';

import { ContactPersonContext } from '../../PersonContext';
import { useStyles } from './styles';
import { useGlobalStyles } from '../../../../../customStyles';
import { swvTheme } from '../../../../../swvTheme';
import { chooseContactPersonPosition, getDate } from '../../../../../helpers/database';
import BoxCenter from '../../../../common-components/BoxCenter';
import { IconButton } from '@mui/material';

export const ContactPersonList = () => {
  const classes = useStyles();
  const cssClass = useGlobalStyles();

  const {
    chosenPerson,
    chosenContactPerson,
    handleNewContactPersonClick,
    setChosenContactPerson,
    companyData,
  } = useContext(ContactPersonContext);

  const disabled = chosenPerson?.ContactPersons?.some(contactPerson => contactPerson.FK_Company === companyData?.ID);

  const onRowClicked = async (row) => {
    await setChosenContactPerson(row);
  };

  return (
    <>
      <Grid xs={12} mt={3} mb={3}>
        <Box sx={{ alignItems: 'center' }}>
          <Paper sx={{ borderRadius: '8px' }}>
            <Typography color={swvTheme.typography.lilac} fontSize={18} fontWeight={500} p={2} py={4}>
              Kontaktpersonen
            </Typography>
            <div className={classes.spaceBetweenTables}>
              {chosenPerson?.ContactPersons?.map((row) => (
                <Table key={row.ID} sx={{ borderBottom: '1px dashed #C7C7C7;' }}
                selected={chosenContactPerson?.ID == row.ID}
                onClick={() => onRowClicked(row)}
                >
                  <TableBody className={cssClass.customTableBody}>
                    <TableRow>
                      <TableCell>Firma</TableCell>
                      <TableCell colSpan={2}>{row.Slave?.Name}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={cssClass.shrinkCell}>Position</TableCell>
                      <TableCell>{chooseContactPersonPosition(row.Position)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Anfang</TableCell>
                      <TableCell colSpan={2}>{getDate(row.Start)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Ende</TableCell>
                      <TableCell colSpan={2}>{getDate(row.End)}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              ))}
            </div>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 2,
                justifyContent: 'flex-end',
                cursor: 'pointer',
              }}
            >
              {companyData?.ID && !disabled && (
                <Box p={2}>
                  <AddIcon sx={{ fill: '#F50000' }} onClick={() => handleNewContactPersonClick()} />
                </Box>
              )}
            </Box>
          </Paper>
        </Box>
      </Grid>
    </>)
};