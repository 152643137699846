import { createUseStyles } from 'react-jss';
import { swvTheme } from './swvTheme';

export const useGlobalStyles = createUseStyles({
  notchosen: {
    margin: '0px',
    padding: '7px',
    fontSize: '0.875rem',
  },
  chosen: {
    margin: '0px',
    padding: '7px',
    backgroundColor: swvTheme.palette.secondary.light,
    fontSize: '0.875rem',
  },
  swvModalDefault: {
    outline: 'none',

    '&.MuiBox-root': {
      display: 'flex',
      flexDirection: 'column',
      padding: '20px 10px',
      position: 'absolute',
      top: '50%',
      left: '50%',
      maxWidth: '95%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#FFFFFF',
      borderRadius: '8px',
      maxHeight: '90vh',
      width: '100%',

      [swvTheme.breakpoints.up("sm")]: {
        width: '600px',
      }
    },
  },
  swvModalResponsiveMods: {
    minWidth: "unset !important",
    width: "clamp(150px, 100svw, 950px)",

    "& .MuiButton-containedPrimary": {
      backgroundColor: swvTheme.palette.button.light,

      "&:hover": {
        backgroundColor: swvTheme.palette.button.dark,
      },
    }
  },
  closeIcon: {
    '&.MuiSvgIcon-root': {
      position: 'absolute',
      right: '24px',
      top: '24px',
      color: swvTheme.palette.secondary.dark,

      '&:hover': {
        cursor: 'pointer',
        color: swvTheme.palette.primary.dark,
      },
    },
  },

  // Often used
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: swvTheme.spacing(1),
    marginBottom: swvTheme.spacing(0.5),
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: swvTheme.spacing(1),
    marginBottom: swvTheme.spacing(0.5),
    gap: swvTheme.spacing(0.25),
  },
  flexColumnCenter: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: swvTheme.spacing(1),
    marginBottom: swvTheme.spacing(0.5),
  },
  // Commonly used in forms
  formFlexColumn: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    marginTop: swvTheme.spacing(1),
    marginBottom: swvTheme.spacing(0.5),
  },
  formFlexRow: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: swvTheme.spacing(1),
  },

  // List Container
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    //width: '100%',
    padding: '3px',
    border: '1px solid grey',
    borderRadius: '4px',
    minHeight: '150px',
    maxHeight: '200px',
    overflowY: 'scroll',
    overflowX: 'hidden',
    marginTop: swvTheme.spacing(1),
    marginBottom: swvTheme.spacing(0.5),
  },

  // Signature Container
  sigContainer: {
    margin: 'auto',
    width: '550px',
    height: '100px',
    backgroundColor: '#fff',
    border: '1px solid #d9d9d9',
    borderRadius: '8px',
    position: 'relative',

    [swvTheme.breakpoints.down("md")]: {
      width: "100%",
    }
  },
  sigPad: {
    width: '100%',
    height: '100px',
  },
  clearSignature: {
    //position: 'relative',
    //top: '-160px',
    //left: '75%',
    cursor: 'pointer',
    position: 'absolute',
    margin: '10px',
    top: 0,
    right: 0,
    color: swvTheme.palette.primary.main,
    '&:hover': { color: swvTheme.palette.primary.dark },
  },

  formCommentInput: {
    display: 'block',
    padding: '10px',
    width: '100%',
    height: '120px',
    fontSize: '1.1rem',
    fontWeight: '400',
    fontFamily: 'Poppins',
    lineHeight: '1.5',
    color: 'rgba(0,0,0,0.87)',
    backgroundColor: 'rgba(0, 0, 0, 0)',
    backgroundImage: 'none',
    border: '1px solid #d9d9d9',
    borderRadius: '8px',
    transition: 'all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1)',
    boxSizing: 'border-box',
    borderWidth: '1.3px',
    borderColor: 'rgba(0, 0, 0, 0.25)',
    resize: 'vertical',

    '&:hover': {
      borderColor: '#E30613',
    },

    '&:focus': {
      outline: 'none',
      borderColor: '#E30613',
      borderWidth: '2px',
    },
  },

  // Tables
  checkCell: {
    width: '30px',
    textAlign: 'center !important',
  },
  btnCell: {
    width: '60px',
    textAlign: 'center !important',
  },
  titleCell: {
    minWidth: '150px',
  },
  dateCell: {
    minWidth: '45px',
  },
  smallCell: {
    width: '120px',
  },
  userRoleCell: {
    width: '135px'
  },
  mediumCell: {
    width: '200px',
  },
  largeCell: {
    width: '400px',
  },
  shrinkCell: {
    width: '36%',
    paddingRight: 0,
  },
  centeredCellText: {
    textAlign: "center !important"
  },
  shrinkIconCell: {
    width: '24px',
    maxWidth: 'none',
    paddingLeft: '0 !important',
  },
  twoColumnDataTable: {
    '& .MuiTableCell-alignRight': {
      paddingTop: 0,
      paddingBottom: 0,

      '& .MuiFormControl-root': {
        width: '100%',
        maxWidth: '230px',
      }
    }
  },
  buttonsTableEdit: {
    flexFlow: 'wrap',
    justifyContent: 'center',

    [swvTheme.breakpoints.up('sm')]: {
      gap: '36px',
    },
  },
  customTableBody: {
    '& td': {
      borderBottom: '1px dashed #C7C7C7',
      padding: '10px 16px',

      '& .MuiFormControl-root': {
        margin: '0',
        width: '100%',
        // maxWidth: '230px',
      },
    }
  },

  // Backgrounds
  gradientBackground: {
    backgroundImage: swvTheme.palette.background.gradient,
  },

  // Buttons
  buttonDark: {
    justifyContent: 'space-between',
    padding: '12px 30px 12px 16px',
    borderRadius: '8px 4px 8px 4px',
    backgroundColor: swvTheme.palette.button.dark,

    '&:hover': {
      backgroundColor: swvTheme.palette.button.dark,
    },
  },
  buttonLogin: {
    marginTop: '10px',
    [swvTheme.breakpoints.down('sm')]: {
      width: '100%',
      justifyContent: 'center',
    },
  },

  showOnDesktop: {
    display: "initial"
  },

  hideOnDesktop: {
    display: "none"
  },

  [swvTheme.breakpoints.down("md")]: {
    showOnDesktop: {
      display: "none"
    },

    hideOnDesktop: {
      display: "initial"
    },
  },

  // Inputs / Input groups
  customInputLabel: {
    '&.MuiInputLabel-shrink': {
      transform: 'initial',
      position: 'initial',
      marginBottom: '12px',
      textTransform: 'uppercase',
      fontSize: '14px',
    },
    '&.Mui-error': {
      color: 'rgba(0, 0, 0, 0.6)',
    }
  },

  customInput: {
    marginTop: '-8px !important',
    backgroundColor: '#FFFFFF',
    border: '1px solid #3E004D',
    borderRadius: '4px',
    padding: '12px',

    '&:after, &:before': {
      border: 'none !important',
    },

    '& .MuiInputAdornment-positionEnd': {
      marginRight: '6px',
    },
  },

  customHelperText: {
    marginLeft: '0',
  },

  customCheckbox: {
    color: '#732382',

    '& .MuiFormControlLabel-label': {
      fontWeight: '500',
      fontSize: '14px',
    },

    '& .MuiCheckbox-root': {
      color: '#732382',
      padding: '6px',
    },

    '& .Mui-checked': {
      color: '#732382',
    },
  },

  defaultCursor: {
    '&:hover': {
      cursor: 'default',
    },
  },

  pointerCursor: {
    '&:hover': {
      cursor: 'pointer',
    },
  },

  checkboxScaled: {
    transform: "scale(1.4)",
    cursor: "pointer",
    accentColor: "#F50000"
  }
});
