import * as endpoints from '../../constants/endpoints';
import { apiClient } from '../apiClient';

export const getOne = async (id, success, error) => {
  const response = await apiClient.getOne(endpoints.getBusinessGroup, id, success, error);
  return response.data;
};

export const getList = async (query, success, error) => {
  const response = await apiClient.getList(endpoints.getBusinessGroupList, query, success, error);
  return response.data;
};

export const insertOne = async (data, success, error) => {
  const response = await apiClient.insertOne(endpoints.insertBusinessGroup, data, success, error);
  return response.data;
}

export const updateOne = async (data, success, error) => {
  const response = await apiClient.updateOne(endpoints.updateBusinessGroup, data, success, error);
  return response.data;
}

export const deleteOne = async (id, success, error) => {
  const response = await apiClient.deleteOne(endpoints.deleteBusinessGroup, id, success, error);
  return response.data;
}
