import { Button, CircularProgress, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from "@mui/material";
import { useGlobalStyles } from "../../../../customStyles";
import React, { SetStateAction, useContext, useEffect, useState } from "react";
import { useGetBusinessSubcategoriesByCountyQuery } from "../../../../redux/services/BusinessSubCategoryService";
import { useGetBusinessSubcategoryUserPermissionsByUserIdQuery, useInsertBusinessSubcategoryUserPermissionsMutation } from "../../../../redux/services/UserPermissionsService";
import { UserContext } from "../../../pages/User/UserContext";
import BusinessSubcategoryPermissionAssistant from "../BusinessSubcategoryPermissionAssistent/BusinessSubcategoryPermissionAssistant";

interface IUniqueCategories {
    ID: number
    County: string
}

let placeholderId = -1;
function getPlaceholderId() {
    return placeholderId--;
}

export default function BusinessSubcategoryUserPermissionsCountyOverviewTable() {

    const cssClass = useGlobalStyles();

    const { currentUser } = useContext(UserContext);

    const { data: businessSubcategories } = useGetBusinessSubcategoriesByCountyQuery();
    const { data: userPermissions, isLoading: permissionsLoading, isFetching: permissionsFetching } = useGetBusinessSubcategoryUserPermissionsByUserIdQuery(currentUser ? currentUser.ID : 0);
    const [insertUserPermissions] = useInsertBusinessSubcategoryUserPermissionsMutation();

    const [uniqueBusinessSubcategories, setUniqueBusinessSubcategories] = useState<Record<string, IUniqueCategories[]>>({});
    const [selectedBusinessSubcategoriesForUser, setSelectedBusinessSubcategoriesForUser] = useState<number[]>([]);

    //fetch user permissions on pop up open
    useEffect(() => {
        const businessSubcategoryIds = new Set<number>();
        userPermissions?.forEach(entry => businessSubcategoryIds.add(entry.BusinessSubcategoryId));
        setSelectedBusinessSubcategoriesForUser([...businessSubcategoryIds]);
    }, [userPermissions])


    //fetch all business subcategories from database, could be changed to static list if they won't change in the future
    useEffect(() => {
        let tempUniqueCategories: Record<string, IUniqueCategories[]> = {};
        if (businessSubcategories && businessSubcategories.length > 0) {
            businessSubcategories.forEach((category) => {
                const key = `${category.FK_BusinessCategory}-${category.Number}`;

                if (!tempUniqueCategories[key]) {
                    tempUniqueCategories[key] = [{ County: "B", ID: getPlaceholderId() }, { County: "K", ID: getPlaceholderId() }, { County: "M", ID: getPlaceholderId() }, { County: "N", ID: getPlaceholderId() }, { County: "O", ID: getPlaceholderId() }, { County: "S", ID: getPlaceholderId() }, { County: "T", ID: getPlaceholderId() }, { County: "V", ID: getPlaceholderId() }, { County: "W", ID: getPlaceholderId() }];
                }

                const isDuplicate = tempUniqueCategories[key].some((item) => item.County === category.County && item.ID === category.ID);
                const index = tempUniqueCategories[key].findIndex(entry => entry.County === category.County);

                if (!isDuplicate) {
                    tempUniqueCategories[key][index] = { County: category.County, ID: category.ID }
                }

            })
        }
        setUniqueBusinessSubcategories(tempUniqueCategories);
    }, [businessSubcategories])

    const handleCheckboxChange = (id: number) => {
        const index = selectedBusinessSubcategoriesForUser.indexOf(id);

        if (index === -1) {
            setSelectedBusinessSubcategoriesForUser((old) => [...old, id]);
        }
        else {
            const newArr = [...selectedBusinessSubcategoriesForUser];
            newArr.splice(index, 1);
            setSelectedBusinessSubcategoriesForUser(newArr);
        }
    }

    const checkboxIsChecked = (id: number) => {
        return selectedBusinessSubcategoriesForUser.includes(id);
    }

    const handleSave = async () => {
        //Send current userid + selected subcategory ids
        const data = {
            UserId: currentUser.ID,
            BusinessSubcategoryIds: [...selectedBusinessSubcategoriesForUser]
        }
        const result = await insertUserPermissions(data).unwrap();
    }

    const [assistantOpen, setAssistantOpen] = useState(false);

    return (
        <>
            <>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow className={cssClass.defaultCursor}>
                            <TableCell style={{ textAlign: "center" }}>Spartennummer</TableCell>
                            <TableCell style={{ textAlign: "center" }}>Fachgruppennummer</TableCell>
                            <TableCell className={cssClass.btnCell}>B</TableCell>
                            <TableCell className={cssClass.btnCell}>K</TableCell>
                            <TableCell className={cssClass.btnCell}>M</TableCell>
                            <TableCell className={cssClass.btnCell}>N</TableCell>
                            <TableCell className={cssClass.btnCell}>O</TableCell>
                            <TableCell className={cssClass.btnCell}>S</TableCell>
                            <TableCell className={cssClass.btnCell}>T</TableCell>
                            <TableCell className={cssClass.btnCell}>V</TableCell>
                            <TableCell className={cssClass.btnCell}>W</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {Object.entries(uniqueBusinessSubcategories).map(([key, value]) => (
                            <TableRow
                                key={key}
                                className={cssClass.defaultCursor}
                            >
                                <TableCell style={{ textAlign: "center" }}>{key.split('-')[0]}</TableCell>
                                <TableCell style={{ textAlign: "center" }}>{key.split('-')[1]}</TableCell>
                                {value.map((entry) => (<TableCell key={entry.ID} className={cssClass.btnCell}>{entry.ID > 0 ? <input className={cssClass.checkboxScaled} id={`checkboxUserPermissions-${entry.ID}`} type='checkbox' checked={checkboxIsChecked(entry.ID)} onChange={() => handleCheckboxChange(entry.ID)} /> : null}</TableCell>))}
                            </TableRow>

                        ))}
                    </TableBody>
                    <TableFooter style={{ position: 'sticky', bottom: 0, width: "100%" }}>
                        <TableRow className={cssClass.defaultCursor} style={{ backgroundColor: '#f5f5f5' }} > {/* , '&:hover': { backgroundColor: '#f5f5f5' }  */}
                            <TableCell colSpan={11}>
                                <div style={{ display: 'flex', justifyContent: 'flex-end', gap: "12px" }}>
                                    <Button onClick={() => setAssistantOpen(!assistantOpen)}>Assistent</Button>
                                    <Button onClick={handleSave}>Speichern</Button>
                                </div>
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
                {assistantOpen && <BusinessSubcategoryPermissionAssistant isOpen={assistantOpen} onClose={() => setAssistantOpen(false)} />}
            </>
        </>
    )
}