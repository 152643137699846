export const styles = {
  decorativeCorders: {
    p: '40px'
  },
  rootContainer: {
    pb: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    height: '230px',
    width: '230px',
    mb: '10px',

    '& svg': {
      width: '100%',
      height: '100%',
    }
  },
  buttonContainer: {
    display: 'flex',
    gap: '25px',
    mt: '30px'
  },
  button: {
    color: 'primary.purple',
    textDecorationColor: 'unset',
    fontSize: '19px'
  }
};
