import React, { useState, useContext, useEffect } from 'react';
import { useWatch, useFormContext } from 'react-hook-form';
import { Messages } from '../../../../../constants/messages';
import Container from '@mui/material/Container';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Box,
  IconButton,
} from '@mui/material';

import { Edit, Close } from '@mui/icons-material/';

import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import FormSelect from '../../../../common-components/FormSelect';
import { ContactPersonContext } from '../../PersonContext';
import { useStyles } from './styles';
import { useGlobalStyles } from '../../../../../customStyles';

import FormDatePicker from '../../../../common-components/FormDatePicker';
import BoxCenter from '../../../../common-components/BoxCenter';
import FormTextField from '../../../../common-components/FormTextField';
import FormBooleanSelect from '../../../../common-components/FormBooleanSelect';
import { ImageUploadPopup } from '../../../ImageUploadPopup';
import { swvTheme } from '../../../../../swvTheme';
import { useSnackbarHelpers } from '../../../../../helpers/utils';
import { chooseContactPersonPosition, getDate } from '../../../../../helpers/database';
import DataItemActionsPopup from '../../../DataItemActions';
import CustomButton from '../../../../common-components/CustomButton';

export const CommunicationDataDetails = ({
  updateCommunicationData,
  insertCommunicationData,
}) => {
  const classes = useStyles();
  const cssClass = useGlobalStyles();

  const { chosenContactPerson, showRow } = useContext(ContactPersonContext);
  const { control } = useFormContext();
  const values = useWatch({ control });
  const [emailError, setEmailError] = useState(false);
  const [emailEventError, setEmailEventError] = useState(false);
  const [emailFinancialError, setEmailFiancialError] = useState(false);
  const [emailNewsletterError, setEmailNewsletterError] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [showWarning, setShowWarning] = useState(false);

  const [isScanCardPopupOpen, setIsScanCardPopupOpen] = useState(false);
  const { showErrorSnackbar } = useSnackbarHelpers();

  const [isDataItemActionsPopupOpen, setIsDataItemActionsPopupOpen] = useState(false);
  const [chosenDataItem, setChosenDataItem] = useState(null);

  const disabled = emailError ||
    emailEventError ||
    emailFinancialError ||
    emailNewsletterError ||
    !values.Position ||
    !values.Start;

  const onScanCardModalOpen = async () => {
    setIsScanCardPopupOpen(true);
  };

  const onScanCardModalClose = async () => {
    setIsScanCardPopupOpen(false);
  };

  const onSaveClicked = async () => {
    if (values.CommunicationDataID)
      await updateCommunicationData(resetWarning)
    else
      await insertCommunicationData(resetWarning);
  };

  const createData = (name, value, renderKey) => {
    return { name, value, renderKey };
  };

  const mapBoolean = (boolean) => {
    if (boolean) {
      return 'Ja';
    } else {
      return 'Nein';
    }
  }

  const tableRows = [
    createData('Telefon:', values?.Telephone, 'Telephone'),
    createData('Telefon (privat):', values?.TelephonePrivate, 'TelephonePrivate'),
    createData('Mobil:', values?.Mobile, 'Mobile'),
    createData('Mobil (privat):', values?.MobilePrivate, 'MobilePrivate'),
    createData('E-Mail:', values?.Email, 'Email'),
    createData('E-Mail (Veranstaltungen):', values?.EmailEvent, 'EmailEvent'),
    createData('E-Mail (Finanzen):', values?.EmailFinancial, 'EmailFinancial'),
    createData('E-Mail (Newsletter):', values?.EmailNewsletter, 'EmailNewsletter'),
    createData('Homepage:', values?.Homepage, 'Homepage'),
    createData('Fax:', values?.Fax, 'Fax'),
    createData('Brief:', mapBoolean(values?.IsLetterApproval), 'IsLetterApproval'),
    createData('E-Mail:', mapBoolean(values?.IsEmailApproval), 'IsEmailApproval'),
    createData('Telefon:', mapBoolean(values?.IsTelephoneApproval), 'IsTelephoneApproval'),
    createData('Mobil:', mapBoolean(values?.IsMobileApproval), 'IsMobileApproval'),
    createData('SMS', mapBoolean(values?.IsSMSApproval), 'IsSMSApproval'),
    createData('Messenger:', mapBoolean(values?.IsMessengerApproval), 'IsMessengerApproval'),
    createData('Newsletter:', mapBoolean(values?.IsNewsletterApproval), 'IsNewsletterApproval'),
    createData('Social Media:', mapBoolean(values?.IsSocialMediaApproval), 'IsSocialMediaApproval'),
    createData('WhatsApp:', values?.WhatsApp, 'WhatsApp'),
    createData('Telegram:', values?.Telegram, 'Telegram'),
    createData('Messenger:', values?.Messenger, 'mesMessengersenger'),
    createData('Signal:', values?.Signal, 'Signal'),
    createData('Xing:', values?.Xing, 'Xing'),
    createData('LinkedIn:', values?.LinkedIn, 'LinkedIn'),
    createData('Facebook:', values?.Facebook, 'Facebook'),
    createData('Instagram:', values?.Instagram, 'Instagram'),
    createData('Twitter:', values?.Twitter, 'Twitter'),
    createData('TikTok:', values?.TikTok, 'TikTok'),

    createData('Position:', chooseContactPersonPosition(values?.Position), 'Position'),
    createData('Anfang:', getDate(values?.Start), 'Start'),
    createData('Ende:', getDate(values?.End), 'End'),
  ];

  useEffect(() => {
    if (isEditMode) {
      values.isModified = true;
    }
  }, [values]);

  useEffect(() => {
    let delay;

    document.querySelectorAll('.personCommunicationItem').forEach((item) => {
      const isNotEmpty = item.getElementsByTagName("td")[1].innerText;

      if (isNotEmpty) {
        item.addEventListener('touchstart', () => {
          delay = setTimeout(check, 1500);

          function check() {
            setIsDataItemActionsPopupOpen(true);
          }
        }, true);

        item.addEventListener('touchend', () => {
          clearTimeout(delay);
        });
      }
    });
  });

  const resetWarning = () => {
    values.isModified = false;
    setShowWarning(false);
    setIsEditMode(false);
  }

  const onDataItemActionsPopupClose = () => {
    setIsDataItemActionsPopupOpen(false);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: 2,
        }}
      >
        <Typography
          color="#732382"
          fontSize={18}
          fontWeight={500}
          py={2}
        >
          Kontaktdaten & Kontakterlaubnis
        </Typography>

        {showWarning &&
          <Typography color="primary">{ Messages.WarningUnsafed }</Typography>}

        {isEditMode ? (
        <>
          <IconButton aria-label="close" onClick={() =>
            { if (values?.isModified)
                setShowWarning(true);
              setIsEditMode(false)
            } }>
            <Close />
          </IconButton>
        </>
      ) : (
        <IconButton
          aria-label="edit"
          color="primary"
          onClick={() => setIsEditMode(!isEditMode)}
        >
          <Edit />
        </IconButton>
      )}
      </Box>

      {isEditMode ? (
        <>
          <Table className={cssClass.twoColumnDataTable}>
            <TableBody className={cssClass.customTableBody}>
              <TableRow>
                <TableCell className={cssClass.shrinkCell}>Telefon</TableCell>
                <TableCell>
                  <FormTextField
                    name="Telephone"
                    label="Telefon"
                    control={control}
                    type="tel"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={cssClass.shrinkCell}>Telefon (privat)</TableCell>
                <TableCell>
                  <FormTextField
                    name="TelephonePrivate"
                    label="Telefon (privat)"
                    control={control}
                    type="tel"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={cssClass.shrinkCell}>Mobil</TableCell>
                <TableCell>
                  <FormTextField
                    name="Mobile"
                    label="Mobil"
                    control={control}
                    type="tel"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={cssClass.shrinkCell}>Mobil (privat)</TableCell>
                <TableCell>
                  <FormTextField
                    name="MobilePrivate"
                    label="Mobil (privat)"
                    control={control}
                    type="tel"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={cssClass.shrinkCell}>E-Mail</TableCell>
                <TableCell>
                  <FormTextField
                    name="Email"
                    label="E-Mail"
                    control={control}
                    type="email"
                    func={setEmailError}
                    />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={cssClass.shrinkCell}>E-Mail (Veranstaltungen)</TableCell>
                <TableCell>
                  <FormTextField
                    name="EmailEvent"
                    label="E-Mail (Veranstaltungen)"
                    control={control}
                    type="email"
                    func={setEmailEventError}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={cssClass.shrinkCell}>E-Mail (Finanzen)</TableCell>
                <TableCell>
                  <FormTextField
                    name="EmailFinancial"
                    label="E-Mail (Finanzen)"
                    control={control}
                    type="email"
                    func={setEmailFiancialError}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={cssClass.shrinkCell}>E-Mail (Newsletter)</TableCell>
                <TableCell>
                  <FormTextField
                    name="EmailNewsletter"
                    label="E-Mail (Newsletter)"
                    control={control}
                    type="email"
                    func={setEmailNewsletterError}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={cssClass.shrinkCell}>Homepage</TableCell>
                <TableCell>
                  <FormTextField
                    name="Homepage"
                    label="Homepage"
                    control={control}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={cssClass.shrinkCell}>Fax</TableCell>
                <TableCell>
                  <FormTextField
                    name="Fax"
                    label="Fax"
                    control={control}
                    type="tel"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={cssClass.shrinkCell}>Brief</TableCell>
                <TableCell>
                  <FormBooleanSelect
                    label="Brief"
                    name="IsLetterApproval"
                    control={control}
                    value={values.IsLetterApproval ?? true}
                    required
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={cssClass.shrinkCell}>Email</TableCell>
                <TableCell>
                  <FormBooleanSelect
                    label="Email"
                    name="IsEmailApproval"
                    control={control}
                    value={values.IsEmailApproval ?? true}
                    required
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={cssClass.shrinkCell}>Telefon</TableCell>
                <TableCell>
                  <FormBooleanSelect
                    label="Telefon"
                    name="IsTelephoneApproval"
                    control={control}
                    value={values.IsTelephoneApproval ?? true}
                    required
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={cssClass.shrinkCell}>Mobil</TableCell>
                <TableCell>
                  <FormBooleanSelect
                    label="Mobil"
                    name="IsMobileApproval"
                    control={control}
                    value={values.IsMobileApproval ?? true}
                    required
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={cssClass.shrinkCell}>SMS</TableCell>
                <TableCell>
                  <FormBooleanSelect
                    label="SMS"
                    name="IsSMSApproval"
                    control={control}
                    value={values.IsSMSApproval ?? true}
                    required
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={cssClass.shrinkCell}>Messenger</TableCell>
                <TableCell>
                  <FormBooleanSelect
                    label="Messenger"
                    name="IsMessengerApproval"
                    control={control}
                    value={values.IsMessengerApproval ?? true}
                    required
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={cssClass.shrinkCell}>Newsletter</TableCell>
                <TableCell>
                  <FormBooleanSelect
                    label="Newsletter"
                    name="IsNewsletterApproval"
                    control={control}
                    value={values.IsNewsletterApproval ?? true}
                    required
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={cssClass.shrinkCell}>Soziale Medien</TableCell>
                <TableCell>
                  <FormBooleanSelect
                    label="Soziale Medien"
                    name="IsSocialMediaApproval"
                    control={control}
                    value={values.IsSocialMediaApproval ?? true}
                    required
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={cssClass.shrinkCell}>WhatsApp</TableCell>
                <TableCell>
                  <FormTextField
                    name="WhatsApp"
                    label="WhatsApp"
                    control={control}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={cssClass.shrinkCell}>Telegram</TableCell>
                <TableCell>
                  <FormTextField
                    name="Telegram"
                    label="Telegram"
                    control={control}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={cssClass.shrinkCell}>Messenger</TableCell>
                <TableCell>
                  <FormTextField
                    name="Messenger"
                    label="Messenger"
                    control={control}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={cssClass.shrinkCell}>Signal</TableCell>
                <TableCell>
                  <FormTextField
                    name="Signal"
                    label="Signal"
                    control={control}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={cssClass.shrinkCell}>Xing</TableCell>
                <TableCell>
                  <FormTextField
                    name="Xing"
                    label="Xing"
                    control={control}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={cssClass.shrinkCell}>LinkedIn</TableCell>
                <TableCell>
                  <FormTextField
                    name="LinkedIn"
                    label="LinkedIn"
                    control={control}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={cssClass.shrinkCell}>Facebook</TableCell>
                <TableCell>
                  <FormTextField
                    name="Facebook"
                    label="Facebook"
                    control={control}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={cssClass.shrinkCell}>Instagram</TableCell>
                <TableCell>
                  <FormTextField
                    name="Instagram"
                    label="Instagram"
                    control={control}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={cssClass.shrinkCell}>Twitter</TableCell>
                <TableCell>
                  <FormTextField
                    name="Twitter"
                    label="Twitter"
                    control={control}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={cssClass.shrinkCell}>TikTok</TableCell>
                <TableCell>
                  <FormTextField
                    name="TikTok"
                    label="TikTok"
                    control={control}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={cssClass.shrinkCell}>Position</TableCell>
                <TableCell>
                  <FormSelect
                    label="Position"
                    name="Position"
                    control={control}
                    value={values.Position || chosenContactPerson?.Position}
                    required
                  >
                    <MenuItem value="F">GeschäftsführerIn</MenuItem>
                    <MenuItem value="G">GesellschafterIn</MenuItem>
                    <MenuItem value="P">ProkuristIn</MenuItem>
                    <MenuItem value="V">Vorstandsmitglied</MenuItem>
                    <MenuItem value="A">Aufsichtsradmitglied</MenuItem>
                  </FormSelect>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={cssClass.shrinkCell}>Anfang</TableCell>
                <TableCell>
                  <FormDatePicker
                    name="Start"
                    label="Anfang"
                    control={control}
                    value={values.Start || chosenContactPerson?.Start || null}
                    required
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={cssClass.shrinkCell}>Ende</TableCell>
                <TableCell>
                  <FormDatePicker
                    name="End"
                    label="Ende"
                    control={control}
                    value={values.End || chosenContactPerson?.End || null}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <BoxCenter sx={{ pb: '25px' }}>
            <CustomButton
              value="Verwerfen"
              onClick={() => {
                setIsEditMode(false);
              }}
              sx={{ width: '180px' }}
              light
              isClose
            />
            <CustomButton
              value="Scannen"
              onClick={() => onScanCardModalOpen()}
              sx={{ width: '180px' }}
            />
            <CustomButton
              onClick={() => onSaveClicked(false)}
              disabled={disabled}
              value="Speichern"
              sx={{ width: '180px' }}
            />
          </BoxCenter>
        </>
      ) : (
        <Table>
          <TableBody>
            {tableRows?.map((row) => (showRow(row) &&
              <TableRow key={row.renderKey} className='personCommunicationItem' onTouchStart={() => setChosenDataItem(row)}>
                <TableCell>
                  {row.name}
                </TableCell>
                <TableCell align="left">{row.value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}

      <ImageUploadPopup
        isOpen={isScanCardPopupOpen}
        onSave={(data) => {
          console.log('Scanned business card data:', data);
          onScanCardModalClose();
        }}
        onClose={onScanCardModalClose}
        isScanMode
      />

      <DataItemActionsPopup
        isOpen={isDataItemActionsPopupOpen}
        onClose={onDataItemActionsPopupClose}
        chosenDataItem={chosenDataItem}
      />
    </LocalizationProvider>
  );
};