import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Container, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, useMediaQuery
} from '@mui/material';

//@ts-ignore
import QuickActionButtons from '../../common-components/QuickActionButtons';
//@ts-ignore
import Navbar from '../Navbar';
//@ts-ignore
import BoxCenter from '../../common-components/BoxCenter';
//@ts-ignore
import CustomButton from '../../common-components/CustomButton';
//@ts-ignore
import { useGlobalStyles } from '../../../customStyles';
//@ts-ignore
import SectionHeading from '../../common-components/SectionHeading';
//@ts-ignore
import { useGetFormsQuery } from '../../../redux/services/FormService';

import { IForm } from '../../../redux/slices/formSlice'

import dayjs from 'dayjs'
import { FormOverviewRow } from '../FormOverviewRow/FormOverviewRow';
import { useUpdateGoalStatusMutation } from '../../../redux/services/FormService';


//import { useStyles } from './styles';

const infoFiles = [
  'Antrag_Wirtschaftsparlament.docx',
  'Fragen_Millionärssteuer.pdf',
  'Grafik_Frauen_Neugründungen.png',
  'WK_Broschüre_ALLE.pdf',
  'Grafik_EPUundKMU.png',
];

export const FormOverview = () => {
  const theme = useTheme();
  //const classes = useStyles();
  const cssClass = useGlobalStyles();

  const { data, error, isLoading } = useGetFormsQuery(null, undefined);
  const forms: Array<IForm> = data;

  const pageSize = 10;
  const resultCount = forms?.length ?? 0;

  const [page, setPage] = useState(0);
  const [formList, setFormList] = useState<Array<IForm>>([])

  useEffect(() => {
    let list: Array<any> = forms?.slice(page * pageSize, (page + 1) * pageSize);

    while (list?.length < 10) {
      list.push({})
    }

    setFormList(list);
  }, [forms])

  const handlePageChange = (event: any, inputPage: number) => {
    const newPage = inputPage - 1;
    setPage(newPage);

    let list: Array<any> = forms.slice(newPage * pageSize, (newPage + 1) * pageSize);

    while (list?.length < 10) {
      list.push({})
    }

    setFormList(list);
  }


  const [updateFormStatus, { error: updateError, isLoading: isUpdating }] = useUpdateGoalStatusMutation();
  const onChange = (form: IForm) => {
    updateFormStatus(form)
    setFormList(old => old.map(el => {
      if (el.ID === form.ID) {
        return form
      }
      return el
    }))
  }

  const isMobile = useMediaQuery("(max-width: 850px)")

  return (
    <>
      <SectionHeading title="Formulare" />
      <Container maxWidth="xl">
        <TableContainer>
          <Table style={isMobile ? { width: "500%" } : {}}>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: "12%" }}>Datum</TableCell>
                <TableCell style={{ width: "25%" }}>Titel</TableCell>
                {/* <TableCell style={{ width: "15%" }}>Firma</TableCell> */}
                {!isMobile && <TableCell style={{ width: "10%" }}>E-Mail Zustellung</TableCell>}
                <TableCell style={{ width: "13%" }}>E-Mail</TableCell>
                {/* <TableCell style={{ width: "15%" }}>Kopie Empfänger</TableCell> */}
                <TableCell style={{ width: "15%" }}>Status</TableCell>
                <TableCell style={{ width: "10%" }}>Ersteller</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {formList?.map((row: IForm) => (
                <FormOverviewRow onChange={onChange} row={row} key={row.ID} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <BoxCenter>
          <Pagination
            shape="rounded"
            count={Math.ceil(resultCount / pageSize)}
            onChange={handlePageChange}
            page={page + 1}
          />
        </BoxCenter>
      </Container>
    </>
  );
};
