import React, { useContext } from 'react';
import { ICompany } from "../../../interfaces/ICompany";
import { IPerson } from "../../../interfaces/IPerson";
import { contactPersonPositions } from "../../../constants/contactPersonPositions";
import ModalWrapper from "../../common-components/ModalWrapper";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Button, FormControl } from "@mui/material";
import FormDatePicker from "../../common-components/FormDatePicker";
import CustomFormSelect from "../../common-components/CustomFormSelect/CustomFormSelect";
import { useForm, useWatch } from "react-hook-form";
import { chooseContactPersonPosition, useSnackbarHelpers } from "../../../helpers";
import BoxCenter from '../../common-components/BoxCenter';
import CustomButton from '../../common-components/CustomButton';
import { useInsertContactPersonMutation } from '../../../redux/services/ContactPersonService';
import { CompanyContext } from '../../pages/Company/CompanyContext';
import dayjs from 'dayjs';
import { Messages } from '../../../constants/messages';

interface IPersonToContactPerson {
    isOpen: boolean,
    onClose: () => void,
    personId: number,
    company: ICompany
}

export default function PersonToContactPersonPopup({ isOpen, onClose, personId, company }: IPersonToContactPerson) {
    //@ts-ignore
    const { setContactPersonList } = useContext(CompanyContext)

    const { showInfoSnackbar, showErrorSnackbar } = useSnackbarHelpers()

    const [insertContactPerson] = useInsertContactPersonMutation();

    const { control, reset, setValue } = useForm({
        defaultValues: {
            start: '',
            end: '',
            position: 'F'
        }
    })

    const values = useWatch({ control })

    const selectOptions = contactPersonPositions.map(({ positionValue }) => ({
        positionValue,
        Title: chooseContactPersonPosition(positionValue),
    }))

    const handleSubmit = async () => {
        const data = {
            position: values.position!,
            start: values.start ? dayjs(values.start).format('YYYY-MM-DD') : undefined,
            end: values.end ? dayjs(values.end).format('YYYY-MM-DD') : undefined,
            personId: personId,
            companyId: company.ID
        }

        const result = await insertContactPerson(data).unwrap()
        if (result.isSuccess) {
            if (result.data?.Person) {
                setContactPersonList((old: any) => ([...old, result.data].sort((a, b) => {
                    return a.Person.Lastname - b.Person.Lastname
                })))
                showInfoSnackbar(Messages.Success)
            } else {
                showInfoSnackbar(Messages.ChangeProposalCreated)
            }
        } else {
            showErrorSnackbar(Messages.Error)
        }
        reset();
        onClose();
    }

    return (
        <ModalWrapper
            isOpen={isOpen}
            onClose={onClose}
            title="Kontaktperson hinzufügen">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <FormControl>

                    <CustomFormSelect
                        name="position"
                        label="Position"
                        control={control}
                        valueKey='positionValue'
                        options={selectOptions}
                        titleKey='Title'
                        required
                    />
                    <FormDatePicker
                        name="start"
                        label="Start"
                        control={control}

                    />
                    <FormDatePicker
                        name="end"
                        label="Ende"
                        control={control}
                    />
                </FormControl>

                <BoxCenter>
                    <Button
                        value='Bestätigen'
                        onClick={() => handleSubmit()}
                    >
                        Hinzufügen
                    </Button>
                </BoxCenter>
            </LocalizationProvider>
        </ModalWrapper>
    )
}   