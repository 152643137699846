import React from 'react';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import Container from '@mui/material/Container';

import SectionHeading from '../SectionHeading';

import { styles } from './styles';
import { useGlobalStyles } from '../../../customStyles';
import { SxProps } from '@mui/material';

interface IModalWrapper {
  children?: React.ReactNode
  isOpen: boolean
  onClose: () => void
  title: string
  modalWidth?: number | string
  className?: string
  sx?: SxProps
  disableModalContainerScroll?: boolean
}

export default function ModalWrapper({ children, isOpen, onClose, title, modalWidth, className, sx, disableModalContainerScroll }: IModalWrapper) {
  const cssGlobalClass = useGlobalStyles();

  const modalClassName = className + ' ' + cssGlobalClass.swvModalDefault;

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box className={modalClassName} sx={{ maxWidth: modalWidth, width: '100% !important', boxSizing: "border-box", ...sx }}>
        <CloseIcon className={cssGlobalClass.closeIcon} onClick={onClose} />
        <SectionHeading
          title={title}
          sx={{ m: '16px 0' }}
        />

        <Container maxWidth="xl" sx={{ overflow: disableModalContainerScroll ? 'hidden' : 'auto', padding: 0 }}>
          {children}
        </Container>
      </Box>
    </Modal>
  );
}
