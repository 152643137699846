import React, { useContext } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import { ContactPersonContext } from '../../PersonContext';
import { useStyles } from './styles';
import { useGlobalStyles } from '../../../../../customStyles';
import { swvTheme } from '../../../../../swvTheme';
import { getDate } from '../../../../../helpers/database';

export const FunctionList = () => {
  const classes = useStyles();
  const cssClass = useGlobalStyles();

  const {
    chosenPerson,
  } = useContext(ContactPersonContext);

  return (
    <Grid xs={12} mt={3}>
      <Box sx={{ alignItems: 'center' }}>
        <Paper sx={{ borderRadius: '8px' }}>
          <Typography color={swvTheme.typography.lilac} fontSize={18} fontWeight={500} p={2}>
            Funktionen
          </Typography>
          <div className={classes.spaceBetweenTables}>
            {chosenPerson?.Member?.Functions?.map((row) => (
              <Table key={row.ID} sx={{ borderBottom: '1px dashed #C7C7C7;' }}>
                <TableBody className={cssClass.customTableBody}>
                  <TableRow>
                    <TableCell>Sparte/Gremium/BG</TableCell>
                    <TableCell colSpan={2}>{row.Panel?.Name ?? row.BusinessCategory?.Name ?? row.BusinessGroup?.Name}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={cssClass.shrinkCell}>Name</TableCell>
                    <TableCell>{row.Name}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Anfang</TableCell>
                    <TableCell colSpan={2}>{getDate(row.Start)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Ende</TableCell>
                    <TableCell colSpan={2}>{getDate(row.End)}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            ))}
          </div>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 2,
              justifyContent: 'flex-end',
              padding: 2,
              cursor: 'pointer',
            }}
          >
          </Box>
        </Paper>
      </Box>
    </Grid>
  );
};