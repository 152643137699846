import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import TableContainer from '@mui/material/TableContainer';
import FilterTop from '../../../../common-components/FilterTop';
import FormTextField from '../../../../common-components/FormTextField';

import { useGlobalStyles } from '../../../../../customStyles';
import { Box, Grid, Typography } from '@mui/material';
import { swvTheme } from '../../../../../swvTheme';
import { useStyles } from './styles';
import DataCard from '../../../../common-components/DataCard';
import { getBoolean, getPhotoUrl } from '../../../../../helpers/database';

export const PersonSearch = ({
  onSearch,
  onRowClick,
  onNewButtonClick,
  deletePerson,
  chosenPerson,
  personList,
  companyData,
}) => {
  const classes = useStyles();
  const cssClass = useGlobalStyles();

  const { control, handleSubmit } = useFormContext();
  const values = useWatch({ control });

  function renderPerson(person) {
    return (
      <DataCard
        key={person.ID}
        object={person}
        lineOne={person.Firstname + " " + person.Lastname}
        lineTwo={'Mitglied: ' + getBoolean(person.Member) + ' - Familie: ' + getBoolean(person.IsFamily)}
        placeholderImage="person"
        image={getPhotoUrl(person)}
        onClick={() => onRowClick(person, true)}
      />
    );
  }

  return (
    <FormControl>
      <Typography variant="p" fontSize={"18px"} color={swvTheme.palette.button.dark} mb={1}>
        SUCHE NACH PERSONEN
      </Typography>
      <FilterTop>
        <form onSubmit={handleSubmit(() => onSearch(values))} style={{ display: 'flex', flexDirection: 'row' }}>
          <FormTextField
            name="FirstnameQuery"
            label="Vorname"
            control={control}
            sx={{ mr: 1 }}
            autoFocus
          />
          <FormTextField
            name="LastnameQuery"
            label="Nachname"
            control={control}
            sx={{ mr: 1 }}
          />

          <Box sx={{ display: 'flex', flexDirection: 'row' }} my={1}>
            <Button
              type='submit'
              sx={{ width: '100%', marginRight: 1 }}
            >
              Suchen
            </Button>

            <Button
              onClick={() => onNewButtonClick()}
              sx={{ width: '100%', marginLeft: 1 }}
            >
              Neu
            </Button>
          </Box>
        </form>
      </FilterTop>

      <TableContainer>
        <Grid className={classes.personTableBottom}>
          <Typography variant={'h1'} fontSize={"18px"} sx={{ color: swvTheme.palette.button.dark }} pt={2} m={"auto"}>
            <span style={{ color: swvTheme.palette.primary.main }}>{personList?.length ?? 0}</span> Ergebnisse
          </Typography>
          {personList?.map(renderPerson)}
        </Grid>
      </TableContainer>
    </FormControl >
  );
};
