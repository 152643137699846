import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQuery } from "./basequery";
import * as endpoints from "../../constants/endpoints";

export const OrganizationAPI = createApi({
    reducerPath: 'Organizations',
    baseQuery,
    tagTypes: ["organizations", "MemberList"],
    endpoints: (build) => ({
        getList: build.query({
            query: () => ({
                url: endpoints.getOrganisationList,
                method: 'POST',
            }),
            providesTags: ['organizations']
        }),
        getMembersList: build.query({
            query: ({ organizationId = undefined, panelId = undefined }) => ({
                url: endpoints.getMemberList,
                params: { organizationId, panelId },
                method: 'GET'
            }),
            providesTags: ['MemberList']
        }),
        insertMember: build.mutation({
            query: (data) => ({
                url: endpoints.insertMember,
                body: data,
                method: "POST"
            }),
            invalidatesTags: ['MemberList']
        }),
        removeMember: build.mutation({
            query: (data) => ({
                url: endpoints.removeMember,
                body: data,
                method: "DELETE"
            }),
            invalidatesTags: ['MemberList']
        }),
    })
});
