import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { ReactComponent as CameraIcon } from '../../../../../assets/icons/camera.svg';
import { ReactComponent as BusinessCardIcon } from '../../../../../assets/icons/business-card.svg';

import { styles } from './styles';
import { PopupModes } from '../../constants';
import BoxCenter from '../../../../common-components/BoxCenter';

export const NoUploads = ({ hiddenFileInputRef, setPhotoUploadMode }) => {
  const openFileBrowser = () => {
    hiddenFileInputRef.current.click();
  };

  const openWebcamScreen = () => {
    setPhotoUploadMode(PopupModes.WEBCAM);
  };

  return (
    <Box sx={styles.noUploadsContainer}>
      <Box sx={styles.noUploadsIcons}>
        <CameraIcon />
        <BusinessCardIcon />
      </Box>

      <Typography
        sx={styles.mainCaption}
      >
        Bild hochladen
      </Typography>
      <Typography
        sx={styles.subCaption}
      >
        Drag & Drop
      </Typography>
      <Typography sx={{ fontSize: '16px' }}>oder</Typography>
      <BoxCenter sx={styles.buttonWrapper}>
        <Button onClick={openWebcamScreen} sx={styles.openFileBrowserButton}>
          Bild aufnehmen
        </Button>
        <Button onClick={openFileBrowser} sx={styles.openFileBrowserButton}>
          Datei auswählen
        </Button>
      </BoxCenter>
    </Box>
  );
};

NoUploads.propTypes = {
  hiddenFileInputRef: PropTypes.object.isRequired,
};
