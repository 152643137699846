import React from 'react';

export default function BusinessCard() {
  return (
    <svg width="36" height="23" viewBox="0 0 36 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1286_6519)">
        <path d="M33.4286 0.5H2.57143C1.15749 0.5 0 1.62533 0 3V20.5C0 21.8747 1.15749 23 2.57143 23H33.4286C34.8425 23 36 21.8747 36 20.5V3C36 1.62533 34.8425 0.5 33.4286 0.5ZM34.7143 20.5C34.7143 21.25 34.2 21.75 33.4286 21.75H2.57143C1.8 21.75 1.28571 21.25 1.28571 20.5V3C1.28571 2.25 1.8 1.75 2.57143 1.75H33.4286C34.2 1.75 34.7143 2.25 34.7143 3V20.5Z" fill="white"/>
        <path d="M4.49965 8.00018H14.7854C15.1707 8.00018 15.4286 7.75018 15.4286 7.37485V4.87485C15.4286 4.50018 15.1714 4.24951 14.7854 4.24951H4.49965C4.11427 4.24951 3.85645 4.49951 3.85645 4.87485V7.37485C3.85645 7.74951 4.11359 8.00018 4.49965 8.00018ZM5.14285 5.50018H14.1428V6.75018H5.14216V5.50018H5.14285Z" fill="white"/>
        <path d="M4.49965 10.5002H18C18.3854 10.5002 18.6432 10.2502 18.6432 9.87484C18.6432 9.49951 18.386 9.24951 18 9.24951H4.49965C4.11427 9.24951 3.85645 9.49951 3.85645 9.87484C3.85645 10.2502 4.11359 10.5002 4.49965 10.5002Z" fill="white"/>
        <path d="M19.6718 11.75H4.50035C4.11498 11.75 3.85715 12 3.85715 12.3753C3.85715 12.7507 4.11429 13.0007 4.50035 13.0007H19.6718C20.0571 13.0007 20.315 12.7507 20.315 12.3753C20.315 12 20.0578 11.75 19.6718 11.75Z" fill="white"/>
        <path d="M14.7854 14.25H4.49965C4.11427 14.25 3.85645 14.5 3.85645 14.8753C3.85645 15.2507 4.11359 15.5007 4.49965 15.5007H14.7854C15.1707 15.5007 15.4286 15.2507 15.4286 14.8753C15.4286 14.5 15.1714 14.25 14.7854 14.25Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_1286_6519">
          <rect width="36" height="22.5" fill="white" transform="translate(0 0.5)"/>
        </clipPath>
      </defs>
    </svg>
  )
}