import * as endpoints from '../../constants/endpoints';
import { apiClient } from '../apiClient';

export const updateOne = async (data, success, error) => {
  const response = await apiClient.updateOne(endpoints.updateCommunicationData, data, success, error);
  return response.data;
};

export const insertOne = async (data, success, error) => {
  const response = await apiClient.insertOne(endpoints.insertCommunicationData, data, success, error);
  return response.data;
};
