import React, { useState, useContext, useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';

import Box from '@mui/material/Box';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';

import SignaturePad from 'react-signature-canvas';

import FormSelect from '../../common-components/FormSelect';
import { chooseContactPersonPosition } from '../../../helpers/database';
import * as ContactPersonService from '../../../services/requests/contactPerson';
import { useSnackbarHelpers, downloadFile } from '../../../helpers/utils';
import { Messages } from '../../../constants/messages';
import FormDatePicker from '../../common-components/FormDatePicker';
import BoxCenter from '../../common-components/BoxCenter';
import ModalWrapper from '../../common-components/ModalWrapper';
import FormTextField from '../../common-components/FormTextField';
import FormBooleanSelect from '../../common-components/FormBooleanSelect';
import FormCheckbox from '../../common-components/FormCheckbox';
import CustomButton from '../../common-components/CustomButton';
import { getDate } from '../../../helpers/database';

import { useStyles } from './styles';
import { useGlobalStyles } from '../../../customStyles';

export default function MembershipPopUp({ isOpen, onClose, chosenPerson }) {
  const classes = useStyles();
  const cssClass = useGlobalStyles();
  const [emailError, setEmailError] = useState(false);
  const [sigPad, setSigPad] = useState(null);
  const [isPersonDisabled, setIsPersonDisabled] = useState(false);
  const [contactPersonList, setContactPersonList] = useState(null);
  const [chosenContactPerson, setChosenContactPerson] = useState(null);

  const { showErrorSnackbar } = useSnackbarHelpers();

  const clear = () => sigPad.clear();

  const onRowClick = (row) => {
    setChosenContactPerson(row);
  };

  const { control, reset } = useForm({
    defaultValues: {
      isVolunteering: '',
      start: '',
      type: '',
      delivery: '',
      signature: '',
      sendMail: false,
      email: '',
    },
  });

  const values = useWatch({ control });

  useEffect(() => {
    if (isOpen)
    {
      if (values.type === 'Person') {
        setIsPersonDisabled(true);

        reset({
          ...values,
          delivery: 'Privat',
        });

        setChosenContactPerson(null);
      } else {
        setIsPersonDisabled(false);

        reset({
          ...values,
          delivery: '',
        });

        ContactPersonService.getList(null, chosenPerson.ID, setContactPersonList);
      }
    }
  }, [values.type]);

  useEffect(() => {
    if (!isOpen) {
      reset({
        isVolunteering: '',
        start: '',
        type: '',
        delivery: '',
        signature: '',
        sendMail: false,
        email: '',
      });
    }
  }, [isOpen]);

  const disabled =
    emailError ||
    values.isVolunteering === '' ||
    !values.start ||
    !values.delivery ||
    (!isPersonDisabled && !chosenContactPerson);

  const handleSubmit = async () => {
    if (chosenPerson && !chosenPerson.AddressRegistration)
    {
      showErrorSnackbar(Messages.ErrorAddress);
      return;
    }

    const data = {
      id: chosenContactPerson?.ID || chosenPerson?.ID,
      isVolunteering: values.isVolunteering,
      start: values.start,
      type: values.type,
      delivery: values.delivery,
      signature: sigPad.getCanvas().toDataURL('image/png'),
      sendMail: values.sendMail,
      email: values.email,
    };

    await ContactPersonService.generateMembershipPDF(data, (data) => {
      downloadFile(data, 'Beitrittsformular.pdf');
    });
  };

  return (
    <ModalWrapper isOpen={isOpen} onClose={onClose} title="Beitrittsformular">
      <FormControl>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <FormDatePicker
              name="start"
              label="Datum"
              control={control}
              required
            />
            <FormBooleanSelect
              label="Mitarbeiten"
              name="isVolunteering"
              control={control}
              value={values.isVolunteering}
              required
            />
            <FormSelect
              required
              label="Typ"
              name="type"
              control={control}
              value={values.type}
            >
              <MenuItem value={'Person'}>Person</MenuItem>
              <MenuItem value={'Firma'}>Firma</MenuItem>
            </FormSelect>
            <FormSelect
              required
              label="Zusendung"
              name="delivery"
              control={control}
              disabled={isPersonDisabled}
              value={isPersonDisabled ? 'Privat' : values.delivery}
            >
              <MenuItem value={'Privat'}>Privat</MenuItem>
              <MenuItem value={'Firma'}>Firma</MenuItem>
            </FormSelect>
        </LocalizationProvider>
      </FormControl>
      {values?.type === 'Firma' &&
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 1 }} >
          <TableContainer>
            <Table sx={{ minWidth: 450 }}>
              <TableHead>
                <TableRow>
                  <TableCell>Firma</TableCell>
                  <TableCell>Position</TableCell>
                  <TableCell className={cssClass.dateCell}>Anfang</TableCell>
                  <TableCell className={cssClass.dateCell}>Ende</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {contactPersonList?.map((row) => (
                  <TableRow
                    key={row.ID}
                    selected={chosenContactPerson?.ID == row.ID}
                    onClick={() => onRowClick(row)}
                  >
                    <TableCell>{row.Slave.Name}</TableCell>
                    <TableCell>{chooseContactPersonPosition(row.Position)}</TableCell>
                    <TableCell>{getDate(row.Start)}</TableCell>
                    <TableCell>{getDate(row.End)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      }
      <Box sx={{ display: 'flex', flexDirection: 'column', mt: 1 }} >
        <span>
          Unterschrift:
        </span>
        <div className={cssClass.sigContainer}>
          <SignaturePad
            canvasProps={{ className: cssClass.sigPad }}
            ref={(ref) => setSigPad(ref)}
          />
          <div className={cssClass.clearSignature} onClick={() => clear()}>
            Zurücksetzen
          </div>
        </div>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', mt: 1 }} >
        <FormCheckbox
          name="sendMail"
          control={control}
          label="Kopie an folgende Email Adresse:"
        />
        <FormTextField
          name="email"
          label="Email"
          control={control}
          type="email"
          func={setEmailError}
          />
      </Box>
      <BoxCenter>
        <CustomButton
            disabled={disabled}
            value="PDF erstellen"
            onClick={() => handleSubmit()}
            sx={{ mb: 10, width: '300px !important' }}
          />
      </BoxCenter>
    </ModalWrapper>
  );
}