import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  labelLight: {
    '& .MuiInputLabel-shrink, & .MuiInputLabel-asterisk, & .MuiFormHelperText-root':
      {
        color: '#FFFFFF !important',
      },
  },
});
