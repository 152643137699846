import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialContactPersonState = {
  contactPersons: [],
};

const ContactPersonSlice = createSlice({
  name: 'contactPerson',
  initialState: initialContactPersonState,
  reducers: {
    setContactPersons(state, action) {
      state.contactPersons = action.payload;
    },
  },
});

export default ContactPersonSlice.reducer;
export const { setContactPersons } = ContactPersonSlice.actions;
