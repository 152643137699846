import React from 'react';
import { Link } from '@mui/material';

import { useStyles } from './styles';
import { useGlobalStyles } from '../../../customStyles';

function FormLink({ children, ...props }) {
  const classes = useStyles();
  const cssGlobalClass = useGlobalStyles();

  return (
    <Link variant="body2" underline="hover" {...props}>
      {children}
    </Link>
  );
}

export default FormLink;
