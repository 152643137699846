import React, { useState, useEffect, useCallback } from 'react';
import { useForm, useWatch } from 'react-hook-form';

import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';

import FilterTop from '../../common-components/FilterTop';
import BoxCenter from '../../common-components/BoxCenter';
import ModalWrapper from '../../common-components/ModalWrapper';
import FormTextField from '../../common-components/FormTextField';

import { useStyles } from './styles';
import { useGlobalStyles } from '../../../customStyles.js';

import * as PersonService from '../../../services/requests/person';
import * as MemberService from '../../../services/requests/member';

import { getPerson, getAddressRegistration } from '../../../helpers/database';
import { useSnackbarHelpers } from '../../../helpers';

export default function RecruiterPopUp({
  isOpen,
  onClose,
  chosenPerson,
  onPersonApply
}) {
  const classes = useStyles();
  const cssClass = useGlobalStyles();

  const { showErrorSnackbar, showInfoSnackbar } = useSnackbarHelpers();
  const [memberList, setMemberList] = useState(null);
  const [chosenMember, setChosenMember] = useState(null);

  // control

  const { control,handleSubmit, reset } = useForm({
    defaultValues: {
      firstname: '',
      lastname: '',
    }
  });

  const values = useWatch({ control });

  const clearListAndFetchMembers = () => {
    setMemberList([]); 
    getMemberList();
};

  useEffect(() => {
    if (!isOpen) {
      reset({
        firstname: '',
        lastname: '',
      });
      setChosenMember(null);
      setMemberList(null);
    }
  }, [isOpen])

  const getMemberList = async () => {
    const result = await MemberService.getListOfMember({
      Firstname: values.firstname,
      Lastname: values.lastname,
      isMember: true,
    })
        
    if (result?.length > 0) {
      setMemberList(result);
    } else if(result?.length === 0){
      showErrorSnackbar('Es konnten keine Treffer gefunden werden.');
    }else{
      showErrorSnackbar('Fehler beim Laden der Liste');
    }
  };

  return (
    <ModalWrapper isOpen={isOpen} onClose={onClose} modalTitle="Werber">
      <FormControl>
      <form onSubmit={handleSubmit(clearListAndFetchMembers)}>
        <FilterTop>
          <FormTextField
            name="firstname"
            label="Vorname"
            control={control}
          />
          <FormTextField
            name="lastname"
            label="Nachname"
            control={control}
          />
          <Button
            type="submit"
            disabled={!values?.firstname && !values?.lastname}
          >
            Suchen
          </Button>
        </FilterTop>
        </form>
        <div className={cssClass.listContainer}>
          {memberList?.map((row) => <p
            key={row.ID}
            className={chosenMember?.ID == row.ID ? cssClass.chosen : cssClass.notchosen}
            onClick={() => setChosenMember(row)}
          >
            {getPerson(row.Person)}{row.Person.AddressRegistration ? `, ${getAddressRegistration(row.Person.AddressRegistration)}` : ''}
          </p>)}
        </div>
      </FormControl>
      <BoxCenter>
        {chosenPerson?.Member?.Recruiter && <Button
          onClick={() => onPersonApply(null)}
        >
          Entfernen
        </Button>}
        <Button
          disabled={!chosenMember}
          onClick={() => onPersonApply(chosenMember)}
        >
          Übernehmen
        </Button>
      </BoxCenter>
    </ModalWrapper>
  );
}
