import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { DeleteForever } from '@mui/icons-material';


import { getDate, getPhotoUrl } from '../../../helpers/database';
import * as FavoriteService from '../../../services/requests/favorite';

import ModalWrapper from '../../common-components/ModalWrapper';
import DataCard from '../../common-components/DataCard/DataCard';

import { styles } from './styles';
import { Box, Grid } from '@mui/material';

export default function FavoritePopUp({ isOpen, onClose }) {
  const navigate = useNavigate();
  const [companyList, setCompanyList] = useState(null);

  useEffect(() => {
    if (isOpen) {
      getFavoriteList();
    }
  }, [isOpen]);

  const getFavoriteList = async () => {
    await FavoriteService.getList(setCompanyList);
  };

  const deleteFavorite = async (id) => {
    await FavoriteService.deleteOne(id, getFavoriteList);
  };

  const onRowClicked = (row) => {
    onClose();
    navigate(`/firma/${row.Company.UniqueWKO}`, { replace: false });
  };

  return (
    <ModalWrapper isOpen={isOpen} onClose={onClose} title="Favoriten">
      <Box sx={styles.rootContainer}>
        <Grid container spacing={2} columns={2}>
          {companyList?.map((row) => (
            <Grid
              key={row.ID}
              item
              xs={2}
              md={1}
              onClick={() => onRowClicked(row)}
            >
              <DataCard
                key={row.ID}
                placeholderImage="company"
                image={getPhotoUrl(row.Company)}
                truncate
                lineOne={row.Company.Name}
                lineTwo={(
                  <Box sx={styles.favoriteItemDeleteLine}>
                    {getDate(row.Creation)}
                    <DeleteForever
                      sx={styles.deleteIcon}
                      onClick={async (e) => {
                        e.stopPropagation();

                        deleteFavorite(row.ID);
                        }
                      }
                    />
                  </Box>
                )}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </ModalWrapper>
  );
}
