import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const SectionHeading = (props: any) => {
  return (
    <Box
      sx={{
        mt: '2em',
        mb: '2em',
        textAlign: 'center',
        ...props.sx,
      }}
    >
      <Typography variant="h1">
        {props.title}
      </Typography>
      {props.subtitle && (
        <Typography variant="body1" sx={{ mt: '14px' }}>{props.subtitle}</Typography>
      )}
    </Box>
  );
};

SectionHeading.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  sx: PropTypes.object,
};

export default SectionHeading;
