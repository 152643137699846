import React, { useState } from 'react';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import TextField from '@mui/material/TextField';
import { ReactComponent as CalendarIcon } from '../../../assets/icons/calendar.svg';

export default function FullwidthDatePicker({
  value,
  disabled,
  required,
  label,
  onChange,
}) {
  const [open, setOpen] = useState(false);

  return (
    <DesktopDatePicker
      label={`${label}` + (required ? ' *' : '')}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      inputFormat="DD.MM.YYYY"
      value={value}
      onChange={onChange}
      components={{
        OpenPickerIcon: () => <CalendarIcon />,
      }}
      renderInput={(params) => (
        <TextField
          onClick={() => {
            if (!disabled) setOpen(true);
          }}
          {...params}
          error={false}
          fullWidth
          margin="dense"
          autoComplete="off"
        />
      )}
      disabled={disabled}
      required={required}
    />
  );
}
