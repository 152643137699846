import React, { useEffect, useState } from 'react';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Container from '@mui/material/Container';

import { a11yProps } from '../../common-components/TabPanel/tabsProps';
import TabPanel from '../../common-components/TabPanel';
import SectionHeading from '../../common-components/SectionHeading';

import { AdminGoalContext } from './AdminGoalContext';

import GoalTable from './components/GoalTable';
import GoalDetails from './components/GoalDetails';
import UserSearch from './components/UserSearch';
import UserGoals from '../UserGoals';

import Navbar from '../Navbar';

import * as GoalService from '../../../services/requests/goal';
import * as UserService from '../../../services/requests/user';
import { useGetListGoalsQuery } from '../../../redux/services/GoalStatusService';

export default function AdminGoal() {

  const [currentTab, setCurrentTab] = useState(0);
  const [goalList, setGoalList] = useState(null);
  const [userList, setUserList] = useState(null);
  const [currentGoal, setCurrentGoal] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);

  const handleTabChange = (_, newValue) => {
    setCurrentTab(newValue);
  };

  /*const getGoalList = async () => {
    await GoalService.getList(setGoalList);
  };*/

  const getUserList = async (query) => {
    await UserService.getList(query, setUserList);
  };

  const { data: dbGoalList } = useGetListGoalsQuery(undefined)

  useEffect(() => {
    console.log("dbGoalList", dbGoalList);
    if (dbGoalList?.data?.length) {
      setGoalList(dbGoalList.data)
    }
  }, [dbGoalList])


  return (
    <AdminGoalContext.Provider
      value={{
        setCurrentTab,

        goalList,
        currentGoal,
        setCurrentGoal,

        currentUser,
        setCurrentUser,
        getUserList,
        userList,
      }}
    >
      <SectionHeading title="Ziele" />
      <Container maxWidth="lg">
        <Tabs value={currentTab} onChange={handleTabChange}>
          <Tab label="Ziele" {...a11yProps(0)} />
          {/*<Tab label="Ziel" {...a11yProps(1)} disabled={!currentGoal} />
          <Tab label="Benutzer" {...a11yProps(2)} disabled={!currentGoal} />
          <Tab
            label="Zielauswertung"
            {...a11yProps(3)}
            disabled={!currentUser || !currentGoal}
          />*/}
        </Tabs>
      </Container>

      <TabPanel value={currentTab} index={0}>
        <GoalTable />
      </TabPanel>

      <TabPanel value={currentTab} index={1}>
        <GoalDetails />
      </TabPanel>

      <TabPanel value={currentTab} index={2}>
        <UserSearch />
      </TabPanel>

      <TabPanel value={currentTab} index={3}>
        <UserGoals currentUser={currentUser} />
      </TabPanel>
    </AdminGoalContext.Provider>
  );
}
