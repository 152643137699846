import React, { useEffect, useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { ContentCopy, Share } from '@mui/icons-material';

import ModalWrapper from '../../common-components/ModalWrapper';
import BoxCenter from '../../common-components/BoxCenter';

import { useStyles } from './styles';
import { useGlobalStyles } from '../../../customStyles';
import { swvTheme } from '../../../swvTheme';
import { useSnackbarHelpers, informationPopup } from '../../../helpers/utils';
import { Messages } from '../../../constants/messages';

export default function DataItemActionsPopup({ isOpen, onClose, chosenDataItem }) {
  const classes = useStyles();
  const cssClass = useGlobalStyles();

  const { } = useSnackbarHelpers();

  const copyText = () => {
    navigator.clipboard.writeText(chosenDataItem.value);
  }

  const shareText = async () => {
    if (navigator.canShare)
      await navigator.share({ title: chosenDataItem.value, text: chosenDataItem.value });
    else
      await informationPopup(Messages.FeatureNotAvailable);
  }

  return (
    <ModalWrapper isOpen={isOpen} onClose={onClose} title={chosenDataItem?.name} sx={{ maxWidth: '360px !important' }}>
      <BoxCenter>
        <Typography fontSize={18} textAlign={"center"} py={1}>{chosenDataItem?.value}</Typography>
      </BoxCenter>
      <BoxCenter sx={{flexDirection: 'row'}}>
        <IconButton
          aria-label="copy"
          color="primary"
          onClick={() => copyText()}
        >
          <ContentCopy />
        </IconButton>
        <IconButton
          aria-label="share"
          color="primary"
          onClick={() => shareText()}
        >
          <Share />
        </IconButton>
      </BoxCenter>
     </ModalWrapper>
  );
}
