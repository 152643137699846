import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQuery } from "./basequery";
import * as endpoints from "../../constants/endpoints";

export const BusinessCategoryAPI = createApi({
    reducerPath: 'BusinessCategory',
    baseQuery,
    tagTypes: ["BusinessCategory", "BusinessCategoryList"],
    endpoints: (build) => ({
        getList: build.query({
            query: () => ({
                url: endpoints.getBusinessCategoryList,
                method: 'POST',
            }),
            providesTags: () => ['BusinessCategoryList']
        })
    })
});

export const { useGetListQuery } = BusinessCategoryAPI