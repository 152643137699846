import React, { useContext, useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { Box, FormControl, Grid, IconButton, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { Close, Edit, Telegram, WhatsApp } from '@mui/icons-material';

import FormTextField from '../../../../common-components/FormTextField';
import CustomButton from '../../../../common-components/CustomButton';
import BoxCenter from '../../../../common-components/BoxCenter';
import SignalApp from '../../../../common-components/Icons/SignalApp/SignalApp';
import { CompanyContext } from '../../CompanyContext';
import { Messages } from '../../../../../constants/messages';
import { swvTheme } from '../../../../../swvTheme';
import { useGlobalStyles } from '../../../../../customStyles';
import Homepage from '../../../../common-components/Icons/Homepage';
import Email from '../../../../common-components/Icons/Email';
import Phone from '../../../../common-components/Icons/Phone';
import Messenger from '../../../../common-components/Icons/Messenger';
import { useStyles } from './styles';
import { ensureHttps } from '../../../../../helpers/utils';
import DataItemActionsPopup from '../../../../popups/DataItemActions';
import { ImageUploadPopup } from '../../../../popups/ImageUploadPopup';

export default function ContactTable({ onCompanySave }) {
  const classes = useStyles();
  const cssClass = useGlobalStyles();

  const [isEditMode, setIsEditMode] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [isDataItemActionsPopupOpen, setIsDataItemActionsPopupOpen] = useState(false);
  const [chosenDataItem, setChosenDataItem] = useState(null);
  const { companyData, setUpdatedCompanyContactFields, showRow, saveCommunicationData } = useContext(CompanyContext);

  const [isScanCardPopupOpen, setIsScanCardPopupOpen] = useState(false);

  const createData = (name, value) => {
    return { name, value };
  };

  const tableRows = [
    createData('Telefon:', companyData.CommunicationData?.Telephone),
    createData('Telefon (privat):', companyData.CommunicationData?.TelephonePrivate),
    createData('Mobil:', companyData.CommunicationData?.Mobile),
    createData('Mobil (privat):', companyData.CommunicationData?.MobilePrivate),
    createData('E-Mail:', companyData.CommunicationData?.Email),
    createData('E-Mail (Veranstaltungen):', companyData.CommunicationData?.EmailEvent),
    createData('E-Mail (Finanzen):', companyData.CommunicationData?.EmailFinancial),
    createData('E-Mail (Newsletter):', companyData.CommunicationData?.EmailNewsletter),
    createData('Homepage:', companyData.CommunicationData?.Homepage),
    createData('Fax:', companyData.CommunicationData?.Fax),

    createData('WhatsApp:', companyData.CommunicationData?.WhatsApp),
    createData('Telegram:', companyData.CommunicationData?.Telegram),
    createData('Messenger:', companyData.CommunicationData?.Messenger),
    createData('Signal:', companyData.CommunicationData?.Signal),
    createData('Xing:', companyData.CommunicationData?.Xing),
    createData('LinkedIn:', companyData.CommunicationData?.LinkedIn),
    createData('Facebook:', companyData.CommunicationData?.Facebook),
    createData('Instagram:', companyData.CommunicationData?.Instagram),
    createData('Twitter:', companyData.CommunicationData?.Twitter),
    createData('TikTok:', companyData.CommunicationData?.TikTok),
  ];

  const { control, reset } = useForm({
    defaultValues: {
      Telephone: '',
      TelephonePrivate: '',
      Mobile: '',
      MobilePrivate: '',
      Email: '',
      EmailEvent: '',
      EmailFinancial: '',
      EmailNewsletter: '',
      Homepage: '',
      Fax: '',

      WhatsApp: '',
      Telegram: '',
      Messenger: '',
      Signal: '',
      Xing: '',
      LinkedIn: '',
      Facebook: '',
      Instagram: '',
      Twitter: '',
      TikTok: '',
    },
  });

  const values = useWatch({ control });

  // needed, so the updatedCompanyFields isn't overwritten in other previous Forms
  setUpdatedCompanyContactFields(values);

  const defaultValues = {
    Telephone: companyData.CommunicationData?.Telephone,
    TelephonePrivate: companyData.CommunicationData?.TelephonePrivate,
    Mobile: companyData.CommunicationData?.Mobile,
    MobilePrivate: companyData.CommunicationData?.MobilePrivate,
    Email: companyData.CommunicationData?.Email,
    EmailEvent: companyData.CommunicationData?.EmailEvent,
    EmailFinancial: companyData.CommunicationData?.EmailFinancial,
    EmailNewsletter: companyData.CommunicationData?.EmailNewsletter,
    Homepage: companyData.CommunicationData?.Homepage,
    Fax: companyData.CommunicationData?.Fax,

    WhatsApp: companyData.CommunicationData?.WhatsApp,
    Telegram: companyData.CommunicationData?.Telegram,
    Messenger: companyData.CommunicationData?.Messenger,
    Signal: companyData.CommunicationData?.Signal,
    Xing: companyData.CommunicationData?.Xing,
    LinkedIn: companyData.CommunicationData?.LinkedIn,
    Facebook: companyData.CommunicationData?.Facebook,
    Instagram: companyData.CommunicationData?.Instagram,
    Twitter: companyData.CommunicationData?.Twitter,
    TikTok: companyData.CommunicationData?.TikTok,
  };

  useEffect(() => {
    if (companyData) {
      reset(defaultValues);
    }
  }, [companyData]);

  useEffect(() => {
    if (isEditMode) {
      values.isModified = true;
    }
  }, [values]);

  useEffect(() => {
    if (isEditMode) {
      for (const value in values) {
        if (values[value] !== defaultValues[value] && (value !== "isModified")) {
          document.getElementById(value).className += " modifiedValue";
        }
      }
    }
  }, [isEditMode]);

  useEffect(() => {
    let delay;

    document.querySelectorAll('.contactItem').forEach((item) => {
      const isNotEmpty = item.getElementsByTagName("td")[1].innerText;

      if (isNotEmpty) {
        item.addEventListener('touchstart', () => {
          delay = setTimeout(check, 1500);
          function check() {
            setIsDataItemActionsPopupOpen(true);
          }
        }, true);

        item.addEventListener('touchend', () => {
          clearTimeout(delay);
        });
      }
    });
  });

  const resetWarning = () => {
    values.isModified = false;
    setShowWarning(false);
    setIsEditMode(false);
  }

  const onDataItemActionsPopupClose = () => {
    setIsDataItemActionsPopupOpen(false);
  };

  const renderCellContainer = (label, icon, onClick, color) => {
    return (
      <TableCell align='right' className={cssClass.shrinkIconCell}>
        <IconButton className={classes.tableLineIcon} sx={{ color: color ?? swvTheme.palette.button.dark}} aria-label={label} onClick={onClick}>
          {icon}
        </IconButton>
      </TableCell>
    )
  }

  const renderCell = (row) => {
    if (!row.value) {
      return <TableCell></TableCell>;
    }

    switch (row.name) {
      case 'WhatsApp:': return renderCellContainer('whatsapp', <WhatsApp />, () => onWhatsAppClicked(row.value), swvTheme.palette.secondary.dark)
      case 'Telegram:': return renderCellContainer('telegram', <Telegram />, () => onTelegramClicked(row.value), swvTheme.palette.secondary.dark)
      case 'Messenger:': return renderCellContainer('messenger', <Messenger />, () => onMessengerClicked(row.value), swvTheme.palette.secondary.dark)
      case 'Signal:': return renderCellContainer('signal', <SignalApp />, () => onSignalClicked(row.value), swvTheme.palette.secondary.dark)
      case 'Homepage:': return renderCellContainer('homepage', <Homepage />, () => onHomepageClicked(row.value))
      case 'E-Mail:':
      case 'E-Mail (Veranstaltungen):':
      case 'E-Mail (Finanzen):':
      case 'E-Mail (Newsletter):': return renderCellContainer('email', <Email />, () => onEmailClicked(row.value))
      case 'Telefon:':
      case 'Telefon (privat):':
      case 'Mobil:':
      case 'Mobil (privat):': return renderCellContainer('telephone',  <Phone />, () => onPhoneClicked(row.value))
      default: return <TableCell></TableCell>;
    }
  }

  const onWhatsAppClicked = async (query) => {
    window.open("https://wa.me/" + query);
  };

  const onTelegramClicked = async (query) => {
    window.open("https://t.me/s/" + query);
  };

  const onMessengerClicked = async (query) => {
    window.open("http://m.me/" + query);
  };

  const onSignalClicked = async (query) => {
    window.open("https://signal.me/#p/" + query);
  };

  const onHomepageClicked = async (query) => {
    window.open(ensureHttps(query))
  }

  const onEmailClicked = async (query) => {
    window.open("mailto:" + query)
  }

  const onPhoneClicked = async (query)  => {
    window.open('tel:' + query)
  }

  // Scan Card modal
  const onScanCardModalOpen = async () => {
    setIsScanCardPopupOpen(true);
  };

  const onScanCardModalClose = async () => {
    setIsScanCardPopupOpen(false);
  };

  return (
    <>
      <Grid xs={12} mt={3}>
        <Box
          sx={{
            backgroundColor: '#FFFFFF',
            borderRadius: '8px',
            boxShadow: swvTheme.boxShadow,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              p: 2,
            }}
          >
            <Typography color={swvTheme.typography.lilac} fontSize={18} fontWeight={500}>
              Kontaktdaten
            </Typography>

            {showWarning &&
              <Typography color="primary">{ Messages.WarningUnsafed }</Typography>}

            {isEditMode ? (
            <>
              <IconButton aria-label="close" onClick={() =>
                { if (values?.isModified)
                    setShowWarning(true);
                  setIsEditMode(false)
                } }>
                <Close />
              </IconButton>
            </>
            ) : (
              <IconButton
                aria-label="edit"
                color="primary"
                onClick={() => setIsEditMode(!isEditMode)}
              >
                <Edit />
              </IconButton>
            )}
          </Box>
          {isEditMode ? (
            <FormControl sx={{ marginBottom: '28px' }}>
              <Table className={cssClass.twoColumnDataTable}>
                <TableBody className={cssClass.customTableBody}>
                  <TableRow>
                    <TableCell id="Telephone" className={cssClass.shrinkCell}>Telefon:</TableCell>
                    <TableCell>
                      <FormTextField name="Telephone" label="" control={control} value={values.Telephone} noDefaultValue />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell id="TelephonePrivate" className={cssClass.shrinkCell}>Telefon (privat):</TableCell>
                    <TableCell>
                      <FormTextField name="TelephonePrivate" label="" control={control} value={values.TelephonePrivate} noDefaultValue />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell id="Mobile" className={cssClass.shrinkCell}>Mobil:</TableCell>
                    <TableCell>
                      <FormTextField name="Mobile" label="" control={control} value={values.Mobile} noDefaultValue />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell id="MobilePrivate" className={cssClass.shrinkCell}>Mobil (privat):</TableCell>
                    <TableCell>
                      <FormTextField name="MobilePrivate" label="" control={control} value={values.MobilePrivate} noDefaultValue />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell id="Email" className={cssClass.shrinkCell}>E-Mail:</TableCell>
                    <TableCell>
                      <FormTextField name="Email" label="" control={control} value={values.Email} type="email" noDefaultValue />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell id="EmailEvent" className={cssClass.shrinkCell}>E-Mail (Veranstaltungen):</TableCell>
                    <TableCell>
                      <FormTextField name="EmailEvent" label="" control={control} value={values.EmailEvent} type="email" noDefaultValue />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell id="EmailFinancial" className={cssClass.shrinkCell}>E-Mail (Finanzen):</TableCell>
                    <TableCell>
                      <FormTextField name="EmailFinancial" label="" control={control} value={values.EmailFinancial} type="email" noDefaultValue />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell id="EmailNewsletter" className={cssClass.shrinkCell}>E-Mail (Newsletter):</TableCell>
                    <TableCell>
                      <FormTextField name="EmailNewsletter" label="" control={control} value={values.EmailNewsletter} type="email" noDefaultValue />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell id="Homepage" className={cssClass.shrinkCell}>Homepage:</TableCell>
                    <TableCell>
                      <FormTextField name="Homepage" label="" control={control} value={values.Homepage} noDefaultValue />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell id="Fax" className={cssClass.shrinkCell}>Fax:</TableCell>
                    <TableCell>
                      <FormTextField name="Fax" label="" control={control} value={values.Fax} noDefaultValue />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell id="WhatsApp" className={cssClass.shrinkCell}>WhatsApp:</TableCell>
                    <TableCell>
                      <FormTextField name="WhatsApp" label="" control={control} value={values.WhatsApp} noDefaultValue />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell id="Telegram" className={cssClass.shrinkCell}>Telegram:</TableCell>
                    <TableCell>
                      <FormTextField name="Telegram" label="" control={control} value={values.Telegram} noDefaultValue />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell id="Messenger" className={cssClass.shrinkCell}>Messenger:</TableCell>
                    <TableCell>
                      <FormTextField name="Messenger" label="" control={control} value={values.Messenger} noDefaultValue />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell id="Signal" className={cssClass.shrinkCell}>Signal:</TableCell>
                    <TableCell>
                      <FormTextField name="Signal" label="" control={control} value={values.Signal} noDefaultValue />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell id="Xing" className={cssClass.shrinkCell}>Xing:</TableCell>
                    <TableCell>
                      <FormTextField name="Xing" label="" control={control} value={values.Xing} noDefaultValue />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell id="LinkedIn" className={cssClass.shrinkCell}>LinkedIn:</TableCell>
                    <TableCell>
                      <FormTextField name="LinkedIn" label="" control={control} value={values.LinkedIn} noDefaultValue />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell id="Facebook" className={cssClass.shrinkCell}>Facebook:</TableCell>
                    <TableCell>
                      <FormTextField name="Facebook" label="" control={control} value={values.Facebook} noDefaultValue />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell id="Instagram" className={cssClass.shrinkCell}>Instagram:</TableCell>
                    <TableCell>
                      <FormTextField name="Instagram" label="" control={control} value={values.Instagram} noDefaultValue />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell id="Twitter" className={cssClass.shrinkCell}>Twitter:</TableCell>
                    <TableCell>
                      <FormTextField name="Twitter" label="" control={control} value={values.Twitter} noDefaultValue />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell id="TikTok" className={cssClass.shrinkCell}>TikTok:</TableCell>
                    <TableCell>
                      <FormTextField name="TikTok" label="" control={control} value={values.TikTok} noDefaultValue />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <BoxCenter className={cssClass.buttonsTableEdit}>
                <CustomButton
                  value="Verwerfen"
                  onClick={() => {
                    resetWarning();
                    reset(defaultValues);
                  }}
                  sx={{ width: '180px' }}
                  light
                  isClose
                />
                <CustomButton
                  value="Scannen"
                  onClick={() => onScanCardModalOpen()}
                  sx={{ width: '180px' }}
                />
                <CustomButton
                  value="Speichern"
                  onClick={() => {
                    saveCommunicationData(resetWarning);
                  }}
                  sx={{ width: '180px' }}
                />
              </BoxCenter>
            </FormControl>
          ) : (
            <Table>
              <TableBody className={cssClass.customTableBody}>
                {tableRows?.map((row) => (showRow(row) &&
                  <TableRow key={row.ID} className='contactItem' onTouchStart={() => setChosenDataItem(row)}>
                    <TableCell className={cssClass.shrinkCell}>{row.name}</TableCell>
                    <TableCell>{row.value}</TableCell>
                    {renderCell(row)}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </Box>
      </Grid>

      <ImageUploadPopup
        isOpen={isScanCardPopupOpen}
        onSave={(data) => {
          console.log('Scanned business card data:', data);
          onScanCardModalClose();
        }}
        onClose={onScanCardModalClose}
        isScanMode
      />

      <DataItemActionsPopup
        isOpen={isDataItemActionsPopupOpen}
        onClose={onDataItemActionsPopupClose}
        chosenDataItem={chosenDataItem}
      />
    </>
  );
}
