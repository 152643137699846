import { ArrowForward, AssistantOutlined, Clear, Close, Download, FilterAltOutlined, KeyboardArrowDownOutlined, KeyboardArrowUpOutlined, Search as SearchIcon } from "@mui/icons-material";
import { Box, Button, Checkbox, Fade, FormControl, FormControlLabel, IconButton, Input, InputLabel, Popper, Tooltip, Typography, InputAdornment, CircularProgress, Collapse, Grid, Pagination, useMediaQuery, } from "@mui/material";
import FormTextField from "../FormTextField";
import { swvTheme as swvThemeImport } from "../../../swvTheme";
import { useGlobalStyles } from "../../../customStyles";
import React, { useContext, useState } from "react";
import BoxCenter from "../BoxCenter";
import { useForm, useWatch } from "react-hook-form";
import * as CompanyService from '../../../services/requests/company';
import { FormSendingContext } from "../../pages/FormSending/FormSendingContext";
import DataCard from "../DataCard";
import { getBusinessRegistrationListOverview, getPhotoUrl } from "../../../helpers";
import { getTitleForType } from "../../../helpers/helperFunctions";

const SearchList = ({ listname }: any) => {
    const cssClass = useGlobalStyles();
    const swvTheme: any = swvThemeImport;

    const pageSize = 100;
    const paginationStyles = {
        ul: {
            '& .MuiPaginationItem-root': {
                '&.Mui-selected': {
                    backgroundColor: swvTheme.typography.lilac,
                    color: 'white',
                },
            },
        },
    }

    const { lists, searchData, setSearchData, searchText, subcategoryValue } = useContext(FormSendingContext);

    const isMobile = useMediaQuery("(max-width: 850px)")

    return <>
        {(lists[listname].length !== undefined) || (searchData[listname].isloading) ? (
            <Box p={2} sx={{ backgroundColor: '#E9E9E9', borderRadius: '4px' }}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        cursor: 'pointer',
                    }}
                >
                    <Typography
                        color={swvTheme.typography.lilac}
                        fontSize={18}
                        fontWeight={500}
                        gap={1}
                        display='flex'
                        alignItems='center'
                        onClick={() => setSearchData((old: any) => ({ ...old, [listname]: { ...old[listname], listOpen: !old[listname].listOpen } }))}
                    >
                        {searchData[listname].listOpen ? (
                            <KeyboardArrowUpOutlined />
                        ) : (
                            <KeyboardArrowDownOutlined />
                        )}
                        {getTitleForType(listname)}
                    </Typography>

                    {searchData[listname].listLoading ? (
                        <Box display='flex' alignItems='center' height={40}>
                            <Typography color='#F50000' fontWeight={700} mr={1}>
                                Suche...
                            </Typography>
                            <Typography color={swvTheme.typography.p}>
                                Ergebnisse
                            </Typography>
                            <CircularProgress size={20} style={{ marginLeft: "10px", marginRight: "7px", color: swvTheme.typography.lilac }} />
                        </Box>
                    ) : (
                        <Box display='flex' alignItems='center'>
                            <Typography color='#F50000' fontWeight={700} mr={1}>
                                {searchData[listname].resultCount}
                            </Typography>
                            <Typography color={swvTheme.typography.p}>
                                Ergebnisse
                            </Typography>
                            <IconButton onClick={() => {
                                //ToDo: onExportClicked(companyList);
                            }}>
                                <Download sx={{ color: swvTheme.typography.lilac }} />
                            </IconButton>
                        </Box>
                    )}
                </Box>
                <Collapse in={searchData[listname].listOpen} unmountOnExit>
                    <Grid container spacing={2} columns={2}>
                        {lists[listname]?.slice(0, pageSize).map((row: any) => (
                            <Grid
                                key={row.ID}
                                item
                                xs={2}
                                md={1}
                                onClick={() => /* ToDo: onRowClicked(row) */ { }}
                            >
                                {searchData[listname].renderDataCard(row)}
                            </Grid>
                        ))}
                    </Grid>
                    {(lists[listname].length !== 0) &&
                        <Grid display='flex' justifyContent='center' marginTop={'10px'}>
                            <Pagination
                                shape="rounded"
                                count={Math.ceil(searchData[listname].resultCount / pageSize)}
                                onChange={(event, page) => searchData[listname].handlePageChange(listname, page, searchText, subcategoryValue)}
                                page={searchData[listname].page + 1}
                                siblingCount={isMobile ? 0 : 1}
                                sx={paginationStyles}
                            />
                        </Grid>
                    }
                </Collapse>
            </Box >

        ) : null}
    </>
}

export default SearchList;