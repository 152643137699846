import React from 'react';
import TableCell from '@mui/material/TableCell';
import { DeleteForever } from '@mui/icons-material';

export default function DeleteCell({ func, id, ...rest }) {
  return (
    <TableCell align="center">
      <DeleteForever
        sx={{ fontSize: 20 }}
        onClick={async (e) => {
          e.stopPropagation();

          if (rest && typeof rest[Symbol.iterator] === 'function') {
            await func(id, ...rest);
          } else {
            await func(id);
          }
        }}
      />
    </TableCell>
  );
}
