import React from 'react';

import { Button } from '@mui/material';
import { ArrowForward, Close } from '@mui/icons-material';

import { useStyles } from './styles';

export default function CustomButton({
  value,
  startIcon,
  largeBorderRadius,
  light,
  isClose,
  onClick,
  disabled,
  sx,
}) {
  const classes = useStyles();

  return (
    <Button
      startIcon={startIcon}
      // endIcon={isClose ? <Close /> : <ArrowForward />}
      size="large"
      className={[
        classes.customButton,
        light ? classes.lightCustomButton : classes.darkCustomButton,
      ]}
      onClick={onClick}
      disabled={disabled}
      sx={{
        borderRadius: largeBorderRadius ? '8px 16px' : '4px 8px',
        ...sx,
      }}
    >
      {value}
    </Button>
  );
}
