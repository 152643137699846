import { createContext } from 'react';
import { IUser } from '../../../interfaces/IUser';


interface IUserContext {
    currentUser: IUser
}

export const defaultUser: IUser = {
    ID: 0,
    Firstname: "",
    Lastname: "",
    Email: "",
    Username: "",
    Mobile: "",
    Photo: "",
    IsAdmin: false,
    IsActive: false,
    County: "",
    UserRole: ""
}

export const UserContext = createContext<IUserContext>({ currentUser: defaultUser });
