import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    businessSubCategories: []
}

export const businessSubCategorySlice = createSlice({
    name: "businessSubCategory",
    initialState,
    reducers: {
        setBusinessSubCategory: (state, action) => {
            state.businessSubCategories = action.payload
        }
    }
})

export const { setBusinessSubCategory } = businessSubCategorySlice.actions;

export default businessSubCategorySlice.reducer;