import React from 'react';
import { useContext, useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';

import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';

import CountySelect from '../../common-components/CountySelect';
import DistrictSelect from '../../common-components/DistrictSelect';
import ModalWrapper from '../../common-components/ModalWrapper';
import BoxCenter from '../../common-components/BoxCenter';
import FormTextField from '../../common-components/FormTextField';

import { useStyles } from './styles';
import { useGlobalStyles } from '../../../customStyles';

export default function AddressPopUp({
  isOpen,
  onClose,
  onInsert,
  onUpdate,
  CompanyContext,
}) {
  const cssClass = useGlobalStyles();
  const classes = useStyles();

  const { chosenAddressRegistrationCompany, chosenAddressRow } = useContext(CompanyContext);

  const { control, reset } = useForm({
    defaultValues: {
      ID: '',
      County: '',
      Zipcode: '',
      City: '',
      Street: '',
      Number: '',
    },
  });

  useEffect(() => {
    if (chosenAddressRegistrationCompany) {
      reset(chosenAddressRegistrationCompany);
    }
  }, [chosenAddressRegistrationCompany]);

  const values = useWatch({ control });

  const disabled =
    !values?.County ||
    !values?.Zipcode ||
    !values?.City ||
    !values?.Street ||
    !values?.Number;

  useEffect(() => {
    if (!isOpen) {
      const defaultValues = {
        ID: '',
        County: '',
        Zipcode: '',
        City: '',
        Street: '',
        Number: '',
        Latitude: '',
        Longitude: '',
      };
      reset(defaultValues);
    }
  }, [isOpen]);

  const onSave = () => {
    if (chosenAddressRegistrationCompany)
      onUpdate(values);
    else
      onInsert(values);
  };

  return (
    <ModalWrapper isOpen={isOpen} onClose={onClose} title={chosenAddressRow?.name} className={cssClass.swvModalResponsiveMods + " " + classes.wrapper}>
      <FormControl>
        <CountySelect
          required
          name="County"
          control={control}
          value={values?.County || ''}
        />
        <DistrictSelect
          name="FK_District"
          control={control}
          value={values.FK_District}
          county={values.County}
        />
        <FormTextField
          name="Zipcode"
          label="PLZ"
          control={control}
          required
        />
        <FormTextField
          name="City"
          label="Ort"
          control={control}
          required
        />
        <FormTextField
          name="Street"
          label="Straße"
          control={control}
          required
        />
        <FormTextField
          name="Number"
          label="Nummer"
          control={control}
          required
        />
        <FormTextField
          name="Latitude"
          label="Breite"
          control={control}
        />
        <FormTextField
          name="Longitude"
          label="Länge"
          control={control}
        />
        <BoxCenter>
          <Button
            onClick={() => onSave(values)}
            disabled={disabled}
          >
            Speichern
          </Button>
        </BoxCenter>
      </FormControl>
    </ModalWrapper>
  );
}