export function testImageUpload(file) {
  return ['png', 'jpeg', 'jpg'].includes(file.type.split('/')[1]);
}

export function testEmailString(email, setError, setErrorEx) {
  if (!email) {
    return '';
  }

  const regex = new RegExp(/^[\.-\w]+@[-\w]+\.[\.-\w]+$/i);
  const valid = regex.test(email);

  setError(!valid);

  if (setErrorEx)
    setErrorEx(!valid);

  return valid ? '' : 'Format (xxx@xxx.xxx)';
}

export function testMobileString(mobile, setError, setErrorEx) {
  if (!mobile) {
    return '';
  }

  const regex = new RegExp(/^\+43\d{7,}$/i);
  const valid = regex.test(mobile);

  setError(!valid);

  if (setErrorEx)
    setErrorEx(!valid);

  return valid ? '' : 'Format (+43XXXXXXXXXXX)';
}

export function testPasswordString(password,  setError, setErrorEx) {
  if (!password) {
    return '';
  }

  if (password == 'x') {
    return '';
  }
  const regexp = new RegExp('^[0-9a-zA-Z]{6,20}');

  const valid = regexp.test(password);

  setError(!valid);

  if (setErrorEx)
    setErrorEx(!valid);

  return valid ? '' : 'mind. 6 stellig';
};
