import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { deDE } from '@mui/material/locale';

const baseTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#F50000',
      red: '#F50000',
      purple: '#732382',
      darkPurple: '#3E004D',
      white: '#FFFFFF',
      dark: '#202020',
    },
    secondary: {
      main: '#00615A',
      light: '#FDEEED',
      gray: '#BDBDBD',
      dark: '#202020',

      inactive: '#C7C7C7',
      active: '#E9E9E9',
    },
    background: {
      default: '#FAFAFA',
      gradient: 'linear-gradient(rgba(245, 0, 0, 1), rgba(115, 35, 130, 1))',
    },
    error: {
      main: '#d32f2f',
      light: '#f25c5c',
      dark: '#c82a2a',
    },
    button: {
      dark: '#3E004D',
      light: '#732382',
    }
  },

  typography: {
    fontFamily: ['Helvetica Neue'],

    h1: {
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: '400',
    },
    h2: {
      fontSize: '16px',
      fontWeight: '500',
    },
    h3: {
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '400',
    },
    body1: {
      fontSize: '16px',
      lineHeight: '24px',
    },
    p: {
      fontSize: '14px',
      fontWeight: '400',
    },
    button: {
      fontSize: '13px',
      fontWeight: '500',
      lineHeight: '120%',
      letterSpacing: '0.65px',
      textTransform: 'uppercase',
    },
    a: {
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '150%',
      letterSpacing: '0.32px',
      textDecorationLine: 'underline'
    },
    input: {
      fontSize: '16px',
      fontWeight: '400',
      letterSpacing: '0.32px',
    },
    label: {
      fontSize: '13px',
      fontWeight: '300',
      letterSpacing: '1.56px',
      textTransform: 'uppercase',
    },
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
})

export let swvTheme = createTheme(baseTheme, {
  typography: {
    color: baseTheme.palette.primary.dark,
    h1: {
      [baseTheme.breakpoints.up('md')]: {
        fontSize: '24px',
        lineHeight: '24.5px',
      },
    },
    h2: {
      [baseTheme.breakpoints.up('md')]: {
        fontSize: '18px',
      },
    },
    h3: {
      [baseTheme.breakpoints.up('md')]: {
        fontSize: '18px',
      },
    },
    p: {
      [baseTheme.breakpoints.up('md')]: {
        fontSize: '16px',
      },
    },
    lilac: baseTheme.palette.primary.purple,
    white: "white"
  },

  boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12) !important;',

  components: {
    FormControlLabel: {
      defaultProps: {
      },
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            //fontSize: '15px',
          }),
      },
    },


    MuiLink: {
      defaultProps: {
      },
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            cursor: 'pointer',
            color: 'primary.purple',
            textDecorationColor: 'unset',
            fontFamily: 'Helvetica Neue',
          }),
      },
    },

    MuiMenuItem: {
      defaultProps: {
      },
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            '&:hover': { backgroundColor: swvTheme.palette.secondary.light },
          }),
      },
    },

    MuiButton: {
      defaultProps: {
        variant: 'contained',
      },
      styleOverrides: {
        root: ({ theme }) => ({
          background: swvTheme.palette.button.light,
          padding: '17px 36px',
          borderRadius: '8px 4px',
          boxShadow: 'none',
          letterSpacing: '0.65px',
          lineHeight: 1,
          minWidth: '100px',
          fontSize: '1rem',
          cursor: 'pointer',
          '&:disabled': { color: 'white', backgroundColor: 'grey' },
          '&:hover': {
            background: swvTheme.palette.button.dark,
            boxShadow: 'none',
          },
        }),
      },
    },

    MuiTableRow: {
      defaultProps: {
        hover: true,
      },
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            borderBottom: '1px dashed #C7C7C7',

            '&:last-child': {
              borderBottom: '1px #202020 solid',
            },
            '&.MuiTableRow-root:hover': {
              backgroundColor: swvTheme.palette.secondary.light,
            },
            '& .MuiTableCell-root': {
              fontSize: '1rem',
              padding: '15px 13px 11px',
              background: 'transparent',
              border: 'none',
            },
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            cursor: 'pointer',
            overflow: 'hidden',
          }),
      },
    },

    MuiInputBase: {
      defaultProps: {
        multiline: false,
        size: 'small',
        margin: 'dense',
      },
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
        }
      },
    },

    MuiFormControl: {
      defaultProps: {
        size: 'small',
        margin: 'dense',
        fullWidth: true,
      },
    },

    MuiInputAdornment: {
      defaultProps: {
        size: 'small',
        margin: 'dense',
      },
    },

    MuiInputLabel: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            '&.Mui-focused': {
              color: swvTheme.typography.lilac,
            },
          })
      }
    },

    MuiOutlinedInput: {
      defaultProps: {},
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: swvTheme.typography.lilac,
            },
          })
      }
    },

    MuiTextField: {
      defaultProps: {
        multiline: false,
        size: 'small',
        margin: 'dense',
      },
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            //height: '60px',
            //margin: '0px',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            cursor: 'pointer',
            // overflow: 'hidden',
            multiline: false,
            // backgroundColor: 'green',
          }),
      },
    },

    MuiSelect: {
      defaultProps: {
        multiline: false,
        size: 'small',
      },
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            // backgroundColor: 'orange',
          }),
      },
    },

    DesktopDatePicker: {
      defaultProps: {},
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            // backgroundColor: 'blue',
          }),
      },
    },

    MuiTableContainer: {
      defaultProps: {
        component: Paper,
      },
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            boxShadow: 0,
            borderRadius: '8px 8px 0 0',
            background: 'transparent',
            '& .MuiInputBase-root': { height: '2.5rem' },
            margin: 'auto',
            '& .MuiTableHead-root': {
              position: 'relative',
              '& .MuiTableRow-root ': {
                borderBottom: 'none',
              },
              '& .MuiTableCell-root': {
                fontSize: '1.125rem',
                fontWeight: 500,
                paddingTop: '21px',
                paddingBottom: '20px',
                borderBottom: 'none !important',
                background: '#f1f1f1',
              },
              '::after': {
                position: 'absolute',
                left: 0,
                right: 0,
                bottom: 0,
                content: '""',
                display: 'block',
                height: '1px',
                background:
                  'linear-gradient(90deg, #F50000 0%, #732382 100%)',
              },
            },
          }),
      },
    },

    MuiTabs: {
      defaultProps: {
        centered: true,
      },
      styleOverrides: {
        indicator: ({ theme }) =>
          theme.unstable_sx({
            display: 'none',
          }),
      },
    },
    MuiTab: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            boxSizing: 'border-box',
            minWidth: '144px',
            fontSize: '1rem',
            border: '1px solid transparent',
            borderRadius: '41px',
            lineHeight: '22px',
            fontWeight: 400,
            textTransform: 'none',
            padding: '15px 27px',
            color: theme.palette.secondary.dark,
            '&.Mui-selected': {
              fontWeight: 500,
              color: theme.palette.secondary.dark,
              background:
                'linear-gradient(#fff, #fff) padding-box, linear-gradient(180deg, #F50000, #732382) border-box',
            },
          }),
      }
    }
  },
  },
  deDE,
);