import React from 'react';
import { IconButton, Grid } from '@mui/material';

import { useStyles } from './styles';
import Email from '../Icons/Email';
import Image from '../Icons/Image';
import Calendar from '../Icons/Calendar';
import Phone from '../Icons/Phone';

export default function QuickActionButtons({ mail, tel, photos }) {
  const classes = useStyles();

  function handleMailClick() {
    const mailtoUrl = `mailto:${mail}`;

    window.location.href = mailtoUrl;
  }

  function handleTelClick() {
    const telToUrl = `tel:${tel}`;

    window.location.href = telToUrl;
  }

  return (
    <Grid container direction="row" className={classes.grid}>
      <Grid item>
        <IconButton onClick={photos ? photos : () => { }} className={classes.iconButton}>
          <Image />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton disabled={!mail} onClick={handleMailClick} className={classes.iconButton}>
          <Email />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton disabled={true /* TODO */} className={classes.iconButton}>
          <Calendar />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton disabled={!tel} onClick={handleTelClick} className={classes.iconButton}>
          <Phone />
        </IconButton>
      </Grid>
    </Grid>
  );
}
