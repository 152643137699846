import axios, { HttpStatusCode } from 'axios';
import * as endpoints from '../constants/endpoints';
import { goTo } from '../router/routerHistory';
import { AUTH_TOKEN_KEY } from '../constants/appConstants';
import { Messages } from '../constants/messages';
import { deletePopup, openSpinner, closeSpinner } from '../helpers/utils';
import { downloadFile, showErrorSnackbar, showInfoSnackbar } from '../helpers/utils';
import { useNavigate } from 'react-router-dom';

var counter = 0;

const openSpinnerEx = async () => {
  console.log('openSpinnerEx', counter);

  if (++counter > 0) {
    await openSpinner();
  }
}

const closeSpinnerEx = () => {
  console.log('closeSpinnerEx', counter);

  counter = 0;
  if (true) {
    closeSpinner();
  }
}

const checkArray = (data) => {
  if (data) {
    for (var key in data) {
      if (data[key] === '')
        data[key] = null;
    }
  }

  return data;
}

const client = axios.create({
  baseURL: process.env.REACT_APP_BE_URL,
  //headers: {
  //  "Content-type": "application/json",
  //},
  //withCredentials: true,
});

client.interceptors.request.use(
  async (config) => {
    if (config.headers) {
      config.headers.Authorization = `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY) || ''}`;
    }

    return config;
  },
  (error) => Promise.reject(error),
);

const refreshToken = async () => {
  try {
    const resp = await client.get(endpoints.refreshToken, { withCredentials: true });
    if (resp.status > 299) {
      localStorage.clear();
      const navigate = useNavigate()
      navigate("/")
      return null
    }
    return resp.data;
  } catch (e) {
    localStorage.clear();
    const navigate = useNavigate()
    navigate("/")
    console.log("Error", e);
  }
}

client.interceptors.response.use(
  (response) => {
    return response
  },
  async function (error) {
    let originalRequest = error?.config;

    if (error?.request?.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;

      const tokens = await refreshToken();
      const access_token = tokens.accessToken;
      localStorage.setItem(AUTH_TOKEN_KEY, access_token)

      client.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${access_token}`

      return client(originalRequest);
    }

    return Promise.reject(error);
  },
);

export const apiClient = {
  s: () => { null },

  put: async (url, data, success, error) => {
    try {
      console.log("host: ", process.env.REACT_APP_BE_URL, client.defaults.baseURL, endpoints.apiUrl);

      console.log("Inv put:        ", url, data);

      const response = await client.put(url, data);

      console.log("Res put:        ", url, response.data);

      if (response.data.isSuccess) {
        if (success) success(response.data.data);

        showInfoSnackbar(Messages.Success);
      } else {
        if (error) error(response.data);

        showErrorSnackbar(Messages.ErrorUpdate);
      }

      return response;
    } catch (e) {
      console.log('Error', e);
      throw (e);
    }
  },

  get: async (url, body = {}, query = {}, config = {}, success, error) => {
    try {
      console.log("host: ", process.env.REACT_APP_BE_URL, client.defaults.baseURL, endpoints.apiUrl);

      if (body != null) {
        const keys = Object.keys(body);

        for (var i = 0; i < keys.length; i++)
          url += (!i ? "?" : "&") + keys[i] + "=" + body[keys[i]];
      }

      console.log("Inv get:        ", url, query);

      const response = await client.get(url, { params: query, ...config });

      console.log("Res get:        ", url, response.data);

      if (response.data.isSuccess) {
        if (success) success(response.data.data);
      } else {
        if (error) error(response.data);

        showErrorSnackbar(Messages.Error);
      }

      return response;
    } catch (e) {
      console.log('Error', e);

      if (error) error(); else showErrorSnackbar(Messages.Error);
    }
  },

  delete: async (url, query = {}, config = {}, success, error) => {
    try {
      console.log("Inv delete:     ", url, query, config);

      const response = await client.delete(url, { params: query, ...config });

      console.log("Res delete:     ", url, response.data);

      if (response.data.isSuccess) {
        if (success) success(response.data.data);

        showInfoSnackbar(Messages.Deleted);
      } else {
        if (error) error(); else showErrorSnackbar(Messages.Error);
      }

      return response;
    } catch (e) {
      console.log('Error', e);

      if (error) error(); else showErrorSnackbar(Messages.Error);
    }
  },

  post: async (url, data, success, error) => {
    try {
      console.log("host: ", process.env.REACT_APP_BE_URL, client.defaults.baseURL, endpoints.apiUrl);

      data = checkArray(data);

      console.log("Inv post:       ", url, data);

      const response = await client.post(url, data);

      console.log("Res post:       ", url, response.data);

      if (response.data.isSuccess) {
        if (success) success(response.data.data, response.data);

        showInfoSnackbar(Messages.Success);
      } else {
        if (error) error(response.data); else showErrorSnackbar(Messages.Error);
      }

      return response;
    } catch (e) {
      console.log('Error', e);

      if (error) error(); else showErrorSnackbar(Messages.Error);
    }
  },

  postDownload: async (url, data, success, error) => {
    try {
      console.log("host: ", process.env.REACT_APP_BE_URL, client.defaults.baseURL, endpoints.apiUrl);

      data = checkArray(data);

      console.log("Inv postDownload:       ", url, data);

      const response = await client.post(url, data);

      console.log("Res postDownload:       ", url, response);

      if (response.data.isSuccess) {
        if (success) success(response.data.data, response.data.count);
      } else {
        if (error) error(response.data);

        showErrorSnackbar(Messages.ErrorFetch);
      }

      return response;
    } catch (e) {
      console.log('Error', e);

      if (error) error(); else showErrorSnackbar(Messages.Error);
    }
  },

  //Old-version of getList
  getList: async (url, data, success, error) => {
    try {
      console.log("host: ", process.env.REACT_APP_BE_URL, client.defaults.baseURL, endpoints.apiUrl);

      data = checkArray(data);

      console.log("Inv getList:    ", url, data);

      //if (success) success(null);

      openSpinnerEx();

      // await new Promise(o => setTimeout(o, 3 * 1000));

      const response = await client.post(url, data);

      console.log("Res getList:    ", url, response.data);

      closeSpinnerEx();

      if (response.data.isSuccess) {
        if (success) success(response.data.data, response.data.count);
      } else {
        if (error) error(response.data);

        showErrorSnackbar(Messages.ErrorFetch);
      }

      return response;
    } catch (e) {
      closeSpinnerEx();

      console.log('Error', e);

      if (error) error(); else showErrorSnackbar(Messages.Error);
    }
  },

  //New Version of getList
  getListNew: async (url, data, query, success, error) => {
    try {
      console.log("host: ", process.env.REACT_APP_BE_URL, client.defaults.baseURL, endpoints.apiUrl);

      data = checkArray(data);

      console.log("Inv getList:    ", url, data);

      if (success) success(null);

      openSpinnerEx();

      // await new Promise(o => setTimeout(o, 3 * 1000));

      const response = await client.get(url, { params: query, data: data });

      console.log("Res getList:    ", url, response.data);

      closeSpinnerEx();

      if (response.status === HttpStatusCode.Ok) {
        if (success) success(response.data, response.data.count);
      } else {
        if (error) error(response.data);

        showErrorSnackbar(Messages.ErrorFetch);
      }

      return response;
    } catch (e) {
      closeSpinnerEx();

      console.log('Error', e);

      if (error) error(); else showErrorSnackbar(Messages.Error);
    }
  },

  getOne: async (url, id, success, error) => {
    try {
      if (id)
        url += "/" + id;

      console.log("Inv getOne:     ", url);

      const response = await client.get(url);

      console.log("Res getOne:     ", url, response.data);

      if (response.data.isSuccess) {
        if (success) success(response.data.data);
      } else {
        if (error) error(response.data);

        showErrorSnackbar(Messages.NotFound);
      }

      return response;
    } catch (e) {
      console.log('Error', e);

      if (error) error(); else showErrorSnackbar(Messages.Error);
    }
  },

  getOneNew: async (url, id, success, error, query) => {
    try {
      console.log("Inv getOne:     ", url);

      const response = await client.get(url, { params: { ...query, id } });

      console.log("Res getOne:     ", url, response.data);

      if (response.data.isSuccess) {
        if (success) success(response.data.data);
      } else {
        if (error) error(response.data);

        showErrorSnackbar(Messages.NotFound);
      }

      return response;
    } catch (e) {
      console.log('Error', e);

      if (error) error(); else showErrorSnackbar(Messages.Error);
    }
  },

  getMany: async (url, id, success, error) => {
    try {
      url += "/" + id;

      console.log("Inv getMany:    ", url);

      const response = await client.get(url);

      console.log("Res getMany:    ", url, response.data);

      if (response.data.isSuccess) {
        if (success) success(response.data.data);
      } else {
        if (error) error(response.data);

        showErrorSnackbar(Messages.ErrorFetch);
      }

      return response;
    } catch (e) {
      console.log('Error', e);

      if (error) error(); else showErrorSnackbar(Messages.Error);
    }
  },

  postForm: async (url, data, success, error) => {
    try {
      console.log("Inv postForm:   ", url, data);

      data = checkArray(data);

      const response = await client.post(url, data, { headers: { 'Content-Type': 'multipart/form-data' } });

      console.log("Res postForm:   ", url, response.data);

      if (response.data.isSuccess) {
        if (success) success(response.data.data);

        showInfoSnackbar(Messages.Success);
      } else {
        if (error) error(response.data);

        showErrorSnackbar(Messages.ErrorUpdate);
      }

      return response;
    } catch (e) {
      console.log('Error', e);

      if (error) error(); else showErrorSnackbar(Messages.Error);
    }
  },

  postOne: async (url, id, success, error) => {
    try {
      var data = { id: id };

      console.log("Inv postOne:    ", url, data);

      const response = await client.post(url, data);

      console.log("Res postOne:    ", url, response.data);

      if (response.data.isSuccess) {
        if (success) success(response.data.data);

        showInfoSnackbar(Messages.Success);
      } else {
        if (error) error(response.data);

        showErrorSnackbar(Messages.ErrorUpdate);
      }

      return response;
    } catch (e) {
      console.log('Error', e);

      if (error) error(); else showErrorSnackbar(Messages.Error);
    }
  },

  insertOne: async (url, data, success, error) => {
    try {
      console.log("host: ", process.env.REACT_APP_BE_URL, client.defaults.baseURL, endpoints.apiUrl);

      data = checkArray(data);

      console.log("Inv insertOne:  ", url, data);

      const response = await client.post(url, data);

      console.log("Res insertOne:  ", url, response.data);

      if (response.data.isSuccess) {
        if (success) success(response.data.data, response.data);

        showInfoSnackbar(Messages.Inserted);
      } else {
        if (error) error(response.data);

        showErrorSnackbar(Messages.ErrorInsert);
      }

      return response;
    } catch (e) {
      console.log('Error', e);

      if (error) error(); else showErrorSnackbar(Messages.Error);
    }
  },

  updateOne: async (url, data, success, error) => {
    try {
      console.log("Inv updateOne:     ", url, data);

      data = checkArray(data);

      const response = await client.put(url, data);

      console.log("Res updateOne:     ", url, response.data);

      if (response.data.isSuccess) {
        if (success) success(response.data.data);

        showInfoSnackbar(Messages.Updated);
      } else {
        if (error) error(response.data);

        showErrorSnackbar(Messages.ErrorUpdate);
      }

      return response;
    } catch (e) {
      console.log('Error', e);

      if (error) error(); else showErrorSnackbar(Messages.Error);
    }
  },

  putOne: async (url, id, success, error) => {
    try {
      url += "/" + id;

      console.log("Inv putOne:     ", url);

      const response = await client.put(url);

      console.log("Res putOne:     ", url, response.data);

      if (response.data.isSuccess) {
        if (success) success(response.data.data);

        showInfoSnackbar(Messages.Success);
      } else {
        if (error) error(response.data);

        showErrorSnackbar(Messages.Error);
      }

      return response;
    } catch (e) {
      console.log('Error', e);

      if (error) error(); else showErrorSnackbar(Messages.Error);
    }
  },

  deleteOne: async (url, id, success, error) => {
    try {
      url += "/" + id;

      console.log("Inv deleteOne:  ", url);

      if (!await deletePopup()) return;

      const response = await client.delete(url);

      console.log("Res deleteOne:  ", url, response.data);

      if (response.data.isSuccess) {
        if (success) success(response.data.data);

        showInfoSnackbar(Messages.Deleted);
      } else {
        if (error) error(response.data);

        showErrorSnackbar(Messages.ErrorDelete);
      }

      return response;
    } catch (e) {
      console.log('Error', e);

      if (error) error(); else showErrorSnackbar(Messages.Error);
    }
  },

  deleteOneNew: async (url, id, success, error) => {
    try {
      console.log("Inv deleteOne:  ", url);

      if (!await deletePopup()) return;

      const response = await client.delete(url, { params: id });

      console.log("Res deleteOne:  ", url, response.data);

      if (response.data.isSuccess) {
        if (success) success(response.data.data);

        showInfoSnackbar(Messages.Deleted);
      } else {
        if (error) error(response.data);

        showErrorSnackbar(Messages.ErrorDelete);
      }

      return response;
    } catch (e) {
      console.log('Error', e);

      if (error) error(); else showErrorSnackbar(Messages.Error);
    }
  },


  getBase64Uri: async (url, id, success, error) => {
    try {
      console.log("Inv getBase64Uri:  ", url);

      const response = await client.get(url, { params: { id } });

      console.log("Res getBase64Uri:  ", response.data);

      if (response.data) {
        //downloadFile(response.data, filename);

        if (success) success(response.data.base64);
        return response.data.base64
      } else {
        if (error) error(response.data);

        showErrorSnackbar(Messages.ErrorDownload);
      }
    } catch (e) {
      console.log('Error getBase64Uri', e);

      if (error) error(); else showErrorSnackbar(Messages.Error);
    }
  },

  downloadOne: async (url, id, filename, success, error) => {
    try {
      console.log("Inv downloadOne:  ", url);

      const response = await client.get(url, { params: { id } });

      console.log("Res downloadOne:  ", response.data);

      if (response.data) {
        downloadFile(response.data, filename);

        if (success) success(response.data);
        return response.data
      } else {
        if (error) error(response.data);

        showErrorSnackbar(Messages.ErrorDownload);
      }
    } catch (e) {
      console.log('Error', e);

      if (error) error(); else showErrorSnackbar(Messages.Error);
    }
  },

  downloadOneOld: (url, id, filename, success, error) => {
    try {
      url = `${endpoints.apiUrlEx}${url}/${id}`;

      const xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.responseType = 'blob';

      xhr.onload = function (_) {
        if (this.status == 200) {
          const element = document.body.appendChild(document.createElement('a'));
          element.href = window.URL.createObjectURL(this.response);
          element.download = filename;
          element.click();
          document.body.removeChild(element);

          return { isSuccess: true };
        } else if (this.status == 404) {
          showErrorSnackbar(Messages.ErrorDownloadNotFound);
          return { isSuccess: false };
        } else {
          showErrorSnackbar(Messages.ErrorDownload);
          return { isSuccess: false };
        }
      };
      xhr.onerror = function (_) {
        return { isSuccess: false };
      };

      xhr.send();
    } catch (e) {
      console.log('Error', e);

      return { isSuccess: false };
    }
  },
};
