export const styles = {
  infoText: {
    mb: '30px',
  },
  linkContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
};
