import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller, useWatch } from 'react-hook-form';

import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import * as ActivityService from '../../../services/requests/activity';
import * as CommentService from '../../../services/requests/comment';
import { AdminCheckContext } from '../../pages/AdminCheck/AdminCheckContext';

import { styles } from './styles';

import BoxCenter from '../../common-components/BoxCenter';
import ModalWrapper from '../../common-components/ModalWrapper';
import FormTextField from '../../common-components/FormTextField';
import FormMemoField from '../../common-components/FormMemoField';

import { chooseActivityType } from '../../../helpers/database';

export const PopupModes = {
  Notizen: 'Notizen',
  Aktivitäten: 'Aktivitäten',
};

export default function AdminCheckPopUp({ isOpen, onClose, mode }) {
  const { control } = useFormContext();
  const values = useWatch({ control });

  const {
    getCommentList,
    getActivityList
  } = useContext(AdminCheckContext);

  const approveAction = async () => {
    const preload = {
      Start: values.Start,
      End: values.End,
      County: values.County,
      IsApproved: values.IsApproved,
    };

    if (mode === PopupModes.Aktivitäten) {
      await ActivityService.approveOne(values.ID, async () => {
        await getActivityList(preload);
        onClose();
      })
    } else {
      await CommentService.approveOne(values.ID, async () => {
        await getCommentList(preload);
        onClose();
      });
    }
  };

  const deleteAction = async () => {
    const preload = {
      Start: values.Start,
      End: values.End,
      County: values.County,
      IsApproved: values.IsApproved,
    };

    if (mode === PopupModes.Aktivitäten) {
      await ActivityService.deleteOne(values.ID, async () => {
        await getActivityList(preload);
        onClose();
      })
    } else {
      await CommentService.deleteOne(values.ID, async () => {
        await getCommentList(preload);
        onClose();
      });
    }
  };

  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={onClose}
      title={mode === PopupModes.Aktivitäten ? 'Aktivität' : 'Kommentar'}
    >
        <FormControl>
            {mode === PopupModes.Aktivitäten && (
              <>
                <Controller
                  control={control}
                  name="Type"
                  render={({ field: { value } }) => (
                    <TextField
                      value={chooseActivityType(value)}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="Typ"
                      label="Typ"
                    />
                  )}
                />
                <FormTextField
                  name="Telephone"
                  label="Telefon"
                  control={control}
                  type="tel"
                />
                <FormTextField
                  name="ContactPerson"
                  label="Ansprechpartner"
                  control={control}
                />
              </>
            )}
            <FormMemoField
              name="Comment"
              label="Kommentar"
              control={control}
              rows={4}
            />
            <BoxCenter>
              <Button
                sx={styles.buttons}
                onClick={() => approveAction()}
              >
                Freigeben
              </Button>
              <Button
                sx={styles.buttons}
                onClick={() => deleteAction()}
              >
                Löschen
              </Button>
            </BoxCenter>
        </FormControl>
    </ModalWrapper>
  );
}

AdminCheckPopUp.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  mode: PropTypes.oneOf([PopupModes.Notizen, PopupModes.Aktivitäten]),
};
