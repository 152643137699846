import React, { useEffect } from 'react';
import { Control, Controller } from 'react-hook-form';
import { FormControl, InputLabel, Select, MenuItem, SxProps, FormHelperText } from '@mui/material';
import { useStyles } from './styles';
import { useGlobalStyles } from '../../../customStyles';

interface IFormSelect {
  name: string
  label?: string
  control?: Control<any>
  value: any
  required?: boolean
  defaultValue?: string
  disabled?: boolean
  emptyText?: string //placeholder text
  onClick?: () => void
  onChange?: () => void
  children?: React.ReactNode
  noDefaultValue?: boolean
  noAsterisk?: boolean
  sx?: SxProps
  fullWidth?: boolean
  errors?: any
  register?: any
  validationSchema?: any
  multiple?: boolean
  options?: Array<any>
  valueKey?: string
  chooseAll?: boolean
}

export default function FormSelect({ chooseAll, options, valueKey, multiple, name, label, control, value, required, defaultValue, disabled, emptyText, onClick, onChange, children, noDefaultValue, noAsterisk, sx, fullWidth, errors, register, validationSchema }: IFormSelect) {
  const labelId = `${name}-label`;
  const classes = useStyles();
  const cssGlobalClass = useGlobalStyles();

  useEffect(() => {
    console.log("VALUE FormSelect", value)
  }, [value])

  return (
    <FormControl fullWidth={fullWidth ? fullWidth : false}>
      <InputLabel id={labelId} error={errors && errors[name]}>
        {label + (required && !noAsterisk ? ' *' : '')}
      </InputLabel>
      {control && !multiple && (
        <>
          <Controller
            render={({ field: { onChange, value: fieldValue } }) => (
              <Select

                sx={{
                  minWidth: '10rem',
                  wordBreak: 'break-all',
                  ...sx,
                }}
                labelId={labelId}
                label={label + (required && !noAsterisk ? ' *' : '')}
                value={fieldValue ?? ''}
                required={required}
                defaultValue={defaultValue ?? ''}
                disabled={disabled}
                onChange={onChange}
                onClick={onClick}
                error={errors && errors[name]}
                {...register?.(name, validationSchema)}
              >

                {!required && !noDefaultValue && (
                  <MenuItem value={''}>{emptyText ?? '--'}</MenuItem>
                )}

                {children}
              </Select>
            )}
            name={name}
            control={control}
          />
          {(errors && errors[name]) && <FormHelperText error={errors && errors[name]}>{errors[name]?.message}</FormHelperText>}
        </>
      )}
      {control && multiple && (
        <Controller
          render={({ field: { onChange, value: fieldValue } }) => (
            <Select

              sx={{
                minWidth: '10rem',
                wordBreak: 'break-all',
                ...sx,
              }}
              labelId={labelId}
              label={label + (required && !noAsterisk ? ' *' : '')}
              value={fieldValue ?? []}
              required={required}
              defaultValue={defaultValue ?? []}
              disabled={disabled}
              onChange={(e) => {
                if (multiple && options && valueKey && e.target.value[e.target.value.length - 1] === "Alle wählen") {
                  if (options.length === (e.target.value.length - 1)) {
                    onChange(options?.filter(element => false))
                  } else {
                    onChange(options?.filter(element => element.Title !== "Alle wählen").map(element => element[valueKey]))
                  }
                } else {
                  onChange(e)
                }
              }}
              onClick={onClick}
              multiple={multiple}
              renderValue={(selected) => selected.join(', ')}
            >

              {!required && !noDefaultValue && (
                <MenuItem value={''}>{emptyText ?? '--'}</MenuItem>
              )}
              {chooseAll &&
                <MenuItem value={'Alle wählen'}>{'Alle wählen'}</MenuItem>
              }
              {children}
            </Select>
          )}
          defaultValue={defaultValue ?? []}
          name={name}
          control={control}
        />
      )}
    </FormControl>
  );
}