export enum ChangeProposalStatus {
    Open = 0,
    Accepted = 1,
    Declined = 2,
}

export enum ChangeProposalActionTypes {
    Create = 'C',
    Update = 'U',
    Delete = 'D'
  }