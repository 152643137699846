import * as endpoints from '../../constants/endpoints';
import { apiClient } from '../apiClient';

export const insertOne = async (user, success, error) => {
  const response = await apiClient.insertOne(endpoints.insertUser, user, success, error);
  return response.data;
};

export const updateOne = async (user, success, error) => {
  const response = await apiClient.updateOne(endpoints.updateUser, user, success, error);
  return response.data;
};

export const getList = async (query, success, error) => {
  const response = await apiClient.getList(endpoints.getUserList, query, success, error);
  return response.data;
};

export const deleteOne = async (id, success, error) => {
  const response = await apiClient.deleteOne(endpoints.deleteUser, id, success, error);
  return response.data;
};

export const inviteOne = async (id, success, error) => {
  const response = await apiClient.postOne(endpoints.inviteUser, id, success, error);
  return response.data;
};
