import React, { useState, useContext, useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { Table, TableBody, TableCell, TableRow, Typography, FormControl, Box, IconButton } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { Edit, Close } from '@mui/icons-material/';

import { getBoolean } from '../../../../../../helpers/database';
import { CompanyContext } from '../../../CompanyContext';
import FormBooleanSelect from '../../../../../common-components/FormBooleanSelect';

import { useGlobalStyles } from '../../../../../../customStyles';
import BoxCenter from '../../../../../common-components/BoxCenter';
import CustomButton from '../../../../../common-components/CustomButton';
import { swvTheme } from '../../../../../../swvTheme';
import { Messages } from '../../../../../../constants/messages';

export default function ContactPermissions() {
  const cssClass = useGlobalStyles();

  const [isEditMode, setIsEditMode] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const { companyData, setUpdatedContactPermissionFields, showRow, saveCommunicationData } = useContext(CompanyContext);

  const createData = (name, value) => {
    return { name, value };
  };

  const tableRows = [
    createData('Brief:', getBoolean(companyData.CommunicationData?.IsLetterApproval)),
    createData('Email:', getBoolean(companyData.CommunicationData?.IsEmailApproval)),
    createData('Telefon:', getBoolean(companyData.CommunicationData?.IsTelephoneApproval)),
    createData('Mobil:', getBoolean(companyData.CommunicationData?.IsMobileApproval)),
    createData('SMS:', getBoolean(companyData.CommunicationData?.IsSMSApproval)),
    createData('Messenger:', getBoolean(companyData.CommunicationData?.IsMessengerApproval)),
    createData('Newsletter:', getBoolean(companyData.CommunicationData?.IsNewsletterApproval)),
    createData('Soziale Medien:', getBoolean(companyData.CommunicationData?.IsSocialMediaApproval)),
  ];

  const { control, reset } = useForm({
    defaultValues: {
      IsLetterApproval: true,
      IsEmailApproval: true,
      IsTelephoneApproval: true,
      IsMobileApproval: true,
      IsSMSApproval: true,
      IsSocialMediaApproval: true,
      IsMessengerApproval: true,
      IsNewsletterApproval: true,
    },
  });

  const values = useWatch({ control });

  setUpdatedContactPermissionFields(values);

  const defaultValues = {
    IsEmailApproval: companyData.CommunicationData?.IsEmailApproval ?? true,
    IsTelephoneApproval: companyData.CommunicationData?.IsTelephoneApproval ?? true,
    IsMobileApproval: companyData.CommunicationData?.IsMobileApproval ?? true,
    IsLetterApproval: companyData.CommunicationData?.IsLetterApproval ?? true,
    IsSMSApproval: companyData.CommunicationData?.IsSMSApproval ?? true,
    IsMessengerApproval: companyData.CommunicationData?.IsMessengerApproval ?? true,
    IsNewsletterApproval: companyData.CommunicationData?.IsNewsletterApproval ?? true,
    IsSocialMediaApproval: companyData.CommunicationData?.IsSocialMediaApproval ?? true,
  };

  useEffect(() => {
    if (companyData) {
      reset(defaultValues);
    }
  }, [companyData]);

  useEffect(() => {
    if (isEditMode) {
      values.isModified = true;
    }
  }, [values]);

  useEffect(() => {
    if (isEditMode) {
      for (const value in values) {
        if (values[value] !== defaultValues[value] && (value !== "isModified")) {
          document.getElementById(value).className += " modifiedValue";
        }
      }
    }
  }, [isEditMode]);

  const resetWarning = () => {
    values.isModified = false;
    setShowWarning(false);
    setIsEditMode(false);
  }

  return (
    <Grid xs={12} mt={3}>
      <Box
        sx={{
          backgroundColor: '#FFFFFF',
          borderRadius: '8px',
          boxShadow: swvTheme.boxShadow,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            p: 2,
          }}
        >
          <Typography color={swvTheme.typography.lilac} fontSize={18} fontWeight={500}>
            Kontakterlaubnis
          </Typography>

          {showWarning &&
            <Typography color="primary">{ Messages.WarningUnsafed }</Typography>}

          {isEditMode ? (
          <>
            <IconButton aria-label="close" onClick={() =>
              { if (values?.isModified)
                  setShowWarning(true);
                setIsEditMode(false)
              } }>
              <Close />
            </IconButton>
          </>
          ) : (
            <IconButton
              aria-label="edit"
              color="primary"
              onClick={() => setIsEditMode(!isEditMode)}
            >
              <Edit />
            </IconButton>
          )}
        </Box>

        {isEditMode ? (
          <FormControl sx={{ marginBottom: '28px' }}>
            <Table className={cssClass.twoColumnDataTable}>
              <TableBody className={cssClass.customTableBody}>
                <TableRow>
                  <TableCell id="IsLetterApproval">Brief</TableCell>
                  <TableCell>
                    <FormBooleanSelect name="IsLetterApproval" label="" control={control} value={values.IsLetterApproval} noDefaultValue />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell id="IsEmailApproval" className={cssClass.shrinkCell}>Email</TableCell>
                  <TableCell>
                    <FormBooleanSelect name="IsEmailApproval" label="" control={control} value={values.IsEmailApproval} noDefaultValue />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell id="IsTelephoneApproval" className={cssClass.shrinkCell}>Telefon</TableCell>
                  <TableCell>
                    <FormBooleanSelect name="IsTelephoneApproval" label="" control={control} value={values.IsTelephoneApproval} noDefaultValue />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell id="IsMobileApproval" className={cssClass.shrinkCell}>Mobil</TableCell>
                  <TableCell>
                    <FormBooleanSelect name="IsMobileApproval" label="" control={control} value={values.IsMobileApproval} noDefaultValue />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell id="IsSMSApproval" className={cssClass.shrinkCell}>SMS</TableCell>
                  <TableCell>
                    <FormBooleanSelect name="IsSMSApproval" label="" control={control} value={values.IsSMSApproval} noDefaultValue />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell id="IsMessengerApproval" className={cssClass.shrinkCell}>Messenger</TableCell>
                  <TableCell>
                    <FormBooleanSelect name="IsMessengerApproval" label="" control={control} value={values.IsMessengerApproval} noDefaultValue />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell id="IsNewsletterApproval" className={cssClass.shrinkCell}>Newsletter</TableCell>
                  <TableCell>
                    <FormBooleanSelect name="IsNewsletterApproval" label="" control={control} value={values.IsNewsletterApproval} noDefaultValue />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell id="IsSocialMediaApproval" className={cssClass.shrinkCell}>Soziale Medien</TableCell>
                  <TableCell>
                    <FormBooleanSelect name="IsSocialMediaApproval" label="" control={control} value={values.IsSocialMediaApproval} noDefaultValue />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <BoxCenter className={cssClass.buttonsTableEdit}>
              <CustomButton
                value="Verwerfen"
                onClick={() => {
                  resetWarning();
                  reset(defaultValues);
                }}
                sx={{ width: '180px' }}
                light
                isClose
              />
              <CustomButton
                value="Speichern"
                onClick={() => {
                  saveCommunicationData(resetWarning);
                  setIsEditMode(false);
                }}
                sx={{ width: '180px' }}
              />
            </BoxCenter>
          </FormControl>
        ) : (
          <Table>
            <TableBody className={cssClass.customTableBody}>
              {tableRows?.map((row) => (showRow(row) &&
                <TableRow key={row.ID}>
                  <TableCell className={cssClass.shrinkCell}>{row.name}</TableCell>
                  <TableCell>{row.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Box>
    </Grid>
  );
}
