import React, { useState , useEffect, useContext} from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Messages } from '../../../../../constants/messages';
import Button from '@mui/material/Button';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  FormControl,
  Box,
  IconButton,
} from '@mui/material';

import { Edit, Close } from '@mui/icons-material/';

import CountySelect from '../../../../common-components/CountySelect';
import DistrictSelect from '../../../../common-components/DistrictSelect';
import BoxCenter from '../../../../common-components/BoxCenter';
import FormTextField from '../../../../common-components/FormTextField';
import { discrictList } from '../../../../common-components/DistrictSelect';
import { useSnackbarHelpers } from '../../../../../helpers/utils';
import { ContactPersonContext } from '../../PersonContext';
import CustomButton from '../../../../common-components/CustomButton';
import { useGlobalStyles } from '../../../../../customStyles';

import { useStyles } from './styles';

export const AddressDetails = ({
  insertAddressRegistration,
  updateAddressRegistration
}) => {
  const cssClass = useGlobalStyles();
  const classes = useStyles();
  const { showRow } = useContext(ContactPersonContext);
  const [isEditMode, setIsEditMode] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const { showErrorSnackbar } = useSnackbarHelpers();

  const { control } = useFormContext();
  const values = useWatch({ control });

  const disabled =
    !values?.Zipcode ||
    !values?.City ||
    !values?.Street ||
    !values?.Number;

    const createData = (name, value) => {
    return { name, value };
  };

  const mapCountry = (county) => {
    switch (county) {
      case 'W':
        return 'Wien';
      case 'N':
        return 'Niederösterreich';
      case 'O':
        return 'Oberösterreich';
      case 'S':
        return 'Salzburg';
      case 'B':
        return 'Burgenland';
      case 'T':
        return 'Tirol';
      case 'K':
        return 'Kärnten';
      case 'M' :
        return 'Steiermark';
      case 'V':
        return 'Voralberg';

      default:
        return null;
    }
  };

  var selectedDistirct = "";

  if(discrictList.split("|").length > 1){
      selectedDistirct=discrictList.split('|').filter((item) => {
      if (item.split(';')[1] == values?.FK_District) {
        return true;
      }
      return false;
    });
    selectedDistirct=(selectedDistirct[0] && selectedDistirct[0].split(';')[2] ? selectedDistirct[0].split(';')[2] : null);
  }

  const tableRows = [
    createData('Bundesland:', mapCountry(values?.County)),
    createData("Bezirk", selectedDistirct),
    createData('PLZ:', values?.Zipcode),
    createData('Stadt:', values?.City),
    createData('Strasse:', values?.Street),
    createData('Nummer:', values?.Number),
    createData('Breite:', values?.Latitude),
    createData('Länge:', values?.Longitude)
  ];

  useEffect(() => {
    if (isEditMode) {
      values.isModified = true;
    }
  }, [values]);

  const resetWarning = () => {
    values.isModified = false;
    setShowWarning(false);
    setIsEditMode(false);
  }

  return (
    <Box>
     <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: 2,
        }}
        >
          <Typography
            color="#732382"
            fontSize={18}
            fontWeight={500}
            py={2}
          >
            Adresse
          </Typography>

          {showWarning &&
              <Typography color="primary">{ Messages.WarningUnsafed }</Typography>}

          {isEditMode ? (
          <>
            <IconButton aria-label="close" onClick={() =>
              { if (values?.isModified)
                  setShowWarning(true);
                setIsEditMode(false)
              } }>
              <Close />
            </IconButton>
          </>
        ) : (
          <IconButton
            aria-label="edit"
            color="primary"
            onClick={() => setIsEditMode(!isEditMode)}
          >
            <Edit />
          </IconButton>
        )}
        </Box>
        {isEditMode ? (
          <FormControl fullWidth>
            <Table className={cssClass.twoColumnDataTable}>
              <TableBody className={cssClass.customTableBody}>
                <TableRow>
                  <TableCell className={cssClass.shrinkCell}>Bundesland</TableCell>
                  <TableCell>
                    <CountySelect
                      name="County"
                      control={control}
                      value={values.County}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={cssClass.shrinkCell}>Bezirk</TableCell>
                  <TableCell>
                    <DistrictSelect
                      name="FK_District"
                      control={control}
                      value={values.FK_District}
                      county={values.County}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={cssClass.shrinkCell}>PLZ</TableCell>
                  <TableCell>
                    <FormTextField
                      name="Zipcode"
                      label="PLZ"
                      control={control}
                      required
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={cssClass.shrinkCell}>Ort</TableCell>
                  <TableCell>
                    <FormTextField
                      name="City"
                      label="Ort"
                      control={control}
                      required
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={cssClass.shrinkCell}>Straße</TableCell>
                  <TableCell>
                    <FormTextField
                      name="Street"
                      label="Straße"
                      control={control}
                      required
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={cssClass.shrinkCell}>Nummer</TableCell>
                  <TableCell>
                    <FormTextField
                      name="Number"
                      label="Nummer"
                      control={control}
                      required
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={cssClass.shrinkCell}>Breite</TableCell>
                  <TableCell>
                    <FormTextField
                      name="Latitude"
                      label="Breite"
                      control={control}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={cssClass.shrinkCell}>Länge</TableCell>
                  <TableCell>
                    <FormTextField
                      name="Longitude"
                      label="Länge"
                      control={control}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          <BoxCenter sx={{ pb: '25px' }}>
            <CustomButton
              value="Verwerfen"
              onClick={() => {
                setIsEditMode(false);
              }}
              sx={{ width: '180px' }}
              light
              isClose
            />
            <CustomButton
              disabled={disabled}
              value="Speichern"
              onClick={() => {
                values.AddressRegistrationID
                  ? updateAddressRegistration(resetWarning)
                  : insertAddressRegistration(resetWarning);
              }}
              sx={{ width: '180px' }}
            />
          </BoxCenter>
        </FormControl> ) : (
        <Table>
         <TableBody>
          {tableRows?.map((row) => (showRow(row) &&
             <TableRow key={row.name}>
               <TableCell >
                 {row.name}
               </TableCell>
               <TableCell align="left">{row.value}</TableCell>
             </TableRow>
           ))}
         </TableBody>
       </Table>
      )}
    </Box>
  );
};