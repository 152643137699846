import { createUseStyles } from 'react-jss';

import { swvTheme } from '../../../swvTheme';

export const useStyles = createUseStyles({
  customButton: {
    justifyContent: 'space-between',
    paddingLeft: "1.5rem !important",
    paddingRight: "1.5rem !important",

    [swvTheme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },

  lightCustomButton: {
    backgroundColor: '#FFFFFF !important',
    color: swvTheme.palette.button.light,
    border: '1px solid',
    borderColor: swvTheme.palette.button.light,

    boxShadow: 'none',

    '&:hover': {
      color: swvTheme.palette.button.dark,
      borderColor: swvTheme.palette.button.dark,
      backgroundColor: swvTheme.palette.primary.white,
    },
  },

  darkCustomButton: {
    backgroundColor: swvTheme.palette.button.light,
    color: '#FFFFFF',

    '&:hover': {
      backgroundColor: swvTheme.palette.button.dark,
    },
  },
});
