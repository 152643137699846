import { Box, Button, Chip, FormControl, MenuItem } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React, { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useGlobalStyles } from '../../../customStyles';
import { IBusinessSubcategory } from '../../../interfaces/IBusinessSubcategory';
import { useGetListQuery } from '../../../redux/services/BusinessCategoryService';
import * as BusinessSubcategoryService from '../../../services/requests/businessSubcategory';
import BoxCenter from '../../common-components/BoxCenter';
import FormTextField from '../../common-components/FormTextField';
import ModalWrapper from '../../common-components/ModalWrapper/ModalWrapper';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import { swvTheme } from '../../../swvTheme';
import FormSelect from '../../common-components/FormSelect';
import CountySelect from '../../common-components/CountySelect';



interface IBusinessSubcategoryAssistant {
    isOpen: boolean,
    onClose: () => void,
    handleApply: (list: IBusinessSubcategory[]) => void,
}

export default function BusinessSubcategoryAssistantPopup({ isOpen, onClose, handleApply }: IBusinessSubcategoryAssistant) {
    const cssClass = useGlobalStyles();

    const { data: businessCategoryListResponse } = useGetListQuery(undefined, { skip: !isOpen });
    const [businessSubcategoryList, setBusinessSubcategoryList] = useState<IBusinessSubcategory[]>([]);
    const [selectedBusinessSubcategories, setSelectedBusinessSubcategories] = useState<IBusinessSubcategory[]>([]);

    const { control, reset } = useForm({
        defaultValues: {
            businessSubcategoryNumber: '',
            businessSubcategoryName: '',
            county: '',
            businessCategory: '',

        }
    });

    const values = useWatch({ control });

    useEffect(() => {
        if (isOpen) {
            reset({
                businessSubcategoryNumber: '',
                businessSubcategoryName: '',
                businessCategory: '',
            });
        } else {
            setSelectedBusinessSubcategories([]);
            setBusinessSubcategoryList([]);
        }
    }, [isOpen])

    const getBusinessSubcategoryList = async () => {
        await BusinessSubcategoryService.getList({
            number: values.businessSubcategoryNumber,
            name: values.businessSubcategoryName,
            county: values.county,
            categoryId: values.businessCategory
        }, setBusinessSubcategoryList);
    };

    const handleAddAll = () => {

        const newSelectedSubcategories = [...selectedBusinessSubcategories, ...businessSubcategoryList];

        let combinedList = [...new Set([...newSelectedSubcategories])]  //used to remove duplicate elements

        setSelectedBusinessSubcategories(combinedList);
    };

    const handleClearAll = () => {
        setSelectedBusinessSubcategories([]);
    };

    const handleListElementClick = (row: any) => {
        if (selectedBusinessSubcategories.some(subcategory => subcategory.ID === row.ID)) {

            setSelectedBusinessSubcategories(selectedBusinessSubcategories.filter(subcategory => subcategory.ID !== row.ID));
        } else {

            setSelectedBusinessSubcategories([...selectedBusinessSubcategories, row]);
        }
    }

    const handleChipDelete = (chipToDelete: IBusinessSubcategory) => {
        setSelectedBusinessSubcategories((chips) => chips ? chips.filter((chip) => chip.ID !== chipToDelete.ID) : []);
    };

    return (
        <ModalWrapper disableModalContainerScroll isOpen={isOpen} onClose={onClose} title='Fachgruppenassistent'>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <FormControl>
                    <FormSelect
                        label="Sparte"
                        name="businessCategory"
                        control={control}
                        value={values?.businessCategory || ''}
                    >
                        {businessCategoryListResponse?.data?.map((row: any) => {
                            return (
                                <MenuItem
                                    key={row.ID}
                                    value={row.ID}
                                >
                                    {row.Name}
                                </MenuItem>
                            );
                        })}
                    </FormSelect>
                    <CountySelect
                        name="county"
                        control={control}
                        value={values.county ?? ''}
                    />
                    <FormTextField
                        name="businessSubcategoryNumber"
                        label="Nummer"
                        control={control}
                    />
                    <FormTextField
                        name="businessSubcategoryName"
                        label="Name"
                        control={control}
                    />
                    <Box display='flex' justifyContent='flex-end'>
                    <Button
                        onClick={() => getBusinessSubcategoryList()}
                        disabled={!values?.businessSubcategoryNumber && !values?.businessSubcategoryName && !values.businessCategory && !values.county}
                    >
                        Suchen
                    </Button>
                </Box>
                <Box className={cssClass.listContainer}>
                    {businessSubcategoryList?.map((row: IBusinessSubcategory) =>
                        <span
                            key={row.ID}
                            className={`${cssClass.pointerCursor} ${selectedBusinessSubcategories?.some(subcategory => subcategory.ID === row.ID) ? cssClass.chosen : cssClass.notchosen}`}
                            onClick={() => handleListElementClick(row)}
                            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                        >
                            {row.County}{row.Number}, {row.Name}
                            <CheckOutlinedIcon fontSize='small' style={{ color: '#732382', visibility: selectedBusinessSubcategories?.some(subcategory => subcategory.ID === row.ID) ? 'visible' : 'hidden' }} />
                        </span>)}
                </Box>
                <Box display='flex' justifyContent='space-between' marginBottom={0.5}>
                    <Button onClick={handleAddAll} disabled={businessSubcategoryList.length <= 0}>
                        Alle hinzufügen
                    </Button>
                    <Button onClick={handleClearAll} disabled={selectedBusinessSubcategories.length <= 0}>
                        Liste leeren
                    </Button>
                </Box>
                <Box height={'80px'} overflow={'auto'}>
                    {selectedBusinessSubcategories?.map((row: IBusinessSubcategory) => (
                        <Chip
                            label={`${row.County}${row.Number}`}
                            key={row.ID}
                            onDelete={() => handleChipDelete(row)}
                            variant='outlined'
                            // sx={{maxWidth: '40px'}}
                            style={{ color: '#732382', borderColor: '#732382' }}
                            deleteIcon={<CancelIcon style={{ color: '#732382' }} />}
                        />
                    ))}
                </Box>
            </FormControl>
            <BoxCenter>
                <Button
                    onClick={() => handleApply(selectedBusinessSubcategories)}
                    disabled={selectedBusinessSubcategories.length === 0}
                >
                    Übernehmen
                </Button>
            </BoxCenter>
        </LocalizationProvider>
        </ModalWrapper >
    )
}