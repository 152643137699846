import React, { useContext, useEffect, useState } from 'react';

import { useStyles } from './styles';
import { useGlobalStyles } from '../../../customStyles';
import { Box, Slide, useMediaQuery } from '@mui/material';

import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import HomeIcon from '@mui/icons-material/Home';
import {
  disableScroll,
  getHeaderHeight,
  reenableScroll,
} from '../../../helpers/utils';

export default function NavMenu({ children, isOpen, setIsOpen }) {
  const classes = useStyles();
  const cssClass = useGlobalStyles();

  const navbarId = 'navbarMenu';

  const [neededContainerHeight, setNeededContainerHeight] = useState('100svh');

  const [checkForOuterClick, setCheckForOuterClick] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setCheckForOuterClick(true)
      disableScroll();
    } else {
      setCheckForOuterClick(false)
      reenableScroll();
    }
  }, [isOpen]);

  function neededContainerHeightPull() {
    return window.innerHeight - getHeaderHeight();
  }

  useEffect(() => {
    setNeededContainerHeight(neededContainerHeightPull() + 'px');
  }, []);

  addEventListener('resize', () => {
    setNeededContainerHeight(neededContainerHeightPull() + 'px');
  });

  addEventListener('mouseup', closeOnClickOutside)
  function closeOnClickOutside() {
    if (!checkForOuterClick) {
      return;
    }

    const navBar = document.getElementById(navbarId)

    const currentlyOverNavbar = navBar
      ? navBar.matches(':hover')
      : false

    setIsOpen(currentlyOverNavbar)
  }
  const isMobile = useMediaQuery("(max-width: 850px)")

  return (
    <>
      {isMobile ?
        (<Slide
          sx={{
            height: neededContainerHeight,
          }}
          direction={'right'}
          in={isMobile ? isOpen : true}
          mountOnEnter
        >
          <Box
            className={classes.navbarMenuContainer}
            id={navbarId}
            sx={{
              height: neededContainerHeight
            }}
          >
            <Box className={classes.navbarMenu}>{children}</Box>
          </Box>
        </Slide>)
        :
        (<Box
          className={classes.navbarMenuContainer}
          id={navbarId}
          sx={{
            boxShadow: "4px 0px -2px 2px gray",
            height: neededContainerHeight,
          }}
        >
          <Box className={classes.navbarMenu}>{children}</Box>
        </Box>)
      }
    </>
  );
}
