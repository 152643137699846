import { createUseStyles } from 'react-jss';
import { swvTheme } from '../../../swvTheme';

export const useStyles = createUseStyles({
    navbarContainer: {
        position: 'fixed',
        boxShadow: `none`
    },
    shadow: {
        boxShadow: `0px 6px 19px 1px rgba(0, 0, 0, 0.64)`
}

});
