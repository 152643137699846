import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { Box, Button } from '@mui/material';

import Webcam from 'react-webcam';

import { PopupModes } from '../../constants';
import { transformBase64StringToFile } from '../../../../../helpers';
import { DecorativeCorners } from '../../../../common-components/DecorativeCorners';

import { styles } from './styles';

export const WebcamImageCapture = ({ onFilesSelected, setPhotoUploadMode }) => {
  const webcamRef = useRef(null);

  const onCapture = () => {
    const base64Image = webcamRef.current.getScreenshot();
    const file = transformBase64StringToFile(base64Image);

    onFilesSelected([file]);
  };

  const [videoConstraints, setvideoConstraints] = useState({
    
    facingMode: "user"
  })
  

  return (
    <Box sx={styles.rootContainer}>
      <DecorativeCorners sx={styles.decorativeCorners}>
      <Webcam
        mirrored
        style={{
          maxWidth: '838px',
          width: '100%',
        }}
        videoConstraints={videoConstraints}
        screenshotFormat="image/jpeg"
        ref={webcamRef}
      />
      </DecorativeCorners>
      <Box sx={styles.buttonContainer}>
        <Button
          variant="outlined"
          sx={styles.secondaryActionButton}
          onClick={() => {
            setPhotoUploadMode(PopupModes.FILE_SELECTION);
          }}
        >
          Abbrechen
        </Button>
        <Button sx={styles.captureButton} onClick={onCapture}>
          Aufnehmen
        </Button>
        <Button  sx={styles.captureButton}  onClick={
          () => {
            setvideoConstraints({
          
              facingMode: videoConstraints.facingMode === "user" ? "environment" : "user"
            })
          }
        }> 
          <svg style={{fill: '#fff'}} xmlns="http://www.w3.org/2000/svg" 
          viewBox="0 0 32 32" width="25" height="25">
            <path  d="M16,17a3,3,0,1,1,3-3A3,3,0,0,1,16,17Zm0-4a1,1,0,1,0,1,1A1,1,0,0,0,16,13Z"/><path d="M27,15.182V8a1,1,0,0,0-1-1H19.781L18.97,3.758A1,1,0,0,0,18,3H14a1,1,0,0,0-.97.758L12.219,7H6A1,1,0,0,0,5,8v7.182C2.424,16.445,1,18.136,1,20c0,3.618,5.308,6.421,12.671,6.915l-.378.378a1,1,0,1,0,1.414,1.414l2-2a1,1,0,0,0,0-1.414l-2-2a1,1,0,0,0-1.414,1.414l.191.191C7.2,24.4,3,22.1,3,20c0-.868.715-1.763,2-2.552V20a1,1,0,0,0,1,1H26a1,1,0,0,0,1-1V17.448c1.285.789,2,1.684,2,2.552a1,1,0,0,0,2,0C31,18.136,29.576,16.445,27,15.182ZM14.781,5h2.438l.5,2H14.281ZM7,19V9H25V19Z"/></svg>
        </Button>
      </Box>
    </Box>
  );
};

WebcamImageCapture.propTypes = {
  onFilesSelected: PropTypes.func.isRequired,
  setPhotoUploadMode: PropTypes.func.isRequired,
};
