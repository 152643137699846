import React from 'react';
import Box from '@mui/material/Box';
import { useStyles } from './styles';

const FilterTop = (props) => {
  const classes = useStyles()

  return (
    <Box className={classes.filterBox}>
      {props.children}
    </Box>
  );
};

export default FilterTop;
