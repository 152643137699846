import { Button, Table, TableBody, TableCell, TableFooter, TableHead, TableRow } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import CustomFormSelect from "../../../common-components/CustomFormSelect"
import { useGlobalStyles } from "../../../../customStyles";
import { UserContext } from "../../../pages/User/UserContext";
import { useForm, useWatch } from "react-hook-form";
import { useGetGeneralPermittedCountiesForUserQuery, useGetGeneralUserPermissionsDistrictsByUserIdQuery, useInsertGeneralUserPermissionsDistrictMutation } from "../../../../redux/services/UserPermissionsService";
import { countiesWithValues } from "../../../../constants/counties";
import { useGetAllDistrictsQuery } from "../../../../redux/services/DistrictService";
import { IDistrict } from "../../../../interfaces/IDistrict";

interface IPermittedCountiesForUser {
    value: string
    name: string
}

export default function GeneralPermissionsDistrictTable() {

    const cssClass = useGlobalStyles();

    const { currentUser } = useContext(UserContext);

    const [insertDistrictPermissions] = useInsertGeneralUserPermissionsDistrictMutation();

    const { data: generalPermittedCounties } = useGetGeneralPermittedCountiesForUserQuery(currentUser ? currentUser.ID : 0);
    const { data: permittedDistricts } = useGetGeneralUserPermissionsDistrictsByUserIdQuery(currentUser ? currentUser.ID : 0);
    const { data: districts } = useGetAllDistrictsQuery();

    const [permittedCounties, setPermittedCounties] = useState<IPermittedCountiesForUser[]>([]);
    const [filteredDistricts, setFilteredDistricts] = useState<IDistrict[]>([]);

    const { control, reset, setValue } = useForm({
        defaultValues: {
            County: ''
        }
    })
    const values = useWatch({ control })

    useEffect(() => {
        setPermittedCounties(generalPermittedCounties ? countiesWithValues.filter(countyWithValue => generalPermittedCounties.includes(countyWithValue.value)) : []);
    }, [generalPermittedCounties])

    const handleSave = async () => {
        const data = {
            UserId: currentUser.ID,
            DistrictIds: [...selectedDistrictsForUser]
        }
        const result = await insertDistrictPermissions(data).unwrap();
    }

    useEffect(() => {
        setFilteredDistricts(districts ? districts.filter(district => district.County === values.County) : []);
    }, [values.County, districts])

    const [selectedDistrictsForUser, setSelectedDistrictsForUser] = useState<number[]>([]);

    useEffect(() => {
        setSelectedDistrictsForUser(permittedDistricts ? permittedDistricts.map(entry => entry.DistrictId) : []);
    }, [permittedDistricts])

    const handleCheckboxChange = (id: number) => {
        const index = selectedDistrictsForUser.indexOf(id);

        if (index === -1) {
            setSelectedDistrictsForUser((old) => [...old, id]);
        }
        else {
            const newArr = [...selectedDistrictsForUser];
            newArr.splice(index, 1);
            setSelectedDistrictsForUser(newArr);
        }
    }

    const checkboxIsChecked = (id: number) => {
        return selectedDistrictsForUser.includes(id);
    }

    return (
        <>
            <CustomFormSelect name="County" label="Bundesland" valueKey="value" titleKey="name" options={permittedCounties} control={control} />
            <Table stickyHeader>
                <TableHead>
                    <TableCell></TableCell>
                    <TableCell>Bezirksname</TableCell>
                    <TableCell>Bezirksnummer</TableCell>
                    <TableCell>Kennzeichen</TableCell>
                </TableHead>
                <TableBody>
                    {filteredDistricts.map((district, index) => (
                        <TableRow key={index} onClick={() => handleCheckboxChange(district.ID)}>
                            <TableCell><input className={cssClass.checkboxScaled} type="checkbox" onChange={() => handleCheckboxChange(district.ID)} checked={checkboxIsChecked(district.ID)} /></TableCell>
                            <TableCell>{district.Name}</TableCell>
                            <TableCell>{district.Number}</TableCell>
                            <TableCell>{district.Licenseplate}</TableCell>
                        </TableRow>
                    ))}

                </TableBody>
                <TableFooter style={{ position: 'sticky', bottom: 0, width: "100%" }}>
                    <TableRow className={cssClass.defaultCursor} style={{ backgroundColor: '#f5f5f5' }} >
                        <TableCell colSpan={11}>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button onClick={handleSave}>Speichern</Button>
                            </div>
                        </TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
        </>
    )
}