import React, { useCallback, useContext, useEffect } from 'react';

import TableContainer from '@mui/material/TableContainer';
import Button from '@mui/material/Button';
import { Edit, DeleteForever } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';

import { CompanyContext } from '../../../../pages/Company/CompanyContext';
import { CompanyLocationContext } from '../../CompanyLocationContext';
import * as BusinessRegistrationService from '../../../../../services/requests/businessRegistration';

import { useStyles } from './styles';
import { useGlobalStyles } from '../../../../../customStyles';

import { getStatus, getDate } from '../../../../../helpers/database';

import DeleteCell from '../../../../common-components/DeleteCell';
import BoxCenter from '../../../../common-components/BoxCenter';
import { Box, IconButton, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { swvTheme } from '../../../../../swvTheme';

export default function BusinessRegistrationList() {
  const classes = useStyles();
  const cssClass = useGlobalStyles();

  const { companyData, getBusinessRegistrationListCompany } = useContext(CompanyContext);

  const {
    setCurrentTab,
    currentAddressRegistration,
    businessRegistrationList,
    currentBusinessRegistration,
    setCurrentBusinessRegistration,
    getBusinessRegistrationList,
    } = useContext(CompanyLocationContext);

  useEffect(() => {
    if (companyData?.ID && currentAddressRegistration) {
      getBusinessRegistrationList();
    }
  }, [currentAddressRegistration]);

  const deleteBusinessRegistration = async (id) => {
    return await BusinessRegistrationService.deleteOne(id, () => {
      getBusinessRegistrationList();
      getBusinessRegistrationListCompany();
    });
  }

  const handleRowClick = (row) => {
    setCurrentBusinessRegistration(row);
    setCurrentTab(2);
  }

  const handleNewClick = () => {
    setCurrentBusinessRegistration(null);
    setCurrentTab(2);
  }

  return (
    <>
      <Typography variant={"h1"} py={2}>Gewerbeanmeldungen</Typography>
      <TableContainer
      >
        {businessRegistrationList?.filter(o =>
          o.AddressRegistration?.ID === currentAddressRegistration?.ID)?.map((row) => (
            <Table key={row.ID}>
              <TableBody className={cssClass.customTableBody}>
                <TableRow>
                  <TableCell colSpan={2}>
                    <strong>{row.Business.BusinessSubcategory.Number}</strong>, {row.Business.BusinessSubcategory.Name}
                  </TableCell>
                  <TableCell align="right" sx={{ paddingLeft: '0 !important', maxWidth: 'none', width: '44px' }}>
                    <IconButton
                      color="primary"
                      onClick={() => deleteBusinessRegistration(row.ID)}
                    >
                      <DeleteForever />
                    </IconButton>
                    <IconButton
                      color="primary"
                      onClick={() => handleRowClick(row)}
                    >
                      <Edit />
                    </IconButton>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={cssClass.shrinkCell}>Start</TableCell>
                  <TableCell colSpan={2}>{getDate(row.Start)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={cssClass.shrinkCell}>Ende</TableCell>
                  <TableCell colSpan={2}>{getDate(row.End)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={cssClass.shrinkCell}>Status</TableCell>
                  <TableCell colSpan={2}>{getStatus(row.Status)}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            ))}
      </TableContainer>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 2,
          justifyContent: 'flex-end',
          px: 2,
          pb: 2,
          pt: 1,
          cursor: 'pointer',
        }}
      >
        <AddIcon sx={{color: swvTheme.palette.primary.main}} onClick={() => handleNewClick()} />
      </Box>
    </>
  );
}