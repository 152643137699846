import React, { useContext, useEffect, useState } from 'react';

import { useGlobalStyles } from '../../../../../customStyles';
import { useStyles } from './styles';
import { CompanyContext } from '../../CompanyContext';
import { chooseCounty, chooseEmployees, getBoolean, getDate } from '../../../../../helpers/database';
import { useForm, useWatch } from 'react-hook-form';
import { Box, FormControl, Grid, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Close, Edit } from '@mui/icons-material/';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import FormTextField from '../../../../common-components/FormTextField';
import FormBooleanSelect from '../../../../common-components/FormBooleanSelect';
import TableContainer from '@mui/material/TableContainer';
import CustomButton from '../../../../common-components/CustomButton';
import BoxCenter from '../../../../common-components/BoxCenter';
import FormDatePicker from '../../../../common-components/FormDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import FormSelect from '../../../../common-components/FormSelect';
import MenuItem from '@mui/material/MenuItem';
import CountySelect from '../../../../common-components/CountySelect';
import { swvTheme } from '../../../../../swvTheme';
import { Messages } from '../../../../../constants/messages';
import DataItemActionsPopup from '../../../../popups/DataItemActions';

export default function BaseDataTables({ onCompanySave }) {
  const cssClass = useGlobalStyles();
  const classes = useStyles();

  const [isEditMode, setIsEditMode] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [isDataItemActionsPopupOpen, setIsDataItemActionsPopupOpen] = useState(false);
  const [chosenDataItem, setChosenDataItem] = useState(null);
  const { companyData, setUpdatedCompanyFields, showRow } = useContext(CompanyContext);

  const createData = (name, value) => {
    return { name, value };
  };

  const tableRows = [
    createData('Name:', companyData.Name),
    createData('Form:', companyData.CompanyForm?.Abbreviation),
    createData('WK-Nummer:', companyData.WKO),
    createData('Gründung:', getDate(companyData.Founding)),
    createData('Kammer:', chooseCounty(companyData.County)),
    createData('Firmenbuch:', companyData.Commercialregister),
    createData('ZVR:', companyData.ZVR),
    createData('MitarbeiterInnen:', chooseEmployees(companyData.Employees)),
    createData('Großunternehmen:', getBoolean(companyData.IsLarge)),
    createData('Gemeinde:', companyData.Municipality?.Name),
  ];

  const { control, reset } = useForm({
    defaultValues: {
      Name: '',
      Abbreviation: '',
      WKO: '',
      Founding: '',
      County: '',
      Commercialregister: '',
      ZVR: '',
      Employees: '',
      IsLarge: false,
      Municipality: '',
    },
  });

  const values = useWatch({ control });

  setUpdatedCompanyFields(values);

  const defaultValues = {
    Name: companyData.Name ?? '',
    Abbreviation: companyData.CompanyForm?.Abbreviation ?? '',
    WKO: companyData.WKO ?? '',
    Founding: companyData.Founding ?? '',
    County: companyData.County ?? '',
    Commercialregister: companyData.Commercialregister ?? '',
    ZVR: companyData.ZVR ?? '',
    Employees: companyData.Employees ?? '',
    IsLarge: companyData.IsLarge ?? false,
    Municipality: companyData.Municipality?.Name ?? '',
  };

  useEffect(() => {
    if (companyData) {
      reset(defaultValues);
    }
  }, [companyData]);

  useEffect(() => {
    if (isEditMode) {
      values.isModified = true;
    }
  }, [values]);

  useEffect(() => {
    if (isEditMode) {
      for (const value in values) {
        if (values[value] !== defaultValues[value] && (value !== "isModified")) {
          document.getElementById(value).className += " modifiedValue";
        }
      }
    }
  }, [isEditMode]);

  useEffect(() => {
    let delay;

    document.querySelectorAll('.baseItem').forEach((item) => {
      const isNotEmpty = item.getElementsByTagName("td")[1].innerText;

      if (isNotEmpty) {
        item.addEventListener('touchstart', () => {
          delay = setTimeout(check, 1500);

          function check() {
            setIsDataItemActionsPopupOpen(true);
          }
        }, true);

        item.addEventListener('touchend', () => {
          clearTimeout(delay);
        });
      }
    });
  });

  const resetWarning = () => {
    values.isModified = false;
    setShowWarning(false);
    setIsEditMode(false);
  }

  const onDataItemActionsPopupClose = () => {
    setIsDataItemActionsPopupOpen(false);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid xs={12} mt={3}>
        <Box
          sx={{
            backgroundColor: '#FFFFFF',
            borderRadius: '8px',
            boxShadow: swvTheme.boxShadow,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              p: 2,
            }}
          >
            <Typography color={swvTheme.typography.lilac} fontSize={18} fontWeight={500}>
              Stammdaten
            </Typography>

            {showWarning &&
              <Typography color="primary">{ Messages.WarningUnsafed }</Typography>}

            {isEditMode ? (
            <>
              <IconButton aria-label="close" onClick={() =>
                { if (values?.isModified)
                    setShowWarning(true);
                    setIsEditMode(false)
                } }>
                <Close />
              </IconButton>
            </>
            ) : (
              <IconButton
                aria-label="edit"
                color="primary"
                onClick={() => setIsEditMode(!isEditMode)}
              >
                <Edit />
              </IconButton>
            )}
          </Box>
          {isEditMode ? (
            <FormControl sx={{ marginBottom: '28px' }}>
              <Table className={cssClass.twoColumnDataTable}>
                <TableBody className={cssClass.customTableBody}>
                  <TableRow>
                    <TableCell id="Name" className={cssClass.shrinkCell}>Name:</TableCell>
                    <TableCell>
                      <FormTextField name="Name" label="" control={control} value={values.Name} noDefaultValue />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell id="Abbreviation" className={cssClass.shrinkCell}>Form:</TableCell>
                    <TableCell>
                      <FormSelect name="Abbreviation" label="" control={control} value={values.Abbreviation}>
                        <MenuItem value="np EU">nicht protokollierter Einzelunternehmer</MenuItem>
                        <MenuItem value="EU">Einzelunternehmer</MenuItem>
                        <MenuItem value="OHG">Offene Handelsgesellschaft</MenuItem>
                        <MenuItem value="KG">Kommanditgesellschaft</MenuItem>
                        <MenuItem value="GesmbH">Gesellschaft mit beschränkter Haftung</MenuItem>
                        <MenuItem value="AG">Aktiengesellschaft</MenuItem>
                        <MenuItem value="GK">Gebietskörperschaft</MenuItem>
                        <MenuItem value="e.V.">Verein</MenuItem>
                        <MenuItem value="EWG">Erwerbs- u. Wirtschaftsgenossenschaften</MenuItem>
                        <MenuItem value="Sonst.">Sonstige Rechtsform</MenuItem>
                        <MenuItem value="JP">(Nicht) prot. Unternehmen jurist. Person</MenuItem>
                        <MenuItem value="OEG">Offene Erwerbsgesellschaften</MenuItem>
                        <MenuItem value="KEG">Kommandit-Erwerbsgesellschaften</MenuItem>
                        <MenuItem value="Ausl.">Ausländische Rechtsform</MenuItem>
                        <MenuItem value="Anst.">Anstalt</MenuItem>
                        <MenuItem value="IV">Europäische wirtschaftliche Interessenvereinigung</MenuItem>
                        <MenuItem value="Fonds">Fonds</MenuItem>
                        <MenuItem value="PP">Politische Partei</MenuItem>
                        <MenuItem value="Priv. Stift.">Privatstiftung</MenuItem>
                        <MenuItem value="Rel.">Kirchen und gesetzlich anerkannte Religionsgesellschaften</MenuItem>
                        <MenuItem value="Spk">Sparkasse</MenuItem>
                        <MenuItem value="Stift.">Stiftung</MenuItem>
                        <MenuItem value="SV">Sozialversicherungsträger</MenuItem>
                        <MenuItem value="VVG">Versicherungsverein auf Gegenseitigkeit</MenuItem>
                        <MenuItem value="Verl.">Verlassenschaft</MenuItem>
                        <MenuItem value="EUG">Europäische Gesellschaft</MenuItem>
                        <MenuItem value="EUGen.">Europäische Genossenschaft</MenuItem>
                        <MenuItem value="OG">Offene Gesellschaft</MenuItem>
                        <MenuItem value="Unbek">Unbekannt</MenuItem>
                      </FormSelect>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell id="WKO" className={cssClass.shrinkCell}>WK-Nummer:</TableCell>
                    <TableCell>
                      <FormTextField name="WKO" label="" control={control} value={values.WKO} noDefaultValue />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell id="Founding" className={cssClass.shrinkCell}>Gründung:</TableCell>
                    <TableCell>
                      <FormDatePicker name="Founding" label="" control={control} value={values.Founding} noDefaultValue />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell id="County" className={cssClass.shrinkCell}>Kammer:</TableCell>
                    <TableCell>
                      <CountySelect name="County" label="" control={control} value={values.County} noDefaultValue />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell id="Commercialregister" className={cssClass.shrinkCell}>Firmenbuch:</TableCell>
                    <TableCell>
                      <FormTextField name="Commercialregister" label="" control={control} value={values.Commercialregister} noDefaultValue />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell id="ZVR" className={cssClass.shrinkCell}>ZVR:</TableCell>
                    <TableCell>
                      <FormTextField name="ZVR" label="" control={control} value={values.ZVR} noDefaultValue />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell id="Employees" className={cssClass.shrinkCell}>MitarbeiterInnen:</TableCell>
                    <TableCell>
                      <FormSelect name="Employees" label="" control={control} value={values.Employees}>
                        <MenuItem value="0">0</MenuItem>
                        <MenuItem value="1">1 - 25</MenuItem>
                        <MenuItem value="26">26 - 500</MenuItem>
                        <MenuItem value="500">500+</MenuItem>
                      </FormSelect>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <BoxCenter className={cssClass.buttonsTableEdit}>
                <CustomButton
                  value="Verwerfen"
                  onClick={() => {
                    resetWarning();
                    reset(defaultValues);
                  }}
                  sx={{ width: '180px' }}
                  light
                  isClose
                />
                <CustomButton
                  value="Speichern"
                  onClick={() => {
                    setIsEditMode(false);
                    onCompanySave(values, resetWarning);
                  }}
                  sx={{ width: '180px' }}
                />
              </BoxCenter>
            </FormControl>
          ) : (
            <Table>
              <TableBody className={cssClass.customTableBody}>
                {tableRows?.map((row) => (showRow(row) &&
                  <TableRow key={row.ID} className='baseItem' onTouchStart={() => setChosenDataItem(row)}>
                    <TableCell className={cssClass.shrinkCell}>{row.name}</TableCell>
                    <TableCell>{row.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </Box>
      </Grid>

      <DataItemActionsPopup
        isOpen={isDataItemActionsPopupOpen}
        onClose={onDataItemActionsPopupClose}
        chosenDataItem={chosenDataItem}
      />
    </LocalizationProvider>
  );
}
