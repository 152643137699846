import React, { useEffect, useState } from 'react';
import { MenuItem, SxProps } from '@mui/material';
import FormSelect from '../FormSelect';
import { useStyles } from './styles';
import { useGlobalStyles } from '../../../customStyles';
import { Control, UseFormRegister } from 'react-hook-form';

export default function CustomFormSelect(
  {
    name,
    label,
    control,
    value,
    required,
    disabled,
    noAsterisk,
    onClick,
    sx,
    options,
    valueKey,
    titleKey,
    register,
    validationSchema,
    errors,
    defaultValue,
    multiple,
    chooseAll,
    fullWidth
  }
    :
    {
      name: string
      label?: string
      control: Control<any>
      value?: any
      required?: boolean
      disabled?: boolean
      noAsterisk?: boolean
      onClick?: any
      sx?: SxProps
      options: Array<any>
      valueKey: string
      titleKey: string
      register?: UseFormRegister<any>
      validationSchema?: any //https://react-hook-form.com/docs/useform/register
      errors?: any
      defaultValue?: any
      multiple?: boolean
      chooseAll?: boolean
      fullWidth?: boolean
    }
) {
  const classes = useStyles();
  const cssGlobalClass = useGlobalStyles();

  //*Bringt die übergebenen Auswahlmöglichkeiten in das Format, das für das Auswhlfeld notwendig ist.
  const transformOptions = (options: any, valueKey: string, titleKey: string) => {
    return options?.map((option: any) => ({
      value: option[valueKey],
      title: option[titleKey]
    }))
  }

  const [copiedOptions, setCopiedOptions] = useState(transformOptions(options, valueKey, titleKey))
  //*Falls sich die options ändern, so sollen diese im Feld aktualisiert werden
  useEffect(() => {
    setCopiedOptions(transformOptions(options, valueKey, titleKey))
  }, [options])

  console.log("OPTIONS", options)

  return (
    <FormSelect
      name={name}
      value={value}
      disabled={disabled}
      label={label}
      control={control}
      defaultValue={defaultValue}
      onClick={onClick}
      required={required}
      noAsterisk={noAsterisk}
      sx={sx}
      errors={errors}
      //@ts-ignore
      validationSchema={validationSchema}
      register={register}
      options={options}
      valueKey={valueKey}
      multiple={multiple}
      chooseAll={chooseAll ?? false}
      fullWidth={fullWidth}
    >
      {copiedOptions?.map((element: any) => {
        return <MenuItem value={element.value} key={element.value}>{element.title}</MenuItem>
      })}
    </FormSelect>
  );
}
