import React, { useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';

import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import * as CompanyService from '../../../services/requests/company';

import { useSnackbarHelpers, questionPopup } from '../../../helpers/utils';
import { Messages } from '../../../constants/messages';

import FormSelect from '../../common-components/FormSelect';
import CountySelect from '../../common-components/CountySelect';
import ModalWrapper from '../../common-components/ModalWrapper';
import BoxCenter from '../../common-components/BoxCenter';
import FormTextField from '../../common-components/FormTextField';
import CustomButton from '../../common-components/CustomButton';

import { goTo } from '../../../router/routerHistory';

//import FullwidthDatePicker from '../../common-components/FullwidthDatePicker';

import { useStyles } from './styles';
import { Message } from '@mui/icons-material';

export default function CompanyPopUp({ isOpen, onClose }) {
  const classes = useStyles();
  const { showErrorSnackbar, showInfoSnackbar } = useSnackbarHelpers();

  const { control, reset } = useForm({
    defaultValues: {
      Name: '',
      Abbreviation: '',
      WKO: '',
      Founding: '',
      County: '',
      Commercialregister: '',
      ZVR: '',
      Employees: '',
    },
  });

  const values = useWatch({ control });

  const onCompanyDataSave = async () => {
    const companyData = {
      Name: values?.Name,
      Employees: values?.Employees,
      Founding: values?.Founding,
      Abbreviation: values?.Abbreviation,
      WKO: values?.WKO,
      County: values?.County,
      Commercialregister: values?.Commercialregister,
      ZVR: values?.ZVR,
    };

    await CompanyService.checkCompanyWKO(values.WKO, async (data) => {
      if (data) {
        const result = await CompanyService.insertOne(companyData)

        if (result.isSuccess) {
          if (result.data) {
            if (await questionPopup(Messages.NavigateToCompanyPage)) {
              goTo(`/firma/${result.data.UniqueWKO}`);
            }
          } else {
            showInfoSnackbar(Messages.ChangeProposalCreated)
          }
        } else {
          showErrorSnackbar(Messages.Error);
        }
        onClose();
      }
    }, () => {
      showErrorSnackbar(Messages.WKOInUse);
    });
  };

  const disabled =
    !values?.Name ||
    !values?.Abbreviation ||
    !values?.WKO ||
    !values?.County;

  useEffect(() => {
    if (!isOpen) {
      reset({
        Name: '',
        Abbreviation: '',
        WKO: '',
        Founding: '',
        County: '',
        Commercialregister: '',
        ZVR: '',
        Employees: '',
      });
    }
  }, [isOpen]);

  return (
    <ModalWrapper isOpen={isOpen} onClose={onClose} title="Firma anlegen">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <FormControl>
          <FormTextField
            name="Name"
            label="Name"
            control={control}
            required
          />
          <FormSelect
            required
            label="Form"
            name="Abbreviation"
            control={control}
            value={values?.Abbreviation || ''}
          >
            <MenuItem value="np EU">nicht protokollierter Einzelunternehmer</MenuItem>
            <MenuItem value="EU">Einzelunternehmer</MenuItem>
            <MenuItem value="OHG">Offene Handelsgesellschaft</MenuItem>
            <MenuItem value="KG">Kommanditgesellschaft</MenuItem>
            <MenuItem value="GesmbH">Gesellschaft mit beschränkter Haftung</MenuItem>
            <MenuItem value="AG">Aktiengesellschaft</MenuItem>
            <MenuItem value="GK">Gebietskörperschaft</MenuItem>
            <MenuItem value="e.V.">Verein</MenuItem>
            <MenuItem value="EWG">Erwerbs- u. Wirtschaftsgenossenschaften</MenuItem>
            <MenuItem value="Sonst.">Sonstige Rechtsform</MenuItem>
            <MenuItem value="JP">(Nicht) prot. Unternehmen jurist. Person</MenuItem>
            <MenuItem value="OEG">Offene Erwerbsgesellschaften</MenuItem>
            <MenuItem value="KEG">Kommandit-Erwerbsgesellschaften</MenuItem>
            <MenuItem value="Ausl.">Ausländische Rechtsform</MenuItem>
            <MenuItem value="Anst.">Anstalt</MenuItem>
            <MenuItem value="IV">Europäische wirtschaftliche Interessenvereinigung</MenuItem>
            <MenuItem value="Fonds">Fonds</MenuItem>
            <MenuItem value="PP">Politische Partei</MenuItem>
            <MenuItem value="Priv. Stift.">Privatstiftung</MenuItem>
            <MenuItem value="Rel.">Kirchen und gesetzlich anerkannte Religionsgesellschaften</MenuItem>
            <MenuItem value="Spk">Sparkasse</MenuItem>
            <MenuItem value="Stift.">Stiftung</MenuItem>
            <MenuItem value="SV">Sozialversicherungsträger</MenuItem>
            <MenuItem value="VVG">Versicherungsverein auf Gegenseitigkeit</MenuItem>
            <MenuItem value="Verl.">Verlassenschaft</MenuItem>
            <MenuItem value="EUG">Europäische Gesellschaft</MenuItem>
            <MenuItem value="EUGen.">Europäische Genossenschaft</MenuItem>
            <MenuItem value="OG">Offene Gesellschaft</MenuItem>
            <MenuItem value="Unbek">Unbekannt</MenuItem>
          </FormSelect>
          <FormTextField
            name="WKO"
            label="WK-Nummer"
            control={control}
            required
          />
          <FormTextField
            name="Founding"
            label="Gründung"
            control={control}
          />
          <CountySelect
            required
            label="Kammer"
            name="County"
            control={control}
            value={values?.County}
          />
          <FormTextField
            name="Commercialregister"
            label="Firmenbuch"
            control={control}
          />
          <FormTextField
            name="ZVR"
            label="ZVR"
            control={control}
          />
          <FormSelect
            name="Employees"
            label="MitarbeiterInnen"
            control={control}
            value={values.Employees}
          >
            <MenuItem value="0">0</MenuItem>
            <MenuItem value="1">1 - 25</MenuItem>
            <MenuItem value="26">26 - 500</MenuItem>
            <MenuItem value="500">500+</MenuItem>
          </FormSelect>
        </FormControl>
      </LocalizationProvider>
      <BoxCenter>
        <CustomButton
          value="Speichern"
          onClick={() => onCompanyDataSave()}
          disabled={disabled}
          sx={{ width: '180px' }}
        />
      </BoxCenter>

    </ModalWrapper>
  );
}