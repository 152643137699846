import Spinner from '../components/common-components/Spinner';
import swal from 'sweetalert';
import { useSnackbar } from 'notistack';
import { logDOM } from '@testing-library/react';
import * as endpoints from '../constants/endpoints';

var enqueueSnackbarEx;

export function showInfoSnackbar(message) {
  // return enqueueSnackbarEx(message, {
  //   variant: 'info',
  //   anchorOrigin: {
  //     vertical: 'top',
  //     horizontal: 'right',
  //   },
  // });
}

export function showErrorSnackbar(message) {
  // return enqueueSnackbarEx(message, {
  //   variant: 'error',
  //   anchorOrigin: {
  //     vertical: 'top',
  //     horizontal: 'right',
  //   },
  // });
}

export function showExceptionSnackbar(e, message) {
  // return enqueueSnackbarEx(message ?? 'Ein Fehler ist aufgetreten', {
  //   variant: 'error',
  //   anchorOrigin: {
  //     vertical: 'top',
  //     horizontal: 'right',
  //   },
  // });
}

export const useSnackbarHelpers = () => {
  const { enqueueSnackbar } = useSnackbar();

  enqueueSnackbarEx = enqueueSnackbar;

  const showExceptionSnackbar = (e, string) => {
    return enqueueSnackbar(string ?? 'Ein Fehler ist aufgetreten', {
      variant: 'error',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
    });
  };

  const showSuccessSnackbar = (string) => {
    return enqueueSnackbar(string, {
      variant: 'success',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
    });
  }

  const showErrorSnackbar = (string) => {
    return enqueueSnackbar(string, {
      variant: 'error',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
    });
  };

  const showInfoSnackbar = (string) => {
    return enqueueSnackbar(string, {
      variant: 'info',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
    });
  };

  return { showExceptionSnackbar, showErrorSnackbar, showInfoSnackbar, showSuccessSnackbar };
};

export const openSpinner = async () => {
  return await swal({
    title: 'Daten werden geladen',
    text: 'Bitte um ein wenig Geduld',
    icon: 'info',
    buttons: false,
    dangerMode: false,
    closeOnEsc: false,
    allowOutsideClick: false,
    // heightAuto: false,
    // position: 'top-start',
    // grow: 'fullscreen',
    // background: '#ffffffaa',
  });
};

export const closeSpinner = () => {
  swal.close();
};

export const deletePopup = async () => {
  return await swal({
    title: 'Datensatz löschen',
    text: 'Kann nicht rückgängig gemacht werden',
    icon: 'warning',
    buttons: true,
    dangerMode: true,
  });
};

export const questionPopup = async (text) => {
  return await swal({
    title: 'Bitte bestätigen',
    text: text ?? 'Kann nicht rückgängig gemacht werden',
    icon: 'warning',
    buttons: true,
    dangerMode: true,
  });
};

export const informationPopup = async (text) => {
  return await swal({
    title: 'Hinweis',
    text: text,
    icon: 'info',
    buttons: {
      cancel: false,
      confirm: true,
    },
  });
};

export const tailString = (string, length = 80) => {
  return string?.length > length ? (string.slice(0, length) + '...') : string;
};

export function downloadFile(bytes, filename) {
  const file = new File([new Uint8Array(Object.values(bytes))], 'filename', { type: 'mimeType' })
  const url = URL.createObjectURL(file);
  const link = document.createElement('a');
  link.download = filename;
  link.href = url;
  link.click();
  link.remove();
}

export function disableScroll() {
  // Get the current scroll position
  const scrollY = window.scrollY;

  // Save the current scroll position as a negative margin on the body
  document.body.style.marginTop = `-${scrollY}px`;

  // Prevent scrolling by setting overflow hidden on the body
  document.body.style.overflow = 'hidden';
}

// Enable scrolling
export function reenableScroll() {
  // Remove the negative margin on the body
  const scrollY = parseInt(document.body.style.marginTop || '0', 10);

  document.body.style.marginTop = '';

  // Restore the overflow property to its original value
  document.body.style.overflow = '';

  // Scroll to the original position
  window.scrollTo(0, scrollY);
}

export function getHeaderHeight() {
  const headerElements = document.getElementsByTagName('header');
  const header = headerElements.length > 0 ? headerElements[0] : false;

  return header ? header.clientHeight : 0
}

export function ensureHttps(link) {
  const protocolRegex = /(http:\/\/|https:\/\/)/;
  return "https://" + link.replace(protocolRegex, '')
}

export async function urlIsLoadable(imageSrc, setURL, options = {}) {
  const { timeout = 3000 } = options;

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);

  try {
    const response = await fetch(imageSrc, {
      ...options,
      signal: controller.signal
    });

    clearTimeout(id);

    setURL(response.status === 200 ? imageSrc : null);

    return response.status === 200;
  } catch (e) {
    console.log(e);
    return false
  }
}

export function generateUrlFromImageReference(imageReference) {
  if (!imageReference.ID) {
    return '';
  }

  const imageReferenceId = imageReference.ID;

  return (endpoints.apiUrlEx + endpoints.getPhotoSrc + '?id=' + imageReferenceId + '&date=' + Date.now());
}