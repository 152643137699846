import { createWorker } from 'tesseract.js';

//Regex pattern for recognizing Emails
const patternEmail = /[\w-\.]+@([\w-]+\.)+[\w-]{2,4}/g;

//Regex pattern for recognizing phone numbers
const patternTelefonnummer =
  /(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{1,3}\)?[\s.-]?\d{1,3}?[\s.-]?\d{1,3}?[\s.-]?\d{1,3}?[\s.-]?\d{1,3}[\s.-]?\d{1,4}/g;

//Regex pattern for recognizing the city
const patternStadt =
  /(\d{4,5})(\s)([A-zöüäß]{3,})(\s)([A-zöüäß]{2})(\s)([A-zöüäß]{3,})|(\d{4,5})(\s)([A-zöüäß]{3,})/g;

//Regex pattern for recognizing the street
const patternStrasse =
  /([A-zöüäß-]{3,}\s\d{1,4})|([A-zöüäß]{3,}\s[A-zöüäß]{3,}\s\d{1,4})/g;

//Regex pattern for the name of the person
const patternName = /[A-Z][a-z'-]+(?: [A-Z][a-z'-]+)*/;

// TODO: find usage for classes
class ProcessVisitenkarteProps {
  /** @type string */
  imageUri = '';
  /** @type Dispatch<SetStateAction<number>> */
  setProgress = null;
}

// TODO: find usage for classes
class ScanVisitenkartenButtonProps {
  /** @type RefObject<HTMLInputElement> */
  inputRef = null;
  /** @type Dispatch<SetStateAction<string>> */
  setImageData = null;
}

function generatePermutations(inputArray) {
  const result = [];

  function permute(arr, permuted) {
    if (!permuted) {
      permuted = [];
    }
    if (permuted.length >= 2) {
      result.push(permuted.join(' '));
    }
    if (arr.length === 0) {
      return;
    }

    for (let i = 0; i < arr.length; i++) {
      const rest = arr.slice(0, i).concat(arr.slice(i + 1));
      permute(rest, permuted.concat(arr[i]));
    }
  }

  permute(inputArray);

  return result;
}

function findSubstringIgnoreCase(mainString, searchString) {
  //Convert both the main string and the search string to lowercase (or uppercase)
  const mainStringLower = mainString.toLowerCase();
  const searchStringLower = searchString.toLowerCase();

  // Use indexOf() to find the lowercase substring
  const indexOfSubstring = mainStringLower.indexOf(searchStringLower);

  if (indexOfSubstring !== -1) {
    // Use the index to extract the original substring with the original casing
    const matchedSubstring = mainString.substring(
      indexOfSubstring,
      indexOfSubstring + searchString.length,
    );

    return matchedSubstring;
  } else {
    // Return null or handle the case when the substring is not found
    return null;
  }
}

//Matches the pattern for the name and saves it to the Object
function addNames(visitenkarteObj, text) {
  visitenkarteObj.firstname = '';
  visitenkarteObj.lastname = '';
  if (visitenkarteObj.email) {
    let email = visitenkarteObj.email[0];

    const emailText = email.replace(/[._%+-@]/g, ' '); // Remove special characters
    const emailStrings = emailText.split(/\s+/);
    emailStrings.pop(); //get rid of tld

    //const combinationStrings = wordPermutations.map((permutation: string) => permutation.join(' '));
    let potentialNames = generatePermutations(emailStrings); // Remove empty spaces
    let potentialName = '';

    potentialNames.forEach((name) => {
      const matchedSubstring = findSubstringIgnoreCase(text, name);

      if (matchedSubstring && matchedSubstring.length > potentialName.length) {
        potentialName = matchedSubstring;
      }
    });

    if (potentialName) {
      let strings = potentialName.split(' ');
      visitenkarteObj.firstname = '';
      visitenkarteObj.lastname = '';
      strings.forEach((string, index) => {
        if (index < strings.length - 1) {
          visitenkarteObj.firstname += string;
        } else {
          visitenkarteObj.lastname = string;
        }
      });
    }
    //this will be extended in the future
  } else {
    const matches = text.match(patternName);
    if (matches) {
      let nameParts = matches[0].split(' ');
      visitenkarteObj.firstname = nameParts[0];
      visitenkarteObj.lastname = nameParts[1];
    }
  }
}

const findMail = (visitenkarteObj, text) => {
  const matches = text.match(patternEmail);
  if (matches) {
    visitenkarteObj.email = matches;
  }
};

//Matches the pattern for the phone number and saves it to the Object
const findTelefonnummer = (visitenkarteObj, text) => {
  const matches = text.match(patternTelefonnummer);
  if (matches) {
    visitenkarteObj.telephone = matches;
  }
};

//Matches the pattern for the address (city and street) and saves it to the Object
const findAdresse = (visitenkarteObj, text) => {
  const matchStadt = text.match(patternStadt);
  if (matchStadt) {
    let stadtParts = matchStadt[0].split(' ');
    visitenkarteObj.postalcode = parseInt(stadtParts[0]);
    visitenkarteObj.city = stadtParts[1];
  }

  const matchStrasse = text.match(patternStrasse);
  if (matchStrasse) {
    let strasseParts = matchStrasse[0].split(' ');

    visitenkarteObj.housenumber = parseInt(
      strasseParts[strasseParts.length - 1],
    );

    visitenkarteObj.street =
      strasseParts.length === 2
        ? strasseParts[0]
        : strasseParts[0].concat(' ').concat(strasseParts[1]);
  }
};

export function optimizeImage(imageUri) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = imageUri;

    image.onload = () => {
      const canvas = document.createElement('canvas');
      const maxWidth = 1920;
      const maxHeight = 1080;

      let width = image.width;
      let height = image.height;

      if (width > maxWidth || height > maxHeight) {
        if (width / maxWidth > height / maxHeight) {
          height *= maxWidth / width;
          width = maxWidth;
        } else {
          width *= maxHeight / height;
          height = maxHeight;
        }
      }

      canvas.width = width;
      canvas.height = height;

      const ctx = canvas.getContext('2d');
      ctx?.drawImage(image, 0, 0, width, height);

      const downscaledImageUrl = canvas.toDataURL('image/jpeg', 0.6);

      // Get the downscaled file size
      fetch(downscaledImageUrl)
        .then((response) => response.blob())
        .then((blob) => {
          resolve(downscaledImageUrl);
        })
        .catch((error) => {
          reject(error);
        });
    };
  });
}

//Generell function for processing the Visitenkarte
export const processVisitenkarte = async (imageUri, setProgress) => {
  const worker = createWorker({
    logger: (m) => {
      if (setProgress && m.status === 'recognizing text') {
        const progress = Math.round(m.progress * 100);
        setProgress(progress);
      }
    },
  });

  await worker.load();
  await worker.loadLanguage('deu');
  await worker.initialize('deu');
  await worker.setParameters({});

  const {
    data: { text }, // text is the text that tesseract recognizes
  } = await worker.recognize(imageUri);

  //create new Visitenkarte Object
  let visitenkarteObj = {};

  function generatePermutations(emailStrings) {
    return undefined;
  }

  //Matches the pattern for the EMail and saves it to the Object

  //All the functions get called here for extracting the infos
  addNames(visitenkarteObj, text);
  findMail(visitenkarteObj, text);
  findTelefonnummer(visitenkarteObj, text);
  findAdresse(visitenkarteObj, text);

  return visitenkarteObj;
};
