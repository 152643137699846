export const styles = {
  dragAndDropContainer: {
    p: {
      xs: '25px 8px',
      sm: '40px 40px 80px'
    }
  },
  dragOverOverlay: {
    background: 'rgba(217, 217, 217, 0.5)',
    borderRadius: '38px',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1,
  },
};
