import { createUseStyles } from 'react-jss';
import { swvTheme } from '../../../swvTheme';

export const useStyles = createUseStyles({
	filterBox: {
		display: 'flex',

		flexDirection: 'column',

		[swvTheme.breakpoints.up('md')]: {
			flexDirection: 'row',
			justifyContent: 'center',
		},

		gap: '10px',
		paddingBottom: "1rem",

		'& button': {
			mt: 1,
			mb: 1,
		},

		'& button:first-of-type': {
			ml: 2,
		},
	}
});