import React, { useState, useCallback } from 'react';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Container from '@mui/material/Container';

import { a11yProps } from '../../common-components/TabPanel/tabsProps';
import TabPanel from '../../common-components/TabPanel';
import SectionHeading from '../../common-components/SectionHeading';

import Navbar from '../Navbar';

import OrganisationList from './components/OrganisationList';
import OrganisationDetails from './components/OrganisationDetails';
import PanelList from './components/PanelList';
import BusinessGroupList from './components/BusinessGroupList';
import BusinessGroupDetails from './components/BusinessGroupDetails';
import FunctionList from './components/FunctionList';
import FunctionDetails from './components/FunctionDetails';

import * as OrganisationService from '../../../services/requests/organisation';
import * as PanelService from '../../../services/requests/panel';
import * as BusinessGroupService from '../../../services/requests/businessGroup';
import * as FunctionService from '../../../services/requests/function';

import { AdminOrganisationContext } from './AdminOrganisationContext';
import PanelDetailsMember from './components/PanelDetailsMember/PanelDetailsMember';
import PanelDetails from './components/PanelDetails';

export default function AdminOrganisation() {
  const [currentTab, setCurrentTab] = useState(0);
  const [organisationList, setOrganisationList] = useState(null);
  const [panelList, setPanelList] = useState(null);
  const [businessGroupList, setBusinessGroupList] = useState(null);
  const [functionList, setFunctionList] = useState(null);
  const [chosenOrganisation, setChosenOrganisation] = useState(null);
  const [chosenPanel, setChosenPanel] = useState(null);
  const [chosenBusinessGroup, setChosenBusinessGroup] = useState(null);
  const [chosenFunction, setChosenFunction] = useState(null);
  const [businessGroupCount, setBusinessGroupCount] = useState(1);


  const handleTabChange = (_, newValue) => {
    setCurrentTab(newValue);
  };

  const getOrganisationList = useCallback(
    async (data) => {
      await OrganisationService.getList(
        {
          name: data?.name,
          type: data?.type,
        },
        setOrganisationList,
      );
    },
    [organisationList],
  );

  const getPanelList = useCallback(
    async (query) => {
      await PanelService.getList(query, setPanelList);
    },
    [panelList],
  );

  const getBusinessGroupList = useCallback(
    async ({ name, county, limit, page }) => {
      await BusinessGroupService.getList(
        { name, county, limit, page },
        (data, count) => {
          setBusinessGroupList(data);
          setBusinessGroupCount(count);
        },
      );
    },
    [businessGroupList],
  );

  const getFunctionList = useCallback(
    async (data) => {
      await FunctionService.getList(
        {
          name: data?.name,
          isAll: data?.isAll || false,
        },
        setFunctionList,
      );
    },
    [functionList],
  );

  return (
    <AdminOrganisationContext.Provider
      value={{
        setCurrentTab,
        getOrganisationList,
        organisationList,
        setOrganisationList,
        chosenOrganisation,
        setChosenOrganisation,

        getPanelList,
        panelList,
        setPanelList,
        chosenPanel,
        setChosenPanel,

        getBusinessGroupList,
        businessGroupList,
        businessGroupCount,
        setBusinessGroupCount,
        chosenBusinessGroup,
        setChosenBusinessGroup,

        getFunctionList,
        functionList,
        chosenFunction,
        setChosenFunction,
      }}
    >
      <SectionHeading title="Organisationen etc." />
      <Container maxWidth="xl">
        <Tabs value={currentTab} onChange={handleTabChange}>
          <Tab label="Organisationen" {...a11yProps(0)} />
          <Tab
            label="Organisation"
            {...a11yProps(1)}
            disabled={!chosenOrganisation}
          />
          <Tab
            label="Gremien"
            {...a11yProps(2)}
            disabled={!chosenOrganisation?.ID}
          />
          <Tab
            label="Gremium"
            {...a11yProps(3)}
            disabled={!chosenOrganisation?.ID || !chosenPanel}
          />
          <Tab
            label="Gremium-Mitglieder"
            {...a11yProps(4)}
            disabled={!chosenOrganisation?.ID || !chosenPanel}
          />
          {/*<Tab
            label="Berufsgruppen"
            {...a11yProps(4)}
            disabled={!chosenOrganisation?.ID}
          />
          <Tab
            label="Berufsgruppe"
            {...a11yProps(5)}
            disabled={!chosenOrganisation?.ID || !chosenBusinessGroup}
          />
          <Tab
            label="Funktionen"
            {...a11yProps(6)}
            disabled={!chosenOrganisation?.ID}
          />
          <Tab
            label="Funktion"
            {...a11yProps(7)}
            disabled={!chosenOrganisation?.ID || !chosenFunction}
    />*/}
        </Tabs>
      </Container>

      <TabPanel value={currentTab} index={0}>
        <OrganisationList />
      </TabPanel>

      <TabPanel value={currentTab} index={1}>
        <OrganisationDetails />
      </TabPanel>

      <TabPanel value={currentTab} index={2}>
        <PanelList />
      </TabPanel>

      <TabPanel value={currentTab} index={3}>
        <PanelDetails />
      </TabPanel>

      <TabPanel value={currentTab} index={4}>
        <PanelDetailsMember />
      </TabPanel>

      {/*<TabPanel value={currentTab} index={4}>
        <BusinessGroupList />
      </TabPanel>

      <TabPanel value={currentTab} index={5}>
        <BusinessGroupDetails />
      </TabPanel>

      <TabPanel value={currentTab} index={6}>
        <FunctionList />
      </TabPanel>

      <TabPanel value={currentTab} index={7}>
        <FunctionDetails />
    </TabPanel>*/}
    </AdminOrganisationContext.Provider>
  );
}
