import React, { useEffect, useState } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';

import * as UserService from '../../../services/requests/user';

import { useSnackbarHelpers } from '../../../helpers/utils';

import Navbar from '../Navbar';
import UserPopUp from '../../popups/User';
import CheckCell from '../../common-components/CheckCell';
import DeleteCell from '../../common-components/DeleteCell';
import SectionHeading from '../../common-components/SectionHeading';

import { chooseCounty, getPerson } from '../../../helpers/database';
import { UserContext } from './UserContext';
import { useGlobalStyles } from '../../../customStyles';
import { Messages } from '../../../constants/messages';
import BoxCenter from '../../common-components/BoxCenter';
import UserPermissionPopUp from '../../popups/UserPermissions/UserPermissions';
import { getUserRoleName } from '../../../helpers/helperFunctions';

export default function User() {
  const cssClass = useGlobalStyles();
  const [userList, setUserList] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userPermissionModalOpen, setUserPermissionModalOpen] = useState(false);

  const { showErrorSnackbar, showSuccessSnackbar } = useSnackbarHelpers();

  async function getAllUsers() {
    await UserService.getList(null, setUserList);
  }

  useEffect(() => {
    setCurrentUser(null);
    getAllUsers();
  }, []);

  const onRowClicked = (row) => {
    setCurrentUser(row);
    setIsModalOpen(true);
  };

  const onDeleteClicked = async (id) => {
    return await UserService.deleteOne(id, () => {
      if (currentUser?.ID == id) {
        setCurrentUser(null);
      }
      getAllUsers();
    });
  };

  const onNewClicked = () => {
    setCurrentUser(null);
    setIsModalOpen(true);
  };

  // UserPopUp

  const onInviteClicked = async (user) => {
    if (user.IsActive) {
      const result = await UserService.inviteOne(user.ID);
      if(result.isSuccess) {
        showSuccessSnackbar(Messages.Invited);
      }
      else {
        showErrorSnackbar(Messages.Error);
      }
    }
    else {
      showErrorSnackbar(Messages.ErrorUserInactive);
    }
  }

  const onSaveClicked = async (user) => {
    const success = async (data) => {
      setIsModalOpen(false);
      await getAllUsers();
      setCurrentUser(data);
    }

    if (currentUser) {
      await UserService.updateOne(user, success);
    } else {
      await UserService.insertOne(user, success);
    }
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleManagePermissionClicked = () => {
    setIsModalOpen(false);
    setUserPermissionModalOpen(true);
  }

  const handlePermissionModalClose = () => {
    setUserPermissionModalOpen(false);
    setIsModalOpen(true);
  }

  return (
    <UserContext.Provider
      value={{
        currentUser,
      }}>
      <Container maxWidth="xl">
        <Box
          sx={{
            position: 'relative',
            padding: '77px 0 44px',
          }}
        >
          <SectionHeading title="Benutzerverwaltung" sx={{ margin: '0 auto' }} />
        </Box>
        <TableContainer>
          <Table sx={{ minWidth: 450 }}>
            <TableHead>
              <TableRow>
                <TableCell className={cssClass.mediumCell}>
                  Benutzername
                </TableCell>
                <TableCell className={cssClass.largeCell}>Email</TableCell>
                <TableCell className={cssClass.mediumCell}>Name</TableCell>
                <TableCell className={cssClass.userRoleCell + " " + cssClass.centeredCellText}>Rolle</TableCell>
                <TableCell className={cssClass.smallCell}>Bundesland</TableCell>
                <TableCell className={cssClass.btnCell}>Admin</TableCell>
                <TableCell className={cssClass.btnCell}>Aktiv</TableCell>
                <TableCell className={cssClass.btnCell}>Löschen</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userList?.map((row) => (
                <TableRow
                  key={row.ID}
                  selected={currentUser?.ID == row.ID}
                  onClick={() => onRowClicked(row)}
                >
                  <TableCell>{row.Username}</TableCell>
                  <TableCell>{row.Email}</TableCell>
                  <TableCell>{getPerson(row)}</TableCell>
                  <TableCell className={cssClass.centeredCellText}>{getUserRoleName(row.UserRole)}</TableCell>
                  <TableCell>{chooseCounty(row.County)}</TableCell>
                  <CheckCell flag={row.IsAdmin} />
                  <CheckCell flag={row.IsActive} />
                  <DeleteCell func={onDeleteClicked} id={row.ID} />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <BoxCenter sx={{ marginBottom: "1rem" }}>
          <Button
            onClick={() => onNewClicked()}
          >
            Benutzer Erstellen
          </Button>
        </BoxCenter>
      </Container>

      <UserPopUp
        isOpen={isModalOpen}
        onClose={handleClose}
        onInviteClicked={onInviteClicked}
        onSaveClicked={onSaveClicked}
        onManagePermissionClicked={() => handleManagePermissionClicked()}
      />
      <UserPermissionPopUp
        isOpen={userPermissionModalOpen}
        onClose={handlePermissionModalClose}
      />
    </UserContext.Provider>
  );
};