import * as endpoints from '../../constants/endpoints';
import { apiClient } from '../apiClient';

export const getList = async (query, success, error) => {
  const response = await apiClient.getListNew(endpoints.getForm, null, query, success, error);
  return response.data;
};

export const getOne = async (id, success, error, query) => {
  const response = await apiClient.getOneNew(endpoints.getForm, id, success, error, query);
  return response.data;
};

export const updateOne = async (data, success, error) => {
  const response = await apiClient.updateOne(endpoints.updateForm, data, success, error);
  return response.data;
};

export const insertOne = async (data, success, error) => {
  const response = await apiClient.insertOne(endpoints.insertForm, data, success, error);
  return response.data;
};

export const deleteOne = async (id, success, error) => {
  const response = await apiClient.deleteOne(endpoints.deleteForm, id, success, error);
  return response.data;
}
