export const transformFileToBase64 = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = async function () {
      resolve(reader.result);
    };
  });
};
