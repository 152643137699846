import React, { useCallback, useState, useContext, useEffect } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';

import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Container from '@mui/material/Container';

import { useSnackbarHelpers, deletePopup } from '../../../../../helpers/utils';

import * as OrganisationService from '../../../../../services/requests/organisation';
import { AdminOrganisationContext } from '../../AdminOrganisationContext';

import DeleteCell from '../../../../common-components/DeleteCell';
import CheckCell from '../../../../common-components/CheckCell';
import FilterTop from '../../../../common-components/FilterTop';
import FormTextField from '../../../../common-components/FormTextField';

import { useStyles } from './styles';
import { useGlobalStyles } from '../../../../../customStyles';

export default function OrganisationList() {
  const classes = useStyles();
  const cssClass = useGlobalStyles();
  const { } = useSnackbarHelpers();

  const {
    setCurrentTab,
    getOrganisationList,
    organisationList,
    setChosenOrganisation,
    setPanelList,
    setChosenPanel,
    chosenOrganisation,
  } = useContext(AdminOrganisationContext);

  const { control } = useForm({
    defaultValues: {
      name: '',
      type: '',
    },
  });

  const values = useWatch({ control });

  const onRowClick = (row) => {
    setChosenOrganisation(row);
    setPanelList(null);
    setChosenPanel(null);
    setCurrentTab(1);
  };

  const deleteOrganisation = async (id) => {
    return await OrganisationService.deleteOne(id, () => {
      if (chosenOrganisation?.ID == id) {
        setChosenOrganisation(null);
      }
      getOrganisationList();
    });
  };

  const onNewButtonClick = () => {
    setChosenOrganisation({ Name: values.name });
    setCurrentTab(1);
  };

  useEffect(() => {
    getOrganisationList(values)
  },[])

  return (
    <Container maxWidth="lg">
      <FormControl>
        <FilterTop>
          <FormTextField
            name="name"
            label="Name"
            control={control}
          />
          <FormTextField
            name="type"
            label="Typ"
            control={control}
          />
          <Button
            onClick={() => getOrganisationList(values)}
          >
            Suchen
          </Button>
          <Button
            onClick={() => onNewButtonClick()}
          >
            Neu
          </Button>
        </FilterTop>

        <TableContainer
          sx={{
            maxHeight: '55vh',
            overflowY: 'auto',
            overflowX: 'hidden',
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Typ</TableCell>
                <TableCell className={cssClass.btnCell}>Familie</TableCell>
                <TableCell className={cssClass.btnCell}>Löschen</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {organisationList?.map((row) => (
                <TableRow
                  key={row.ID}
                  selected={chosenOrganisation?.ID == row.ID}
                  onClick={() => onRowClick(row)}
                >
                  <TableCell>{row.Name}</TableCell>
                  <TableCell>{row.Type}</TableCell>
                  <CheckCell flag={row.IsFamily} />
                  <DeleteCell func={deleteOrganisation} id={row.ID} />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </FormControl>
    </Container>
  );
}