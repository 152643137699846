import React, { useCallback, useContext, useEffect } from 'react';
import { useForm, useWatch, Controller } from 'react-hook-form';

import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';

import * as OrganisationService from '../../../../../services/requests/organisation';

import { useSnackbarHelpers } from '../../../../../helpers/utils';

import FormBooleanSelect from '../../../../common-components/FormBooleanSelect';
import FormTextField from '../../../../common-components/FormTextField';
import CountySelect from '../../../../common-components/CountySelect';
import BoxCenter from '../../../../common-components/BoxCenter';
import { AdminElectionProposalContext } from '../../AdminElectionProposalContext';

import { useStyles } from './styles';
import CustomFormSelect from '../../../../common-components/CustomFormSelect/CustomFormSelect';
import { Messages } from '../../../../../constants/messages';
import { counties } from '../../../../../constants/counties';
import { useUpdateElectionProposalMutation } from '../../../../../redux/services/ElectionProposalService';
import { CircularProgress } from '@mui/material';


export default function ElectionProposalDetails() {
  const classes = useStyles();
  const { chosenElectionProposal, getOrganisationList, setChosenElectionProposal, setCurrentTab } = useContext<any>(AdminElectionProposalContext);


  type Inputs = {
    ID: number
    title: string,
    electionYear: number,
    county: string,
  }

  const { register, handleSubmit, control, reset, getValues, setValue, formState: { errors } } = useForm<Inputs>({
    defaultValues: {
      ID: 0,
      electionYear: 2025,
      county: "",
      title: ""
    },
    values: {
      ID: chosenElectionProposal?.ID,
      electionYear: chosenElectionProposal?.ElectionYear,
      county: chosenElectionProposal?.County,
      title: chosenElectionProposal?.Title
    }
  });

  const [updateElectionProposal, { error: createError, isLoading: isCreating }] = useUpdateElectionProposalMutation();


  return (
    <Container maxWidth="sm">
      <FormControl>
        <FormTextField
          label="Titel"
          name="title"
          control={control}
          errors={errors}
          required
          value={chosenElectionProposal.title}
          //@ts-ignore
          validationSchema={{
            required: Messages.ErrorTitle
          }}
        />
        <FormTextField
          label="Wahljahr"
          readOnly={true}
          control={control}
          name="electionYear"
          required
        />
        {/*@ts-ignore*/}
        <CustomFormSelect
          name="county"
          label="Bundesland"
          disabled={false}
          value={chosenElectionProposal.county}
          options={counties}
          valueKey='name'
          titleKey='name'
          required
          control={control}
          errors={errors}
          validationSchema={{
            required: Messages.ErrorCounty
          }}
        />
        <Button
          sx={{ height: "3.5em", marginTop: "0.5em" }}
          onClick={handleSubmit((d) => updateElectionProposal(d).then(() => { setCurrentTab(0) }))}
        >
          {isCreating ? <><CircularProgress /></> : <>Speichern</>}
        </Button>
      </FormControl>
    </Container>
  );
}
